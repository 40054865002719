import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import AddNewForm from '../forms/AddNewForm';
import moment from 'moment';
import { usePondDODailyRecord } from '../../../hooks/usePondDODailyRecord';

const AddNewModal = (props) => {

    const { visible, selectedPond, setVisible, setRefresh } = props;

    const [form] = Form.useForm();

    const { create } = usePondDODailyRecord();

    const [submitting, setSubmitting] = useState(false);

    const handleOk = () => {
        form.validateFields().then((values, err) => {
            if (!err) {
                console.log(values);
                setSubmitting(true);
                
                const params = {
                    recordTimestamp: moment(values.recordTimestamp).format(),
                    temperature: values.temperature,
                    do: values.do,
                    pondActivityId: selectedPond.id,
                    pondId: selectedPond.pondId
                }

                create(params).then(res => {
                    if (res.status === 'success') {
                        setRefresh(true)
                        setVisible(false)
                        form.resetFields();
                    }
                    setSubmitting(false)
                })
            }
        })
    }


    const handleCancel = () => {
        setVisible(false);
    }

    return (
        <Modal
            title={ 'New Pond DO Daily Record (' + selectedPond.Pond?.label + ')'}
            destroyOnClose
            visible={visible}
            confirmLoading={submitting}
            onOk={handleOk}
            okText='Submit'
            onCancel={handleCancel}>

            <AddNewForm
                form={form}
            />

        </Modal>
    );
}

export default AddNewModal