import React, { useState, useEffect } from "react";
import ReactFlow, { Handle } from "react-flow-renderer";
import moment from "moment";

const mapWidth = window.innerWidth / 2 - 20;
const mapHeight = window.innerHeight - 170;

const SourceNodeComponent = ({ data }) => {
  return (
    <div
      style={{
        padding: 10,
        borderRadius: 3,
        width: 150,
        fontSize: 12,
        color: "#222",
        textAlign: "center",
        borderWidth: 1,
        borderStyle: "solid",
        background: "#fff",
        borderColor: "#0041d0",
      }}
    >
      <Handle type="source" position="right" id={"s_" + data.id} />
      <div>{data.label}</div>
      <div style={{ fontSize: 8 }}>{data.id ? "ID: " + data.id : data.hatchDate}</div>
    </div>
  );
};

const EndNodeComponent = ({ data }) => {
  return (
    <div
      style={{
        padding: 10,
        borderRadius: 3,
        width: 150,
        fontSize: 12,
        color: "#222",
        textAlign: "center",
        borderWidth: 1,
        borderStyle: "solid",
        background: "#fff",
        borderColor: "#ff0072",
      }}
    >
      <Handle type="target" position="left" />
      <div>{data.label}</div>
      <div style={{ fontSize: 8 }}>{data.timestamp}</div>
    </div>
  );
};

const TransferMap = (props) => {
  const { sourceNodes, currentNode } = props;

  const [sources, setSources] = useState([]);
  const [current, setCurrent] = useState([]);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const sourceNodesLength = sourceNodes.length ? sourceNodes.length : 0;

    if (sourceNodesLength) {
      setSources(
        sourceNodes.map((item, index) => {
          let offsetY = mapHeight / sourceNodesLength / 2 - 25;

          return {
            id: "s_" + item.sourceTankUsageId,
            data: {
              label: item.label,
              id: item.sourceTankUsageId,
            },
            type: "source",
            sourcePosition: "right",
            position: { x: 0, y: (index * mapHeight) / sourceNodesLength + offsetY },
          };
        })
      );
    } else {
      setSources([
        {
          id: "s_0",
          data: {
            label: "Larval Hatch",
            hatchDate: moment(currentNode.hatchDate).format("YYYY-MM-DD"),
          },
          type: "source",
          sourcePosition: "right",
          position: { x: 0, y: mapHeight / 2 - 25 },
        },
      ]);
    }
  }, [sourceNodes, currentNode.hatchDate]);

  useEffect(() => {
    setCurrent([
      {
        id: "t_" + currentNode.id,
        data: {
          label: currentNode.Tank.label,
          timestamp: moment(currentNode.stockInTimestamp).format("YYYY-MM-DD"),
        },
        type: "end",
        targetPosition: "left",
        position: { x: mapWidth / 2, y: mapHeight / 2 - 25 },
      },
    ]);
  }, [currentNode]);

  useEffect(() => {
    if (sources.length && current.length) {
      let array = [];
      for (const item of current) {
        for (const source of sources) {
          array.push({
            id: source.id + "_" + item.id,
            source: source.id,
            target: item.id,
            animated: false,
            type: "smoothstep",
          });
        }
      }

      setLinks(array);
    }
  }, [sources, current]);

  return (
    <div style={{ width: mapWidth, height: mapHeight }}>
      <ReactFlow
        nodesDraggable={false}
        paneMoveable={false}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        nodeTypes={{
          source: SourceNodeComponent,
          end: EndNodeComponent,
        }}
        elements={[...sources, ...current, ...links]}
      />
    </div>
  );
};

export default TransferMap;
