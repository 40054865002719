import React, { useState, useEffect, useContext } from "react";
import { Drawer, Tabs } from "antd";
import { useLarvalDataReport } from "../../../hooks/useLarvalDataReport";
import BroodstockInfo from "../components/BroodstockInfo";
import IncubationInfo from "../components/IncubationInfo";
import LarvalInfo from "../components/LarvalInfo";
import HarvestInfo from "../components/HarvestInfo";
import TransferInfo from "../components/TransferInfo";
import { ResponsiveContext } from "../../../App";

const { TabPane } = Tabs;

const DetailDrawer = (props) => {
  const { item, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findBroodstockData, findIncubationData, findLarvalData, findHarvestData, findHatcheryTransferData } =
    useLarvalDataReport();

  const [loading, setLoading] = useState(false);
  const [broodstockInfo, setBroodstockInfo] = useState([]);
  const [incubationInfo, setIncubationInfo] = useState([]);
  const [larvalInfo, setLarvalInfo] = useState([]);
  const [harvestInfo, setHarvestInfo] = useState([]);
  const [transferInfo, setTransferInfo] = useState([]);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      findBroodstockData({ batchNo: item.batchNo }).then((res) => {
        if (res.status === "success") {
          setBroodstockInfo(res.data);
        }
      });
      findIncubationData({ batchNo: item.batchNo }).then((res) => {
        if (res.status === "success") {
          setIncubationInfo(res.data);
        }
      });
      findLarvalData({ batchNo: item.batchNo }).then((res) => {
        if (res.status === "success") {
          setLarvalInfo(res.data);
        }
      });
      findHarvestData({ batchNo: item.batchNo }).then((res) => {
        if (res.status === "success") {
          setHarvestInfo(res.data);
        }
        setLoading(false);
      });
      findHatcheryTransferData({ batchNo: item.batchNo }).then((res) => {
        if (res.status === "success") {
          setTransferInfo(res.data);
        }
      });
    }
  }, [
    visible,
    findBroodstockData,
    findHarvestData,
    findHatcheryTransferData,
    findIncubationData,
    findLarvalData,
    item,
  ]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer title={item.batchNo} placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "80%"}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Broodstock" key="broodstock">
          <BroodstockInfo broodstockInfo={broodstockInfo} />
        </TabPane>
        <TabPane tab="Incubation" key="incubation">
          <IncubationInfo incubationInfo={incubationInfo} />
        </TabPane>
        {larvalInfo.map((item) => {
          if (item[0] && item[0].Tank) {
            return (
              <TabPane tab={item[0].Tank.label} key={item[0].Tank.label}>
                <LarvalInfo data={item} loading={loading} />
              </TabPane>
            );
          }
          return null;
        })}
        <TabPane tab="Harvest" key="harvest">
          <HarvestInfo data={harvestInfo} />
        </TabPane>
        <TabPane tab="To Farm" key="toFarm">
          <TransferInfo data={transferInfo} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default DetailDrawer;
