import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useHatcheryReceipt } from "../../../hooks/useHatcheryReceipt";
import { useRawMaterial } from "../../../hooks/useRawMaterial";
import { useOption } from "../../../hooks/useOption";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useRawMaterial();
  const { findAll: findOption } = useOption();
  const { create } = useHatcheryReceipt();

  const [submitting, setSubmitting] = useState(false);

  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [speciesData, setSpeciesData] = useState([]);
  const [sourceData, setSourceData] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll("Active", "Hatchery").then((res) => {
        if (res.status === "success") {
          setRawMaterialData(res.data);
        }
      });
      findOption("Active").then((res) => {
        if (res.status === "success") {
          const species = res.data.filter((item) => item.type === "Broodstock Species");
          const source = res.data.filter((item) => item.type === "Broodstock Source");
          setSpeciesData(species);
          setSourceData(source);
        }
      });
      form.setFieldsValue({
        arrivalTimestamp: moment(),
      });
    }
  }, [visible, form, findAll, findOption]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);

        const params = {
          rawMaterialId: values.rawMaterialId,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          source: values.source,
          species: values.species,
          weightInGram: values.weightInGram,
          remarks: values.remarks,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Hatchery Receipt"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} rawMaterialData={rawMaterialData} speciesOption={speciesData} sourceOption={sourceData} />
    </Modal>
  );
};

export default AddNewModal;
