import React from "react";
import { Tooltip, Typography } from "antd";
import { blue } from "@ant-design/colors";
import { EditOutlined } from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const { Link } = Typography;

const DeliveryOrderTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail, excludeCols } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{text}</Link>,
    },
    {
      title: "Date",
      dataIndex: "departTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Product",
      dataIndex: "product",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      render: (text) => (text ? text : "-"),
    },
    // {
    //   title: "Person",
    //   dataIndex: "personInCharge",
    //   render: (text) => (text ? text : "-"),
    // },
    // {
    //   title: "Vehicle",
    //   dataIndex: "vehicleNumber",
    //   render: (text) => (text ? text : "-"),
    // },
    // {
    //   title: "From Farm",
    //   render: (text, row) => (row.SourceFarm ? row.SourceFarm.label : "-"),
    // },
    {
      title: "To Processing",
      render: (text, row) => (row.TargetProcessing ? row.TargetProcessing.label : "-"),
    },
    {
      title: "Total PCS",
      render: (text, row) =>
        row.DeliveryOrderDetails
          ? row.DeliveryOrderDetails.map((item) => item.quantity).reduce((a, b) => a + b, 0)
          : "-",
    },
    {
      title: "Total KG",
      render: (text, row) =>
        row.DeliveryOrderDetails
          ? Number(row.DeliveryOrderDetails.map((item) => item.weightInKg).reduce((a, b) => a + b, 0)).toFixed(3)
          : "-",
    },

    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ].filter((item) => {
    if (!excludeCols || !excludeCols.length) return item;

    return !excludeCols.includes(item.title);
  });

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default DeliveryOrderTable;
