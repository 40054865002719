import React, { useContext } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { useTranslation } from "react-i18next";
import { SettingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { AuthContext } from '../../../App';
import { useAuth } from '../../../hooks/useAuth';

const ProfileMenu = () => {

    const { t } = useTranslation();

    const { authState, dispatch } = useContext(AuthContext);

    const { logout } = useAuth();

    const signout = () => logout().then(() => dispatch({
        type: "LOGOUT"
    }));

    const menu = () => (
        <Menu style={{ width: 120 }}>

            <Menu.Item key="settings">
                <Link to={"/account/settings"}>
                    <SettingOutlined /> {t('common.settings')}
                </Link>
            </Menu.Item>

            <Menu.Item key="signout"
                onClick={signout}
                style={{ borderTop: '1px solid lightgrey' }}>
                {t('action.logout')}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <Button type="link">
                {t("message.greeting")}, {authState.authUser.name}</Button>
        </Dropdown>


    )
}

export default ProfileMenu;