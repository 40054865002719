import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { useFactoryFreezing } from "../../../hooks/useFactoryFreezing";
import SSCFreezingForm from "../SSCFreezingForm";
import moment from "moment";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFactoryFreezing();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        freezerNo: editItem.freezerNo,
        startTimestamp: moment(editItem.startTimestamp),
        endTimestamp: moment(editItem.endTimestamp),
        freezerTemperature: editItem.freezerTemperature,
        productTemperature: editItem.productTemperature,
        remarks: editItem.remarks,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          freezerNo: values.freezerNo,
          startTimestamp: moment(values.startTimestamp).format(),
          endTimestamp: values.endTimestamp ? moment(values.endTimestamp).format() : null,
          freezerTemperature: values.freezerTemperature,
          productTemperature: values.productTemperature,
          remarks: values.remarks,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Blast Freezing Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <SSCFreezingForm form={form} isEdit />
    </Modal>
  );
};

export default EditModal;
