import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFarmProduction } from "../../hooks/useFarmProduction";

import FarmProductionTable from "../../components/farmProduction/tables/FarmProductionTable";
import AddNewModal from "../../components/farmProduction/modals/AddNewModal";
import EditModal from "../../components/farmProduction/modals/EditModal";
import Filter from "../../components/farmProduction/components/Filter";

import moment from "moment";

const { Title } = Typography;

const FarmProduction = () => {
  const { t } = useTranslation();

  const { findAll } = useFarmProduction();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);
  const [filterType, setFilterType] = useState("All");
  const [filterStatus, setFilterStatus] = useState("New");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        type: filterType,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, filterType, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  return (
    <>
      <Filter
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterType={filterType}
        filterStatus={filterStatus}
        setFilterType={setFilterType}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Farm Production Record</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <FarmProductionTable error={error} loading={loading} dataSource={data} editRow={editRow} />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default FarmProduction;
