import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import { useHatcheryTransfer } from "../../../hooks/useHatcheryTransfer";
import { useFarmReceipt } from "../../../hooks/useFarmReceipt";
import { useFarm } from "../../../hooks/useFarm";
import HatcheryToFarmForm from "../HatcheryToFarmForm";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useHatcheryTransfer();
  const { create } = useFarmReceipt();

  const [submitting, setSubmitting] = useState(false);
  const [incomingTransfer, setIncomingTransfer] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({
        status: "New",
        farmId: "All",
      }).then((res) => {
        if (res.status === "success") {
          setIncomingTransfer(res.data);
        }
      });

      form.setFieldsValue({
        arrivalTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          location: values.location,
          sourceType: "Hatchery",
          sourceName: "Sematan Hatchery",
          hatcheryTransferId: values.hatcheryTransferId,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          hatcheryQuantity: values.hatcheryQuantity,
          farmQuantity: values.farmQuantity,
          weightInKg: values.weightInKg,
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          remarks: values.remarks,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Hatchery to Nursery Farm Transfer"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <HatcheryToFarmForm form={form} incomingTransfer={incomingTransfer} />
    </Modal>
  );
};

export default AddNewModal;
