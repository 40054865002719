import React from "react";
import { Descriptions, Tag } from "antd";
import moment from "moment";
import DailyRecordTable from "../tables/DailyRecordTable";

const LarvalInfo = (props) => {
  const { data, loading } = props;

  const DescriptionTitle = (props) => {
    const { item } = props;

    const doc = moment(item.stockInTimestamp).startOf("day").diff(moment(item.hatchDate).startOf("day"), "days", false);

    if (doc < 5) {
      return "Larval Stocking";
    } else if (doc > 5 && doc < 15) {
      return "Zoea Transfer";
    } else {
      return "Tank Transfer";
    }
  };

  return data.map((item) => (
    <div key={item.id}>
      <Descriptions title={<DescriptionTitle item={item} />} size="small">
        <Descriptions.Item label="Batch No">{item.batchNo}</Descriptions.Item>
        <Descriptions.Item label="Stock Qty">
          {isNaN(item.stockInQuantity) ? "-" : Number(item.stockInQuantity).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Stock Date">{moment(item.stockInTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Hatch Date">{moment(item.hatchDate).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Tank No">
          <Tag>{item.Tank.label}</Tag>
        </Descriptions.Item>
      </Descriptions>

      <DailyRecordTable dataSource={item.TankDailyRecords} tankData={item} loading={loading} />
    </div>
  ));
};

export default LarvalInfo;
