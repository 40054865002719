import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Modal, Space, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { usePond } from "../../hooks/usePond";
import Filter from "../../components/pondSampling/PondSamplingFilter";

import moment from "moment";
import NewPondTreatmentModal from "../../components/pondTreatment/NewPondTreatmentModal";
import { usePondTreatment } from "../../hooks/usePondTreatment";
import PondTreatmentList from "../../components/pondTreatment/PondTreatmentList";
import EditPondTreatmentModal from "../../components/pondTreatment/EditPondTreatmentModal";

const { Title } = Typography;

const PondTreatment = () => {
  const { t } = useTranslation();

  const { findAll: findPond } = usePond();
  const { findAll, destroy } = usePondTreatment();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterPond, setFilterPond] = useState("All");
  const [filterDate, setFilterDate] = useState([moment().add(-7, "days"), moment()]);

  const [pondOption, setPondOption] = useState([]);
  const [data, setData] = useState([]);
  const [treatmentData, setTreatmentData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findPond({ status: "New" })
        .then((res) => {
          if (res.status === "success") {
            setPondOption(res.data);
          }
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));

      const startDate = filterDate && filterDate[0] ? filterDate[0].startOf("day").valueOf() : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].endOf("day").valueOf() : null;

      findAll({ pondId: filterPond, startDate: startDate, endDate: endDate }).then((res) => {
        if (res.status === "success") {
          setData(res.data);

          let array = [];
          for (const data of res.data) {
            for (const item of data.PondTreatmentDetails) {
              if (array.findIndex((i) => i.treatmentType === item.treatmentType) === -1) {
                array.push({
                  treatmentType: item.treatmentType,
                  uom: item.uom,
                  amount: item.amount,
                  // count: 1,
                });
              } else {
                const index = array.findIndex((i) => i.treatmentType === item.treatmentType);
                array[index].amount += item.amount;
                // array[index].count += 1;
              }
            }
          }
          setTreatmentData(array);
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh, filterPond, findPond, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const deleteRow = (row) => {
    Modal.confirm({
      title: "Are you sure delete this item?",
      onOk: () => {
        destroy(row.id).then(() => {
          setRefresh(true);
        });
      },
      okType: "danger",
      okText: "Delete",
    });
  };

  return (
    <>
      <Filter
        pondOption={pondOption}
        filterPond={filterPond}
        setFilterPond={setFilterPond}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Pond Treatment</Title>

        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Space wrap>
              {treatmentData.map((item, index) => (
                <Tag key={index}>
                  {item.treatmentType}: {Number(item.amount).toFixed(2)} {item.uom}
                </Tag>
              ))}
            </Space>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <PondTreatmentList data={data} editRow={editRow} deleteRow={deleteRow} />

        <NewPondTreatmentModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditPondTreatmentModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default PondTreatment;
