import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { useProcessingOrder } from "../../hooks/useProcessingOrder";
import moment from "moment";
import ProcessingOrderForm from "./ProcessingOrderForm";

const EditProcessingOrderModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useProcessingOrder();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        orderNo: editItem.orderNo,
        deliverTo: editItem.deliverTo,
        orderDate: moment(editItem.orderDate),
        remarks: editItem.remarks,
        location: editItem.location,
        productType: editItem.productType,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          orderNo: values.orderNo,
          deliverTo: values.deliverTo,
          orderDate: moment(values.orderDate).format,
          remarks: values.remarks,
          location: values.location,
          productType: values.productType,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Delivery Order"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <ProcessingOrderForm form={form} isEdit />
    </Modal>
  );
};

export default EditProcessingOrderModal;
