const Constant = {
  TOKEN_ID: "ibAuthToken20201007",
  SECURITY_URL: process.env.REACT_APP_SECURITY_URL,
  SERVER_URL: process.env.REACT_APP_SERVER_URL,
  // SECURITY_URL: "http://localhost:5000",
  // // SECURITY_URL: "https://neocrab.sinisana.net/security",
  // SERVER_URL: "http://localhost:4000",
  // // SERVER_URL: "https://neocrab.sinisana.net/api",
};

export default Constant;
