import React from "react";
import { DatePicker, Form, Select, Input, InputNumber } from "antd";
import { harvestShift } from "../../../utils/Options";
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddNewForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select placeholder="Select a Type" allowClear>
          {["Hard Shell Crab", "Shrimp"].map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="productionTimestamp" label="Timestamp" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item name="harvestShift" label="Harvest Shift">
        <Select allowClear>
          {harvestShift.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="quantity" label="Quantity">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="weightInKg" label="Weight (KG)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
