import React, { useState, useEffect, useContext } from "react";
import { Drawer, Tabs, Row, Col, Typography, Space, Button, Tag } from "antd";
import {
  ExperimentOutlined,
  HomeOutlined,
  ReloadOutlined,
  BgColorsOutlined,
  ExportOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { useTank } from "../../hooks/useTank";
import TankTransferRecordTable from "../tankTransfer/TankTransferRecordTable";
import EditTankTransferModal from "../tankTransfer/EditTankTransferModal";
import HatcheryHarvestModal from "../hatcheryProduction/HatcheryHarvestModal";
import NewTankTransferModal from "../tankTransfer/NewTankTransferModal";
import moment from "moment";
import DailyLarvalRecordTable from "../larvalDailyRecord/DailyLarvalRecordTable";
import { toArrayBuffer } from "../../utils/HelperFunctions";
import { useTankTransfer } from "../../hooks/useTankTransfer";
import EditDailyLarvalModal from "../larvalDailyRecord/EditDailyLarvalModal";
import NewDailyLarvalModal from "../larvalDailyRecord/NewDailyLarvalModal";
import ChemicalUsage from "../larvalDailyRecord/components/ChemicalUsage";
import HatcheryHarvestTable from "../hatcheryProduction/HatcheryHarvestTable";
import EditHatcheryHarvestModal from "../hatcheryProduction/EditHatcheryHarvestModal";
import { ResponsiveContext } from "../../App";

const { TabPane } = Tabs;

const { Title, Paragraph } = Typography;

const LarvalTankDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne, findTankTransfer, findLarvalData, findHarvestData } = useTank();
  const { exportToExcel } = useTankTransfer();

  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dailyLoading, setDailyLoading] = useState(false);

  const [header, setHeader] = useState({});
  const [tankTransferData, setTankTransferData] = useState([]);
  const [dailyLarvalData, setDailyLarvalData] = useState([]);
  const [harvestData, setHarvestData] = useState([]);

  const [editItem, setEditItem] = useState({});
  const [newTankTransferModal, setNewTankTransferModal] = useState(false);
  const [editTankTransferModal, setEditTankTransferModal] = useState(false);
  const [hatcheryHarvestModal, setHatcheryHarvestModal] = useState(false);
  const [newDailyLarvalModal, setNewDailyLarvalModal] = useState(false);
  const [editDailyLarvalModal, setEditDailyLarvalModal] = useState(false);
  const [editHatcheryHarvestModal, setEditHatcheryHarvestModal] = useState(false);

  useEffect(() => {
    if ((visible && id) || (visible && id && refresh)) {
      setLoading(true);

      Promise.all([findOne(id), findTankTransfer(id), findHarvestData(id)]).then((res) => {
        if (res[0].status === "success") {
          setHeader(res[0].data);
        }
        if (res[1].status === "success") {
          setTankTransferData(res[1].data);
        }
        if (res[2].status === "success") {
          setHarvestData(res[2].data);
        }
        setRefresh(false);
        setLoading(false);
      });
    }
  }, [id, visible, refresh, findOne, findTankTransfer, findHarvestData]);

  useEffect(() => {
    if (header.TankUsages && header.TankUsages.length) {
      setDailyLoading(true);
      findLarvalData(header.TankUsages[0].id).then((res) => {
        if (res.status === "success") {
          setDailyLarvalData(res.data);
        }
        setDailyLoading(false);
      });
    } else {
      setDailyLarvalData([]);
    }
  }, [header, findLarvalData]);

  const onClose = () => {
    setVisible(false);
  };

  const editTransferData = (item) => {
    setEditItem(item);
    setEditTankTransferModal(true);
  };

  const editDailyLarval = (item) => {
    setEditItem(item);
    setEditDailyLarvalModal(true);
  };

  const editHarvest = (item) => {
    setEditItem(item);
    setEditHatcheryHarvestModal(true);
  };

  const doHarvest = (item) => {
    setEditItem(item);
    setHatcheryHarvestModal(true);
  };

  const calcDoc = (item) => {
    if (item.TankUsages && item.TankUsages[0] && item.TankUsages[0].hatchDate)
      return moment().startOf("day").diff(moment(item.TankUsages[0].hatchDate).startOf("day"), "days", false);
    return -1;
  };

  const exportDailyLarvalData = (id) => {
    exportToExcel(id).then((res) => {
      if (res.status === "success") {
        const file = new Blob([toArrayBuffer(res.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "Daily Larval Record.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "70%"}>
      <Title level={5}>{header.label}</Title>
      <Row gutter={40}>
        <Col>
          <Paragraph>
            <Space>
              <HomeOutlined />
              {header.shed ? "Shed " + header.shed : "-"}
            </Space>
          </Paragraph>
          <Paragraph>
            <Space>
              <ExperimentOutlined />
              {header.sizeInLitre ? Number(header.sizeInLitre).toLocaleString() + " L" : "-"}
            </Space>
          </Paragraph>
        </Col>
        <Col>
          {header.TankUsages && header.TankUsages.length ? (
            <>
              <Paragraph>
                <Space>
                  Day of Culture (DOC)
                  <span
                    style={{
                      color: calcDoc(header) < 10 ? "green" : calcDoc(header) > 30 ? "red" : "orange",
                    }}
                  >
                    {calcDoc(header)}
                  </span>
                </Space>
              </Paragraph>
              <Paragraph>
                <Space>
                  Batch
                  <Tag>{header.TankUsages && header.TankUsages.length ? header.TankUsages[0].batchNo : "-"}</Tag>
                </Space>
              </Paragraph>
            </>
          ) : null}
        </Col>
      </Row>

      <Tabs>
        <TabPane tab="Daily Larval Record" key="dailyLarvalRecord">
          <Row justify="space-between">
            <Col>
              <Button type="primary" icon={<FormOutlined />} onClick={() => setNewDailyLarvalModal(true)}>
                New Larval Data
              </Button>
            </Col>
            <Col>
              <Space>
                {header.TankUsages && header.TankUsages.length ? (
                  <Button onClick={() => exportDailyLarvalData(header.TankUsages[0].id)} icon={<ExportOutlined />}>
                    Export to Excel
                  </Button>
                ) : null}
                <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
              </Space>
            </Col>
          </Row>

          <DailyLarvalRecordTable
            dataSource={dailyLarvalData}
            loading={dailyLoading}
            tankUsage={header.TankUsages ? header.TankUsages[0] : null}
            editRow={editDailyLarval}
          />

          <ChemicalUsage data={dailyLarvalData} />
        </TabPane>

        <TabPane tab="Tank Transfer" key="transferRecord">
          <Row justify="space-between">
            <Col>
              <Button
                icon={<BgColorsOutlined />}
                type="primary"
                disabled={loading}
                onClick={() => setNewTankTransferModal(true)}
              >
                Transfer In
              </Button>
            </Col>
            <Col>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Col>
          </Row>

          <TankTransferRecordTable
            loading={loading}
            dataSource={tankTransferData}
            editRow={editTransferData}
            editProduction={doHarvest}
            downloadLarvalData={exportDailyLarvalData}
          />
        </TabPane>

        <TabPane tab="Harvest Record" key="harvest">
          <Row justify="space-between">
            <Col></Col>
            <Col>
              <Space>
                <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
              </Space>
            </Col>
          </Row>

          <HatcheryHarvestTable dataSource={harvestData} loading={loading} editRow={editHarvest} />
        </TabPane>
      </Tabs>

      <NewDailyLarvalModal
        id={header.TankUsages && header.TankUsages.length ? header.TankUsages[0].id : null}
        visible={newDailyLarvalModal}
        setVisible={setNewDailyLarvalModal}
        setRefresh={setRefresh}
      />

      <EditDailyLarvalModal
        editItem={editItem}
        visible={editDailyLarvalModal}
        setVisible={setEditDailyLarvalModal}
        setRefresh={setRefresh}
      />

      <NewTankTransferModal
        tank={header}
        visible={newTankTransferModal}
        setVisible={setNewTankTransferModal}
        setRefresh={setRefresh}
      />

      <EditTankTransferModal
        editItem={editItem}
        visible={editTankTransferModal}
        setVisible={setEditTankTransferModal}
        setRefresh={setRefresh}
      />

      <HatcheryHarvestModal
        editItem={editItem}
        visible={hatcheryHarvestModal}
        setVisible={setHatcheryHarvestModal}
        setRefresh={setRefresh}
      />

      <EditHatcheryHarvestModal
        editItem={editItem}
        visible={editHatcheryHarvestModal}
        setVisible={setEditHatcheryHarvestModal}
        setRefresh={setRefresh}
      />
    </Drawer>
  );
};

export default LarvalTankDrawer;
