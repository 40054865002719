export const getObjByValue = (array = [], key = null, value = null) => {
  for (const item of array) {
    if (item[key] === value) {
      return item;
    }
  }

  return null;
};

export const toArrayBuffer = (buf) => {
  var ab = new ArrayBuffer(buf.length);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buf.length; ++i) {
    view[i] = buf[i];
  }
  return ab;
};
