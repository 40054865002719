import React, { useState, useEffect } from "react";
import { Modal, Form, Divider } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useHatcheryProduction } from "../../../hooks/useHatcheryProduction";
import OpenTransferTable from "../tables/OpenTransferTable";
import { useHatcheryTransfer } from "../../../hooks/useHatcheryTransfer";
import { useFarm } from "../../../hooks/useFarm";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useFarm();
  const { findOpenTransfer } = useHatcheryProduction();
  const { create } = useHatcheryTransfer();

  const [farmOption, setFarmOption] = useState([]);
  const [crabletProductionData, setCrabletProductionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      findOpenTransfer().then((res) => {
        if (res.status === "success") {
          setCrabletProductionData(res.data);
        }
      });
      findAll().then((res) => {
        if (res.status === "success") {
          setFarmOption(res.data);
        }
      });
      form.setFieldsValue({
        transferTimestamp: moment(),
      });
    }
  }, [visible]);

  useEffect(() => {
    form.setFieldsValue({
      quantity: selectedRows.map((item) => item.quantity).reduce((a, b) => a + b, 0),
    });
  }, [selectedRows]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (selectedRows.length <= 0) {
        return;
      }
      if (!err) {
        setSubmitting(true);
        const params = {
          transferTimestamp: moment(values.transferTimestamp).format(),
          quantity: values.quantity,
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          remarks: values.remarks,
          farmId: values.farmId,
          hatcheryProductionArrayId: selectedRows.map((item) => item.id),
        };
        // console.log(params);
        // submit(params)

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Hatchery Transfer"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} farmOption={farmOption} />

      <Divider />

      <h4>Open Transfer</h4>

      <OpenTransferTable
        dataSource={crabletProductionData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </Modal>
  );
};

export default AddNewModal;
