import React from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const ProcessingSearchOrder = (props) => {
  const { form, data, setSelectedPackingData, selectedPackingData,setRefresh } = props;

  const onFinish = (values) => {
    setSelectedPackingData(values.productionBatchNo);
    setRefresh(true);
  };

  return (
    <Form form={form} {...layout} onFinish={onFinish}>
      <Form.Item
        name="productionBatchNo"
        label="Production Batch No."
        rules={[{ required: true }]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
        >
          {data.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.packingBatchNo}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Apply
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProcessingSearchOrder;
