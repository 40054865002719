import React, { useState, useEffect } from "react";
import { Descriptions, List, Divider, Row, Col } from "antd";
import moment from "moment";

const HarvestInfo = (props) => {
  const { data } = props;

  const [totalHarvest, setTotalHarvest] = useState(0);

  useEffect(() => {
    if (data && data.length) {
      const total = data.map((item) => item.quantity).reduce((a, b) => a + b, 0);
      setTotalHarvest(total);
    } else {
      setTotalHarvest(0);
    }
  }, [data]);

  return (
    <>
      <Descriptions title="Information" size="small">
        <Descriptions.Item label="Total Harvested">{Number(totalHarvest).toLocaleString()}</Descriptions.Item>
      </Descriptions>

      <Divider />

      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <div style={{ flex: 1 }}>
              <Row justify="space-between">
                <Col>
                  <span style={{ fontSize: 12, color: "gray" }}>Date: </span>
                  <p>{moment(item.productionTimestamp).format("YYYY-MM-DD")}</p>
                </Col>
                <Col>
                  <span style={{ fontSize: 12, color: "gray" }}>Tank: </span>
                  <p>{item.TankUsage ? item.TankUsage.Tank.label : "-"}</p>
                </Col>
                <Col>
                  <span style={{ fontSize: 12, color: "gray" }}>Species: </span>
                  <p>{item.broodstockSpecies}</p>
                </Col>
                <Col>
                  <span style={{ fontSize: 12, color: "gray" }}>Qty: </span>
                  <p>{isNaN(item.quantity) ? item.quantity : Number(item.quantity).toLocaleString()}</p>
                </Col>
                <Col>
                  <span style={{ fontSize: 12, color: "gray" }}>Avg Weight (g):</span>
                  <p>{item.avgWeightInGram}</p>
                </Col>
                <Col>
                  <span style={{ fontSize: 12, color: "gray" }}>Remarks:</span>
                  <p>{item.remarks ? item.remarks : "-"}</p>
                </Col>
              </Row>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

export default HarvestInfo;
