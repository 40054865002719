import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, Alert, Select } from "antd";
import moment from "moment";
import { useTankTransfer } from "../../../hooks/useTankTransfer";
import { useTank } from "../../../hooks/useTank";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const TransferTankModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findOpenTank } = useTank();
  const { transferToNewTank } = useTankTransfer();

  const [submitting, setSubmitting] = useState(false);
  const [tankOption, setTankOption] = useState([]);

  useEffect(() => {
    if (visible) {
      findOpenTank().then((res) => {
        if (res.status === "success") {
          setTankOption(res.data);
        }
      });
      form.setFieldsValue({
        stockOutTimestamp: moment(),
      });
    }
  }, [visible, form, findOpenTank]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          tankId: values.tankId,
          stockOutTimestamp: moment(values.stockOutTimestamp).format(),
        };
        transferToNewTank(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Tank Transfer: " + editItem.Tank?.label}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="stockOutTimestamp" label="Stock Out Timestamp" rules={[{ required: true }]}>
          <DatePicker showTime allowClear />
        </Form.Item>
        <Form.Item name="tankId" label="To Tank" rules={[{ required: true }]}>
          <Select allowClear>
            {tankOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      <Alert
        message="Warning"
        description="This is full stock out. Larval Tank status will change from 'Operating' to 'New'. If this is partial larval transfer between tanks, please create a new Tank Transfer record instead."
        type="warning"
        showIcon
      />
    </Modal>
  );
};

export default TransferTankModal;
