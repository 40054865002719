import React, { useContext } from "react";
import { Drawer, Tag, Descriptions, Divider, Tabs } from "antd";
import moment from "moment";
import TransferMap from "../components/TransferMap";
import { ResponsiveContext } from "../../../App";

const { TabPane } = Tabs;

const DetailDrawer = (props) => {
  const { editItem, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);
  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer
      title="Tank Transfer Details"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={isMobile ? null : "50%"}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Info" key="info">
          <Descriptions title="Tank">
            <Descriptions.Item label="Tank No">
              <Tag>{editItem.Tank?.label}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Sources" span={2}>
              {editItem.TankUsageSources && editItem.TankUsageSources.length
                ? editItem.TankUsageSources.map((item) => <Tag key={item.id}>{item.label}</Tag>)
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Stock In">
              <Tag>{moment(editItem.stockInTimestamp).format("YYYY-MM-DD")}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Stock Out">
              {editItem.stockOutTimestamp ? <Tag>{moment(editItem.stockOutTimestamp).format("YYYY-MM-DD")}</Tag> : "-"}
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Descriptions title="Larval Info">
            <Descriptions.Item label="DOC">
              <Tag>{moment().startOf("day").diff(moment(editItem.hatchDate).startOf("day"), "days", false)}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Batch No">
              <Tag>{editItem.batchNo}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Larval Quantity">
              <Tag>{Number(editItem.stockInQuantity).toLocaleString()}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Remarks" span={3}>
              {editItem.remarks ? editItem.remarks : "-"}
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Descriptions title="Broodstock">
            <Descriptions.Item label="Broodstock No">
              <Tag>{editItem.broodstockNo}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Spawned">
              {editItem.IncubationRecord && (
                <Tag>{moment(editItem.IncubationRecord.spawnDate).format("YYYY-MM-DD")}</Tag>
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Hatched">
              <Tag>{moment(editItem.hatchDate).format("YYYY-MM-DD")}</Tag>
            </Descriptions.Item>
          </Descriptions>
        </TabPane>
        <TabPane tab="Transfer History" key="history">
          <TransferMap sourceNodes={editItem.TankUsageSources} currentNode={editItem} />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default DetailDrawer;
