import React from "react";
import TableLoader from "../../common/TableLoader";
import { Tooltip, Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { sscSource } from "../../../utils/Options";
import { getObjByValue } from "../../../utils/HelperFunctions";

const PontoonTable = (props) => {
  const { dataSource, loading, error, editRow } = props;

  const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    // },
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Pond",
      render: (text, row) => (row.Pond ? row.Pond.label : "-"),
    },
    {
      title: "SSC Source",
      dataIndex: "sscSource",
      render: (text) => (text ? <Tag color={getObjByValue(sscSource, "value", text).color}>{text}</Tag> : "-"),
    },
    {
      title: "SSC Count",
      dataIndex: "sscCount",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: true }}
    />
  );
};

export default PontoonTable;
