import React from "react";
import { DatePicker, List, Typography, Row, Col, Button } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;

const ActivityList = (props) => {
  const { data, activityDateFilter, setActivityDateFilter } = props;

  const onChange = (date) => setActivityDateFilter(date);

  const renderContent = (item) => {
    if (item.type === "Broodstock Receipt") {
      const value = JSON.parse(item.value);

      return (
        <ul>
          <li>Species: {value.species}</li>
          <li>Weight (g): {value.weightInGram}</li>
          <li>Arrival: {moment(value.arrivalTimestamp).format("h:mm a")}</li>
        </ul>
      );
    } else if (item.type === "New Spawn") {
      const value = JSON.parse(item.value);

      return (
        <ul>
          <li>Broodstock #: {value.broodstockNo ?? "-"}</li>
          <li>Species: {value.species ?? "-"}</li>
          <li>Weight (g): {value.weightInGram ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Incubation Monitor") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Broodstock: {value.broodstockNo + ", " + value.species + ", " + value.weightInGram + " g" ?? "-"}</li>
          <li>DOC: {value.doc ?? "-"}</li>
          <li>Due to Hatch: {value.dueToHatch ?? "-"}</li>
          <li>EM Size: {value.emSize ?? "-"}</li>
          <li>Stage: {value.stage ?? "-"}</li>
          <li>Remarks: {value.remarks ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "New Hatch") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Broodstock: {value.broodstockNo + ", " + value.species + ", " + value.weightInGram + " g" ?? "-"}</li>
          <li>Quantity (Zoea): {Number(value.quantity).toLocaleString() ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Larval Stock In") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Batch: {value.batchNo ?? "-"}</li>
          <li>Tank: {value.tank ?? "-"}</li>
          <li>Quantity: {Number(value.stockInQuantity).toLocaleString() ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Tank Transfer") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Batch: {value.batchNo ?? "-"}</li>
          <li>From: {value.source ?? "-"}</li>
          <li>To: {value.target ?? "-"}</li>
          <li>Quantity: {Number(value.quantity).toLocaleString() ?? "-"}</li>
          <li>Remarks: {value.remarks ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Treatment") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Artemia (g): {value.artemia ?? "-"}</li>
          <li>Microparticulate (g): {value.micro ?? "-"}</li>
          <li>Cifro (g): {value.cifro ?? "-"}</li>
          <li>EDTA (g): {value.edta ?? "-"}</li>
          <li>K-5 : {value.k5InGram ?? "-"}</li>
          <li>Germ Free : {value.germFree ?? "-"}</li>
          <li>M-Plus : {value.mplus ?? "-"}</li>
          <li>Oregano: {value.oreganoInMl ?? "-"}</li>
          <li>Shrimp Favour : {value.shrimpFavour ?? "-"}</li>
          <li>Super MS : {value.superms ?? "-"}</li>
          <li>Tystatin (ml) : {value.tystatin ?? "-"}</li>
          <li>Remarks: {value.remarks ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Harvest") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Batch: {value.batchNo ?? "-"}</li>
          <li>Broodstock: {value.broodstockNo + ", " + value.broodstockSpecies ?? "-"}</li>
          <li>Quantity: {Number(value.quantity).toLocaleString() ?? "-"}</li>
          <li>Avg Weight (g): {value.avgWeightInGram ?? "-"}</li>
          <li>Remarks: {value.remarks ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Deliver To Farm") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>To: {value.farm ?? "-"}</li>
          <li>Quantity: {Number(value.quantity).toLocaleString() ?? "-"}</li>
          <li>Person: {value.personInCharge ?? "-"}</li>
          <li>Vehicle Number: {value.vehicleNumber ?? "-"}</li>
          <li>Remarks: {value.remarks ?? "-"}</li>
        </ul>
      );
    }
    return "No Data";
  };

  return (
    <List
      size="small"
      itemLayout="vertical"
      header={
        <>
          <Title level={5}>Today's Activities at Hatchery</Title>
          <Row justify="space-between">
            <Col>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => {
                  const date = moment(activityDateFilter).subtract(1, "day");
                  setActivityDateFilter(date);
                }}
              />
            </Col>
            <Col>
              <DatePicker value={activityDateFilter} allowClear onChange={onChange} />
            </Col>
            <Col>
              <Button
                type="link"
                icon={<DoubleRightOutlined />}
                onClick={() => {
                  const date = moment(activityDateFilter).add(1, "day");
                  setActivityDateFilter(date);
                }}
              />
            </Col>
          </Row>
        </>
      }
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            title={
              <Row justify="space-between">
                <Col>
                  {item.type === "Treatment"
                    ? item.type + ", " + JSON.parse(item.value).tank + ", " + JSON.parse(item.value).larvalStage
                    : item.type}
                </Col>
                <Col>
                  <Text type="secondary">{moment(item.createdAt).format("h:mm a")}</Text>
                </Col>
              </Row>
            }
            description={
              item.createdBy ? (
                <Text type="secondary" style={{ fontSize: 12, fontStyle: "italic" }}>
                  by {item.createdBy.split(":::")[1]}
                </Text>
              ) : null
            }
          />
          {renderContent(item)}
        </List.Item>
      )}
      pagination={{
        pageSize: 5,
      }}
    />
  );
};

export default ActivityList;
