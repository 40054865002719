import React from "react";
import { Descriptions, Row, Col, Typography, Tag } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getObjByValue } from "../../utils/HelperFunctions";
import { sscSource } from "../../utils/Options";

const { Title } = Typography;

const SSCHarvestInfo = (props) => {
  const { item } = props;

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>Date : {moment(item.harvestDate).format("YYYY-MM-DD")}</Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions>
            <Descriptions.Item label="Source">
              <Tag color={getObjByValue(sscSource, "value", item.sourceType)?.color}>{item.sourceType}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Quantity">{Number(item.quantity).toLocaleString()}</Descriptions.Item>
            <Descriptions.Item label="Balance">{Number(item.balance).toLocaleString()}</Descriptions.Item>
            {/* <Descriptions.Item label="AntChain Trace Code">
              {item.antChainTraceCode ? (
                <Button type="link" icon={<BlockOutlined />} href={item.antChainTraceCode} target="_blank" />
              ) : (
                "-"
              )}
            </Descriptions.Item> */}
          </Descriptions>
        </Col>

        <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right" }}>{t("common.status")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>{item.status ? item.status : "-"}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SSCHarvestInfo;
