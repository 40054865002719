import React, { useState, useContext } from "react";
import { List, Button, Typography } from 'antd';
import { useTranslation } from "react-i18next";
import { AuthContext } from '../../../App';
import EditPasswordModal from '../modals/EditPasswordModal';
import EditPinModal from '../modals/EditPinModal';

const { Title } = Typography;

const SecuritySettings = (props) => {

    const { t } = useTranslation();

    const { authState, dispatch } = useContext(AuthContext);

    const [editPasswordModal, setEditPasswordModal] = useState(false);
    const [editPinModal, setEditPinModal] = useState(false);

    const editPassword = () => {
        setEditPasswordModal(true);
    }

    const editPin = () => {
        setEditPinModal(true);
    }

    return (
        <div>
            <Title level={4}>
                {t('account.setting.security')}
            </Title>

            <List>
                <List.Item
                    actions={[
                        <Button type="link" onClick={() => editPassword()}>
                            {t('action.edit')}
                        </Button>,
                    ]}>
                    <List.Item.Meta
                        title={t('common.password')}
                        description={'********'} />
                </List.Item>

                <List.Item
                    actions={[
                        <Button type="link" onClick={() => editPin()}>
                            {t('action.edit')}
                        </Button>,
                    ]}>
                    <List.Item.Meta
                        title={t('account.setting.pin')}
                        description={authState.authUser.pin} />
                </List.Item>

                <List.Item></List.Item>
            </List>

            <EditPasswordModal
                visible={editPasswordModal}
                setVisible={setEditPasswordModal}
            />

            <EditPinModal
                state={authState}
                dispatch={dispatch}
                visible={editPinModal}
                setVisible={setEditPinModal}
            />
        </div>
    )
}

export default SecuritySettings;