import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Typography, List } from "antd";
import { useFluree } from "../../hooks/useFluree";
import moment from "moment";
const { Text } = Typography;

const FlureeBlockModal = (props) => {
  const { id, visible, setVisible } = props;

  const { block, query } = useFluree();

  const [data, setData] = useState([]);
  const [predicate, setPredicate] = useState({});

  useEffect(() => {
    if (visible && id) {
      block({
        block: id,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data[0].flakes.filter((item) => item[0] === res.data[0].t));
        }
      });

      query({
        select: ["name"],
        from: "_predicate",
      }).then((res) => {
        if (res.status === "success") {
          setPredicate(
            res.data
              .filter((item) => item.name.startsWith("_block/") || item.name === "_tx/hash")
              .reduce((a, v) => ({ ...a, [v._id]: v.name }), {})
          );
        }
      });
    }
  }, [visible, id]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Block " + id}
      destroyOnClose
      visible={visible}
      footer={<Button onClick={handleCancel}>Close</Button>}
      onCancel={handleCancel}
    >
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={predicate[item[1]]}
              description={predicate[item[1]] === "_block/instant" ? moment(item[2]).format() : item[2]}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default FlureeBlockModal;
