import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Empty, Card, Space, Tag, Select } from "antd";
import { ExperimentOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { hatcheryShed } from "../../utils/Options";
import { useTank } from "../../hooks/useTank";
import EditTankModal from "../../components/tank/EditTankModal";
import AddTankModal from "../../components/tank/AddTankModal";
import LarvalTankDrawer from "../../components/tank/LarvalTankDrawer";

const { Title, Text } = Typography;

const { Option } = Select;

const LarvalTank = () => {
  const { findAll } = useTank();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [filterOpStatus, setFilterOpStatus] = useState("Operating");
  const [filterShed, setFilterShed] = useState("All");
  const [data, setData] = useState([]);

  const [editItem, setEditItem] = useState({});

  const [larvalTankDrawer, setLarvalTankDrawer] = useState(false);

  const [addTankModal, setAddTankModal] = useState(false);
  const [editTankModal, setEditTankModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      findAll({ status: "New", shed: filterShed, opStatus: filterOpStatus }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
        setLoading(false);
      });
    }
  }, [refresh, filterShed, filterOpStatus, findAll]);

  useEffect(() => {
    setRefresh(true);
  }, [filterShed, filterOpStatus]);

  const edit = (item) => {
    setEditItem(item);
    setEditTankModal(true);
  };

  const view = (item) => {
    setEditItem(item);
    setLarvalTankDrawer(true);
  };

  const calcDoc = (item) => {
    if (item.TankUsages[0] && item.TankUsages[0].hatchDate)
      return moment().startOf("day").diff(moment(item.TankUsages[0].hatchDate).startOf("day"), "days", false);
    return -1;
  };

  const handleChange = (value) => {
    setFilterOpStatus(value);
  };
  return (
    <>
      <Row style={{ marginTop: 16 }} justify="space-between" gutter={[16, 16]}>
        <Col>
          <Space>
            <Button onClick={() => setFilterShed("All")} type={filterShed === "All" ? "primary" : null}>
              All
            </Button>
            {hatcheryShed.map((item) => (
              <Button key={item} onClick={() => setFilterShed(item)} type={filterShed === item ? "primary" : null}>
                Shed {item}
              </Button>
            ))}
          </Space>
        </Col>
        <Col>
          <Select style={{ width: 120 }} defaultValue={filterOpStatus} onChange={handleChange}>
            <Option value="All">All</Option>
            <Option value="Operating">Operating</Option>
          </Select>
        </Col>
      </Row>

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={3}>Larval Tank</Title>

        <Row style={{ marginTop: 16, marginBottom: 16 }} justify="space-between">
          <Col>
            <Button onClick={() => setAddTankModal(true)} type="primary">
              New Tank
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        {data.length ? (
          <Row gutter={[16, 16]}>
            {data.map((item) => (
              <Col key={item.id} xs={12} sm={6}>
                <Card size="small">
                  <Row>
                    <Col>
                      <Text type="secondary" style={{ fontSize: 12 }}>
                        {item.shed ? "Shed " + item.shed : ""}
                      </Text>
                      <Title level={5} style={{ marginTop: 0 }}>
                        <Space>
                          {item.label}
                          <Button
                            onClick={() => edit(item)}
                            type="link"
                            size="small"
                            icon={<EditOutlined size="small" />}
                          />
                        </Space>
                      </Title>
                    </Col>
                  </Row>

                  <div onClick={() => view(item)}>
                    <Row gutter={8}>
                      <Col>
                        <ExperimentOutlined />
                      </Col>
                      <Col>
                        <Text type="secondary">
                          {item.sizeInLitre ? Number(item.sizeInLitre).toLocaleString() : "-"} L
                        </Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        style={{
                          fontSize: 36,
                          color: calcDoc(item) < 10 ? "green" : calcDoc(item) > 30 ? "red" : "orange",
                          textAlign: "center",
                        }}
                        span={24}
                      >
                        {item.TankUsages.length ? calcDoc(item) : "-"}
                      </Col>
                    </Row>

                    <Row justify="space-between">
                      <Col>{item.TankUsages.length ? <Tag>{item.TankUsages[0].batchNo}</Tag> : null}</Col>
                      <Col>
                        <Text type="secondary" style={{ fontSize: 12 }}>
                          {item.TankUsages.length
                            ? "Stock on " + moment(item.TankUsages[0].stockInTimestamp).format("YYYY-MM-DD")
                            : null}
                        </Text>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty />
        )}

        <LarvalTankDrawer
          id={editItem.id}
          visible={larvalTankDrawer}
          setVisible={setLarvalTankDrawer}
          setRefresh={setRefresh}
        />

        <AddTankModal visible={addTankModal} setVisible={setAddTankModal} setRefresh={setRefresh} />

        <EditTankModal
          editItem={editItem}
          visible={editTankModal}
          setVisible={setEditTankModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default LarvalTank;
