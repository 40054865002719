import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import Filter from "./components/Filter";
import TankTransferTable from "./tables/TankTransferTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import { useTank } from "../../hooks/useTank";
import { useTankTransfer } from "../../hooks/useTankTransfer";
import TransferTankModal from "./modals/TransferTankModal";
import ProductionModal from "./modals/ProductionModal";

import moment from "moment";
import DetailDrawer from "./drawers/DetailDrawer";

const { Title } = Typography;

const TankTransferList = () => {
  const { t } = useTranslation();

  const { findAll: findTank } = useTank();
  const { findAll } = useTankTransfer();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDateRange, setFilterDateRange] = useState([moment().subtract(1, "month"), moment()]);
  const [filterTank, setFilterTank] = useState("All");
  const [filterStatus, setFilterStatus] = useState("New");

  const [tankOption, setTankOption] = useState([]);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [transferTankModal, setTransferTankModal] = useState(false);
  const [productionModal, setProductionModal] = useState(false);
  const [detailDrawer, setDetailDrawer] = useState(false);

  useEffect(() => {
    findTank("New").then((res) => {
      if (res.status === "success") {
        setTankOption(res.data);
      }
    });
  }, [findTank]);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDateRange ? filterDateRange[0].format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
      const endDate = filterDateRange ? filterDateRange[1].format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");

      findAll(filterStatus, filterTank, startDate, endDate)
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findAll, filterStatus, filterTank, filterDateRange, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editTransferToTank = (row) => {
    setEditItem(row);
    setTransferTankModal(true);
  };

  const editProduction = (row) => {
    setEditItem(row);
    setProductionModal(true);
  };

  const viewDetail = (row) => {
    setEditItem(row);
    setDetailDrawer(true);
  };

  return (
    <>
      <Filter
        tankOption={tankOption}
        filterDateRange={filterDateRange}
        filterTank={filterTank}
        filterStatus={filterStatus}
        setFilterDateRange={setFilterDateRange}
        setFilterTank={setFilterTank}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Larval Tank Transfer</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <TankTransferTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          editTransferToTank={editTransferToTank}
          editProduction={editProduction}
          viewDetail={viewDetail}
        />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <TransferTankModal
          editItem={editItem}
          visible={transferTankModal}
          setVisible={setTransferTankModal}
          setRefresh={setRefresh}
        />

        <ProductionModal
          editItem={editItem}
          visible={productionModal}
          setVisible={setProductionModal}
          setRefresh={setRefresh}
        />

        <DetailDrawer editItem={editItem} visible={detailDrawer} setVisible={setDetailDrawer} />
      </div>
    </>
  );
};

export default TankTransferList;
