import React from "react";
import { Table, InputNumber, Input, Select } from "antd";
import { sscGrade, sscSize } from "../../utils/Options";

const { Option } = Select;

const FactoryReceiptDetailTable = (props) => {
  const { detailData, setDetailData, readOnly } = props;

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      render: (text, row, index) => Number(index) + 1,
    },
    {
      title: "Grade",
      dataIndex: "grade",
      render: (text, row) =>
        readOnly ? (
          text
        ) : (
          <Select defaultValue={text} style={{ width: 80 }} onChange={(value) => onGradeChange(value, row)}>
            {sscGrade.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ),
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (text, row) =>
        readOnly ? (
          text
        ) : (
          <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => onSizeChange(value, row)}>
            {sscSize.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ),
    },
    {
      title: "Farm",
      children: [
        {
          title: "Qty",
          dataIndex: "farmQuantity",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "Weight (KG)",
          dataIndex: "farmWeightInKg",
          render: (text) => (text ? Number(text).toFixed(2) : "-"),
        },
      ],
    },
    {
      title: "Recipient",
      children: [
        {
          title: "Qty",
          dataIndex: "processingQuantity",
          render: (text, row) =>
            readOnly ? (
              text ? (
                text
              ) : (
                "-"
              )
            ) : (
              <InputNumber defaultValue={text} allowClear onChange={(value) => onQuantityChange(value, row)} />
            ),
        },
        {
          title: "Weight (KG)",
          dataIndex: "processingWeightInKg",
          render: (text, row) =>
            readOnly ? (
              text ? (
                Number(text).toFixed(2)
              ) : (
                "-"
              )
            ) : (
              <InputNumber defaultValue={text} allowClear onChange={(value) => onWeightChange(value, row)} />
            ),
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text, row) =>
        readOnly ? (
          text ? (
            text
          ) : (
            "-"
          )
        ) : (
          <Input defaultValue={text} allowClear onChange={(e) => onRemarkChange(e.target.value, row)} />
        ),
    },
  ];

  const onGradeChange = (value, row) => {
    const index = detailData.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      const newArray = [...detailData];
      newArray[index] = {
        ...newArray[index],
        grade: value,
      };

      setDetailData(newArray);
    }
  };

  const onSizeChange = (value, row) => {
    const index = detailData.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      const newArray = [...detailData];
      newArray[index] = {
        ...newArray[index],
        size: value,
      };

      setDetailData(newArray);
    }
  };

  const onQuantityChange = (value, row) => {
    const index = detailData.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      const newArray = [...detailData];
      newArray[index] = {
        ...newArray[index],
        processingQuantity: value,
      };
      setDetailData(newArray);
    }
  };

  const onWeightChange = (value, row) => {
    const index = detailData.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      const newArray = [...detailData];
      newArray[index] = {
        ...newArray[index],
        processingWeightInKg: value,
      };

      setDetailData(newArray);
    }
  };

  const onRemarkChange = (value, row) => {
    const index = detailData.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      const newArray = [...detailData];
      newArray[index] = {
        ...newArray[index],
        remarks: value,
      };

      setDetailData(newArray);
    }
  };

  return (
    <Table
      rowKey="id"
      size="small"
      scroll={{ x: "max-content" }}
      bordered
      columns={columns}
      dataSource={detailData}
      style={{ marginTop: 24 }}
    />
  );
};

export default FactoryReceiptDetailTable;
