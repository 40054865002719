import React from "react";
import { Form, Input } from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const RawMaterialForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default RawMaterialForm;
