import React from "react";
import { DatePicker, List, Typography, Row, Col, Button } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;

const ProcessingActivity = (props) => {
  const { data, activityDateFilter, setActivityDateFilter } = props;

  const onChange = (date) => setActivityDateFilter(date);

  const renderContent = (item) => {
    if (item.type === "Grading Details Record") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.totalPcs ? <li>Quantity: {Number(value.totalPcs).toLocaleString()} pcs</li> : null}
          {value.totalWeight ? <li>Weight: {Number(value.totalWeight).toLocaleString()} kg</li> : null}
          <li>Remarks: {value.remarks ? value.remarks : "-"}</li>
        </ul>
      );
    } else if (item.type === "Weighing & Packaging Record") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Batch No: {value.batchNo ?? "-"}</li>
          {value.totalPcs ? <li>Quantity: {Number(value.totalPcs).toLocaleString()} pcs</li> : null}
          {value.totalWeight ? <li>Weight: {Number(value.totalWeight).toLocaleString()} kg</li> : null}
          <li>Remarks: {value.remarks ? value.remarks : "-"}</li>
        </ul>
      );
    } else if (item.type === "Freezer Temperature Monitoring (Blast Freezer)") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Batch No: {value.batchNo ?? "-"}</li>
          <li>Start Time: {moment(value.startTimestamp).format("h:mm a") ?? "-"}</li>
          <li>End Time: {moment(value.endTimestamp).format("h:mm a") ?? "-"}</li>
          <li>Freezer No: {value.freezerNo ?? "-"}</li>
          <li>Freezer Temperature: {value.freezerTemperature ?? "-"}</li>
          <li>Product Temperature: {value.productTemperature ?? "-"}</li>
        </ul>
      );
    } else if (item.type === "Delivery Order") {
      const value = JSON.parse(item.value);

      return (
        <ul>
          <li>Order No: {value.orderNo ?? "-"}</li>
          <li>Delivered To: {value.deliverTo ?? "-"}</li>
          <li>Product: {value.productType ?? "-"}</li>
          <li>Quantity: {Number(value.totalPcs).toLocaleString()} pcs</li>
          <li>Weight: {Number(value.totalWeight).toLocaleString()} kg</li>
        </ul>
      );
    }
    return "No Data";
  };

  return (
    <List
      size="small"
      itemLayout="vertical"
      header={
        <>
          <Title level={5}>Today's Activities at Bako Processing</Title>
          <Row justify="space-between">
            <Col>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => {
                  const date = moment(activityDateFilter).subtract(1, "day");
                  setActivityDateFilter(date);
                }}
              />
            </Col>
            <Col>
              <DatePicker value={activityDateFilter} allowClear onChange={onChange} />
            </Col>
            <Col>
              <Button
                type="link"
                icon={<DoubleRightOutlined />}
                onClick={() => {
                  const date = moment(activityDateFilter).add(1, "day");
                  setActivityDateFilter(date);
                }}
              />
            </Col>
          </Row>
        </>
      }
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            title={
              <Row justify="space-between">
                <Col>{item.type}</Col>
                <Col>
                  <Text type="secondary">{moment(item.createdAt).format("h:mm a")}</Text>
                </Col>
              </Row>
            }
            description={
              item.createdBy ? (
                <Text type="secondary" style={{ fontSize: 12, fontStyle: "italic" }}>
                  by {item.createdBy.split(":::")[1]}
                </Text>
              ) : null
            }
          />
          {renderContent(item)}
        </List.Item>
      )}
      pagination={{
        pageSize: 5,
      }}
    />
  );
};

export default ProcessingActivity;
