import React, { useState, useEffect } from "react";
import { Descriptions, Row, Col, Card, Select, Form, DatePicker, Typography, Empty, Space, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useDashboard } from "../../../hooks/useDashboard";
import { usePond } from "../../../hooks/usePond";
import { sscGrade, sscSize } from "../../../utils/Options";
const moment = require("moment");

const { Option } = Select;
const { RangePicker } = DatePicker;

const SoftShellCrab = () => {
  const { findSSCMonitoringData } = useDashboard();

  const [selectedDateRange, setSelectedDateRange] = useState([moment().subtract(7, "day"), moment()]);

  const [harvestData, setHarvestData] = useState([]);
  const [totalAliveInternal, setTotalAliveInternal] = useState(0);
  const [totalAliveExternal, setTotalAliveExternal] = useState(0);
  const [totalDead, setTotalDead] = useState(0);
  const [totalMissing, setTotalMissing] = useState(0);
  const [totalHarvest, setTotalHarvest] = useState(0);

  useEffect(() => {
    if (selectedDateRange) {
      const startDate = moment(selectedDateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(selectedDateRange[1]).format("YYYY-MM-DD");

      findSSCMonitoringData({ startDate: startDate, endDate: endDate }).then((res) => {
        if (res.status === "success") {
          setTotalAliveInternal(res.data.totalAliveInternal);
          setTotalAliveExternal(res.data.totalAliveExternal);
          setTotalDead(res.data.totalDead);
          setTotalMissing(res.data.totalMissing);
          setTotalHarvest(res.data.totalHarvest);

          let ssc = [];

          res.data.deliveryOrders.forEach((item) => {
            item.DeliveryOrderDetails.forEach((detailItem) => {
              ssc.push(detailItem);
            });
          });
          setHarvestData(ssc);
        }
      });
    }
  }, [selectedDateRange]);

  const onDateChanged = (value) => {
    if (value) {
      setSelectedDateRange(value);
    }
  };

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} flex="none">
          <RangePicker style={{ width: 250 }} value={selectedDateRange} onChange={onDateChanged} />
        </Col>

        <Col xs={24} flex="auto">
          <Space>
            <Button size="small" type="link" onClick={() => setSelectedDateRange([moment(), moment()])}>
              Today
            </Button>
            <Button
              size="small"
              type="link"
              onClick={() => setSelectedDateRange([moment().subtract(7, "day"), moment()])}
            >
              Last 7 Days
            </Button>

            <Button
              size="small"
              type="link"
              onClick={() => setSelectedDateRange([moment().startOf("month"), moment().endOf("month")])}
            >
              This Month
            </Button>

            <Button
              size="small"
              type="link"
              onClick={() =>
                setSelectedDateRange([
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ])
              }
            >
              Last Month
            </Button>
          </Space>
        </Col>
      </Row>

      <Card style={{ marginTop: 16 }}>
        <Descriptions title="Running SSC Count">
          <Descriptions.Item label={"Total"}>
            <Typography.Text strong>
              {Number(totalAliveInternal + totalAliveExternal).toLocaleString()} pcs
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label={"Internal Pontoons"}>
            <Typography.Text strong>{Number(totalAliveInternal).toLocaleString()} pcs</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label={"External Pontoons"}>
            <Typography.Text strong>{Number(totalAliveExternal).toLocaleString()} pcs</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <Descriptions title="SSC Monitoring">
          <Descriptions.Item label={"Total Harvest"}>
            <Typography.Text strong>{Number(totalHarvest).toLocaleString()} pcs</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label={"Total Dead"}>
            <Typography.Text strong>{Number(totalDead).toLocaleString()} pcs</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label={"Total Missing"}>
            <Typography.Text strong>{Number(totalMissing).toLocaleString()} pcs</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card style={{ marginTop: 16 }}>
        {sscGrade.map((grade) => (
          <Descriptions title={"Grade " + grade}>
            {sscSize.map((size) => (
              <Descriptions.Item label={size}>
                <Typography.Text strong>
                  {Number(
                    harvestData
                      .filter((item) => item.grade === grade && item.size === size)
                      .reduce((a, b) => a + b.quantity, 0)
                  ).toLocaleString()}{" "}
                  pcs
                </Typography.Text>
                , &nbsp;&nbsp;
                <Typography.Text strong>
                  {Number(
                    harvestData
                      .filter((item) => item.grade === grade && item.size === size)
                      .reduce((a, b) => a + b.weightInKg, 0)
                  ).toLocaleString()}{" "}
                  kg
                </Typography.Text>
              </Descriptions.Item>
            ))}
          </Descriptions>
        ))}
      </Card>
    </>
  );
};

export default SoftShellCrab;
