import React, { useState, useEffect } from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import { useFarm } from "../../hooks/useFarm";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ExternalToFarmForm = (props) => {
  const { form } = props;

  const { findAll } = useFarm();

  const [farmOption, setFarmOption] = useState([]);

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setFarmOption(res.data);
      }
    });
  }, []);

  return (
    <Form {...layout} form={form} name="external-to-farm-form">
      <Form.Item name="location" label="Location" rules={[{ required: true }]}>
        <Select>
          {farmOption.map((item) => (
            <Select.Option key={item.label} value={item.label}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="arrivalTimestamp" label="Arrival Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="sourceName" label="Received From" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="farmQuantity" label="Quantity (PCS)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="weightInKg" label="Weight (KG)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="dead" label="Dead (PCS)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="deadWeightInKg" label="Dead Weight (KG)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="small" label="Small (PCS)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default ExternalToFarmForm;
