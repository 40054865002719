import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDeliveryOrder } from "../../hooks/useDeliveryOrder";

import Filter from "./components/Filter";
import DeliveryOrderTable from "./tables/DeliveryOrderTable";
import AddNewModal from "./modals/AddNewModal";
import AddDetailModal from "./modals/AddDetailModal";
import EditModal from "./modals/EditModal";
// import EditDetailModal from "./modals/EditDetailModal";
import OrderDetailDrawer from "./drawers/OrderDetailDrawer";

import moment from "moment";

const { Title } = Typography;

const HatcheryReceiptList = () => {
  const { t } = useTranslation();

  const { findAll } = useDeliveryOrder();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterStatus, setFilterStatus] = useState("All");
  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [addDetailModal, setAddDetailModal] = useState(false);
  const [orderDetailDrawer, setOrderDetailDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0] : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1] : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, findAll, t]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [data, editItem]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setOrderDetailDrawer(true);
  };

  const addDetail = () => {
    setAddDetailModal(true);
  };

  return (
    <>
      <Filter
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Delivery Order</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <DeliveryOrderTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          viewDetail={viewDetail}
        />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <AddDetailModal
          editItem={editItem}
          visible={addDetailModal}
          setVisible={setAddDetailModal}
          setRefresh={setRefresh}
        />

        {/* <EditDetailModal
          editItem={editDetailItem}
          visible={editDetailModal}
          setVisible={setEditDetailModal}
          setRefresh={setRefresh}
        /> */}

        <OrderDetailDrawer
          editItem={editItem}
          visible={orderDetailDrawer}
          setVisible={setOrderDetailDrawer}
          addDetail={addDetail}
          // editDetail={editDetail}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default HatcheryReceiptList;
