import React from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { sscGrade, sscSize } from "../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;
const StorageItemForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="storage-item-form">
      <Form.Item name="grade" label="Grade" rules={[{ required: true }]}>
        <Select>
          {sscGrade.map((grade) => (
            <Option key={grade} value={grade}>
              {grade}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="size" label="Size" rules={[{ required: true }]}>
        <Select>
          {sscSize.map((grade) => (
            <Option key={grade} value={grade}>
              {grade}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} placeholder="PCS" inputMode="numeric" allowClear />
      </Form.Item>

      <Form.Item name="weightInKg" label="Weight" rules={[{ required: true }]}>
        <InputNumber min={0} placeholder="KG" inputMode="decimal" allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} />
      </Form.Item>
    </Form>
  );
};

export default StorageItemForm;
