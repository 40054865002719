import React, { useEffect, useState } from "react";
import { useDashboard } from "../../hooks/useDashboard";
import ChemicalUsage from "./components/ChemicalUsage";
import ActivityList from "./components/ActivityList";
import LarvalStage from "./components/LarvalStage";
import { Row, Col, Card, Statistic } from "antd";

const moment = require("moment");

const Dashboard = () => {
  const { findHatcheryActivity, findBroodstockData, findIncubationData, findLarvalData, findHarvestData } =
    useDashboard();
  const [refresh, setRefresh] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const [broodstockData, setBroodstockData] = useState({});
  const [incubationData, setIncubationData] = useState({});
  const [larvalData, setLarvalData] = useState({});
  const [harvestData, setHarvestData] = useState({});

  const [activityDateFilter, setActivityDateFilter] = useState(moment());

  useEffect(() => {
    if (refresh) {
      if (activityDateFilter) {
        const date = moment(activityDateFilter).format("YYYY-MM-DD");

        findHatcheryActivity(date).then((res) => {
          if (res.status === "success") {
            setActivityData(res.data);
          }
          setRefresh(false);
        });

        findBroodstockData().then((res) => {
          if (res.status === "success") {
            setBroodstockData(res.data);
          }
          setRefresh(false);
        });

        findIncubationData().then((res) => {
          if (res.status === "success" && res.data && res.data.length > 0) {
            setIncubationData({
              totalSpawn: res.data.length,
              nextHatchDate: res.data[0] ? moment(res.data[0].spawnDate).add("days", 10).format("YYYY-MM-DD") : "",
            });
          }
          setRefresh(false);
        });

        findLarvalData().then((res) => {
          if (res.status === "success") {
            setLarvalData(res.data);
          }
          setRefresh(false);
        });

        findHarvestData().then((res) => {
          if (res.status === "success") {
            setHarvestData(res.data);
          }
          setRefresh(false);
        });
      }
    }
  }, [
    refresh,
    activityDateFilter,
    findBroodstockData,
    findHarvestData,
    findHatcheryActivity,
    findIncubationData,
    findLarvalData,
  ]);

  useEffect(() => {
    if (activityDateFilter) {
      setRefresh(true);
    }
  }, [activityDateFilter]);

  return (
    <>
      <Row style={{ marginTop: 16 }} gutter={[16, 24]}>
        <Col xs={24} sm={24} md={18}>
          <Card>
            <ActivityList
              data={activityData}
              activityDateFilter={activityDateFilter}
              setActivityDateFilter={setActivityDateFilter}
            />
          </Card>

          <div style={{ height: 16 }}></div>

          <LarvalStage setRefresh={setRefresh} />

          <div style={{ height: 16 }}></div>

          <Card>
            <ChemicalUsage />
          </Card>
        </Col>

        <Col xs={24} sm={24} md={6}>
          <Card title="Broodstock" size="small">
            <Row>
              <Col span={12}>
                <Statistic
                  title={"New"}
                  value={broodstockData.newCount ?? "-"}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Spawn"}
                  value={broodstockData.spawnCount ?? "-"}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Hatch"}
                  value={broodstockData.hatchCount ?? "-"}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Discard"}
                  value={broodstockData.discardCount ?? "-"}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
            </Row>
          </Card>

          <div style={{ height: 16 }}></div>

          <Card title="Spawn / Incubation" size="small">
            <Row>
              <Col span={24}>
                <Statistic
                  title={"Total Spawn"}
                  value={
                    isNaN(incubationData.totalSpawn)
                      ? 0
                      : new Intl.NumberFormat("en-US", {
                          notation: "compact",
                        }).format(incubationData.totalSpawn)
                  }
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={24}>
                <Statistic
                  title={"Next Est. Hatch"}
                  value={incubationData.nextHatchDate ? incubationData.nextHatchDate : "-"}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
            </Row>
          </Card>

          <div style={{ height: 16 }}></div>

          <Card title="New Larval" size="small">
            <Row>
              <Col span={12}>
                <Statistic
                  title={"This Month"}
                  value={new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(larvalData.currentMonthTotal)}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Last Month"}
                  value={new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(larvalData.lastMonthTotal)}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Op. Tank"}
                  value={larvalData.operatingCount}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Total Tank"}
                  value={larvalData.tankCount}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
            </Row>
          </Card>

          <div style={{ height: 16 }}></div>

          <Card title="Harvest" size="small">
            <Row>
              <Col span={12}>
                <Statistic
                  title={"This Month"}
                  value={new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(harvestData.currentMonthTotal)}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Last Month"}
                  value={new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(harvestData.lastMonthTotal)}
                  precision={0}
                  valueStyle={{ color: "#3f8600" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Next Harvest"}
                  value={
                    harvestData.nextHarvestTank
                      ? harvestData.nextHarvestTank.map((item) => item.Tank.label).join(", ")
                      : "-"
                  }
                  precision={0}
                  valueStyle={{ color: "#3f8600", fontSize: 14 }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title={"Est. Date"}
                  value={moment(harvestData.earliestHatchDate).add(30, "day").format("YYYY-MM-DD")}
                  precision={0}
                  valueStyle={{ color: "#3f8600", fontSize: 14 }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
