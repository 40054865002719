import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFarmReceipt } from "../../hooks/useFarmReceipt";

import Filter from "../../components/farmReceipt/components/Filter";

import AddNewModal from "../../components/farmReceipt/modals/AddNewModal";
import EditModal from "../../components/farmReceipt/modals/EditModal";
import StockInModal from "../../components/farmReceipt/modals/StockInModal";
import HatcheryToFarmTable from "../../components/farmReceipt/tables/HatcheryToFarmTable";

const { Title } = Typography;

const HatcheryToFarm = () => {
  const { t } = useTranslation();

  const { findAll } = useFarmReceipt();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterStatus, setFilterStatus] = useState("New");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [stockInModal, setStockInModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll({ status: filterStatus })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editTransferToHapa = (row) => {
    setEditItem(row);
    setStockInModal(true);
  };

  return (
    <>
      <Filter filterStatus={filterStatus} setFilterStatus={setFilterStatus} setRefresh={setRefresh} />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Hatchery To Nursery Farm Transfer</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <HatcheryToFarmTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          editTransferToHapa={editTransferToHapa}
        />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <StockInModal editItem={editItem} visible={stockInModal} setVisible={setStockInModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default HatcheryToFarm;
