import React, { useState, useEffect, useContext } from "react";
import { Button, Drawer, Descriptions, Divider, Space, Modal, Row, Col } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ResponsiveContext } from "../../App";
import { useFarmStorageRecord } from "../../hooks/useFarmStorageRecord";
import moment from "moment";
import EditStorageItemModal from "./EditStorageItemModal";
import NewStorageItemModal from "./NewStorageItemModal";

const FarmStorageDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne, deleteDetail } = useFarmStorageRecord();

  const [refresh, setRefresh] = useState(false);
  const [header, setHeader] = useState({});
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [deleteItem, setDeleteItem] = useState();
  const [newStorageItemModal, setNewStorageItemModal] = useState(false);
  const [editStorageItemModal, setEditStorageItemModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    if ((visible && id) || refresh) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setHeader(res.data);
          setData(res.data.FarmStorageRecordDetails);
        }
        setRefresh(false);
      });
    }
  }, [id, visible, findOne, refresh]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditStorageItemModal(true);
  };

  const deleteRow = (item) => {
    setDeleteItem(item);
    setConfirmModal(true);
  };

  const confirmDelete = () => {
    deleteDetail(deleteItem.id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setConfirmModal(false);
      }
    });
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "70%"}>
      <Descriptions>
        <Descriptions.Item label="Date">{moment(header.recordTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Stock Movement">
          {header.stockMovement ? header.stockMovement : "-"}
        </Descriptions.Item>

        <Descriptions.Item label="Total PCS">{data.reduce((acc, cur) => acc + cur.quantity, 0)}</Descriptions.Item>
        <Descriptions.Item label="Total KG">
          {data.reduce((acc, cur) => acc + cur.weightInKg, 0).toFixed(3)}
        </Descriptions.Item>
        <Descriptions.Item label="Remarks">{header.remarks}</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">Accumulated SSC</Divider>

      <div className="flex flex-row gap-4 md:gap-8">
        {header.accumulatedStock &&
          Object.keys(header.accumulatedStock).map((key) => (
            <div key={key}>
              <div className="font-bold">{key} :</div>
              <div>
                {header.accumulatedStock[key]["quantity"] ? header.accumulatedStock[key]["quantity"] : "-"} pcs,{" "}
                {header.accumulatedStock[key]["weightInKg"] ? header.accumulatedStock[key]["weightInKg"] : "-"} kg
              </div>
            </div>
          ))}
      </div>

      <Divider />

      <Row>
        <Col>
          <Button type="primary" onClick={() => setNewStorageItemModal(true)}>
            Add
          </Button>
        </Col>
      </Row>

      <div className="mt-4">
        {data &&
          data.map((item) => (
            <div key={item.id} className="p-2 flex flex-row gap-4 md:gap-8 lg:gap-16 border-b">
              <div className="flex-none">
                <div className="text-xs text-gray-400">ID. {item.id}</div>
              </div>
              <div class="flex-grow">
                <div>
                  <div className="flex flex-row justify-between">
                    <div>
                      <div className="text-xs text-gray-400">Grade:</div>
                      <div>{item.grade}</div>
                    </div>
                    <div>
                      <div className="text-xs text-gray-400">Size:</div>
                      <div>{item.size}</div>
                    </div>
                    <div>
                      <div className="text-xs text-gray-400">Qty:</div>
                      <div>{item.quantity} pcs</div>
                    </div>
                    <div>
                      <div className="text-xs text-gray-400">Weight:</div>
                      <div>{item.weightInKg} kg</div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 italic text-xs text-gray-500">{item.remarks}</div>
              </div>
              <div class="flex-none">
                <Space>
                  <Button type="link" icon={<EditOutlined />} onClick={() => editRow(item)} />

                  <Button type="link" icon={<DeleteOutlined />} danger onClick={() => deleteRow(item)} />
                </Space>
              </div>
            </div>
          ))}
      </div>

      <NewStorageItemModal
        id={id}
        visible={newStorageItemModal}
        setVisible={setNewStorageItemModal}
        setRefresh={setRefresh}
      />

      <EditStorageItemModal
        editItem={editItem}
        visible={editStorageItemModal}
        setVisible={setEditStorageItemModal}
        setRefresh={setRefresh}
      />

      <Modal visible={confirmModal} onOk={confirmDelete} onCancel={() => setConfirmModal(false)}>
        <p>Are you sure to delete this record?</p>
      </Modal>
    </Drawer>
  );
};

export default FarmStorageDrawer;
