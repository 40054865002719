import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { usePondParameter } from "../../hooks/usePondParameter";
import moment from "moment";
import PondParameterForm from "./PondParameterForm";
import { useParameterType } from "../../hooks/useParameterType";

const NewPondParameterModal = (props) => {
  const { pond, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = usePondParameter();
  const { findAll: findParameterType } = useParameterType();

  const [submitting, setSubmitting] = useState(false);

  const [parameterOptionGroup1, setParameterOptionGroup1] = useState([]);
  const [parameterOptionGroup2, setParameterOptionGroup2] = useState([]);
  const [parameterTypeArray, setParameterTypeArray] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(),
      });

      findParameterType().then((res) => {
        if (res.status === "success") {
          setParameterOptionGroup1(
            res.data.filter((item) => item.name.toLowerCase() === "do" || item.name.toLowerCase() === "temperature")
          );
          setParameterOptionGroup2(
            res.data.filter((item) => item.name.toLowerCase() !== "do" && item.name.toLowerCase() !== "temperature")
          );
        }
      });
    }
  }, [visible, form, pond]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const parameterTypeRecord = parameterTypeArray.filter((item) => item.amount && item.amount > 0);
        const date = moment(values.recordTimestamp).format("YYYY-MM-DD");
        const time = moment(values.measureTime).format("h:mm a");
        const datetime = `${date} ${time}`;

        const params = {
          recordTimestamp: moment(datetime, "YYYY-MM-DD h:mm a").format(),
          remarks: values.remarks,
          pondId: Array.isArray(values.pondId) ? values.pondId : [values.pondId],
          parameterTypeRecord: parameterTypeRecord,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setParameterTypeArray([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"New Water Parameter Measurement"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondParameterForm
        form={form}
        parameterOptionGroup1={parameterOptionGroup1}
        parameterOptionGroup2={parameterOptionGroup2}
        parameterTypeArray={parameterTypeArray}
        setParameterTypeArray={setParameterTypeArray}
      />
    </Modal>
  );
};

export default NewPondParameterModal;
