import React from "react";
import { Form, Select, Input } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const FeedTypeForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="name" label="Product Name" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>
      <Form.Item name="uom" label="Unit of Measure" rules={[{ required: true }]}>
        <Select allowClear>
          <Option value="kg">Kilogram (kg)</Option>
          <Option value="g">Gram (g)</Option>
          <Option value="l">Litre (l)</Option>
          <Option value="ml">Mililitre (ml)</Option>
        </Select>
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default FeedTypeForm;
