import React from "react";
import { DatePicker, Form, Input, InputNumber } from "antd";

const EditForm = (props) => {
  const { form } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="productionTimestamp" label="Harvest Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="batchNo" label="Batch No.">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="broodstockNo" label="Broodstock No.">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="broodstockSpecies" label="Broodstock Species.">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="avgWeightInGram" label="Avg Weight (g)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default EditForm;
