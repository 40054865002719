import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import EditForm from "../forms/EditForm";
import moment from "moment";
import { useSSCDailyMonitor } from "../../../hooks/useSSCDailyMonitor";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useSSCDailyMonitor();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        shift: editItem.shift,
        alive: editItem.alive,
        dead: editItem.dead,
        harvest: editItem.harvest,
        missing: editItem.missing,
        stockInQuantity: editItem.stockInQuantity,
        stockOutQuantity: editItem.stockOutQuantity,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          shift: values.shift,
          alive: values.alive,
          dead: values.dead,
          harvest: values.harvest,
          missing: values.missing,
          remarks: values.remarks,
          stockInQuantity: values.stockInQuantity,
          stockOutQuantity: values.stockOutQuantity,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit SSC Daily Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <EditForm form={form} editItem={editItem} />
    </Modal>
  );
};

export default EditModal;
