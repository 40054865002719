import React, { useEffect, useState } from "react";
import { DatePicker, Form, Select, InputNumber } from "antd";
import { useFarmRawMaterial } from "../../hooks/useFarmRawMaterial";
import { usePond } from "../../hooks/usePond";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const PondCycleForm = (props) => {
  const { form, isEdit } = props;

  const { findAll } = useFarmRawMaterial();
  const { findAll: findPond } = usePond();

  const [materialOption, setMaterialOption] = useState([]);
  const [pondOption, setPondOption] = useState([]);

  useEffect(() => {
    findPond({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setPondOption(res.data);
      }
    });
    findAll().then((res) => {
      if (res.status === "success") {
        setMaterialOption(res.data);
      }
    });
  }, [findPond, findAll]);

  return (
    <Form {...layout} form={form} name="grow-out-form">
      {!isEdit && (
        <Form.Item name="pondId" label="Pond" rules={[{ required: true }]}>
          <Select allowClear>
            {pondOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item name="cycleNo" label="Cycle No" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="material" label="Material" rules={[{ required: true }]}>
        <Select>
          {materialOption.map((item) => (
            <Option key={item.id} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="startAt" label="Start Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="endAt" label="End Date">
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="stockingDensity" label="Stocking Density">
        <InputNumber min={0} allowClear />
      </Form.Item>
    </Form>
  );
};

export default PondCycleForm;
