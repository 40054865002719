import React from "react";
import { Tooltip, Typography, Dropdown, Menu, Button } from "antd";
import { EditOutlined, EllipsisOutlined, } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import QRCode from "qrcode.react";
import { QrcodeOutlined } from "@ant-design/icons";

const { Link } = Typography;

const PackingTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail, viewQrCode, retrieveQr } = props;

  const columns = [
    {
      title: "QR Code(s)",
      render: (text, row) => 
      <div style={{ display: "flex", justifyContent: "left", fontSize: '32px' }}>
          <Tooltip title="View Generated QR Codes">
            <QrcodeOutlined onClick={() => viewQrCode(row)} />
          </Tooltip>
        </div>
    },
    {
      title: "Packed on",
      render: (text, row) => moment(row.packTimestamp).format("YYYY-MM-DD, h:mm:ss a"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={<EditOutlined />}
                onClick={() => editRow(row)}
              >
                Edit
              </Menu.Item>
              

              <Menu.Item
                key="retrieveQR"
                icon={<QrcodeOutlined />}
                onClick={() => retrieveQr(row)}
              >
                Retrieve QR Code
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
        // <Tooltip title="Edit">
        //   <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        // </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader error={error} loading={loading} dataSource={dataSource} columns={columns} style={{ marginTop: 24 }} />
  );
};

export default PackingTable;
