export const hatcheryType = ["Broodstock Species", "Broodstock Source", "Broodstock Tank"];

export const hatcheryShed = ["1", "2", "3"];

export const larvalStatus = ["New", "Transfer Out"];

export const hatcheryHarvestStatus = ["New", "Transfer Out"];

export const hatcheryTransferStatus = ["New", "Complete"];

export const tankTransferStatus = ["All", "New", "Transfer Out"];

export const farmReceiptStatus = ["New", "Complete"];

export const growOutStatus = ["All", "New", "Complete"];

export const pondStatus = ["All", "New"];

export const harvestShift = ["Shift 1 (6am to 2pm)", "Shift 2 (2pm to 10pm)", "Shift 3 (10pm to 6am)"];

export const farmProductionStatus = ["New", "Delivery", "Complete"];

export const sscHarvestDailyStatus = ["New", "Confirm"];

export const farmProductionType = ["Soft Shell Crab", "Hard Shell Crab", "Shrimp", "Crablet"];

export const deliveryProductType = ["Soft Shell Crab", "Hard Shell Crab", "Shrimp", "Crablet"];

export const sscGrade = ["A", "AF", "B", "BF"];

export const sscSize = ["Under 50", "50/69", "70/99", "100/119", "120/149", "150/199", "Above 200"];

export const sscSource = [
  { value: "Internal", color: "green" },
  { value: "External", color: "red" },
];

export const farmDeliveryStatus = ["New", "Complete", "Cancelled"];

export const factoryReceiptStatus = ["New", "Complete"];

export const factoryProductionStatus = ["New", "Frozen", "Delivered"];

export const babyPackStatus = ["New", "Master Pack", "Delivered", "Discard"];

export const packingStatus = ["New", "Delivered"];

export const masterPackStatus = ["New", "Storage", "Delivered", "Discard"];

export const processingOrderStatus = ["New", "Complete", "Cancelled"];

export const qrDomain = [
  { key: "https://neocrab.sinisana.net/api/qr", value: "Live" },
  { key: "https://ncstaging.sinisana.net/api/qr", value: "Test" },
];

export const qrType = ["Internal", "Public"];

export const qrStatus = ["New", "Activated", "Discard"];

export const pondType = ["Grow Out", "Pontoon", "Nursery", "Greenhouse", "Polyculture", "HSC"];

export const unitOfMeasure = {
  kg: "Kilogram (kg)",
  g: "Gram (g)",
  l: "Litre (l)",
  ml: "Mililitre (ml)",
  pkt: "Pack (pkt)",
  pcs: "Pieces (pcs)",
  "%": "Percentage (%)",
  ppm: "Parts per million (ppm)",
  ppt: "Parts per trillion (ppt)",
  pH: "pH",
  C: "Celsius (C)",
  cm: "Centimeter (cm)",
};
