import React from "react";
import { DatePicker, Form, Select, Input } from "antd";
import moment from "moment";
import { farmReceiptStatus } from "../../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const EditForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form" labelAlign="left">
      <Form.Item name="arrivalTimestamp" label="Arrival Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="vehicleNumber" label="Vehicle Number">
        <Input />
      </Form.Item>

      <Form.Item name="personInCharge" label="Delivered By">
        <Input />
      </Form.Item>

      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Select>
          {farmReceiptStatus.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default EditForm;
