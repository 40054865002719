import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import { useFarmReceipt } from "../../../hooks/useFarmReceipt";
import ExternalToFarmForm from "../ExternalToFarmForm";

const NewExternalToFarmModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFarmReceipt();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        arrivalTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          location: values.location,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          farmQuantity: values.farmQuantity,
          weightInKg: values.weightInKg,
          dead: values.dead,
          small: values.small,
          remarks: values.remarks,
          sourceType: "External",
          sourceName: values.sourceName,
          deadWeightInKg: values.deadWeightInKg,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New External to Nursery Farm Transfer"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <ExternalToFarmForm form={form} />
    </Modal>
  );
};

export default NewExternalToFarmModal;
