import React, { useState, useEffect } from "react";
import { Descriptions, Row, Col, Card, Select, Typography, Alert, Divider } from "antd";
import { usePond } from "../../../hooks/usePond";
import { render } from "@testing-library/react";
const moment = require("moment");

const { Option } = Select;

const PondTab = () => {
  const { findAll, findOne } = usePond();

  const [selectedPond, setSelectedPond] = useState();
  const [pondOption, setPondOption] = useState([]);

  const [pondData, setPondData] = useState({});
  const [pondCycleData, setPondCycleData] = useState({});
  const [pondPreparationData, setPondPreparationData] = useState([]);
  const [pondFeedData, setPondFeedData] = useState([]);
  const [pondTreatmentData, setPondTreatmentData] = useState([]);
  const [pondParameterData, setPondParameterData] = useState([]);
  const [pondSamplingData, setPondSamplingData] = useState([]);
  const [pondHarvestData, setPondHarvestData] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    findAll({ status: "All" }).then((res) => {
      if (res.status === "success") {
        setPondOption(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedPond) {
      findOne(selectedPond).then((res) => {
        if (res.status === "success") {
          setPondData(res.data.pond);
          setPondCycleData(res.data.growOut);
          setPondPreparationData(res.data.pondPreparation);
          setPondFeedData(res.data.pondFeed);
          setPondTreatmentData(res.data.pondTreatment);
          setPondParameterData(res.data.pondParameter);
          setPondSamplingData(res.data.pondSampling);
          setPondHarvestData(res.data.pondHarvest);
          setError("");
        } else {
          setError(res.message);
        }
      });
    }
  }, [selectedPond]);

  const renderPondPreparationSummary = (data = []) => {
    let obj = {};

    for (const item of data) {
      if (item.PondPreparationDetails && item.PondPreparationDetails.length > 0) {
        for (const detail of item.PondPreparationDetails) {
          if (obj[detail.preparationType + " (" + detail.uom + ")"])
            obj[detail.preparationType + " (" + detail.uom + ")"] += Number(detail.amount);
          else obj[detail.preparationType + " (" + detail.uom + ")"] = Number(detail.amount);
        }
      }
    }

    return [
      ...Object.keys(obj).map((key) => {
        return (
          <Descriptions.Item label={key} key={key}>
            {Number(obj[key]).toLocaleString()}
          </Descriptions.Item>
        );
      }),
      <Descriptions.Item label="Total">
        <Typography.Text mark>{Number(Object.values(obj).reduce((a, b) => a + b, 0)).toLocaleString()}</Typography.Text>
      </Descriptions.Item>,
    ];
  };

  const renderPondFeedSummary = (data = []) => {
    let obj = {};

    for (const item of data) {
      if (item.PondFeedDetails && item.PondFeedDetails.length > 0) {
        for (const detail of item.PondFeedDetails) {
          if (obj[detail.feedType + " (" + detail.uom + ")"])
            obj[detail.feedType + " (" + detail.uom + ")"] += Number(detail.amount);
          else obj[detail.feedType + " (" + detail.uom + ")"] = Number(detail.amount);
        }
      }
    }

    return [
      ...Object.keys(obj).map((key) => {
        return (
          <Descriptions.Item label={key} key={key}>
            {Number(obj[key]).toLocaleString()}
          </Descriptions.Item>
        );
      }),
      <Descriptions.Item label="Total">
        <Typography.Text mark>{Number(Object.values(obj).reduce((a, b) => a + b, 0)).toLocaleString()}</Typography.Text>
      </Descriptions.Item>,
    ];
  };

  const renderPondTreatmentSummary = (data = []) => {
    let obj = {};

    for (const item of data) {
      if (item.PondTreatmentDetails && item.PondTreatmentDetails.length > 0) {
        for (const detail of item.PondTreatmentDetails) {
          if (obj[detail.treatmentType + " (" + detail.uom + ")"])
            obj[detail.treatmentType + " (" + detail.uom + ")"] += Number(detail.amount);
          else obj[detail.treatmentType + " (" + detail.uom + ")"] = Number(detail.amount);
        }
      }
    }

    return [
      ...Object.keys(obj).map((key) => {
        return (
          <Descriptions.Item label={key} key={key}>
            {Number(obj[key]).toLocaleString()}
          </Descriptions.Item>
        );
      }),
      <Descriptions.Item label="Total">
        <Typography.Text mark>{Number(Object.values(obj).reduce((a, b) => a + b, 0)).toLocaleString()}</Typography.Text>
      </Descriptions.Item>,
    ];
  };

  // const renderPondParameterSummary = (data = []) => {
  //   let obj = {};

  //   for (const item of data) {
  //     if (item.PondParameterDetails && item.PondParameterDetails.length > 0) {
  //       for (const detail of item.PondParameterDetails) {
  //         if (obj[detail.parameterType + " (" + detail.uom + ")"])
  //           obj[detail.parameterType + " (" + detail.uom + ")"] += Number(detail.amount);
  //         else obj[detail.parameterType + " (" + detail.uom + ")"] = Number(detail.amount);
  //       }
  //     }
  //   }

  //   return [
  //     ...Object.keys(obj).map((key) => {
  //       return (
  //         <Descriptions.Item label={key} key={key}>
  //           {Number(obj[key]).toLocaleString()}
  //         </Descriptions.Item>
  //       );
  //     }),
  //     <Descriptions.Item label="Total">
  //       <Typography.Text mark>{Number(Object.values(obj).reduce((a, b) => a + b, 0)).toLocaleString()}</Typography.Text>
  //     </Descriptions.Item>,
  //   ];
  // };

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} flex="none">
          <Select
            style={{ width: 200 }}
            value={selectedPond}
            onChange={(value) => setSelectedPond(value)}
            allowClear
            placeholder={"Select a pond"}
          >
            {pondOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {error ? (
        <Card style={{ marginTop: 16 }}>
          <Alert message={error} type="error" />
        </Card>
      ) : pondData.id ? (
        <>
          <Card style={{ marginTop: 16 }} bodyStyle={{ padding: 0 }}>
            <Descriptions bordered>
              <Descriptions.Item label="Pond No">
                <Typography.Text strong>{pondData.label}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Cycle No">
                <Typography.Text strong>{pondCycleData.cycleNo}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Material">
                <Typography.Text strong>{pondCycleData.material}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="DOC">
                <Typography.Text strong>
                  {moment.duration(moment().startOf("day").diff(moment(pondCycleData.startAt).startOf("day"))).asDays()}
                </Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Stock Date">
                <Typography.Text strong>{moment(pondCycleData.startAt).format("ll")}</Typography.Text>
              </Descriptions.Item>
              <Descriptions.Item label="Stock Density (pcs)">
                <Typography.Text strong>{Number(pondCycleData.stockingDensity).toLocaleString()}</Typography.Text>
              </Descriptions.Item>
            </Descriptions>
          </Card>

          <Card style={{ marginTop: 16 }} title="Pond Preparation">
            <Descriptions size="small">
              {pondPreparationData.length ? (
                renderPondPreparationSummary(pondPreparationData)
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions>

            {pondPreparationData.length > 0 && (
              <>
                <Divider orientation="left">Last Preparation</Divider>

                <Descriptions size="small">
                  <Descriptions.Item label="Date">
                    <Typography.Text strong>
                      {moment(pondPreparationData[0].recordTimestamp).format("ll")}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Activity">
                    <Typography.Text strong>
                      {pondPreparationData[0].activity ? pondPreparationData[0].activity : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  {pondPreparationData[0].PondPreparationDetails &&
                  pondPreparationData[0].PondPreparationDetails.length > 0
                    ? pondPreparationData[0].PondPreparationDetails.map((item) => (
                        <Descriptions.Item label={item.preparationType + " (" + item.uom + ")"} key={item.id}>
                          <Typography.Text strong>{Number(item.amount).toLocaleString()}</Typography.Text>
                        </Descriptions.Item>
                      ))
                    : null}
                </Descriptions>
              </>
            )}
          </Card>

          <Card style={{ marginTop: 16 }} title="Feeding">
            <Descriptions size="small">
              {pondFeedData.length ? (
                renderPondFeedSummary(pondFeedData)
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions>

            {pondFeedData.length > 0 && (
              <>
                <Divider orientation="left">Last Feeding</Divider>

                <Descriptions size="small">
                  <Descriptions.Item label="Date">
                    <Typography.Text strong>{moment(pondFeedData[0].recordTimestamp).format("ll")}</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Activity">
                    <Typography.Text strong>
                      {pondFeedData[0].activity ? pondFeedData[0].activity : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  {pondFeedData[0].PondFeedDetails && pondFeedData[0].PondFeedDetails.length > 0
                    ? pondFeedData[0].PondFeedDetails.map((item) => (
                        <Descriptions.Item label={item.feedType + " (" + item.uom + ")"} key={item.id}>
                          <Typography.Text strong>{Number(item.amount).toLocaleString()}</Typography.Text>
                        </Descriptions.Item>
                      ))
                    : null}
                </Descriptions>
              </>
            )}
          </Card>

          <Card style={{ marginTop: 16 }} title="Treatment">
            <Descriptions size="small">
              {pondTreatmentData.length ? (
                renderPondTreatmentSummary(pondTreatmentData)
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions>

            {pondTreatmentData.length > 0 && (
              <>
                <Divider orientation="left">Last Treatment</Divider>

                <Descriptions size="small">
                  <Descriptions.Item label="Date">
                    <Typography.Text strong>
                      {moment(pondTreatmentData[0].recordTimestamp).format("ll")}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Remarks">
                    <Typography.Text strong>
                      {pondTreatmentData[0].remarks ? pondTreatmentData[0].remarks : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  {pondTreatmentData[0].PondTreatmentDetails && pondTreatmentData[0].PondTreatmentDetails.length > 0
                    ? pondTreatmentData[0].PondTreatmentDetails.map((item) => (
                        <Descriptions.Item label={item.treatmentType + " (" + item.uom + ")"} key={item.id}>
                          <Typography.Text strong>{Number(item.amount).toLocaleString()}</Typography.Text>
                        </Descriptions.Item>
                      ))
                    : null}
                </Descriptions>
              </>
            )}
          </Card>

          <Card style={{ marginTop: 16 }} title="Water Parameter">
            {/* <Descriptions size="small">
              {pondParameterData.length ? (
                renderPondParameterSummary(pondParameterData)
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions> */}

            {pondParameterData.length > 0 && (
              <>
                <Divider orientation="left">Last Water Parameter Measurement</Divider>

                <Descriptions size="small">
                  <Descriptions.Item label="Date">
                    <Typography.Text strong>
                      {moment(pondParameterData[0].recordTimestamp).format("ll")}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Remarks">
                    <Typography.Text strong>
                      {pondParameterData[0].remarks ? pondParameterData[0].remarks : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  {pondParameterData[0].PondParameterDetails && pondParameterData[0].PondParameterDetails.length > 0
                    ? pondParameterData[0].PondParameterDetails.map((item) => (
                        <Descriptions.Item label={item.parameterType + " (" + item.uom + ")"} key={item.id}>
                          <Typography.Text strong>{Number(item.amount).toLocaleString()}</Typography.Text>
                        </Descriptions.Item>
                      ))
                    : null}
                </Descriptions>
              </>
            )}
          </Card>

          <Card style={{ marginTop: 16 }} title="Sampling">
            <Divider orientation="left">Last Water Parameter Measurement</Divider>

            <Descriptions size="small">
              {pondSamplingData.length > 0 ? (
                <>
                  <Descriptions.Item label="Date">
                    <Typography.Text strong>{moment(pondSamplingData[0].recordTimestamp).format("ll")}</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"ABW (g)"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].avgWeightInGram
                        ? Number(pondSamplingData[0].avgWeightInGram).toLocaleString()
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Est. Survival (%)"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].estSurvivalInPerc
                        ? Number(pondSamplingData[0].estSurvivalInPerc).toLocaleString()
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Est. Biomass (kg)"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].estBiomassInKg
                        ? Number(pondSamplingData[0].estBiomassInKg).toLocaleString()
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Running Feed (kg)"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].runningFeedInKg
                        ? Number(pondSamplingData[0].runningFeedInKg).toLocaleString()
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Running FCR"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].runningFCR ? Number(pondSamplingData[0].runningFCR).toLocaleString() : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Weight Gained (g)"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].weightGainInGram
                        ? Number(pondSamplingData[0].weightGainInGram).toLocaleString()
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label={"Stock Density (pcs)"}>
                    <Typography.Text strong>
                      {pondSamplingData[0].stockDensityInPcs
                        ? Number(pondSamplingData[0].stockDensityInPcs).toLocaleString()
                        : "-"}
                    </Typography.Text>
                  </Descriptions.Item>

                  <Descriptions.Item label="Remarks">
                    <Typography.Text strong>
                      {pondSamplingData[0].remarks ? pondSamplingData[0].remarks : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                </>
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions>
          </Card>

          <Card style={{ marginTop: 16 }} title="Harvest">
            <Divider orientation="left">Accumulate</Divider>

            <Descriptions size="small">
              {pondHarvestData.length ? (
                <>
                  <Descriptions.Item label="Small Crab (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalSmallInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="For SSC (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalSSCInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="HSC (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalHSCInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Shrimp (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalShrimpInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Greenhouse (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalGreenhouseInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Dead (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalDeadInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Broodstock (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalBroodstockInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Total (pcs)">
                    <Typography.Text strong mark>
                      {pondHarvestData.reduce((acc, cur) => acc + cur.totalHarvestInPcs, 0)}
                    </Typography.Text>
                  </Descriptions.Item>
                </>
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions>

            <Divider orientation="left">Last Harvest</Divider>

            <Descriptions size="small">
              {pondHarvestData.length > 0 ? (
                <>
                  <Descriptions.Item label="Date">
                    <Typography.Text strong>{moment(pondHarvestData[0].recordTimestamp).format("ll")}</Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Small Crab (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalSmallInPcs ? pondHarvestData[0].totalSmallInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="For SSC (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalSSCInPcs ? pondHarvestData[0].totalSSCInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="HSC (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalHSCInPcs ? pondHarvestData[0].totalHSCInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Shrimp (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalShrimpInPcs ? pondHarvestData[0].totalShrimpInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Greenhouse (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalGreenhouseInPcs ? pondHarvestData[0].totalGreenhouseInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Dead (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalDeadInPcs ? pondHarvestData[0].totalDeadInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Broodstock (pcs)">
                    <Typography.Text strong>
                      {pondHarvestData[0].totalBroodstockInPcs ? pondHarvestData[0].totalBroodstockInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                  <Descriptions.Item label="Total (pcs)">
                    <Typography.Text strong mark>
                      {pondHarvestData[0].totalHarvestInPcs ? pondHarvestData[0].totalHarvestInPcs : "-"}
                    </Typography.Text>
                  </Descriptions.Item>
                </>
              ) : (
                <Descriptions.Item>No Data</Descriptions.Item>
              )}
            </Descriptions>
          </Card>
        </>
      ) : (
        <Card style={{ marginTop: 16 }}>
          <Alert message="Please select a pond to view the data." type="info" />
        </Card>
      )}
    </>
  );
};

export default PondTab;
