import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { usePondFeed } from "../../hooks/usePondFeed";
import PondFeedForm from "./PondFeedForm";
import moment from "moment";
import { useFeedType } from "../../hooks/useFeedType";

const EditPondFeedModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePondFeed();
  const { findAll: findFeedType } = useFeedType();

  const [submitting, setSubmitting] = useState(false);
  const [feedTypeOption, setFeedTypeOption] = useState([]);
  const [feedTypeArray, setFeedTypeArray] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        feedActivity: editItem.feedActivity,
      });
      findFeedType().then((res) => {
        if (res.status === "success") {
          let options = [...res.data];

          for (let option of options) {
            let defaultValue = 0;
            for (const item of editItem.PondFeedDetails) {
              if (item.feedType === option.name && item.uom === option.uom) {
                defaultValue = item.amount;
              }
            }
            option.defaultAmount = defaultValue;
          }

          setFeedTypeOption(options);
        }
      });
      setFeedTypeArray(
        editItem.PondFeedDetails.map((item) => {
          return {
            name: item.feedType,
            amount: item.amount,
            uom: item.uom,
          };
        })
      );
    } else {
      setFeedTypeOption([]);
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const feedTypeRecord = feedTypeArray.filter((item) => item.amount && item.amount > 0);

        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          feedActivity: values.feedActivity,
          feedTypeRecord: feedTypeRecord,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Feeding"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondFeedForm
        form={form}
        feedTypeOption={feedTypeOption}
        feedTypeArray={feedTypeArray}
        setFeedTypeArray={setFeedTypeArray}
        isEdit
      />
    </Modal>
  );
};

export default EditPondFeedModal;
