import React from "react";
import { Tooltip, Space } from "antd";
import { EditOutlined, CopyOutlined } from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import { blue } from "@ant-design/colors";
import moment from "moment";
import ExportToExcel from "../../common/ExportToExcel";

const DailyRecordTable = (props) => {
  const { dataSource, loading, error, tankData, editRow, editDuplicate } = props;

  const columns = [
    {
      title: "DOC",
      fixed: "left",
      dataIndex: "recordTimestamp",
      // render: (text) => moment(text).diff(moment(tankData.stockInTimestamp), 'days')
      render: (text) => moment(text).startOf("day").diff(moment(tankData.hatchDate).startOf("day"), "days", false),
    },
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("YYYY/MM/DD"),
    },
    {
      title: "Stage",
      dataIndex: "larvalStage",
    },
    {
      title: "Density (/L)",
      dataIndex: "larvalDensity",
      // render: (text, row) => (text ? text : <FormOutlined onClick={() => editLarvalDensity(row)} />),
      render: (text, row) => (text ? text : "-"),
    },
    {
      title: "Salinity (ppt)",
      dataIndex: "salinityInPpt",
      // render: (text, row) => (
      //   <Space>
      //     <FormOutlined onClick={() => editSalinity(row)} />
      //     {row.salinity ? <CheckOutlined style={{ color: green.primary }} /> : null}
      //   </Space>
      // ),
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Temperature (C)",
      children: [
        {
          title: "6 a.m.",
          dataIndex: "temperature1",
          render: (text) => (text ? text : "-"),
          // render: (text, row) => (
          //   <Space>
          //     <FormOutlined onClick={() => editTemperature(row)} />
          //     {row.temperature ? <CheckOutlined style={{ color: green.primary }} /> : null}
          //   </Space>
          // ),
        },
        {
          title: "3 p.m.",
          dataIndex: "temperature2",
          render: (text) => (text ? text : "-"),
        },
      ],
    },
    {
      title: "Artemia Fed (g)",
      children: [
        {
          title: "Type",
          dataIndex: "artemiaType",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "6 a.m.",
          dataIndex: "artemiaFedInGram1",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "3 p.m.",
          dataIndex: "artemiaFedInGram2",
          render: (text) => (text ? text : "-"),
        },
      ],
    },
    {
      title: "Microparticulate (g/Tank)",
      children: [
        {
          title: "9 a.m.",
          dataIndex: "microInGram1",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "3 p.m.",
          dataIndex: "microInGram2",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "10 p.m.",
          dataIndex: "microInGram3",
          render: (text) => (text ? text : "-"),
        },
      ],
    },
    {
      title: "Water Treatment",
      children: [
        {
          title: "Oregano (ml)",
          dataIndex: "oreganoInMl",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "K-5 (g)",
          dataIndex: "k5InGram",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "M-Plus (g)",
          dataIndex: "mplus",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "EDTA (g)",
          dataIndex: "edta",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "Tystatin (ml)",
          dataIndex: "tystatin",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "Cifro (tablets)",
          dataIndex: "cifro",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "Super MS (ml)",
          dataIndex: "superms",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "Shrimp Favour (g)",
          dataIndex: "shrimpFavour",
          render: (text) => (text ? text : "-"),
        },
        {
          title: "GERM Free (tablets)",
          dataIndex: "germFree",
          render: (text) => (text ? text : "-"),
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: 400,
      render: (text, row) => (text ? text : "-"),
    },
    {
      title: "Action",
      fixed: "right",
      // width: 100,
      render: (text, row) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
          </Tooltip>
          <Tooltip title="Duplicate">
            <CopyOutlined style={{ color: blue.primary }} onClick={() => editDuplicate(row)} />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <>
      <ExportToExcel docName="Daily Larval Record" columns={columns} dataSource={dataSource} />
      <TableLoader
        size="small"
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        scroll={{ x: "max-content" }}
        bordered
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <>
        //       <Row>
        //         <Col>
        //           <p>Remarks: {record.remarks ? record.remarks : "-"}</p>
        //         </Col>
        //       </Row>
        //       <Row gutter={[24, 16]}>
        //         <Col span={8}>
        //           <Card size="small" title="Salinity (ppt)">
        //             <Row gutter={[24, 16]}>
        //               {record.salinity &&
        //                 record.salinity.map((item) => (
        //                   <Col span={12} key={item.key}>
        //                     <Text strong>{item.key}</Text> : <Text>{item.value ? item.value : "-"}</Text>
        //                   </Col>
        //                 ))}
        //             </Row>
        //           </Card>
        //         </Col>

        //         <Col span={8}>
        //           <Card size="small" title="Temperature (C)">
        //             <Row gutter={[24, 16]}>
        //               {record.temperature &&
        //                 record.temperature.map((item) => (
        //                   <Col span={12} key={item.key}>
        //                     <Text strong>{item.key}</Text> : <Text>{item.value ? item.value : "-"}</Text>
        //                   </Col>
        //                 ))}
        //             </Row>
        //           </Card>
        //         </Col>

        //         <Col span={8}>
        //           <Card size="small" title="Artemia">
        //             <Row gutter={[24, 16]}>
        //               {record.artemia &&
        //                 record.artemia.map((item) => (
        //                   <Col span={12} key={item.key}>
        //                     <Text strong>{item.key}</Text> : <Text>{item.value ? item.value : "-"}</Text>
        //                   </Col>
        //                 ))}
        //             </Row>
        //           </Card>
        //         </Col>

        //         <Col span={8}>
        //           <Card size="small" title="Microparticulate">
        //             <Row gutter={[24, 16]}>
        //               {record.microparticulate &&
        //                 record.microparticulate.map((item) => (
        //                   <Col span={12} key={item.key}>
        //                     <Text strong>{item.key}</Text> : <Text>{item.value ? item.value : "-"}</Text>
        //                   </Col>
        //                 ))}
        //             </Row>
        //           </Card>
        //         </Col>
        //         <Col span={16}>
        //           <Card size="small" title="Water Treatment">
        //             <Row gutter={[24, 16]}>
        //               {record.waterTreatment &&
        //                 record.waterTreatment.map((item) => (
        //                   <Col span={8} key={item.key}>
        //                     <Text strong>{item.key}</Text> : <Text>{item.value ? item.value : "-"}</Text>
        //                   </Col>
        //                 ))}
        //             </Row>
        //           </Card>
        //         </Col>
        //       </Row>
        //     </>
        //   ),
        //   rowExpandable: (record) => record.parameter !== null,
        // }}
      />
    </>
  );
};

export default DailyRecordTable;
