import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

const OpenTransferTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, setSelectedRows } = props;

  const columns = [
    {
      title: "Batch",
      dataIndex: "batchNo",
    },
    {
      title: "Grade",
      dataIndex: "grade",
    },
    {
      title: "Weight(KG)",
      dataIndex: "weightRangeInKg",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Freezing Duration",
      render: (text, row) =>
        row.FactoryFreezingRecord
          ? moment(row.FactoryFreezingRecord.startTimestamp).format("h:mm a") +
            " to " +
            moment(row.FactoryFreezingRecord.endTimestamp).format("h: mm a")
          : "-",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <Table
      rowKey="id"
      rowSelection={{
        ...rowSelection,
      }}
      columns={columns}
      dataSource={dataSource}
      style={{ marginTop: 24 }}
    />
  );
};

export default OpenTransferTable;
