import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Divider, Typography, InputNumber } from "antd";
import ProcessingOrderForm from "../../components/processingOrder/ProcessingOrderForm";
import moment from "moment";
import { useProcessingOrder } from "../../hooks/useProcessingOrder";
import { ResponsiveContext } from "../../App";
import { useFactoryProduction } from "../../hooks/useFactoryProduction";
import { usePackingBatch } from "../../hooks/usePackingBatch";
import ProcessingSearchOrder from "./tables/ProcessingSearchOrder";

const { Text } = Typography;

const NewProcessingOrderModal = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const [form] = Form.useForm();

  const { create } = useProcessingOrder();
  const { findAll, findBoxBatches } = useFactoryProduction();
  const { findAllDetails } = usePackingBatch();

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [batchData, setBatchData] = useState({});
  const [softShellGrade, setSoftShellGrade] = useState();
  const [packingData, setPackingData] = useState([]);
  const [selectedPackingData, setSelectedPackingData] = useState([]);
  const [batchMetadata, setBatchMetadata] = useState([]);

  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        location: "Bako Processing",
        orderDate: moment(),
        productType: "Soft Shell Crab",
        batchArrayId: [id],
      });
    }
  }, [visible, form]);

  useEffect(() => {
    findAllDetails({ status: "Frozen" }).then((res) => {
      if (res.status === "success") {
        setPackingData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    const packingDataStr = selectedPackingData.join(",");
    if (selectedPackingData.length > 0) {
      findBoxBatches({ status: "Frozen", batchId: packingDataStr }).then(
        (res) => {
          if (res.status === "success") {
            setButtonDisabled(false)
            
            const unique = [
              ...new Set(
                res.data.map((item) => item.factoryProductionRecordId)
              ),
            ];

            let massagedData = [];
            let metadata = [];

            unique.forEach((factoryProductionRecordId) => {
              let groupedData = [];
              res.data.forEach((data) => {
                if (
                  data.factoryProductionRecordId === factoryProductionRecordId
                ) {
                  groupedData.push(data);
                }
              });

              if (groupedData.length > 0) {
                massagedData[groupedData[0].factoryProductionRecordId] = groupedData;
                metadata[groupedData[0].factoryProductionRecordId] = groupedData[0].FactoryProductionRecord
              }
            });
            
            setBatchMetadata(metadata);
            setBatchData(massagedData);
          }
        }
      );
    }

    // findAll({ status: "Frozen" }).then((res) => {
    //   console.log("res", res.data)
    //   if (res.status === "success") {
    //     setBatchData(res.data);
    //   }
    // });
  }, [selectedPackingData]);

  const handleOk = () => {
    let orderDetailData = [];
    
    Object.keys(batchData).forEach((key) => {
      batchData[key].forEach((detail) => {
        if (detail.soldQuantity > 0) {
          orderDetailData.push({
            factoryProductionDetailId: detail.id,
            grade: detail.grade,
            size: detail.size,
            soldQuantity: detail.soldQuantity,
            soldWeightInKg: detail.soldWeightInKg,
          });
        }
      });
    });
    
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          orderNo: values.orderNo,
          location: values.location,
          deliverTo: values.deliverTo,
          orderDate: moment(values.orderDate).format(),
          productType: values.productType,
          remarks: values.remarks,
          orderDetailData: orderDetailData,
        };
        
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setBatchData([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onQuantityChange = (batch, value) => {
    const index = batchData[batch.factoryProductionRecordId].findIndex((item) => item.id === batch.id);

    if (index > -1 && value <= batchData[batch.factoryProductionRecordId][index].balanceQuantity) {
      const newBatchData = {...batchData};
      newBatchData[batch.factoryProductionRecordId][index].soldQuantity = value;
      
      setBatchData(newBatchData);
    }
  };
  // const onQuantityChange = (batch, detail, value) => {
  //   const index = batchData.findIndex((item) => item.id === batch.id);

  //   if (index > -1) {
  //     const newBatchData = [...batchData];
  //     newBatchData[index].FactoryProductionDetails.forEach((item) => {
  //       if (item.id === detail.id) {
  //         item.soldQuantity = value;
  //       }
  //     });
  //     setBatchData(newBatchData);
  //   }
  // };

  const onWeightChange = (batch, value) => {
    const index = batchData[batch.factoryProductionRecordId].findIndex((item) => item.id === batch.id);

    if (index > -1 && value <= batchData[batch.factoryProductionRecordId][index].balanceWeightInKg) {
      const newBatchData = {...batchData};
      newBatchData[batch.factoryProductionRecordId][index].soldWeightInKg = value;

      setBatchData(newBatchData);
    }
  };

  // const onWeightChange = (batch, detail, value) => {
  //   const index = batchData.findIndex((item) => item.id === batch.id);

  //   if (index > -1) {
  //     const newBatchData = [...batchData];
  //     newBatchData[index].FactoryProductionDetails.forEach((item) => {
  //       if (item.id === detail.id) {
  //         item.soldWeightInKg = value;
  //       }
  //     });
  //     setBatchData(newBatchData);
  //   }
  // };

  return (
    <Modal
      title="New Delivery Order"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      okButtonProps={{ disabled: buttonDisabled }}
      width={isMobile ? null : "50%"}
    >
      <ProcessingSearchOrder
        setSelectedPackingData={setSelectedPackingData}
        selectedPackingData={selectedPackingData}
        data={packingData}
        setRefresh={setRefresh}
      />

      {selectedPackingData.length > 0 ? (
        <>
          <ProcessingOrderForm form={form} />

          <Divider orientation="left">SSC Packaging Batch</Divider>
        </>
      ) : null}

      {error && <Text type="danger">Please input at least one quantity </Text>}

      {Object.keys(batchData).map(id => (
          <>
            <div className="flex p-1 gap-4 text-lg">
              <div className="flex-shrink">#{id}</div>
              <div className="">
                <span className="text-xs text-gray-400 mr-1">Batch:</span>
                {batchMetadata[id]?.batchNo}
              </div>
              <div className="">
                <span className="text-xs text-gray-400 mr-1">Date:</span>
                {moment(
                  batchMetadata[id]?.productionTimestamp
                ).format("DD MMM YYYY")}
              </div>
            </div>
            {batchData[id].map((item) => (
              item.balanceQuantity > 0 && (
                <div key={id} className="border-b mt-2 mb-2">
                  {/* {batch.FactoryProductionDetails.map((detail) => ( */}
                  <div key={id} className="flex p-1 gap-4 md:ml-8 my-1">
                    <div className="">Grade {item.grade}</div>
                    <div className="flex-1">{item.size}</div>
                    <div>
                      <InputNumber
                        // value={item.soldQuantity}
                        placeholder="PCS"
                        max={item.balanceQuantity}
                        onChange={(value) => onQuantityChange(item, value)}
                      />
                      <div className="text-xs text-gray-400">
                        Max {item.balanceQuantity} pcs
                      </div>
                    </div>
                    <div>
                      <InputNumber
                        // value={item.soldWeightInKg}
                        placeholder="KG"
                        max={item.balanceWeightInKg}
                        onChange={(value) => onWeightChange(item, value)}
                      />
                      <div className="text-xs text-gray-400">
                        Max {item.balanceWeightInKg.toFixed(2)} kg
                      </div>
                    </div>
                  </div>
                </div>
              )
            ))}
          </>
        ))}
      {/* {batchData.length > 0 &&
        batchData.map((batch) => (
          <div key={batch.id} className="border-b mt-2 mb-2">
            <div className="flex p-1 gap-4 text-lg">
              <div className="flex-shrink">#{batch.id}</div>
              <div className="">
                <span className="text-xs text-gray-400 mr-1">Batch:</span>
                {batch.batchNo}
              </div>
              <div className="">
                <span className="text-xs text-gray-400 mr-1">Date:</span>
                {moment(batch.productTimestamp).format("DD MMM YYYY")}
              </div>
            </div>
            {batch.FactoryProductionDetails.map((detail) => (
              <div key={detail.id} className="flex p-1 gap-4 md:ml-8 my-1">
                <div className="">Grade {detail.grade}</div>
                <div className="flex-1">{detail.size}</div>
                <div>
                  <InputNumber
                    placeholder="PCS"
                    max={detail.balanceQuantity}
                    onChange={(value) => onQuantityChange(batch, detail, value)}
                  />
                  <div className="text-xs text-gray-400">
                    Max {detail.balanceQuantity} pcs
                  </div>
                </div>
                <div>
                  <InputNumber
                    placeholder="KG"
                    max={detail.balanceWeightInKg}
                    onChange={(value) => onWeightChange(batch, detail, value)}
                  />
                  <div className="text-xs text-gray-400">
                    Max {detail.balanceWeightInKg} kg
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))} */}
    </Modal>
  );
};

export default NewProcessingOrderModal;
