import React from "react";
import { Card, Descriptions, Typography } from "antd";
import moment from "moment";

const { Link } = Typography;

const MaterialReceiptCard = (props) => {
  const { title, data, viewDetail } = props;

  return (
    <Card title={title} size="small" {...props}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="Material Receipt ID">{data.id ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Date">{moment(data.arrivalTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Person">{data.personInCharge ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Vehicle">{data.vehicleNumber ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Remarks">{data.remarks ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Total Pcs">
          {data.FactoryReceiptDetails ? (
            <Link onClick={() => viewDetail("Material Receipt", data)}>
              {data.FactoryReceiptDetails.map((item) => item.processingQuantity).reduce((a, b) => a + b, 0)}
            </Link>
          ) : (
            "-"
          )}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default MaterialReceiptCard;
