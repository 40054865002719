import React, { useState, useEffect } from "react";
import { Modal, Form, Divider } from "antd";
import moment from "moment";
import { useFarmProduction } from "../../../hooks/useFarmProduction";
import OpenTransferTable from "../tables/OpenTransferTable";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import { useProcessing } from "../../../hooks/useProcessing";
import { useFarm } from "../../../hooks/useFarm";
import DeliveryOrderForm from "../forms/DeliveryOrderForm";
import { useSSCHarvest } from "../../../hooks/useSSCHarvest";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useFarmProduction();
  const { findAll: findSSC } = useSSCHarvest();
  const { findAll: findFarm } = useFarm();
  const { findAll: findProcessing } = useProcessing();

  const { create } = useDeliveryOrder();

  const [submitting, setSubmitting] = useState(false);

  const [productType, setProductType] = useState();
  const [sscSource, setSSCSource] = useState();
  const [farmOption, setFarmOption] = useState([]);
  const [processingOption, setProcessingOption] = useState([]);
  const [farmProductionData, setFarmProductionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (visible) {
      setSelectedRows([]);

      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setFarmProductionData(res.data);
        }
      });

      findFarm().then((res) => {
        if (res.status === "success") {
          setFarmOption(res.data);
        }
      });

      findProcessing({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setProcessingOption(res.data);
        }
      });

      form.setFieldsValue({
        departTimestamp: moment(),
      });
    }
  }, [visible, findAll, findFarm, findProcessing, form]);

  useEffect(() => {
    if (productType && sscSource) {
      if (productType === "Soft Shell Crab") {
        findSSC({ status: "New", sscSource: sscSource }).then((res) => {
          if (res.status === "success") {
            let array = res.data.map((item) => {
              return {
                ...item,
                type: "Soft Shell Crab",
              };
            });
            setFarmProductionData(array);
          }
        });
      } else {
        findAll({ status: "New" }).then((res) => {
          if (res.status === "success") {
            setFarmProductionData(res.data);
          }
        });
      }
    }
  }, [productType, sscSource]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      // if (selectedRows.length <= 0) {
      //   return;
      // }
      if (!err) {
        setSubmitting(true);
        const array = farmProductionData.filter((item) => selectedRows.map((item) => item.id).includes(item.id));
        const params = {
          orderNo: values.orderNo,
          product: values.product,
          departTimestamp: moment(values.departTimestamp).format(),
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          farmId: values.farmId,
          processingId: values.processingId,
          remarks: values.remarks,
          orderDetailArray: array,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setSelectedRows([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onProductChange = (value) => {
    setProductType(value);
    // findAll({ status: "New", type: value }).then((res) => {
    //   if (res.status === "success") {
    //     setFarmProductionData(res.data);
    //   }
    // });
  };

  const onSSCSourceChange = (value) => {
    setSSCSource(value);
    // findAll({ status: "New", type: productType, sscSource: value }).then((res) => {
    //   if (res.status === "success") {
    //     setFarmProductionData(res.data);
    //   }
    // });
  };

  return (
    <Modal
      title="New Delivery Order"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <DeliveryOrderForm
        form={form}
        farmOption={farmOption}
        processingOption={processingOption}
        onProductChange={onProductChange}
        productType={productType}
        onSSCSourceChange={onSSCSourceChange}
      />

      <Divider />

      <h4>Order Details</h4>

      <OpenTransferTable
        dataSource={farmProductionData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setFarmProductionData={setFarmProductionData}
      />
    </Modal>
  );
};

export default AddNewModal;
