import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, InputNumber } from "antd";
import moment from "moment";
import { usePondHarvest } from "../../hooks/usePondHarvest";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditPondHarvestModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePondHarvest();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        totalSmallInPcs: editItem.totalSmallInPcs,
        totalSSCInPcs: editItem.totalSSCInPcs,
        totalHSCInPcs: editItem.totalHSCInPcs,
        totalShrimpInPcs: editItem.totalShrimpInPcs,
        totalDeadInPcs: editItem.totalDeadInPcs,
        totalBroodstockInPcs: editItem.totalBroodstockInPcs,
        totalGreenhouseInPcs: editItem.totalGreenhouseInPcs,

        avgWeightInGram: editItem.avgWeightInGram,
        survivalRate: editItem.survivalRate,
        totalFeedInKg: editItem.totalFeedInKg,
        fcr: editItem.fcr,
        totalHarvestInKg: editItem.totalHarvestInKg,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          recordTimestamp: moment(values.recordTimestamp),
          totalSmallInPcs: values.totalSmallInPcs,
          totalSSCInPcs: values.totalSSCInPcs,
          totalHSCInPcs: values.totalHSCInPcs,
          totalShrimpInPcs: values.totalShrimpInPcs,
          totalDeadInPcs: values.totalDeadInPcs,
          totalBroodstockInPcs: values.totalBroodstockInPcs,
          totalGreenhouseInPcs: values.totalGreenhouseInPcs,

          avgWeightInGram: values.avgWeightInGram,
          survivalRate: values.survivalRate,
          totalFeedInKg: values.totalFeedInKg,
          fcr: values.fcr,
          totalHarvestInKg: values.totalHarvestInKg,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Pond Harvest"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
          <DatePicker allowClear showTime />
        </Form.Item>

        <Form.Item name="totalSmallInPcs" label="Small (to Ponds)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalSSCInPcs" label="SSC">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalHSCInPcs" label="HSC">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalShrimpInPcs" label="Shrimp">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalDeadInPcs" label="Dead">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalBroodstockInPcs" label="Broodstock">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalGreenhouseInPcs" label="Greenhouse">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" />
        </Form.Item>

        <Form.Item name="totalHarvestInKg" label="Total Weight (kg)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="kg" />
        </Form.Item>

        <Form.Item name="avgWeightInGram" label="ABW (g)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="g" />
        </Form.Item>

        <Form.Item name="survivalRate" label="Survival Rate">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="%" />
        </Form.Item>

        <Form.Item name="totalFeedInKg" label="Total Feed (kg)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="kg" />
        </Form.Item>

        <Form.Item name="fcr" label="FCR" extra="Total Feed / Total Weight">
          <InputNumber inputMode="numeric" min={0} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPondHarvestModal;
