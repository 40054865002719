import React, { useState, useEffect, useContext } from "react";
import { Drawer, Descriptions, Row, Col, Typography, Tabs, Button, Space, Input, InputNumber, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import FactoryReceiptDetailTable from "./FactoryReceiptDetailTable";
import moment from "moment";
import { useFactoryReceipt } from "../../hooks/useFactoryReceipt";
import { ResponsiveContext } from "../../App";
import { useFactoryReceiptDetail } from "../../hooks/useFactoryReceiptDetail";
import NewFactoryReceiptDetailModal from "./NewFactoryReceiptDetailModal";

const { TabPane } = Tabs;
const { Title } = Typography;

const FactoryReceiptDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne } = useFactoryReceipt();
  const { update, destroy } = useFactoryReceiptDetail();

  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [detailData, setDetailData] = useState([]);

  const [isEditing, setIsEditing] = useState(false);
  const [editItem, setEditItem] = useState({});

  const [newFactoryReceiptDetailModal, setNewFactoryReceiptDetailModal] = useState(false);

  useEffect(() => {
    if ((visible && id) || refresh) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setData(res.data);
          if (res.data.FactoryReceiptDetails && res.data.FactoryReceiptDetails.length) {
            // const newArray = res.data.FactoryReceiptDetails.map((item, index) => {
            //   return {
            //     id: item.id,
            //     grade: item.grade,
            //     size: item.size,
            //     farmQuantity: item.farmQuantity,
            //     farmWeightInKg: item.farmWeightInKg,
            //     processingQuantity: item.processingQuantity,
            //     processingWeightInKg: item.processingWeightInKg,
            //     remarks: item.remarks,
            //   };
            // });
            setDetailData([...res.data.FactoryReceiptDetails]);
          } else {
            setDetailData([]);
          }
        }
        setRefresh(false);
      });
    }
  }, [refresh, visible, id, findOne]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (item) => {
    setEditItem(item);
    setIsEditing(true);
  };

  const onSave = () => {
    update(editItem.id, editItem).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setIsEditing(false);
        setEditItem({});
      }
    });
  };

  const onDelete = (item) => {
    destroy(item.id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };
  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "70%"}>
      <Row justify="space-between" style={{ marginTop: 16, marginBottom: 16 }}>
        <Col>
          <Title level={4}>{`Grading Details Record #${data.id}`}</Title>
          {data.DeliveryOrder ? `Order No #${data.DeliveryOrder.orderNo}` : null}
        </Col>
      </Row>

      <Descriptions>
        <Descriptions.Item label="Date">{moment(data.arrivalTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Delivered By">{data.personInCharge}</Descriptions.Item>
        <Descriptions.Item label="Vehicle">{data.vehicleNumber}</Descriptions.Item>
        <Descriptions.Item label="Received By">
          {data.createdBy ? data.createdBy.split(":::")[1] : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Total PCS">
          {detailData.reduce((acc, cur) => acc + cur.processingQuantity, 0)}
        </Descriptions.Item>
        <Descriptions.Item label="Total Weight">
          {Number(detailData.reduce((acc, cur) => acc + cur.processingWeightInKg, 0)).toFixed(3)} KG
        </Descriptions.Item>

        <Descriptions.Item label="Remarks">{data.remarks ? data.remarks : "-"}</Descriptions.Item>
        <Descriptions.Item label="Status">{data.status ? data.status : "-"}</Descriptions.Item>
      </Descriptions>

      <Tabs>
        <TabPane tab="Table View" key="1">
          <FactoryReceiptDetailTable detailData={detailData} setDetailData={setDetailData} readOnly={true} />
        </TabPane>
        <TabPane tab="List View" key="2">
          <Row>
            <Col>
              <Button type="primary" onClick={() => setNewFactoryReceiptDetailModal(true)}>
                New Item
              </Button>
            </Col>
          </Row>

          {detailData.map((item, index) => (
            <div key={item.id} className="border-b p-4">
              <div className="font-semibold">
                {index + 1}. Grade {item.grade} {item.size}
              </div>
              <div className="flex flex-row flex-wrap gap-8 md:gap-12 my-2">
                <div>
                  <div className="text-gray-400">Farm:</div>
                  <div className="flex flex-row gap-8">
                    <div>{item.farmQuantity ?? "-"} pcs</div>
                    <div>{item.farmWeightInKg ?? "-"} kg</div>
                  </div>
                </div>
                <div>
                  <div className="text-gray-400">Recipient:</div>
                  <div className="flex flex-row gap-8">
                    {isEditing && editItem.id === item.id ? (
                      <div>
                        <InputNumber
                          value={editItem.processingQuantity}
                          onChange={(value) => setEditItem({ ...editItem, processingQuantity: value })}
                        />
                      </div>
                    ) : (
                      <div>{item.processingQuantity} pcs</div>
                    )}
                    {isEditing && editItem.id === item.id ? (
                      <div>
                        <InputNumber
                          value={editItem.processingWeightInKg}
                          onChange={(value) => setEditItem({ ...editItem, processingWeightInKg: value })}
                        />
                      </div>
                    ) : (
                      <div>{item.processingWeightInKg} kg</div>
                    )}
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="text-gray-400">Remarks:</div>
                  {isEditing && editItem.id === item.id ? (
                    <div>
                      <Input
                        value={editItem.remarks}
                        allowClear
                        onChange={(e) => setEditItem({ ...editItem, remarks: e.target.value })}
                      />
                    </div>
                  ) : (
                    <div>{item.remarks ? item.remarks : "-"}</div>
                  )}
                </div>
                <div className="self-center">
                  {isEditing && editItem.id === item.id ? (
                    <Space>
                      <Button type="primary" onClick={onSave}>
                        Save
                      </Button>
                      <Button
                        danger
                        onClick={() => {
                          setIsEditing(false);
                          setEditItem({});
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  ) : (
                    <Space>
                      <Button type="link" onClick={() => editRow(item)} icon={<EditOutlined />} />
                      <Popconfirm title="Sure to delete item?" onConfirm={() => onDelete(item)}>
                        <Button type="link" danger icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Space>
                  )}
                </div>
              </div>
            </div>
          ))}
        </TabPane>
      </Tabs>

      <NewFactoryReceiptDetailModal
        id={data.id}
        visible={newFactoryReceiptDetailModal}
        setVisible={setNewFactoryReceiptDetailModal}
        setRefresh={setRefresh}
      />
    </Drawer>
  );
};

export default FactoryReceiptDrawer;
