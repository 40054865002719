import React, { useEffect, useState } from "react";
import { Modal, Form, Select, DatePicker, Input } from "antd";
import moment from "moment";
import { useOption } from "../../../hooks/useOption";
import { useHatcheryReceipt } from "../../../hooks/useHatcheryReceipt";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TagStatusModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findOption } = useOption();
  const { update } = useHatcheryReceipt();

  const [submitting, setSubmitting] = useState(false);

  const [locationOption, setLocationOption] = useState([]);

  useEffect(() => {
    if (visible) {
      findOption("Active").then((res) => {
        if (res.status === "success") {
          const location = res.data.filter(
            (item) => item.type === "Broodstock Tank"
          );
          setLocationOption(location);
        }
      });
      form.setFieldsValue({
        label: editItem.label,
        stockDate: moment(),
      });
    }
  }, [visible, form, findOption, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          location: values.location,
          stockDate: moment(values.stockDate).format(),
          status: "New",
          label: values.label,
        };
        setSubmitting(true);
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Transfer to Broodstock Tank"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="label" label="Tag" rules={[{ required: true }]}>
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="location"
          label="Broodstock Tank"
          rules={[{ required: true }]}
        >
          <Select allowClear>
            {locationOption.map((item) => (
              <Option key={item.label} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="stockDate"
          label="Stock Date"
          rules={[{ required: true }]}
        >
          <DatePicker allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TagStatusModal;
