import React, { useState } from "react";
import { Timeline, Row, Col } from "antd";
import SscCard from "./SscCard";
import moment from "moment";
import ProcessingOrderCard from "./ProcessingOrderCard";
import ProcessingOrderDetailDrawer from "../../processingOrder/ProcessingOrderDetailDrawer";
import FactoryFreezingDrawer from "../../factoryFreezing/FactoryFreezingDrawer";
import MaterialReceiptCard from "./MaterialReceiptCard";
import FactoryReceiptDrawer from "../../factoryReceipts/FactoryReceiptDrawer";
import DeliveryOrderCard from "./DeliveryOrderCard";
import FarmReceiptCard from "./FarmReceiptCard";
import HatcheryCard from "./HatcheryCard";

const SearchByBatchResult = (props) => {
  const { currentNode, parentNodes, childNodes } = props;

  const [detailItem, setDetailItem] = useState({});
  const [materialReceiptDrawer, setMaterialReceiptDrawer] = useState(false);
  const [sscFreezingDrawer, setSscFreezingDrawer] = useState(false);
  const [processingOrderDetailDrawer, setProcessingOrderDetailDrawer] = useState(false);

  const viewDetail = (phase, item) => {
    if (phase === "Delivery Order (Processing)") {
      setDetailItem(item);
      setProcessingOrderDetailDrawer(true);
    } else if (phase === "SSC Freezing") {
      setDetailItem(item);
      setSscFreezingDrawer(true);
    } else if (phase === "Material Receipt") {
      setDetailItem(item);
      setMaterialReceiptDrawer(true);
    } else if (phase === "Delivery Order") {
    }
  };

  return (
    <Row justify="space-between" gutter={16}>
      <Col span={24}>
        <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
          <Timeline mode="left">
            {parentNodes.map((item) =>
              item.phase === "Material Receipt" ? (
                <Timeline.Item key={item.phase} label={moment(item.data[0].arrivalTimestamp).format("YYYY-MM-DD")}>
                  <MaterialReceiptCard title={item.phase} data={item.data[0]} viewDetail={viewDetail} />
                </Timeline.Item>
              ) : item.phase === "Delivery Order" ? (
                <Timeline.Item key={item.phase} label={moment(item.data[0].departTimestamp).format("YYYY-MM-DD")}>
                  <DeliveryOrderCard title={item.phase} data={item.data[0]} viewDetail={viewDetail} />
                </Timeline.Item>
              ) : item.phase === "Farm Material Receipt" ? (
                <Timeline.Item key={item.phase} label={moment(item.data[0].arrivalTimestamp).format("YYYY-MM-DD")}>
                  <FarmReceiptCard title={item.phase} data={item.data[0]} />
                </Timeline.Item>
              ) : item.phase === "Hatchery" ? (
                <Timeline.Item key={item.phase} label={moment(item.data[0].productionTimestamp).format("YYYY-MM-DD")}>
                  <HatcheryCard title={item.phase} data={item.data[0]} />
                </Timeline.Item>
              ) : null
            )}

            {currentNode.phase === "Soft Shell Crab" ? (
              <Timeline.Item label={moment(currentNode.data.productionTimestamp).format("YYYY-MM-DD")} color="red">
                <SscCard title="Soft Shell Crab Batch" data={currentNode.data} viewDetail={viewDetail} />
              </Timeline.Item>
            ) : currentNode.phase === "Delivery Order" ? (
              <Timeline.Item label={moment(currentNode.data.departTimestamp).format("YYYY-MM-DD")} color="red">
                <DeliveryOrderCard title={currentNode.phase} data={currentNode.data} viewDetail={viewDetail} />
              </Timeline.Item>
            ) : null}

            {childNodes.map((item) =>
              item.phase === "Material Receipt" ? (
                <Timeline.Item key={item.phase} label={moment(item.data[0].arrivalTimestamp).format("YYYY-MM-DD")}>
                  <MaterialReceiptCard title={item.phase} data={item.data[0]} viewDetail={viewDetail} />
                </Timeline.Item>
              ) : item.phase === "Soft Shell Crab" ? (
                <Timeline.Item key={item.phase} label={moment(item.data[0].productionTimestamp).format("YYYY-MM-DD")}>
                  <SscCard title="Soft Shell Crab Batch" data={item.data[0]} viewDetail={viewDetail} />
                </Timeline.Item>
              ) : item.phase === "Processing Order" ? (
                <Timeline.Item label={moment(item.data[0].orderDate).format("YYYY-MM-DD")}>
                  <ProcessingOrderCard title="Delivery Order (Processing)" data={item.data} viewDetail={viewDetail} />
                </Timeline.Item>
              ) : null
            )}
          </Timeline>
        </div>
      </Col>

      <FactoryReceiptDrawer id={detailItem.id} visible={materialReceiptDrawer} setVisible={setMaterialReceiptDrawer} />

      <FactoryFreezingDrawer id={detailItem.id} visible={sscFreezingDrawer} setVisible={setSscFreezingDrawer} />

      <ProcessingOrderDetailDrawer
        id={detailItem.id}
        visible={processingOrderDetailDrawer}
        setVisible={setProcessingOrderDetailDrawer}
      />
    </Row>
  );
};

export default SearchByBatchResult;
