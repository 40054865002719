import React, { useState, useContext } from "react";
import { Form, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../App";
import { useAuth } from "../../../hooks/useAuth";

const BasicSettingsForm = () => {
  const { t } = useTranslation();

  const { authState, dispatch } = useContext(AuthContext);

  const { updateBasicSettings } = useAuth();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(async (values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          id: authState.authUser.id,
          email: values.email,
          name: values.name,
        };

        const res = await updateBasicSettings(params);

        if (res.status === "success") {
          notification.success({
            message: t("notification.success"),
            top: 72,
          });

          dispatch({
            type: "UPDATE",
            payload: {
              authUser: res.data.auth,
            },
          });
        } else {
          notification.error({
            message: t("notification.fail"),
            top: 72,
          });
        }
        setSubmitting(false);
      }
    });
  };

  return (
    <Form form={form} name="basic-settings-form" layout="vertical">
      <Form.Item
        name="email"
        label={t("common.email")}
        rules={[{ required: true }]}
        initialValue={authState.authUser.email}
      >
        <Input type="email" disabled />
      </Form.Item>

      <Form.Item
        name="name"
        label={t("common.name")}
        rules={[{ required: true }]}
        initialValue={authState.authUser.name}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={submitting} onClick={() => handleSubmit()}>
          {t("action.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BasicSettingsForm;
