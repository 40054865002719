import React from "react";
import { DatePicker, Form, Select, Row, Col, Input } from "antd";

const { Option } = Select;

const AddNewForm = (props) => {
  const { form, hatcheryData } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="spawnDate" label="Spawn Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="hatcheryReceiptId" label="Hatchery Receipt" rules={[{ required: true }]}>
        <Select allowClear>
          {hatcheryData.map((item) => (
            <Option key={item.id} value={item.id}>
              <Row justify="space-between">
                <Col>{item.RawMaterial ? item.RawMaterial.name : "-"}</Col>
                <Col>{item.location ? item.location : "-"}</Col>
                <Col>{item.species}</Col>
              </Row>
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
