import React, { useState, useEffect } from "react";
import { DatePicker, Form, Input, InputNumber, Select, Checkbox } from "antd";
import { useFactoryProduction } from "../../hooks/useFactoryProduction";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const { Option } = Select;

const SSCFreezingForm = (props) => {
  const { form, isEdit } = props;

  const { findAll } = useFactoryProduction();

  const [batchOption, setBatchOption] = useState([]);

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setBatchOption(res.data);
      }
    });
  }, []);

  return (
    <Form {...layout} form={form} name="add-new-form">
      {!isEdit && (
        <>
          <Form.Item name="factoryProductionRecordId" label="Batch No" rules={[{ required: true }]}>
            <Select>
              {batchOption.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.batchNo}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item {...tailLayout} name="markAsComplete" valuePropName="checked">
            <Checkbox>Set batch as complete</Checkbox>
          </Form.Item>
        </>
      )}
      <Form.Item name="freezerNo" label="Freezer #" rules={[{ required: true }]}>
        {/* <Input allowClear /> */}
        <Select>
          <Option value="Blast Freezer No 1">Blast Freezer No 1</Option>
          <Option value="Blast Freezer No 2">Blast Freezer No 2</Option>
        </Select>
      </Form.Item>

      <Form.Item name="startTimestamp" label="Start Time" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item name="endTimestamp" label="End Time" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item name="freezerTemperature" label="Freezer Temp. (C)" rules={[{ required: true }]}>
        <InputNumber allowClear />
      </Form.Item>

      <Form.Item name="productTemperature" label="Product Temp. (C)" rules={[{ required: true }]}>
        <InputNumber allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default SSCFreezingForm;
