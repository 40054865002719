import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import { useTank } from "../../hooks/useTank";
import { useTankTransfer } from "../../hooks/useTankTransfer";
import TankTransferForm from "./TankTransferForm";

const NewTankTransferModal = (props) => {
  const { tank, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findTank } = useTank();
  const { findAll, createMultiple } = useTankTransfer();

  const [submitting, setSubmitting] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const [tankOption, setTankOption] = useState([]);
  const [sourceTankUsageOption, setSourceTankUsageOption] = useState([]);
  const [tankArray, setTankArray] = useState([]);

  useEffect(() => {
    if (visible) {
      findTank({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setTankOption(res.data);
        }
      });
      findAll("New").then((res) => {
        if (res.status === "success") {
          setSourceTankUsageOption(res.data);
        }
      });
      form.setFieldsValue({
        stockInTimestamp: moment(),
        tankId: tank.id,
      });
    }
  }, [visible, form, tank, findAll, findTank]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values, err) => {
        if (!err) {
          const tankArrayRecord = tankArray.filter((item) => item.quantity && item.quantity > 0);

          setSubmitting(true);

          const params = {
            tankArray: tankArrayRecord,
            batchNo: values.batchNo,
            broodstockNo: values.broodstockNo,
            stockInTimestamp: moment(values.stockInTimestamp).format(),
            sourceTankUsageId: values.sourceTankUsageId,
            hatchDate: moment(values.hatchDate).format(),
            remarks: values.remarks,
          };
          createMultiple(params).then((res) => {
            if (res.status === "success") {
              setRefresh(true);
              setVisible(false);
              form.resetFields();
              setTankArray([]);
              setCurrentStep(0);
            }
            setSubmitting(false);
          });
        }
      })
      .catch((err) => {
        if (err.errorFields[0].name[0] === "sourceTankUsageId") {
          setCurrentStep(0);
        } else if (err.errorFields[0].name[0] === "tankId") {
          setCurrentStep(1);
        } else {
          setCurrentStep(2);
        }
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const previous = () => setCurrentStep((currentStep) => currentStep - 1);

  const next = () => setCurrentStep((currentStep) => currentStep + 1);

  return (
    <Modal
      title="Transfer In"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      okButtonProps={{ disabled: currentStep < 2 }}
    >
      <TankTransferForm
        form={form}
        tankOption={tankOption}
        sourceTankUsageOption={sourceTankUsageOption}
        tankArray={tankArray}
        setTankArray={setTankArray}
        currentStep={currentStep}
        previous={previous}
        next={next}
      />
    </Modal>
  );
};

export default NewTankTransferModal;
