import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Drawer, Layout, Menu } from "antd";
import {
  FileDoneOutlined,
  SettingOutlined,
  LeftOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import "./SideMenu.css";
import logomini from "../../../assets/logomini.png";
import logo from "../../../assets/logoheader.png";
import { AuthContext, ResponsiveContext } from "../../../App";

const { Sider } = Layout;

const { SubMenu } = Menu;

const SideMenu = (props) => {
  const { collapsed, setCollapsed } = props;

  const { can } = useContext(AuthContext);
  const { isMobile, setIsMobile } = useContext(ResponsiveContext);

  const [collapsedWidth, setCollapsedWidth] = useState();

  useEffect(() => {
    if (isMobile) {
      setCollapsedWidth(0);
    } else {
      setCollapsedWidth(80);
    }
  }, [isMobile]);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const onBreakpoint = (broken) => {
    setIsMobile(broken);
  };

  const onSelect = (e) => {
    if (isMobile) {
      setCollapsed(true);
    }
  };

  const MenuList = (props) => {
    return (
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        onSelect={onSelect}
      >
        {can("Hatchery") && (
          <SubMenu key="hatchery" title="Hatchery" icon={<FileDoneOutlined />}>
            {can("HatcheryDashboard") && (
              <Menu.Item key="hatcheryDashboard">
                <Link to="/hatcheryDashboard">Dashboard</Link>
              </Menu.Item>
            )}

            {can("HatcheryReceipt") && (
              <Menu.Item key="hatcheryReceipts">
                <Link to="/hatcheryReceipts">Broodstock</Link>
              </Menu.Item>
            )}

            {can("IncubationRecord") && (
              <Menu.Item key="incubationRecord">
                <Link to="/incubationRecords">Incubation Record</Link>
              </Menu.Item>
            )}

            {can("TankTransfer") && (
              <Menu.Item key="larvalTank">
                <Link to="/larvalTanks">Larval Tank</Link>
              </Menu.Item>
            )}

            {can("TankTransfer") && (
              <Menu.Item key="tankUsages">
                <Link to="/tankUsages">Larval Tank Transfer</Link>
              </Menu.Item>
            )}

            {can("LarvalHistoryRecord") && (
              <Menu.Item key="larvalHistoryRecords">
                <Link to="/larvalHistoryRecords">Larval Record History</Link>
              </Menu.Item>
            )}

            {can("HatcheryProduction") && (
              <Menu.Item key="hatcheryProduction">
                <Link to="/hatcheryProductions">Hatchery Harvest</Link>
              </Menu.Item>
            )}

            {can("HatcheryTransfer") && (
              <Menu.Item key="hatcheryTransfers">
                <Link to="/hatcheryTransfers">Hatchery Transfer</Link>
              </Menu.Item>
            )}

            {can("HatcheryReport") && (
              <SubMenu key="hatcheryReport" title="Reports">
                <Menu.Item key="larvalDataRpt">
                  <Link to="/larvalDataRpt">Larval Data</Link>
                </Menu.Item>
                <Menu.Item key="crabletProductionReport">
                  <Link to="/crabletProductionReport">Crablet Production</Link>
                </Menu.Item>
                <Menu.Item key="hatcheryProductionReport">
                  <Link to="/hatcheryProductionReport">
                    Hatchery Production
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}
          </SubMenu>
        )}

        {can("Farm") && (
          <SubMenu key="farm" title="Farm" icon={<FileDoneOutlined />}>
            {can("FarmDashboard") && (
              <Menu.Item key="farmDashboard">
                <Link to="/farmDashboard">Dashboard</Link>
              </Menu.Item>
            )}

            {can("PondPreparationRecord") && (
              <SubMenu key="pond" title="Ponds">
                <Menu.Item key="allPonds">
                  <Link to="/pond">All Ponds</Link>
                </Menu.Item>
                <Menu.Item key="pondCycle">
                  <Link to="/pondCycles">Cycles</Link>
                </Menu.Item>
                <Menu.Item key="pondPreparation">
                  <Link to="/pondPreparations">Preparation</Link>
                </Menu.Item>
                <Menu.Item key="pondFeed">
                  <Link to="/pondFeeds">Feeding</Link>
                </Menu.Item>
                <Menu.Item key="pondTreatment">
                  <Link to="/pondTreatments">Treatment</Link>
                </Menu.Item>
                <Menu.Item key="pondParameter">
                  <Link to="/pondParameters">Parameters</Link>
                </Menu.Item>
                <Menu.Item key="pondSampling">
                  <Link to="/pondSamplings">Samplings</Link>
                </Menu.Item>
                <Menu.Item key="pondHarvest">
                  <Link to="/pondHarvests">Harvests</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {can("FarmReceipt") && (
              <SubMenu key="farmReceipt" title="Material Receipt">
                <Menu.Item key="hatcheryToFarm">
                  <Link to="/hatcheryToFarm">Hatchery to Farm</Link>
                </Menu.Item>
                <Menu.Item key="externalToFarm">
                  <Link to="/externalToFarm">External to Farm</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {can("HapaRecord") && (
              <SubMenu key="nursery" title="Nursery">
                <Menu.Item key="nurseryRecords">
                  <Link to="/nurseryRecords">Nursery Batch</Link>
                </Menu.Item>
                <Menu.Item key="nurseryHapa">
                  <Link to="/nurseryHapa">Nursery HAPA</Link>
                </Menu.Item>
                <Menu.Item key="nurseryHapaFeeds">
                  <Link to="/nurseryHapaFeeds">HAPA Feed</Link>
                </Menu.Item>
              </SubMenu>
            )}

            {can("FarmProduction") && (
              <Menu.Item key="farmProduction">
                <Link to="/farmProductions">Farm Production</Link>
              </Menu.Item>
            )}
            {can("SSCDailyMonitor") && (
              <Menu.Item key="sscDailyMonitor">
                <Link to="/sscDailyMonitor">SSC Daily Monitor</Link>
              </Menu.Item>
            )}
            {can("SSCDailyMonitor") && (
              <Menu.Item key="sscFeeding">
                <Link to="/sscFeeding">SSC Feeding</Link>
              </Menu.Item>
            )}
            {can("FarmProduction") && (
              <Menu.Item key="sscHarvest">
                <Link to="/sscHarvest">SSC Harvest</Link>
              </Menu.Item>
            )}
            {can("FarmProduction") && (
              <Menu.Item key="farmStorage">
                <Link to="/farmStorage">SSC Storage</Link>
              </Menu.Item>
            )}
            {can("DeliveryOrder") && (
              <Menu.Item key="deliveryOrder">
                <Link to="/deliveryOrders">Delivery Order</Link>
              </Menu.Item>
            )}

            {can("FarmDashboard") && (
              <SubMenu key="farmReport" title="Reports">
                <Menu.Item key="sscRawMatData">
                  <Link to="/farmReports/sscRawMatData">SSC Raw Mat</Link>
                </Menu.Item>
                <Menu.Item key="farmReports/sscHarvestData">
                  <Link to="/farmReports/sscHarvestData">SSC Report</Link>
                </Menu.Item>
              </SubMenu>
            )}
          </SubMenu>
        )}

        {can("Processing") && (
          <SubMenu key="factory" title="Processing" icon={<FileDoneOutlined />}>
            {can("ProcessingDashboard") && (
              <Menu.Item key="processingDashboard">
                <Link to="/processingDashboard">Dashboard</Link>
              </Menu.Item>
            )}
            {can("ProcessingReceipt") && (
              <Menu.Item key="factoryReceipts">
                <Link to="/factoryReceipts">Grading Details Record</Link>
              </Menu.Item>
            )}
            {can("ProcessingProduction") && (
              <Menu.Item key="factoryProduction">
                <Link to="/factoryProductions">
                  Weighing & Packaging Record
                </Link>
              </Menu.Item>
            )}
            {can("ProcessingFreezing") && (
              <Menu.Item key="factoryFreezing">
                <Link to="/factoryFreezings">
                  Freezer Temperature Monitoring (Blast Freezer)
                </Link>
              </Menu.Item>
            )}
            {can("ProcessingBabyBox") && (
              <Menu.Item key="babyBoxes">
                <Link to="/babyBoxes">Packing Box</Link>
              </Menu.Item>
            )}
            {/* {can("ProcessingMasterBox") && (
            <Menu.Item key="masterBoxes">
              <Link to="/masterBoxes">Master Box</Link>
            </Menu.Item>
          )}
          {can("ProcessingStorage") && (
            <Menu.Item key="factoryStorageRecords">
              <Link to="/factoryStorageRecords">Storage Record</Link>
            </Menu.Item>
          )} */}
            {can("ProcessingOrder") && (
              <Menu.Item key="processingOrder">
                <Link to="/processingOrder">Delivery Order</Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {/* {can("Reports") && (
          <SubMenu key="costingReport" icon={<SettingOutlined />} title="Costing Reports">
              {can("PrawnProductionData") && (
                <Menu.Item key="prawnProductionData">
                  <Link to="/prawnProductionData">Prawn Production</Link>
                </Menu.Item>
              )}

              {can("NurseryCrabletData") && (
                <Menu.Item key="nurseryCrabletData">
                  <Link to="/nurseryCrabletData">Costing for Nursery Crablet</Link>
                </Menu.Item>
              )}

              {can("CrabletsTransferData") && (
                <Menu.Item key="crabletsTransferData">
                  <Link to="/crabletsTransferData">Crablets Transfer Report</Link>
                </Menu.Item>
              )}
          </SubMenu>
        )} */}

        {can("Processing") && (
          <Menu.Item key="recall" icon={<FileSearchOutlined />}>
            <Link to="/recall">Recall</Link>
          </Menu.Item>
        )}

        {can("Settings") && (
          <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
            {can("HatcherySettings") && (
              <>
                <Menu.Item key="rawMaterials">
                  <Link to="/rawMaterials">Raw Material</Link>
                </Menu.Item>

                <Menu.Item key="option">
                  <Link to="/options">Options</Link>
                </Menu.Item>

                {/* <Menu.Item key="tanks">
                <Link to="/tanks">Larval Tank</Link>
              </Menu.Item> */}
                <Menu.Item key="farms">
                  <Link to="/farms">Farm</Link>
                </Menu.Item>
              </>
            )}

            {can("FarmSettings") && (
              <>
                <Menu.Item key="farmRawMaterial">
                  <Link to="/farmRawMaterials">Raw Material</Link>
                </Menu.Item>
                {/* <Menu.Item key="growOut">
                <Link to="/growOuts">Grow Out</Link>
              </Menu.Item> */}
                {/* <Menu.Item key="pontoon">
                <Link to="/pontoons">Pontoon</Link>
              </Menu.Item> */}
                <Menu.Item key="feedType">
                  <Link to="/feedTypes">Feed Types</Link>
                </Menu.Item>

                <Menu.Item key="treatmentType">
                  <Link to="/treatmentTypes">Treatment Types</Link>
                </Menu.Item>

                <Menu.Item key="parameterType">
                  <Link to="/parameterTypes">Parameter Types</Link>
                </Menu.Item>

                <Menu.Item key="processing">
                  <Link to="/processings">Processing</Link>
                </Menu.Item>
              </>
            )}

            {can("RecipeSettings") && (
              <>
                <Menu.Item key="recipesList">
                  <Link to="/recipesList">Recipes</Link>
                </Menu.Item>
              </>
            )}
            {/* 
          {can("ProcessingSettings") && (
            <>
              <Menu.Item key="qrCodes">
                <Link to="/qrCodes">QR Code</Link>
              </Menu.Item>
            </>
          )} */}
          </SubMenu>
        )}
        {/* 
      {can("AntChainSettings") && (
        <SubMenu key="antChain" icon={<BlockOutlined />} title="AntChain">
          <Menu.Item key="antChainProducts">
            <Link to="/antChainProducts">Product</Link>
          </Menu.Item>
        </SubMenu>
      )} */}
      </Menu>
    );
  };

  return isMobile ? (
    <Drawer
      bodyStyle={{
        backgroundColor: "#001529",
        padding: 0,
      }}
      headerStyle={{ backgroundColor: "#001529" }}
      width="100%"
      placement="left"
      visible={!collapsed}
      onClose={() => setCollapsed(true)}
      // closeIcon={
      //   <CloseCircleFilled style={{ color: "#f0f0f0", position: "absolute", right: 20, top: 20, fontSize: 24 }} />
      // }
    >
      <div style={{ padding: 10 }} align="center">
        <img alt="logo" src={logo} />
      </div>

      <MenuList />

      <div
        style={{
          backgroundColor: "#002140",
          lineHeight: "48px",
          color: "white",
          marginTop: 24,
        }}
        align="center"
        onClick={() => setCollapsed(true)}
      >
        <LeftOutlined style={{ fontWeight: "bold" }} />
      </div>
    </Drawer>
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      collapsedWidth={collapsedWidth}
      onCollapse={onCollapse}
      width={250}
      breakpoint="sm"
      onBreakpoint={onBreakpoint}
    >
      <div style={{ padding: 10 }} align="center">
        <img alt="logo" src={collapsed ? logomini : logo} />
      </div>

      <MenuList />
    </Sider>
  );
};

export default SideMenu;
