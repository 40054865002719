import React from "react";
import { DatePicker, List, Typography, Row, Col, Button } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Text } = Typography;

const ActivityList = (props) => {
  const { data, activityDateFilter, setActivityDateFilter } = props;

  const onChange = (date) => setActivityDateFilter(date);

  const renderContent = (item) => {
    if (item.type === "Material Receipt") {
      const value = JSON.parse(item.value);

      return (
        <ul>
          <li>Source: {value.sourceType === "External" ? value.sourceName : "Hatchery"}</li>
          <li>Qty: {Number(value.farmQuantity).toLocaleString()}</li>
          <li>Weight (kg): {value.weightInKg ? value.weightInKg : "-"}</li>
          {value.dead ? <li>Dead: {Number(value.dead).toLocaleString()} pcs</li> : null}
          {value.small ? <li>Small: {Number(value.small).toLocaleString()} pcs</li> : null}
          <li>Remarks: {value.remarks ? value.remarks : "-"}</li>
        </ul>
      );
    } else if (item.type === "Nursery") {
      const value = JSON.parse(item.value);

      return (
        <ul>
          <li>Label: {value.hapaLabel ?? "-"}</li>
          <li>Qty: {value.stockInQuantity ? Number(value.stockInQuantity).toLocaleString() : "-"}</li>
        </ul>
      );
    } else if (item.type === "Grow Out Stock In") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Pond: {value.pondLabel ?? "-"}</li>
          <li>Cycle #: {value.cycleNo ?? "-"}</li>
          <li>Material: {value.material ?? "-"}</li>
          <li>Qty: {value.stockInQuantity ? Number(value.stockInQuantity).toLocaleString() : "-"}</li>
        </ul>
      );
    } else if (item.type === "SSC Stock In") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Pontoon: {value.pontoonLabel ?? "-"}</li>
          <li>Qty: {value.stockInQuantity ? Number(value.stockInQuantity).toLocaleString() : "-"}</li>
        </ul>
      );
    } else if (item.type === "Grow Out Stock Out") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Label: {value.growOutLabel ?? "-"}</li>
          <li>Qty: {value.stockOutQuantity ? Number(value.stockOutQuantity).toLocaleString() : "-"}</li>
        </ul>
      );
    } else if (item.type === "Pond Preparation") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          <li>Label: {value.pondLabel ?? "-"}</li>
          <li>Elite Lime (container): {value.eliteLimeInContainer ? value.eliteLimeInContainer : "-"}</li>
          <li>Hydrated Lime (bag): {value.hydratedLimeInBag ? value.hydratedLimeInBag : "-"}</li>
          <li>Teaseed (sack): {value.teaseedInSack ? value.teaseedInSack : "-"}</li>
        </ul>
      );
    } else if (item.type === "Parameter") {
      const value = JSON.parse(item.value);
      // console.log(value);
      // const parameters = JSON.parse(value.parameters);
      return (
        <ul>
          {value.pondLabel && <li>Pond: {value.pondLabel}</li>}
          {value.parameters &&
            value.parameters.map((el) => {
              const array = el.split(":::");
              return <li>{array[0] + ": " + array[1] + array[2]}</li>;
            })}
        </ul>
      );
    } else if (item.type === "Sampling") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.pondLabel && <li>Label: {value.pondLabel}</li>}
          {value.avgWeightInGram && <li>Avg Weight: {value.avgWeightInGram} g</li>}
        </ul>
      );
    } else if (item.type === "Feeding") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.pondLabel && <li>Label: {value.pondLabel}</li>}
          {value.feeds &&
            value.feeds.map((el) => {
              const array = el.split(":::");
              return <li>{array[0] + ": " + array[1] + array[2]}</li>;
            })}
          {value.feedActivity && <li>Activity: {value.feedActivity}</li>}
        </ul>
      );
    } else if (item.type === "Treatment") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.pondLabel && <li>Label: {value.pondLabel}</li>}
          {value.treatments &&
            value.treatments.map((el) => {
              const array = el.split(":::");
              return <li>{array[0] + ": " + array[1] + array[2]}</li>;
            })}
        </ul>
      );
    } else if (item.type === "Harvest") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.type && <li>Type: {value.type}</li>}
          {value.quantity && <li>Qty: {Number(value.quantity).toLocaleString()} pcs</li>}
          {value.weightInKg && <li>Weight: {Number(value.weightInKg).toLocaleString()} kg</li>}
          {value.harvestShift && <li>Shift: {value.harvestShift}</li>}
          {/* {value.grade && <li>Grade: {value.grade}</li>} */}
        </ul>
      );
    } else if (item.type === "SSC Harvest") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.sscSource ? <li>SSC Source: {value.sscSource}</li> : null}
          {value.quantity ? <li>Qty: {Number(value.quantity).toLocaleString()}</li> : null}
          {value.harvestShift ? <li>Shift: {value.harvestShift}</li> : null}
          {value.gradeA ? <li>Grade A: {value.gradeA}</li> : null}
          {value.gradeAF ? <li>Grade AF: {value.gradeAF}</li> : null}
          {value.gradeBF ? <li>Grade BF: {value.gradeBF}</li> : null}
        </ul>
      );
    } else if (item.type === "SSC Audit") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.shift && <li>Shift: {value.shift}</li>}
          {value.quantity && <li>Total Qty: {Number(value.quantity).toLocaleString()}</li>}
          {value.totalPontoon && <li>Pontoon audited: {value.totalPontoon}</li>}
        </ul>
      );
    } else if (item.type === "SSC Feeding") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.shift ? <li>Shift: {value.shift}</li> : null}
          {value.feedType ? <li>Feed Type: {value.feedType}</li> : null}
          {value.totalFeed ? <li>Total Feed: {Number(value.totalFeed).toLocaleString()} kg</li> : null}
          {value.totalPontoon ? <li>Pontoon fed: {value.totalPontoon}</li> : null}
        </ul>
      );
    } else if (item.type === "Delivery Order") {
      const value = JSON.parse(item.value);
      return (
        <ul>
          {value.orderNo && <li>Order #: {value.orderNo}</li>}
          {value.product && <li>Product: {value.product}</li>}
          {value.location && <li>From: {value.location}</li>}
          {value.processing && <li>To: {value.processing}</li>}
          {value.departTimestamp && <li>Departure: {moment(value.departTimestamp).format("H:mm a")}</li>}
          {value.vehicleNumber && <li>Vehicle: {value.vehicleNumber}</li>}
          {value.personInCharge && <li>Person: {value.personInCharge}</li>}
          {value.remarks && <li>Remarks: {value.remarks}</li>}
        </ul>
      );
    }
    return "No Data";
  };

  return (
    <List
      size="small"
      itemLayout="vertical"
      header={
        <>
          <Title level={5}>Today's Activities at Farm</Title>
          <Row justify="space-between">
            <Col>
              <Button
                type="link"
                icon={<DoubleLeftOutlined />}
                onClick={() => {
                  const date = moment(activityDateFilter).subtract(1, "day");
                  setActivityDateFilter(date);
                }}
              />
            </Col>
            <Col>
              <DatePicker value={activityDateFilter} allowClear onChange={onChange} />
            </Col>
            <Col>
              <Button
                type="link"
                icon={<DoubleRightOutlined />}
                onClick={() => {
                  const date = moment(activityDateFilter).add(1, "day");
                  setActivityDateFilter(date);
                }}
              />
            </Col>
          </Row>
        </>
      }
      dataSource={data}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            title={
              <Row justify="space-between">
                <Col>{item.type}</Col>
                <Col>
                  <Text type="secondary">{moment(item.createdAt).format("h:mm a")}</Text>
                </Col>
              </Row>
            }
            description={
              item.createdBy ? (
                <Text type="secondary" style={{ fontSize: 12, fontStyle: "italic" }}>
                  by {item.createdBy.split(":::")[1]}
                </Text>
              ) : null
            }
          />
          {renderContent(item)}
        </List.Item>
      )}
      pagination={{
        pageSize: 5,
      }}
    />
  );
};

export default ActivityList;
