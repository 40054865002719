import React, { useState } from "react";
import { Modal, Form } from "antd";
import StorageItemForm from "./StorageItemForm";
import { useFarmStorageRecord } from "../../hooks/useFarmStorageRecord";

const NewStorageItemModal = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { createItem } = useFarmStorageRecord();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          grade: values.grade,
          size: values.size,
          quantity: values.quantity,
          weightInKg: values.weightInKg,
          remarks: values.remarks,
          farmStorageRecordId: id,
        };

        createItem(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Storage Item"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <StorageItemForm form={form} />
    </Modal>
  );
};

export default NewStorageItemModal;
