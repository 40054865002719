import React, { useEffect, useState } from "react";
import { Timeline, Row, Col, Card, Typography } from "antd";
import FlureeHistoryModal from "../../fluree/FlureeHistoryModal";

const { Link } = Typography;

const SearchResultCard = (props) => {
  const { data, showHistory } = props;

  const [eventName, setEventName] = useState("");
  const [secretInfo, setSecretInfo] = useState({});

  useEffect(() => {
    if (data && data.length > 0) {
      var event = JSON.parse(data[0].Info);
      setEventName(event.EventName);
      setSecretInfo(event.SecretInfo);
    }
  }, [data]);

  return (
    <Card
      title={
        <Link underline href="#" onClick={() => showHistory(data[0]._id)}>
          {eventName}
        </Link>
      }
      extra={data && data.length > 1 && "More data"}
    >
      {Object.keys(secretInfo).map((item) => (
        <Row justify="space-between" key={item}>
          <Col>{item.replace(/([A-Z])/g, " $1").trim()}:</Col>
          <Col>{secretInfo[item] ? secretInfo[item] : "-"}</Col>
        </Row>
      ))}
    </Card>
  );
};

const SearchResult = (props) => {
  const { data } = props;

  const [childrenEvent, setChildrenEvent] = useState([]);
  const [flureeHistoryId, setFlureeHistoryId] = useState("");
  const [flureeHistoryModal, setFlureeHistoryModal] = useState(false);

  useEffect(() => {
    if (data && data.length) {
      let array = extractChildData(data[0]);
      setChildrenEvent(array);
    }
  }, [data]);

  useEffect(() => {
    console.log(childrenEvent);
  }, [childrenEvent]);

  const extractChildData = (data, array = []) => {
    if (data.Childs && data.Childs.length) {
      array.push(data.Childs);
      return extractChildData(data.Childs[0], array);
    }
    return array;
  };

  const showHistory = (id) => {
    setFlureeHistoryId(id);
    setFlureeHistoryModal(true);
  };

  return (
    <Row justify="space-between" gutter={16}>
      <Col span={24}>
        <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
          <Timeline mode="left">
            {data && data.length > 0 && (
              <Timeline.Item label={data[0].UploadTimestamp} color="red">
                <SearchResultCard data={data} showHistory={showHistory} />
              </Timeline.Item>
            )}

            {childrenEvent &&
              childrenEvent.map((item, index) => (
                <Timeline.Item label={item[0].UploadTimestamp} key={index}>
                  <SearchResultCard data={item} showHistory={showHistory} />
                </Timeline.Item>
              ))}
          </Timeline>
        </div>

        <FlureeHistoryModal id={flureeHistoryId} visible={flureeHistoryModal} setVisible={setFlureeHistoryModal} />
      </Col>
    </Row>
  );
};

export default SearchResult;
