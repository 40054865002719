import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Card, Space, Tag, Empty } from "antd";
// import { useTranslation } from "react-i18next";

import SscDrawer from "./drawers/SscDrawer";
import AddNewModal from "./modals/AddNewModal";
import NewAuditModal from "./modals/NewAuditModal";

import { usePontoon } from "../../hooks/usePontoon";
import { getObjByValue } from "../../utils/HelperFunctions";
import { sscSource } from "../../utils/Options";

const { Title } = Typography;

const SSCDailyMonitor = () => {
  // const { t } = useTranslation();

  const { findAll } = usePontoon();

  const [refresh, setRefresh] = useState(true);

  const [data, setData] = useState([]);

  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [auditModal, setAuditModal] = useState(false);
  const [sscDrawer, setSscDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [refresh, findAll]);

  const viewDetail = (item) => {
    setEditItem(item);
    setSscDrawer(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>Soft Shell Crab Monitor</Title>

      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Space>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              SSC Count
            </Button>
            <Button onClick={() => setAuditModal(true)}>Audit</Button>
          </Space>
        </Col>
      </Row>

      {data.length ? (
        <Row gutter={[16, 16]}>
          {data.map((item) => (
            <Col key={item.id} xs={12} sm={6}>
              <Card onClick={() => viewDetail(item)} size="small" title={item.label}>
                <Row>
                  <Col xs={24} md={12}>
                    {item.sscSource ? (
                      <Tag color={getObjByValue(sscSource, "value", item.sscSource).color}>{item.sscSource}</Tag>
                    ) : null}
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="text-xs text-gray-400">{item.Pond.label}</div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{ fontSize: 24, color: "green", textTransform: "capitalize", textAlign: "center" }}
                    span={24}
                  >
                    {item.sscCount ? item.sscCount : 0} <span style={{ fontSize: 10, color: "grey" }}>pcs</span>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty />
      )}

      <SscDrawer item={editItem} visible={sscDrawer} setVisible={setSscDrawer} setRefresh={setRefresh} />

      <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

      <NewAuditModal visible={auditModal} setVisible={setAuditModal} setRefresh={setRefresh} />
    </div>
  );
};

export default SSCDailyMonitor;
