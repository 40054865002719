import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useFactoryFreezing = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl +
          "/wapi/factoryFreezingRecords?startDate=" +
          query.startDate +
          "&endDate=" +
          query.endDate +
          "&freezerNo=" +
          query.freezerNo,
        config
      );
    },
    [authState.token, send]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/factoryFreezingRecords/" + id, config);
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/factoryFreezingRecords", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/factoryFreezingRecords/" + id, config);
    },
    [authState.token, send]
  );

  const addDetail = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/factoryFreezingRecords/" + id + "/addDetail", config);
    },
    [authState.token, send]
  );

  const deleteItem = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/factoryFreezingRecords/" + id, config);
    },
    [authState.token, send]
  );

  return {
    findAll,
    findOne,
    create,
    update,
    addDetail,
    deleteItem,
  };
};

export { useFactoryFreezing };
