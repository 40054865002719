import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFactoryProduction } from "../../hooks/useFactoryProduction";

import FactoryProductionTable from "./tables/FactoryProductionTable";
import NewFactoryProductionModal from "./NewFactoryProductionModal";
import EditModal from "./modals/EditModal";
import AssignQRModal from "./modals/AssignQRModal";
import Filter from "./components/Filter";
import QrCodeModal from "./modals/QrCodeModal";

import moment from "moment";
import NewFactoryFreezingModal from "../factoryFreezing/NewFactoryFreezingModal";
import FactoryFreezingDrawer from "../factoryFreezing/FactoryFreezingDrawer";
import NewProcessingOrderModal from "../processingOrder/NewProcessingOrderModal";
import ProcessingOrderDetailDrawer from "../processingOrder/ProcessingOrderDetailDrawer";
import FactoryProductionDrawer from "./FactoryProductionDrawer";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";

const { Title } = Typography;

const FactoryProductionList = () => {
  const { t } = useTranslation();

  const { findAll, destroy } = useFactoryProduction();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([
    moment().subtract(1, "month"),
    moment(),
  ]);
  const [filterBatchNo, setFilterBatchNo] = useState("");
  const [filterStatus, setFilterStatus] = useState("All");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [deleteItem, setDeleteItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [assignQRModal, setAssignQRModal] = useState(false);
  const [newFactoryFreezingModal, setNewFactoryFreezingModal] = useState(false);
  const [factoryProductionDrawer, setFactoryProductionDrawer] = useState(false);
  const [factoryFreezingDrawer, setFactoryFreezingDrawer] = useState(false);
  const [newProcessingOrderModal, setNewProcessingOrderModal] = useState(false);
  const [processingOrderDrawer, setProcessingOrderDrawer] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewQrCodeModal, setViewQrCodeModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate =
        filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate =
        filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
        batchNo: filterBatchNo,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh]);

  const editRow = (item) => {
    setEditItem(item);
    setEditModal(true);
  };

  const freezeRow = (item) => {
    setEditItem(item);
    setNewFactoryFreezingModal(true);
  };

  const assignQR = (item) => {
    setEditItem(item);
    setAssignQRModal(true);
  };

  const createDO = (item) => {
    setEditItem(item);
    setNewProcessingOrderModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setFactoryProductionDrawer(true);
  };

  const deleteRow = (item) => {
    setDeleteItem(item);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    destroy(deleteItem.id).then((res) => {
      setDeleteModal(false);
      setRefresh(true);
    });
  };

  const viewQrCode = (item) => {
    setEditItem(item);
    setViewQrCodeModal(true);
  };

  return (
    <>
      <Filter
        filterBatchNo={filterBatchNo}
        setFilterBatchNo={setFilterBatchNo}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />
      <div
        className="site-layout-background"
        style={{ padding: 24, marginTop: 16 }}
      >
        <Title level={2}>Weighing & Packaging Record</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button
              icon={<ReloadOutlined />}
              disabled={loading}
              onClick={() => setRefresh(true)}
            />
          </Col>
        </Row>

        <FactoryProductionTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          assignQR={assignQR}
          freezeRow={freezeRow}
          createDO={createDO}
          viewDetail={viewDetail}
          deleteRow={deleteRow}
          // viewQrCode={viewQrCode}
        />

        <NewFactoryProductionModal
          visible={addNewModal}
          setVisible={setAddNewModal}
          setRefresh={setRefresh}
        />

        <EditModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />

        <AssignQRModal
          editItem={editItem}
          visible={assignQRModal}
          setVisible={setAssignQRModal}
          setRefresh={setRefresh}
        />

        <NewFactoryFreezingModal
          id={editItem.id}
          visible={newFactoryFreezingModal}
          setVisible={setNewFactoryFreezingModal}
          setRefresh={setRefresh}
        />

        <QrCodeModal
          editItem={editItem}
          visible={viewQrCodeModal}
          setVisible={setViewQrCodeModal}
          setRefresh={setRefresh}
        />

        <FactoryFreezingDrawer
          id={editItem.id}
          visible={factoryFreezingDrawer}
          setVisible={setFactoryFreezingDrawer}
        />

        <NewProcessingOrderModal
          id={editItem.id}
          visible={newProcessingOrderModal}
          setVisible={setNewProcessingOrderModal}
          setRefresh={setRefresh}
        />

        <ProcessingOrderDetailDrawer
          id={editItem.id}
          visible={processingOrderDrawer}
          setVisible={setProcessingOrderDrawer}
          setRefresh={setRefresh}
        />

        <FactoryProductionDrawer
          id={editItem.id}
          visible={factoryProductionDrawer}
          setVisible={setFactoryProductionDrawer}
        />

        <ConfirmDeleteModal
          visible={deleteModal}
          onOk={() => confirmDelete()}
          onCancel={() => setDeleteModal(false)}
        />
      </div>
    </>
  );
};

export default FactoryProductionList;
