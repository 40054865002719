import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useGrowOutRecord = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAllByGrowOut = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/growOutRecords?growOutId=" + id, config);
    },
    [authState.token, send]
  );

  // replace findAllByGrowOut
  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/growOutRecords?growOutId=" + query.growOutId, config);
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/growOutRecords", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/growOutRecords/" + id, config);
    },
    [authState.token, send]
  );

  // Replaced by pondHarvest
  // const harvestGrowOut = useCallback(
  //   async (params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //       body: JSON.stringify(params),
  //     };

  //     return await send(apiBaseUrl + "/wapi/growOutRecords/harvest", config);
  //   },
  //   [authState.token, send]
  // );

  return {
    findAll,
    findAllByGrowOut,
    create,
    update,
    // harvestGrowOut,
  };
};

export { useGrowOutRecord };
