import React, { useContext } from "react";
import { Dropdown, Tag, Menu, Button, Space, Tooltip, Typography } from "antd";
import {
  PlusOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  AuditOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { orange } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import { getObjByValue } from "../../../utils/HelperFunctions";
import { sscSource } from "../../../utils/Options";
import { AuthContext } from "../../../App";

const { Link } = Typography;

const SSCHarvestTable = (props) => {
  const { dataSource, loading, error, view, createDeliveryOrder, consolidate, storeInFridge } = props;

  const { can } = useContext(AuthContext);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, row) => (
        <Space>
          <Link onClick={() => view(row)}>{text}</Link>
          {/* {row.antChainTraceCode && (
            <Button type="link" icon={<BlockOutlined />} href={row.antChainTraceCode} target="_blank" />
          )} */}
          {row.balance > 0 &&
          moment().startOf("day").diff(moment(row.harvestDate).startOf("day"), "days", false) > 0 ? (
            <Tooltip title="Outstanding">
              <ExclamationCircleFilled style={{ color: orange.primary }} />
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
    {
      title: "Date",
      dataIndex: "harvestDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Source",
      dataIndex: "sourceType",
      render: (text) => (text ? <Tag color={getObjByValue(sscSource, "value", text).color}>{text}</Tag> : "-"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              {can("Confirm SSC Harvest") && (
                <Menu.Item
                  key="edit"
                  icon={<AuditOutlined />}
                  onClick={() => consolidate(row)}
                  disabled={row.status === "Confirm"}
                >
                  Confirm
                </Menu.Item>
              )}
              <Menu.Item
                key="storage"
                icon={<DatabaseOutlined />}
                onClick={() => storeInFridge(row)}
                disabled={row.balance <= 0}
              >
                Storage
              </Menu.Item>
              <Menu.Item
                key="deliveryOrder"
                icon={<PlusOutlined />}
                onClick={() => createDeliveryOrder(row)}
                disabled={row.balance <= 0}
              >
                Delivery Order
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      // expandable={{
      //   expandedRowRender: (row) => (
      //     <SSCHarvestRecordTable
      //       dataSource={row.SSCHarvestRecords}
      //       loading={loading}
      //       error={error}
      //       editRow={editHarvestRecord}
      //     />
      //   ),
      //   rowExpandable: (row) => row.SSCHarvestRecords && row.SSCHarvestRecords.length,
      // }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default SSCHarvestTable;
