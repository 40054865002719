import React, { useState, useEffect } from "react";
import { Modal, Form, Divider, Button, Typography, Row, Col } from "antd";
import { useFactoryReceipt } from "../../../hooks/useFactoryReceipt";
import EditForm from "../forms/EditForm";
import FactoryReceiptDetailTable from "../FactoryReceiptDetailTable";
import moment from "moment";

const { Text } = Typography;

const EditModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFactoryReceipt();

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        arrivalTimestamp: moment(editItem.arrivalTimestamp),
        vehicleNumber: editItem.vehicleNumber,
        personInCharge: editItem.personInCharge,
        status: editItem.status,
        remarks: editItem.remarks,
      });

      if (editItem.FactoryReceiptDetails && editItem.FactoryReceiptDetails.length) {
        const newArray = editItem.FactoryReceiptDetails.map((item, index) => {
          return {
            id: index,
            grade: item.grade,
            size: item.size,
            farmQuantity: item.farmQuantity,
            farmWeightInKg: item.farmWeightInKg,
            processingQuantity: item.processingQuantity,
            processingWeightInKg: item.processingWeightInKg,
            remarks: item.remarks,
          };
        });

        setDetailData(newArray);
      } else {
        setDetailData([]);
      }
    }
  }, [visible, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const detailRecord = detailData.filter((item) => item.processingQuantity > 0);

        if (detailRecord <= 0) {
          setError(true);
          return;
        }
        setError(false);

        setSubmitting(true);

        const params = {
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          remarks: values.remarks,
          status: values.status,
          detailData: detailRecord,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const addRow = () => {
    const item = {
      id: detailData.length,
      grade: null,
      size: null,
      farmQuantity: null,
      farmWeightInKg: null,
      processingQuantity: null,
      processingWeightInKg: null,
      remarks: null,
    };
    setDetailData([...detailData, item]);
  };

  return (
    <Modal
      title={"Edit Material Receipt"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width="75%"
    >
      <EditForm form={form} />

      <Divider orientation="left">Details</Divider>

      <Row justify="end">
        <Col>
          <Button type="primary" onClick={addRow}>
            New Row
          </Button>
        </Col>
      </Row>

      {error && <Text type="danger">Please input at least one quantity </Text>}

      <FactoryReceiptDetailTable detailData={detailData} setDetailData={setDetailData} />
    </Modal>
  );
};

export default EditModal;
