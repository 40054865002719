import React, { useState, useEffect } from "react";
import { Modal, Form, Divider } from "antd";
import moment from "moment";
// import { useFactoryProduction } from "../../../hooks/useFactoryProduction";
import { useFactoryFreezing } from "../../hooks/useFactoryFreezing";
// import OpenTransferTable from "../tables/OpenTransferTable";
import SSCFreezingForm from "./SSCFreezingForm";

const NewFactoryFreezingModal = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFactoryFreezing();

  const [submitting, setSubmitting] = useState(false);

  // const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        factoryProductionRecordId: id,
        startTimestamp: moment(),
        endTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      // if (selectedRows.length <= 0) {
      //   return;
      // }
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          freezerNo: values.freezerNo,
          startTimestamp: moment(values.startTimestamp).format(),
          endTimestamp: values.endTimestamp ? moment(values.endTimestamp).format() : null,
          freezerTemperature: values.freezerTemperature,
          productTemperature: values.productTemperature,
          remarks: values.remarks,
          factoryProductionRecordId: values.factoryProductionRecordId,
          // factoryProductionArrayId: selectedRows.map((item) => item.id),
          markAsComplete: values.markAsComplete,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Blast Freezing Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <SSCFreezingForm form={form} />

      {/* <Divider orientation="left">Production Record</Divider>

      <OpenTransferTable dataSource={productionData} setSelectedRows={setSelectedRows} /> */}
    </Modal>
  );
};

export default NewFactoryFreezingModal;
