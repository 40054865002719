import React from "react";
import { Card, Descriptions } from "antd";
import moment from "moment";

const HatcheryCard = (props) => {
  const { title, data } = props;

  return (
    <Card title={title} size="small" {...props}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="Larval Batch">{data.batchNo ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Hatch Date">
          {data.IncubationRecord ? moment(data.IncubationRecord.hatchDate).format("YYYY-MM-DD") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Harvest Date">
          {moment(data.productionTimestamp).format("YYYY-MM-DD")}
        </Descriptions.Item>
        <Descriptions.Item label="Broodstock No">{data.broodstockNo ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Species">{data.broodstockSpecies ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Spawn Date">
          {data.IncubationRecord ? moment(data.IncubationRecord.spawnDate).format("YYYY-MM-DD") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Release Date">
          {data.IncubationRecord &&
          data.IncubationRecord.HatcheryReceipt &&
          data.IncubationRecord.HatcheryReceipt.releaseDate
            ? moment(data.IncubationRecord.HatcheryReceipt.releaseDate).format("YYYY-MM-DD")
            : "-"}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default HatcheryCard;
