import React, { useEffect } from "react";
import { Card, Form, Select, Button, Typography, Divider } from "antd";
import moment from "moment";
import { useFarmReport } from "../../hooks/useFarmReport";
import { toArrayBuffer } from "../../utils/HelperFunctions";

const { Option } = Select;
const { Title } = Typography;

const SSCHarvestData = () => {
  const [form] = Form.useForm();

  const { sscHarvestData } = useFarmReport();

  useEffect(() => {
    form.setFieldsValue({
      year: moment().year(),
      month: (moment().month() + 1).toString(),
    });
  }, [form]);

  const onFinish = (values) => {
    const date = moment.utc(values.year + "-" + values.month, "YYYY-MM");
    sscHarvestData({
      startDate: date.startOf("month").valueOf(),
      endDate: date.endOf("month").valueOf(),
    }).then((res) => {
      if (res.status === "success") {
        const file = new Blob([toArrayBuffer(res.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "SSC Report - " + date.format("MMM YYYY") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <Card style={{ marginTop: 16 }}>
      <Title level={3}>SSC Report</Title>

      <Divider />

      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Year" name="year">
          <Select allowClear style={{ width: 150 }}>
            <Option value="2021">2021</Option>
            <Option value="2022">2022</Option>
            <Option value="2023">2023</Option>
            <Option value="2024">2024</Option>
            <Option value="2025">2025</Option>
            <Option value="2026">2026</Option>
            <Option value="2027">2027</Option>
            <Option value="2028">2028</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Month" name="month">
          <Select allowClear style={{ width: 150 }}>
            <Option value="1">January</Option>
            <Option value="2">February</Option>
            <Option value="3">March</Option>
            <Option value="4">April</Option>
            <Option value="5">May</Option>
            <Option value="6">June</Option>
            <Option value="7">July</Option>
            <Option value="8">August</Option>
            <Option value="9">September</Option>
            <Option value="10">October</Option>
            <Option value="11">November</Option>
            <Option value="12">December</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Generate
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SSCHarvestData;
