import React, { useState, useEffect, useContext } from "react";
import { Drawer, Tabs, Typography, Tag } from "antd";
import { usePond } from "../../../hooks/usePond";

import { ResponsiveContext } from "../../../App";
import PondPreparationTab from "./PondPreparationTab";
import PondFeedTab from "./PondFeedTab";
import PondPontoonTab from "./PondPontoonTab";
// import PondSamplingTab from "./PondSamplingTab";
// import PondParameterTab from "./PondParameterTab";
import PondTreatmentTab from "./PondTreatmentTab";
import PondCycleTab from "./PondCycleTab";

const { TabPane } = Tabs;

const { Title } = Typography;

const PondDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne, findGrowOut } = usePond();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pond, setPond] = useState({});

  const [growOutData, setGrowOutData] = useState([]);

  useEffect(() => {
    if ((visible && id) || (visible && id && refresh)) {
      setLoading(true);
      Promise.all([findOne(id), findGrowOut(id)]).then((res) => {
        if (res[0].status === "success") {
          setPond(res[0].data.pond);
        } else {
          setPond([]);
        }

        if (res[1].status === "success") {
          setGrowOutData(res[1].data);
        } else {
          setGrowOutData([]);
        }

        setLoading(false);
        setRefresh(false);
      });
    }
  }, [visible, refresh, id, findOne, findGrowOut]);

  const onClose = () => {
    setVisible(false);
  };

  const hasValidCycle = () => growOutData.filter((item) => item.status === "New").length;

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? "100%" : "70%"} destroyOnClose>
      <div class="flex justify-between mb-4">
        <div>
          <Title level={4}>{pond.label}</Title>
          <Tag color={pond.pondType === "Grow Out" ? "cyan" : "magenta"}>{pond.pondType}</Tag>
        </div>
      </div>

      <Tabs>
        <TabPane tab="Pond Cycle" key="pondCycle">
          <PondCycleTab pond={pond} dataSource={growOutData} loading={loading} setRefresh={setRefresh} />
        </TabPane>

        {/* <TabPane tab="Preparation" key="preparation">
          <PondPreparationTab pond={pond} hasValidCycle={hasValidCycle} />
        </TabPane>

        <TabPane tab="Feeding" key="feeding">
          <PondFeedTab pond={pond} hasValidCycle={hasValidCycle} />
        </TabPane>

        <TabPane tab="Treatment" key="treatment">
          <PondTreatmentTab pond={pond} hasValidCycle={hasValidCycle} />
        </TabPane> */}

        {/* <TabPane tab="Parameters" key="parameter">
          <PondParameterTab pond={pond} hasValidCycle={hasValidCycle} />
        </TabPane> */}

        {/* <TabPane tab="Sampling" key="sampling">
          <PondSamplingTab pond={pond} hasValidCycle={hasValidCycle} />
        </TabPane> */}

        {pond.pondType === "Pontoon" && (
          <TabPane tab="Pontoon" key="pontoon">
            <PondPontoonTab pond={pond} hasValidCycle={hasValidCycle} />
          </TabPane>
        )}
      </Tabs>
    </Drawer>
  );
};

export default PondDrawer;
