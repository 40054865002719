import React, { useState, useEffect } from "react";
import { Drawer, Descriptions, List } from "antd";
import moment from "moment";
import { useNurseryHapa } from "../../hooks/useNurseryHapa";

const NurseryRecordDrawer = (props) => {
  const { item, visible, setVisible } = props;

  const { findAll } = useNurseryHapa();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    if ((visible || refresh) && item.id) {
      setLoading(true);
      findAll({ nurseryRecordId: item.id }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [visible, refresh, item]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer placement="bottom" onClose={onClose} visible={visible} height={"80%"}>
      <Descriptions style={{ marginBottom: 16 }}>
        <Descriptions.Item label="Arrival Date">{moment(item.FarmReceipt?.arrivalTimestamp).format("ll")}</Descriptions.Item>
        <Descriptions.Item label="Stock Date">{moment(item.stockDate).format("ll")}</Descriptions.Item>
        <Descriptions.Item label="Stock Quantity (PCS)">{item.stockQuantityInPcs}</Descriptions.Item>
        <Descriptions.Item label="Harvest Quantity (PCS)">{item.harvestQuantityInPcs}</Descriptions.Item>
        <Descriptions.Item label="Total HAPA">{item.totalHapa}</Descriptions.Item>
        <Descriptions.Item label="Status">{item.status}</Descriptions.Item>
        <Descriptions.Item label="Remarks" span={3}>
          {item.remarks ? item.remarks : "-"}
        </Descriptions.Item>
      </Descriptions>

      <List
        loading={loading}
        itemLayout="vertical"
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={"HAPA " + item.label} />
            <div>
              <Descriptions>
                <Descriptions.Item label="Stock Date">{moment(item.stockDate).format("lll")}</Descriptions.Item>
                <Descriptions.Item label="Stock Qty">{Number(item.stockQuantityInPcs).toLocaleString()} pcs</Descriptions.Item>
                <Descriptions.Item label="Harvest Date">{item.harvestDate ? moment(item.harvestDate).format("lll") : "-"}</Descriptions.Item>
                <Descriptions.Item label="Harvest Qty">
                  {item.harvestQuantityInPcs ? Number(item.harvestQuantityInPcs).toLocaleString() + " pcs" : "-"}
                </Descriptions.Item>
                <Descriptions.Item label="Running Feed">{Number(item.runningFeedInKg).toFixed(2)} kg</Descriptions.Item>
                <Descriptions.Item label="Status">{item.status}</Descriptions.Item>
                <Descriptions.Item label="Feed Details">
                  <div>
                    {item.feedDetails && item.feedDetails.map((detail, index) => (
                      <p>
                        {index + 1}. {detail.feedType} {`(${Number(detail.totalFeedInKg).toFixed(2)} kg)`}
                      </p>
                    ))}
                  </div>
                </Descriptions.Item>
                {item.GrowOutRecords && item.GrowOutRecords.length != 0 ? (
                  <Descriptions.Item label="Pond">{item.GrowOutRecords.map((t) => t.GrowOut.Pond.label)}</Descriptions.Item>
                ) : (
                  <Descriptions.Item label="Order No.">
                    {item.FarmProductionRecords && item.FarmProductionRecords.length != 0 ? item.FarmProductionRecords.map((t) => t.DeliveryOrder ? t.DeliveryOrder.orderNo : "-") : "-"}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default NurseryRecordDrawer;
