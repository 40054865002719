import React from "react";
// import { useTranslation } from "react-i18next";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { blue } from "@ant-design/colors";

const DailyRecordTable = (props) => {
  // const { t } = useTranslation();

  const { dataSource, loading, error, editRow } = props;

  const columns = [
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Shift",
      dataIndex: "shift",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Time",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("h:mm a"),
    },
    {
      title: "Alive",
      dataIndex: "alive",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "SSC",
      dataIndex: "harvest",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Dead",
      dataIndex: "dead",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Missing",
      dataIndex: "missing",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Stock In",
      dataIndex: "stockInQuantity",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Stock Out",
      dataIndex: "stockOutQuantity",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default DailyRecordTable;
