export const broodstockStatus = {
  Quarantined: {
    color: "red",
  },
  New: {
    color: "blue",
  },
  Incubation: {
    color: "orange",
  },
  Hatched: {
    color: "cyan",
  },
  Discard: {
    color: "",
  },
  Released: {
    color: "green",
  },
};

export const incubationStatus = {
  New: {
    color: "blue",
  },
  Hatched: {
    color: "cyan",
  },
  Discard: {
    color: "",
  },
};

export const larvalTransferStatus = {
  New: {
    color: "blue",
  },
  "Transfer Out": {
    color: "",
  },
};
