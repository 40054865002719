import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import SSCProductionItemForm from "./SSCProductionItemForm";
import { useFactoryProductionDetail } from "../../hooks/useFactoryProductionDetail";

const EditFactoryProductionDetailModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFactoryProductionDetail();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        grade: editItem.grade,
        size: editItem.size,
        quantity: editItem.quantity,
        balanceQuantity: editItem.balanceQuantity,
        weightInKg: editItem.weightInKg,
        balanceWeightInKg: editItem.balanceWeightInKg,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          grade: values.grade,
          size: values.size,
          quantity: values.quantity,
          balanceQuantity: values.balanceQuantity,
          weightInKg: values.weightInKg,
          balanceWeightInKg: values.balanceWeightInKg,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Item"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <SSCProductionItemForm form={form} />
    </Modal>
  );
};

export default EditFactoryProductionDetailModal;
