import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { useGrowOut } from "../../hooks/useGrowOut";
import moment from "moment";
import PondCycleForm from "./PondCycleForm";

const EditGrowOutModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useGrowOut();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        cycleNo: editItem.cycleNo,
        material: editItem.material,
        status: editItem.status,
        startAt: editItem.startAt ? moment(editItem.startAt) : null,
        endAt: editItem.endAt ? moment(editItem.endAt) : null,
        stockingDensity: editItem.stockingDensity,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          cycleNo: values.cycleNo,
          material: values.material,
          label: values.label,
          status: values.status,
          startAt: values.startAt,
          endAt: values.endAt,
          stockingDensity: values.stockingDensity,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Cycle"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <PondCycleForm form={form} isEdit />
    </Modal>
  );
};

export default EditGrowOutModal;
