import React, { useEffect, useState, useContext } from "react";
import { Layout, Space, Button, Badge } from "antd";
import { BellOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ReloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./AppHeader.css";
import { AuthContext, ResponsiveContext } from "../../../App";
import ProfileMenu from "../menus/ProfileMenu";
import LocaleMenu from "../menus/LocaleMenu";
import { useNotification } from "../../../hooks/useNotification";

const { Header } = Layout;

const AppHeader = (props) => {
  const { authState } = useContext(AuthContext);
  const { isMobile } = useContext(ResponsiveContext);

  const { getUnSeenNotification, clearHasSeenNotification, subscribeToNotification, unSubscribeNotification } =
    useNotification();

  const { collapsed, setCollapsed } = props;

  const [newNotification, setNewNotification] = useState([]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    let unmounted = false;

    subscribeToNotification((item) => {
      setNewNotification((newNotification) => [item, ...newNotification]);
    });
    getUnSeenNotification().then((res) => {
      if (res.status === "success" && !unmounted) {
        let array = [];
        for (let i = 0; i < res.data.unSeenCount; i++) {
          array.push(i);
        }
        setNewNotification(array);
      }
    });

    return () => {
      unSubscribeNotification();
      unmounted = true;
    };
  }, []);

  const clearNotification = () => {
    setNewNotification([]);

    clearHasSeenNotification({ userId: authState.authUser.id });
  };

  return (
    <Header style={{ background: "#fff", padding: 0 }}>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggle,
      })}

      {!isMobile && (
        <div style={{ float: "right", marginRight: 24 }}>
          <Space>
            <Button type="text" onClick={() => clearNotification()}>
              <Link to="/account/notifications">
                <Badge size="small" count={newNotification.length} offset={[3, -3]} style={{ fontSize: 11 }}>
                  <BellOutlined />
                </Badge>
              </Link>
            </Button>

            <ProfileMenu />

            <LocaleMenu />
          </Space>
        </div>
      )}

      {isMobile && collapsed ? (
        <div style={{ float: "right", marginRight: 24 }}>
          <Space>
            {/* <Button type="text" onClick={() => clearNotification()}>
              <Link to="/account/notifications">
                <Badge size="small" count={newNotification.length} offset={[3, -3]} style={{ fontSize: 11 }}>
                  <BellOutlined />
                </Badge>
              </Link>
            </Button> */}

            <ProfileMenu />

            {/* <LocaleMenu /> */}

            <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()} />
          </Space>
        </div>
      ) : null}
    </Header>
  );
};

export default AppHeader;
