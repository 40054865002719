import React, { useEffect } from "react";
import { Card, Form, DatePicker, Select, Button, Input } from "antd";
import { broodstockStatus } from "../../../utils/Status";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filter = (props) => {
  const {
    filterBroodstockNo,
    setFilterBroodstockNo,
    filterStatus,
    setFilterStatus,
    filterDate,
    setFilterDate,
    setRefresh,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      broodstockNo: filterBroodstockNo,
      status: filterStatus,
      date: filterDate,
    });
  }, [form, filterBroodstockNo, filterStatus, filterDate]);

  const onFinish = (values) => {
    setFilterBroodstockNo(values.broodstockNo);
    setFilterStatus(values.status);
    setFilterDate(values.date);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Status" name="status">
          <Select style={{ width: 120 }}>
            <Option value="All">All</Option>
            {Object.keys(broodstockStatus).map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Arrival Date" name="date">
          <RangePicker />
        </Form.Item>

        <Form.Item label="Broodstock No" name="broodstockNo">
          <Input allowClear />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
