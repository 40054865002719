import React from "react";
import { Typography } from 'antd';
import { useTranslation } from "react-i18next";
import BasicSettingsForm from '../forms/BasicSettingsForm';

const { Title } = Typography;

const BasicSettings = () => {

    const { t } = useTranslation();

    return (
        <>
            <Title level={4}>
                {t('account.setting.basic')}
            </Title>

            <BasicSettingsForm />
        </>
    )
}

export default BasicSettings;