import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useTankTransfer = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (status, tankId, startDate, endDate) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl +
          "/wapi/tankUsages?status=" +
          status +
          "&tankId=" +
          tankId +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate,
        config
      );
    },
    [authState.token, send]
  );

  const findOperatingTank = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await send(apiBaseUrl + "/wapi/tankUsages/operating", config);
  }, [authState.token, send]);

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankUsages", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankUsages/" + id, config);
    },
    [authState.token, send]
  );

  const transferToNewTank = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankUsages/" + id + "/transferToNewTank", config);
    },
    [authState.token, send]
  );

  const convertToProduction = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankUsages/" + id + "/convertToProduction", config);
    },
    [authState.token, send]
  );

  const exportToExcel = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankUsages/" + id + "/export/excel", config);
    },
    [authState.token, send]
  );

  const createMultiple = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankUsages/createMultiple", config);
    },
    [authState.token, send]
  );

  return {
    findAll,
    findOperatingTank,
    create,
    update,
    transferToNewTank,
    convertToProduction,
    exportToExcel,
    createMultiple,
  };
};

export { useTankTransfer };
