import React from "react";
import { FormOutlined } from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const DailyRecordTable = (props) => {
  const { dataSource, loading, error, editRow } = props;

  const columns = [
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("YYYY/MM/DD"),
    },
    {
      title: "Time",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("h:mm a"),
    },
    {
      title: "Temperature",
      dataIndex: "temperature",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "DO",
      dataIndex: "do",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "",
      render: (text, row) => <FormOutlined onClick={() => editRow(row)} />,
    },
  ];

  return (
    <TableLoader error={error} loading={loading} dataSource={dataSource} columns={columns} style={{ marginTop: 24 }} />
  );
};

export default DailyRecordTable;
