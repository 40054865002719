import React from "react";
import LoginForm from "./forms/LoginForm";

const Login = () => {
  return (
    <div className="max-w-lg mx-auto mt-16 p-8">
      <h1 class="text-3xl">Login</h1>
      <LoginForm />
    </div>
  );
};

export default Login;
