import React from "react";
import { Form, Input, InputNumber, DatePicker, Divider, Descriptions, Space } from "antd";
import { sscGrade, sscSize } from "../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const FarmStorageForm = (props) => {
  const { form, onInputChange, onWeightChange, isEdit } = props;

  return (
    <Form {...layout} form={form} name="farm-storage-form">
      <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
        <DatePicker />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} />
      </Form.Item>

      {!isEdit && (
        <>
          <Divider orientation="left">Soft Shell Crab</Divider>

          {sscGrade.map((grade) => (
            <Descriptions title={"Grade " + grade} size="small" key={grade} column={1}>
              {sscSize.map((item) => (
                <Descriptions.Item
                  label={item}
                  key={grade + "_" + item}
                  contentStyle={{ justifyContent: "flex-end" }}
                  style={{ paddingLeft: 16 }}
                >
                  <Space>
                    <InputNumber
                      placeholder="pcs"
                      inputMode="numeric"
                      allowClear
                      onChange={(value) => onInputChange(grade, item, value)}
                    />
                    <InputNumber
                      placeholder="(kg)"
                      inputMode="decimal"
                      allowClear
                      onChange={(value) => onWeightChange(grade, item, value)}
                    />
                  </Space>
                </Descriptions.Item>
              ))}
            </Descriptions>
          ))}
        </>
      )}
    </Form>
  );
};

export default FarmStorageForm;
