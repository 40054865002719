import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, InputNumber, Typography, Alert } from "antd";
import moment from "moment";
import { useFarmReceipt } from "../../../hooks/useFarmReceipt";
import { usePontoon } from "../../../hooks/usePontoon";

const { Text } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ExternalPontoonStockInModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findPontoon } = usePontoon();

  const { stockInMaterial } = useFarmReceipt();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [pontoonOption, setPontoonOption] = useState([]);
  const [pontoonArray, setPontoonArray] = useState([]);

  useEffect(() => {
    if (visible) {
      findPontoon({ sscSource: "All" }).then((res) => {
        if (res.status === "success") {
          setPontoonOption(res.data);
        }
      });

      form.setFieldsValue({
        stockInTimestamp: moment(),
      });
    }
  }, [visible, findPontoon, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const pontoonRecord = pontoonArray.filter((item) => item.quantity && item.quantity > 0);

        if (pontoonRecord.length <= 0) {
          setError(true);
          return;
        }
        setError(false);

        const params = {
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          pontoonArray: pontoonRecord,
        };
        setSubmitting(true);

        stockInMaterial(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            setPontoonArray([]);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onPontoonChange = (value, item) => {
    const index = pontoonArray.map((item) => item.pontoonId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...pontoonArray];
      newArray[index] = {
        pontoonId: item.id,
        quantity: value,
      };
      setPontoonArray(newArray);
    } else {
      setPontoonArray([...pontoonArray, { pontoonId: item.id, quantity: value }]);
    }
  };

  return (
    <Modal
      title={"SSC Pontoon Stock In"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Alert
        style={{ marginBottom: 16 }}
        type="warning"
        message=" Temporarily allow raw material from external source to go into Internal Pontoon because the operation is not
        ready. Please inform developer when operation is ready."
      />

      <Form {...layout} form={form} labelAlign="left">
        <Form.Item name="stockInTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        {pontoonOption.map((item) => (
          <Form.Item label={item.label} extra={item.sscSource}>
            <InputNumber inputMode="numeric" min={0} allowClear onChange={(value) => onPontoonChange(value, item)} />
          </Form.Item>
        ))}

        {error && <Text type="danger">Please input at least one Stock In quantity </Text>}
      </Form>
    </Modal>
  );
};

export default ExternalPontoonStockInModal;
