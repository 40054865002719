import React from "react";
import { Tooltip, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import QRCode from "qrcode.react";

const { Link } = Typography;

const BabyPackTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{text}</Link>,
    },
    {
      title: "QR Code",
      render: (text, row) => <QRCode id={row.id} value={row.QRCode.code} size={64} />,
    },
    {
      title: "Tracker",
      render: (text, row) => (row.QRCode.code ? row.QRCode.code : "-"),
    },
    {
      title: "Packed on",
      dataIndex: "packTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Total Weight (KG)",
      dataIndex: "weightInKg",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader error={error} loading={loading} dataSource={dataSource} columns={columns} style={{ marginTop: 24 }} />
  );
};

export default BabyPackTable;
