import React, { useState, useEffect } from "react";
import { Modal, Form, DatePicker, Select, Input, InputNumber } from "antd";
import { harvestShift, farmProductionType, farmProductionStatus } from "../../../utils/Options";
import moment from "moment";
import { useFarmProduction } from "../../../hooks/useFarmProduction";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFarmProduction();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        type: editItem.type,
        productionTimestamp: moment(editItem.productionTimestamp),
        quantity: editItem.quantity,
        weightIngKg: editItem.weightIngKg,
        harvestShift: editItem.harvestShift,
        remarks: editItem.remarks,
        status: editItem.status,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          type: values.type,
          productionTimestamp: moment(values.productionTimestamp).format(),
          quantity: values.quantity,
          weightIngKg: values.weightIngKg,
          harvestShift: values.harvestShift,
          remarks: values.remarks,
          status: values.status,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Farm Production Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="edit-form">
        <Form.Item name="type" label="Type" rules={[{ required: true }]}>
          <Select placeholder="Select a Type" allowClear>
            {farmProductionType.map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="productionTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="harvestShift" label="Harvest Shift">
          <Select allowClear>
            {harvestShift.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="quantity" label="Quantity">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="weightInKg" label="Weight (KG)">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>

        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select allowClear>
            {farmProductionStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
