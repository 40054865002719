import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import FarmStorageForm from "./FarmStorageForm";
import { useFarmStorageRecord } from "../../hooks/useFarmStorageRecord";

const NewFarmStorageModal = (props) => {
  const { sscHarvestItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFarmStorageRecord();

  const [submitting, setSubmitting] = useState(false);
  const [accumulatedSSC, setAccumulatedSSC] = useState();
  const [softShellGrade, setSoftShellGrade] = useState();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(),
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          remarks: values.remarks,
          sscHarvestDailyId: sscHarvestItem.id,
          accumulatedSSC: accumulatedSSC,
          softShellGrade: softShellGrade,
          stockMovement: "IN",
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            setAccumulatedSSC(null);
            setSoftShellGrade(null);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onAccInputChange = (grade, value) => {
    let grades = { ...accumulatedSSC };

    grades = {
      ...grades,
      [grade]: {
        ...grades[grade],
        quantity: value,
      },
    };
    setAccumulatedSSC(grades);
  };

  const onAccWeightChange = (grade, value) => {
    let grades = { ...accumulatedSSC };

    grades = {
      ...grades,
      [grade]: {
        ...grades[grade],
        weightInKg: value,
      },
    };

    setAccumulatedSSC(grades);
  };

  const onInputChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        quantity: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  const onWeightChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        weightInKg: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  return (
    <Modal
      title="New Farm Storage Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <FarmStorageForm
        form={form}
        // accumulatedSSC={accumulatedSSC}
        // onAccInputChange={onAccInputChange}
        // onAccWeightChange={onAccWeightChange}
        onInputChange={onInputChange}
        onWeightChange={onWeightChange}
      />
    </Modal>
  );
};

export default NewFarmStorageModal;
