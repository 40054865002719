import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space } from "antd";
import { ReloadOutlined, ExportOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHatcheryProduction } from "../../hooks/useHatcheryProduction";
import HatcheryProductionTable from "./tables/HatcheryProductionTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import Filter from "./components/Filter";
import moment from "moment";
import { toArrayBuffer } from "../../utils/HelperFunctions";

const { Title } = Typography;

const HatcheryProductionList = () => {
  const { t } = useTranslation();

  const { findAll, exportToExcel } = useHatcheryProduction();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);
  const [filterStatus, setFilterStatus] = useState("New");
  const [filterTank, setFilterTank] = useState("All");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
        tankId: filterTank,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, filterTank, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const doExcelExport = () => {
    const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
    const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

    exportToExcel({
      status: filterStatus,
      startDate: startDate,
      endDate: endDate,
      tankId: filterTank,
    }).then((res) => {
      if (res.status === "success") {
        const file = new Blob([toArrayBuffer(res.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "Hatchery Harvest.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <>
      <Filter
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterTank={filterTank}
        setFilterTank={setFilterTank}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Hatchery Harvest</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Space>
              <Button onClick={doExcelExport} icon={<ExportOutlined />}>
                Export to Excel
              </Button>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Space>
          </Col>
        </Row>

        <HatcheryProductionTable error={error} loading={loading} dataSource={data} editRow={editRow} />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default HatcheryProductionList;
