import React from "react";
import { Card, Descriptions, Typography, Space, Tag } from "antd";

const { Link } = Typography;

const ProcessingOrderCard = (props) => {
  const { title, data, viewDetail } = props;

  return (
    <Card title={title} size="small" {...props}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="Order(s)">
          <Space>
            {data.map((item) => (
              <Tag>
                <Link onClick={() => viewDetail("Delivery Order (Processing)", item)}>Order #{item.id}</Link>
              </Tag>
            ))}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default ProcessingOrderCard;
