import React, { useEffect } from "react";
import { Card, Form, Button, DatePicker, Input } from "antd";

const { RangePicker } = DatePicker;

const Filter = (props) => {
  const { filterFreezerNo, setFilterFreezerNo, filterDate, setFilterDate, setRefresh } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      freezerNo: filterFreezerNo,
      date: filterDate,
    });
  }, [form]);

  const onFinish = (values) => {
    setFilterFreezerNo(values.freezerNo);
    setFilterDate(values.date);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Date" name="date">
          <RangePicker />
        </Form.Item>

        <Form.Item label="Freezer" name="freezerNo">
          <Input allowClear />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
