import React, { useState } from "react";
import { Modal, Form, DatePicker, InputNumber } from "antd";
import { useIncubationRecord } from "../../../hooks/useIncubationRecord";
import moment from "moment";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const HatchModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { updateHatch } = useIncubationRecord();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          hatchDate: values.hatchDate ? moment(values.hatchDate).format() : null,
          quantity: values.quantity,
        };
        updateHatch(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Hatch Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="hatch-form">
        <Form.Item name="hatchDate" label="Hatch Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>
        <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
          <InputNumber min={0} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default HatchModal;
