import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import EditForm from "../forms/EditForm";
import { useIncubationRecord } from "../../../hooks/useIncubationRecord";
import moment from "moment";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useIncubationRecord();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        spawnDate: moment(editItem.spawnDate),
        hatchDate: editItem.hatchDate ? moment(editItem.hatchDate) : null,
        quantity: editItem.quantity,
        remarks: editItem.remarks,
        status: editItem.status,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          spawnDate: moment(values.spawnDate).format(),
          hatchDate: values.hatchDate ? moment(values.hatchDate).format() : null,
          quantity: values.quantity,
          remarks: values.remarks,
          status: values.status,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Incubation Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <EditForm form={form} />
    </Modal>
  );
};

export default EditModal;
