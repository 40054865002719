import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const usePondActivity = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  // const findOperatingPond = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };

  //   return await send(apiBaseUrl + "/wapi/pondActivities/operating", config);
  // }, [authState.token, send]);

  const findDODailyRecordById = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/pondActivities/" + id + "/doDailyRecords", config);
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords", config);
    },
    [authState.token, send]
  );

  return {
    // findOperatingPond,
    findDODailyRecordById,
    create,
  };
};

export { usePondActivity };
