import React, { useEffect } from "react";
import { Card, Form, Select, Button, DatePicker } from "antd";
import { sscHarvestDailyStatus } from "../../../utils/Options";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filter = (props) => {
  const { filterDate, setFilterDate, filterStatus, setFilterStatus, setRefresh } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      date: filterDate,
      status: filterStatus,
    });
  }, [form, filterDate, filterStatus]);

  const onFinish = (values) => {
    setFilterDate(values.date);
    setFilterStatus(values.status);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Date" name="date">
          <RangePicker />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select allowClear style={{ width: 150 }}>
            <Option value="All">All</Option>
            {sscHarvestDailyStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
