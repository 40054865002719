import React from "react";
import { DatePicker, Form, Input, InputNumber, Steps, Divider, Checkbox, Row, Col, Tag, Space, Button } from "antd";
import moment from "moment";

const { Step } = Steps;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const TankTransferForm = (props) => {
  const { form, tankOption, sourceTankUsageOption, tankArray, setTankArray, currentStep, previous, next } = props;

  const onSourceTankSelected = (e) => {
    if (e.target.checked) {
      const value = e.target.value;
      const index = sourceTankUsageOption.map((item) => item.id).indexOf(value);

      if (index > -1) {
        form.setFieldsValue({
          batchNo: sourceTankUsageOption[index].batchNo,
          broodstockNo: sourceTankUsageOption[index].broodstockNo,
          hatchDate: sourceTankUsageOption[index].hatchDate ? moment(sourceTankUsageOption[index].hatchDate) : null,
        });
      }
    }
  };

  const onQuantityChanged = (value, item) => {
    const index = tankArray.map((item) => item.tankId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...tankArray];
      newArray[index] = {
        tankId: item.id,
        quantity: value,
      };
      setTankArray(newArray);
    } else {
      setTankArray([...tankArray, { tankId: item.id, quantity: value }]);
    }
  };

  return (
    <Form {...layout} form={form} name="tank-transfer-form">
      <Steps current={currentStep} size="small">
        <Step title="Sources" />
        <Step title="Transfer In" />
        <Step title="Information" />
      </Steps>

      <Divider />

      <div hidden={currentStep !== 0}>
        <Form.Item name="sourceTankUsageId" label="Transfer from (source)" rules={[{ required: true }]}>
          <Checkbox.Group>
            <Row>
              {sourceTankUsageOption.map((item) => (
                <Col span={24}>
                  <Checkbox value={item.id} style={{ lineHeight: "32px" }} onChange={onSourceTankSelected}>
                    {item.Tank.label} <Tag>{item.batchNo}</Tag>
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </div>

      <div hidden={currentStep !== 1}>
        <p>Transfer into</p>
        {tankOption.map((item) => (
          <Form.Item key={item.id} label={item.label}>
            <InputNumber min={0} allowClear onChange={(value) => onQuantityChanged(value, item)} />
          </Form.Item>
        ))}
      </div>

      <div hidden={currentStep !== 2}>
        <Form.Item name="batchNo" label="Batch No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="broodstockNo" label="Broodstock No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="hatchDate" label="Hatch Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockInTimestamp" label="Stock In Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        {/* <Form.Item name="stockInQuantity" label="Quantity" rules={[{ required: true }]}>
          <InputNumber min={0} allowClear />
        </Form.Item> */}

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </div>

      <Space>
        {currentStep > 0 && <Button onClick={previous}>Previous</Button>}

        {currentStep < 2 && (
          <Button
            type="primary"
            onClick={next}
            disabled={currentStep === 1 && tankArray.filter((item) => item.quantity).length === 0}
          >
            Next
          </Button>
        )}
      </Space>
    </Form>
  );
};

export default TankTransferForm;
