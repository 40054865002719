import React from "react";
import { Card, Descriptions } from "antd";
import moment from "moment";

const FarmReceiptCard = (props) => {
  const { title, data } = props;

  return (
    <Card title={title} size="small" {...props}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="Material Receipt ID">{data.id ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Date">{moment(data.arrivalTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Person">{data.personInCharge ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Vehicle">{data.vehicleNumber ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Remarks">{data.remarks ?? "-"}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default FarmReceiptCard;
