import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import EditForm from "../forms/EditForm";
import { useRawMaterial } from "../../../hooks/useRawMaterial";
import { useHatcheryReceipt } from "../../../hooks/useHatcheryReceipt";
import { useOption } from "../../../hooks/useOption";
import moment from "moment";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useRawMaterial();
  const { findAll: findOption } = useOption();
  const { update } = useHatcheryReceipt();

  const [submitting, setSubmitting] = useState(false);

  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [speciesData, setSpeciesData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll("Active").then((res) => {
        if (res.status === "success") {
          setRawMaterialData(res.data);
        }
      });
      findOption("Active").then((res) => {
        if (res.status === "success") {
          const species = res.data.filter((item) => item.type === "Broodstock Species");
          const source = res.data.filter((item) => item.type === "Broodstock Source");
          const location = res.data.filter((item) => item.type === "Broodstock Tank");
          setSpeciesData(species);
          setSourceData(source);
          setLocationData(location);
        }
      });
      form.setFieldsValue({
        label: editItem.label,
        rawMaterialId: editItem.rawMaterialId,
        species: editItem.species,
        weightInGram: editItem.weightInGram,
        source: editItem.source,
        stockDate: editItem.stockDate ? moment(editItem.stockDate) : null,
        arrivalTimestamp: moment(editItem.arrivalTimestamp),
        location: editItem.location,
        status: editItem.status,
        releaseDate: editItem.releaseDate ? moment(editItem.releaseDate) : null,
        remarks: editItem.remarks,
      });
    }
  }, [visible, editItem, findAll, findOption, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          label: values.label,
          rawMaterialId: values.rawMaterialId,
          species: values.species,
          stockDate: values.stockDate ? moment(values.stockDate).format() : null,
          weightInGram: values.weightInGram,
          source: values.source,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          location: values.location,
          status: values.status,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Hatchery Receipt"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <EditForm
        form={form}
        rawMaterialData={rawMaterialData}
        speciesOption={speciesData}
        sourceOption={sourceData}
        locationOption={locationData}
      />
    </Modal>
  );
};

export default EditModal;
