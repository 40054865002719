import React from "react";
import { Card, Descriptions, Tag, Typography } from "antd";

const { Link } = Typography;

const SscCard = (props) => {
  const { title, data, viewDetail, bordered } = props;

  return (
    <Card title={title} size="small" bordered={bordered}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="SSC ID">{data.id}</Descriptions.Item>
        <Descriptions.Item label="Batch No">
          <Tag>{data.batchNo}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Remarks">{data.remarks ? data.remarks : "-"}</Descriptions.Item>
        <Descriptions.Item label="Freezing Records">
          {data.FactoryFreezingRecords && data.FactoryFreezingRecords.length
            ? data.FactoryFreezingRecords.map((item) => (
                <Tag>
                  <Link onClick={() => viewDetail("SSC Freezing", item, item)}>#{item.id}</Link>
                </Tag>
              ))
            : "-"}
        </Descriptions.Item>

        {/* <Descriptions.Item label="Grade">{data.grade}</Descriptions.Item>
        <Descriptions.Item label="Weight (kg)">
          {data.weightInKg ? data.weightInKg : data.weightRangeInKg ? data.weightRangeInKg : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Freezer No">
          {data.FactoryFreezingRecord ? data.FactoryFreezingRecord.freezerNo : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Freezer Temperature">
          {data.FactoryFreezingRecord ? data.FactoryFreezingRecord.freezerTemperature : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Product Temperature">
          {data.FactoryFreezingRecord ? data.FactoryFreezingRecord.productTemperature : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Freezing Start">
          {data.FactoryFreezingRecord ? moment(data.FactoryFreezingRecord.startTimestamp).format("h:mm a") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Freezing End">
          {data.FactoryFreezingRecord ? moment(data.FactoryFreezingRecord.endTimestamp).format("h:mm a") : "-"}
        </Descriptions.Item> */}
        {/* <Descriptions.Item label="Pack/Unpack History">
          {data.SscActivities && data.SscActivities.length ? (
            <Link onClick={() => viewDetail("Soft Shell Crab Activity", data, data.SscActivities)}>
              {data.SscActivities.length} record(s)
            </Link>
          ) : (
            "-"
          )}
        </Descriptions.Item> */}
      </Descriptions>
    </Card>
  );
};

export default SscCard;
