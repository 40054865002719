import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import FarmStorageForm from "./FarmStorageForm";
import { useFarmStorageRecord } from "../../hooks/useFarmStorageRecord";

const EditFarmStorageModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFarmStorageRecord();

  const [submitting, setSubmitting] = useState(false);
  const [accumulatedSSC, setAccumulatedSSC] = useState({});

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        remarks: editItem.remarks,
      });
      setAccumulatedSSC(editItem.accumulatedStock);
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          remarks: values.remarks,
          accumulatedSSC: accumulatedSSC,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            setAccumulatedSSC(null);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onAccInputChange = (grade, value) => {
    let grades = { ...accumulatedSSC };

    grades = {
      ...grades,
      [grade]: {
        ...grades[grade],
        quantity: value,
      },
    };

    setAccumulatedSSC(grades);
  };

  const onAccWeightChange = (grade, value) => {
    let grades = { ...accumulatedSSC };

    grades = {
      ...grades,
      [grade]: {
        ...grades[grade],
        weightInKg: value,
      },
    };

    setAccumulatedSSC(grades);
  };

  return (
    <Modal
      title="Edit Farm Storage Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <FarmStorageForm
        form={form}
        accumulatedSSC={accumulatedSSC}
        onAccInputChange={onAccInputChange}
        onAccWeightChange={onAccWeightChange}
        isEdit
      />
    </Modal>
  );
};

export default EditFarmStorageModal;
