import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip, Tag, Typography } from "antd";
import { blue } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const { Link } = Typography;

const GrowOutTable = (props) => {
  const { dataSource, loading, error, editRow, viewRow, doExcelExport } = props;

  const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    // },
    {
      title: "Cycle No",
      dataIndex: "cycleNo",
      render: (text, row) => (text ? <Link onClick={() => viewRow(row)}>{text}</Link> : "-"),
    },
    {
      title: "Material",
      dataIndex: "material",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Stocking Density",
      dataIndex: "stockingDensity",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (text === "New" ? <Tag color="green">{text}</Tag> : <Tag>{text}</Tag>),
    },
    {
      title: "DOC",
      render: (text, row) =>
        row.endAt
          ? moment.duration(moment(row.endAt).startOf("day").diff(moment(row.startAt).startOf("day"))).asDays()
          : moment.duration(moment().startOf("day").diff(moment(row.startAt).startOf("day"))).asDays(),
    },
    {
      title: "Start",
      dataIndex: "startAt",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : " -"),
    },
    {
      title: "End",
      dataIndex: "endAt",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : " -"),
    },
    {
      title: "Pond Daily Record",
      render: (text, row) => <Link onClick={() => doExcelExport(row)}>Export</Link>,
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: true }}
    />
  );
};

export default GrowOutTable;
