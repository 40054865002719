import React from "react";
import { Modal } from "antd";

const ConfirmDeleteModal = (props) => {
  const { visible, onOk, onCancel } = props;

  return (
    <Modal visible={visible} onOk={onOk} onCancel={onCancel}>
      <p>Are you sure to delete this record?</p>
    </Modal>
  );
};

export default ConfirmDeleteModal;
