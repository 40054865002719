import React, { useState, useEffect, useContext } from "react";
import { Button, Drawer, Tabs } from "antd";
import { useSSCHarvest } from "../../hooks/useSSCHarvest";
import SSCHarvestInfo from "./SSCHarvestInfo";
import DeliveryOrderTable from "../deliveryOrder/tables/DeliveryOrderTable";
import FarmReceiptInfo from "../farmReceipt/components/FarmReceiptInfo";
import { ResponsiveContext } from "../../App";
import EditSSCHarvestRecordModal from "../sscHarvestRecord/modals/EditSSCHarvestRecordModal";

const { TabPane } = Tabs;

const SSCHarvestDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne: findSSCHarvest } = useSSCHarvest();

  const [refresh, setRefresh] = useState(false);
  const [header, setHeader] = useState({});
  const [editItem, setEditItem] = useState({});
  const [editSSCHarvestRecordModal, setEditSSCHarvestRecordModal] = useState(false);

  useEffect(() => {
    if ((visible && id) || refresh) {
      findSSCHarvest(id).then((res) => {
        if (res.status === "success") {
          setHeader(res.data);
        }
        setRefresh(false);
      });
    }
  }, [id, visible, findSSCHarvest, refresh]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditSSCHarvestRecordModal(true);
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "70%"}>
      <SSCHarvestInfo item={header} />

      <Tabs>
        <TabPane tab="Harvest / Grading" key="harvestRecord">
          <div>
            {header.SSCHarvestRecords &&
              header.SSCHarvestRecords.map((item) => (
                <div className="p-2 flex flex-row gap-4 md:gap-8 lg:gap-16 border-b">
                  <div className="flex-none">
                    <div className="text-xs text-gray-400">ID. {item.id}</div>
                  </div>
                  <div class="flex-grow">
                    <div className="flex flex-row justify-between mb-2">
                      <div className="text-xs md:text-md">{item.harvestShift}</div>
                      <div className="text-lg">
                        <span className="text-xs text-gray-400">Total:</span> {item.quantity}
                        <span className="text-xs text-gray-400"> pcs</span>
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-row justify-between">
                        {item.SSCHarvestRecordDetails.map((item) => (
                          <div key={item.id}>
                            <span className="text-xs text-gray-400">{item.grade}:</span> {item.quantity}
                            <span className="text-xs text-gray-400"> pcs</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mt-2 italic text-xs text-gray-500">{item.remarks}</div>
                  </div>
                  <div class="flex-none">
                    <Button type="link" onClick={() => editRow(item)} disabled={item.status !== "New"}>
                      Edit
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </TabPane>

        <TabPane tab="Delivery Order" key="deliveryOrder">
          <DeliveryOrderTable dataSource={header.DeliveryOrders} loading={false} excludeCols={["ID", "Action"]} />
        </TabPane>
        <TabPane tab="Raw Material Receipt" key="rawMaterial">
          <FarmReceiptInfo item={header.FarmReceipt} />
        </TabPane>
      </Tabs>

      <EditSSCHarvestRecordModal
        editItem={editItem}
        visible={editSSCHarvestRecordModal}
        setVisible={setEditSSCHarvestRecordModal}
        setRefresh={setRefresh}
      />
    </Drawer>
  );
};

export default SSCHarvestDrawer;
