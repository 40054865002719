import React, { useEffect, useState } from "react";
import { Modal, Form, Alert } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useGrowOut } from "../../hooks/useGrowOut";
import moment from "moment";
import PondCycleForm from "./PondCycleForm";

const NewPondCycleModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useGrowOut();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        startAt: moment(),
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          material: values.material,
          cycleNo: values.cycleNo,
          startAt: moment(values.startAt).format(),
          pondId: values.pondId,
          stockingDensity: values.stockingDensity,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Cycle"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondCycleForm form={form} />

      <Alert
        showIcon
        icon={<ExclamationCircleOutlined />}
        type="warning"
        message="Create a new cycle will automatically end the other cycles."
      />
    </Modal>
  );
};

export default NewPondCycleModal;
