import React, { useState, useEffect } from "react";
import { Modal, Form, Divider, Descriptions, InputNumber, Space } from "antd";
import moment from "moment";
import { useProcessing } from "../../hooks/useProcessing";
import { useFarm } from "../../hooks/useFarm";
import { sscGrade, sscSize } from "../../utils/Options";
import DeliveryOrderForm from "../deliveryOrder/forms/DeliveryOrderForm";
import { useDeliveryOrder } from "../../hooks/useDeliveryOrder";

const FarmStorageStockOutModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findFarm } = useFarm();
  const { findAll: findProcessing } = useProcessing();

  const { create } = useDeliveryOrder();

  const [submitting, setSubmitting] = useState(false);

  const [farmOption, setFarmOption] = useState([]);
  const [processingOption, setProcessingOption] = useState([]);
  const [softShellGrade, setSoftShellGrade] = useState();

  useEffect(() => {
    if (visible) {
      findFarm().then((res) => {
        if (res.status === "success") {
          setFarmOption(res.data);
        }
      });

      findProcessing({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setProcessingOption(res.data);
        }
      });

      form.setFieldsValue({
        departTimestamp: moment(),
      });
    }
  }, [visible, findFarm, findProcessing, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (Object.keys(softShellGrade).length === 0) {
        return;
      }
      if (!err) {
        setSubmitting(true);
        const params = {
          stockMovement: "OUT",
          orderNo: values.orderNo,
          product: "Soft Shell Crab",
          departTimestamp: moment(values.departTimestamp).format(),
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          farmId: values.farmId,
          processingId: values.processingId,
          remarks: values.remarks,
          grades: softShellGrade,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setSoftShellGrade();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onInputChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        quantity: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  const onWeightChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        weightInKg: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  return (
    <Modal
      title="New Delivery Order"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <DeliveryOrderForm form={form} farmOption={farmOption} processingOption={processingOption} />

      <Divider orientation="left">Order Items</Divider>

      {sscGrade.map((grade) => (
        <Descriptions title={"Grade " + grade} size="small" key={grade} column={1}>
          {sscSize.map((item) => (
            <Descriptions.Item
              label={item}
              key={grade + "_" + item}
              contentStyle={{ justifyContent: "flex-end" }}
              style={{ paddingLeft: 16 }}
            >
              <Space>
                <InputNumber
                  placeholder="pcs"
                  inputMode="numeric"
                  allowClear
                  onChange={(value) => onInputChange(grade, item, value)}
                />
                <InputNumber
                  placeholder="kg"
                  inputMode="numeric"
                  allowClear
                  onChange={(value) => onWeightChange(grade, item, value)}
                />
              </Space>
            </Descriptions.Item>
          ))}
        </Descriptions>
      ))}
    </Modal>
  );
};

export default FarmStorageStockOutModal;
