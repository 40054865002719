import React from "react";
import { DatePicker, Form, Select, InputNumber, Input } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddNewForm = (props) => {
  const { form, incubationOption } = props;

  const onChange = (value) => {
    const index = incubationOption.map((item) => item.id).indexOf(value);
    if (index > -1) {
      form.setFieldsValue({
        batchNo: incubationOption[index].batchNo,
        broodstockNo: incubationOption[index].broodstockNo,
        broodstockSpecies: incubationOption[index].HatcheryReceipt?.species,
      });
    }
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      {/* <Form.Item name="rawMaterialId" label="Raw Material" rules={[{ required: true }]}>
        <Select allowClear>
          {rawMaterialData.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item> */}

      <Form.Item name="productionTimestamp" label="Harvest Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="tankUsageId" label="Larval Tank">
        <Select allowClear onChange={onChange}>
          {incubationOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.Tank.label + " - " + item.batchNo}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="batchNo" label="Batch No.">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="broodstockNo" label="Broodstock No.">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="broodstockSpecies" label="Broodstock Species.">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="avgWeightInGram" label="Avg Weight (g)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
