import React, { useState } from "react";
import { Modal, Form } from "antd";
import TankForm from "./forms/TankForm";
import { useTank } from "../../hooks/useTank";

const AddTankModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useTank();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          label: values.label,
          shed: values.shed,
          sizeInLitre: values.sizeInLitre,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Tank"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <TankForm form={form} />
    </Modal>
  );
};

export default AddTankModal;
