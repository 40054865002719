import React from "react";
import { Button, Modal, Typography, Row, Col } from "antd";

const QrCodeModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;
  let qrRecords = editItem.FactoryProductionQrRecords
    ? editItem.FactoryProductionQrRecords
    : [];

  let packingBatchDetail = editItem.FactoryProductionRecords
    ? editItem.FactoryProductionRecords
    : [];
  console.log(editItem);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Generated QR Code"
      destroyOnClose
      visible={visible}
      onOk={handleCancel}
      onCancel={handleCancel}
      width={800}
      footer={[
        <Button key="submit" type="primary" onClick={handleCancel}>
          Ok
        </Button>,
      ]}
    >
    <div style={{ width:"100%", display: "table" }}>
      <div style={{ border: "1px solid rgba(0, 0, 0, 0.05)", width: "100%" }}>
        <div style={{ display: "inline" }}>
          <div style={{ border: "1px solid rgba(0, 0, 0, 0.05)" }}>
            <Row>
              <Col style={{ paddingLeft: "10px", paddingRight: "10px"}} span={8}>Packing No</Col>
              <Col style={{ paddingLeft: "10px", paddingRight: "10px"}} span={8}>Batch No.</Col>
              <Col style={{ paddingLeft: "10px", paddingRight: "10px"}} span={8}>Trace Code</Col>
            </Row>
          </div>
          <div style={{ border: "1px solid rgba(0, 0, 0, 0.05)" }}>
            <Row>
              <Col style={{ paddingLeft: "10px", paddingRight: "10px"}} span={8}>
                {
                  editItem.packingBatchNo
                }
                {/* {packingBatchDetail.map((item) =>
                  // console.log(item["PackingBatchDetail"])
                  item["PackingBatchDetail"].packingBatchNo
                    ? <p>{item["PackingBatchDetail"].packingBatchNo}</p>
                    : {}
                )} */}
              </Col>
              <Col style={{ paddingLeft: "10px", paddingRight: "10px"}} span={8}>
                {packingBatchDetail.map((item) => (
                  
                    item.batchNo ? <p>{item.batchNo}</p> : "-"
                 
                ))}
              </Col>
              <Col style={{ paddingLeft: "10px", paddingRight: "10px"}} span={8}>
                {qrRecords.map((item) => (
                  <Typography.Link onClick={() => openInNewTab(item.qrUrl)}>
                    {item.qrUrl ? item.qrUrl : "-"}{" "}
                  </Typography.Link>
                ))}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
    </Modal>
  );
};

export default QrCodeModal;
