import React, { useState } from "react";
import { Table, InputNumber, Input, Select, Popconfirm, Typography, Space, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { blue, red } from "@ant-design/colors";
import { sscGrade, sscSize } from "../../utils/Options";
import { useProcessingOrderDetail } from "../../hooks/useProcessingOrderDetail";

const { Option } = Select;

const ProcessingOrderDetailTable = (props) => {
  const { dataSource, setRefresh } = props;

  const [editItem, setEditItem] = useState({});

  const { update, destroy } = useProcessingOrderDetail();

  const columns = [
    {
      title: "Grade",
      dataIndex: "grade",
      render: (text, row) =>
        isEditing(row) ? (
          <Select defaultValue={text} style={{ width: 80 }} onChange={(value) => onGradeChange(value, row)}>
            {sscGrade.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ) : (
          text
        ),
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (text, row) =>
        isEditing(row) ? (
          <Select defaultValue={text} style={{ width: 120 }} onChange={(value) => onSizeChange(value, row)}>
            {sscSize.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ) : (
          text
        ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (text, row) =>
        isEditing(row) ? (
          <InputNumber defaultValue={text} allowClear onChange={(value) => onQuantityChange(value, row)} />
        ) : text ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: "Weight (KG)",
      dataIndex: "weightInKg",
      render: (text, row) =>
        isEditing(row) ? (
          <InputNumber defaultValue={text} allowClear onChange={(value) => onWeightChange(value, row)} />
        ) : text ? (
          Number(text).toFixed(2)
        ) : (
          "-"
        ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text, row) =>
        isEditing(row) ? (
          <Input defaultValue={text} allowClear onChange={(e) => onRemarkChange(e.target.value, row)} />
        ) : text ? (
          text
        ) : (
          "-"
        ),
    },
    {
      title: "Action",
      render: (text, row) => {
        const editable = row.id === editItem.id;

        return editable ? (
          <span>
            <Typography.Link onClick={save} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={() => setEditItem({})}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined style={{ color: blue.primary }} />}
              onClick={() => setEditItem(row)}
            />

            <Popconfirm title="Sure to remove item?" onConfirm={() => deleteItem(row)}>
              <Button type="link" icon={<DeleteOutlined style={{ color: red.primary }} />} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const isEditing = (row) => row.id === editItem.id;

  const save = () => {
    update(editItem.id, {
      grade: editItem.grade,
      size: editItem.size,
      weightInKg: editItem.weightInKg,
      quantity: editItem.quantity,
      remarks: editItem.remarks,
    }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setEditItem({});
      }
    });
  };

  const deleteItem = (item) => {
    destroy(item.id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  const onGradeChange = (value, row) => {
    setEditItem({
      ...editItem,
      grade: value,
    });
  };

  const onSizeChange = (value, row) => {
    setEditItem({
      ...editItem,
      size: value,
    });
  };

  const onQuantityChange = (value, row) => {
    setEditItem({
      ...editItem,
      quantity: value,
    });
  };

  const onWeightChange = (value, row) => {
    setEditItem({
      ...editItem,
      weightInKg: value,
    });
  };

  const onRemarkChange = (value, row) => {
    setEditItem({
      ...editItem,
      remarks: value,
    });
  };

  return (
    <Table
      rowKey="id"
      // size="small"
      scroll={{ x: "max-content" }}
      // bordered
      columns={columns}
      dataSource={dataSource}
      style={{ marginTop: 24 }}
    />
  );
};

export default ProcessingOrderDetailTable;
