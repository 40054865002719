import React from "react";
import { useTranslation } from "react-i18next";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { blue } from "@ant-design/colors";

const HatcheryReceiptTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, loading, error, editRow } = props;

  const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    // },

    // {
    //     title: 'Source Pond',
    //     dataIndex: 'SourcePond',
    //     render: (text, row) => row.SourcePond ? row.SourcePond.label : '-',
    // },
    // {
    //     title: 'Source HAPA',
    //     dataIndex: 'SourceHapa',
    //     render: (text, row) => row.SourceHapa ? row.SourceHapa.label : '-',
    // },
    {
      title: "Date",
      dataIndex: "stockInTimestamp",
      render: (text, row) =>
        row.stockInTimestamp
          ? moment(row.stockInTimestamp).format("YYYY/MM/DD")
          : row.stockOutTimestamp
          ? moment(row.stockOutTimestamp).format("YYYY/MM/DD")
          : "-",
    },
    {
      title: "Stock In Quantity",
      dataIndex: "stockInQuantity",
      render: (text, row) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Stock Out Quantity",
      dataIndex: "stockOutQuantity",
      render: (text, row) => (text ? Number(text).toLocaleString() : "-"),
    },
    // {
    //   title: "Source",
    //   render: (text, row) => {
    //     if (row.source) {
    //       return row.source;
    //     } else if (row.SourceGrowOut) {
    //       return row.SourceGrowOut.label;
    //     } else if (row.SourceHapa) {
    //       return row.SourceHapa.label;
    //     }
    //     return "-";
    //   },
    // },
    {
      title: "Dead (pcs)",
      dataIndex: "dead",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Broodstock (pcs)",
      dataIndex: "broodstock",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default HatcheryReceiptTable;
