import React from "react";
import { Form, Select, InputNumber } from "antd";
import { sscGrade, sscSize } from "../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SSCProductionItemForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="grade" label="Grade">
        <Select>
          {sscGrade.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="size" label="Size">
        <Select>
          {sscSize.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="balanceQuantity" label="Balance Qty" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="weightInKg" label="Weight (KG)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="balanceWeightInKg" label="Balance Weight (KG)" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>
    </Form>
  );
};

export default SSCProductionItemForm;
