import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { usePondFeed } from "../../hooks/usePondFeed";
import PondFeedForm from "./PondFeedForm";
import moment from "moment";
import { useFeedType } from "../../hooks/useFeedType";

const NewPondFeedModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = usePondFeed();
  const { findAll: findFeedType } = useFeedType();

  const [submitting, setSubmitting] = useState(false);

  const [feedTypeOption, setFeedTypeOption] = useState([]);
  const [feedTypeArray, setFeedTypeArray] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(),
      });

      findFeedType().then((res) => {
        if (res.status === "success") {
          setFeedTypeOption(res.data);
        }
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const feedTypeRecord = feedTypeArray.filter((item) => item.amount && item.amount > 0);

        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          feedActivity: values.feedActivity,
          pondId: Array.isArray(values.pondId) ? values.pondId : [values.pondId],
          feedTypeRecord: feedTypeRecord,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setFeedTypeArray([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"New Feeding"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondFeedForm
        form={form}
        feedTypeOption={feedTypeOption}
        feedTypeArray={feedTypeArray}
        setFeedTypeArray={setFeedTypeArray}
      />
    </Modal>
  );
};

export default NewPondFeedModal;
