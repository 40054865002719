import React from "react";
import TableLoader from "../../common/TableLoader";
import { Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

const OptionTable = (props) => {
  const { dataSource, loading, error, editRow } = props;

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default OptionTable;
