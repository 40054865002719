import React, { useState } from 'react';
import { Modal, Form, InputNumber } from 'antd';
import moment from 'moment';
import { usePondDODailyRecord } from '../../../hooks/usePondDODailyRecord';

const EditModal = (props) => {

    const { visible, item, setVisible, setRefresh } = props;

    const [form] = Form.useForm();

    const { update } = usePondDODailyRecord();

    const [submitting, setSubmitting] = useState(false);


    const handleOk = () => {
        form.validateFields().then((values, err) => {
            if (!err) {
                const params = {
                    temperature: values.temperature,
                    do: values.do
                }
                setSubmitting(true);
                update(item.id, params).then(res => {
                    if (res.status === 'success') {
                        setRefresh(true)
                        setVisible(false)
                        form.resetFields();
                    }
                    setSubmitting(false)
                })
            }
        })
    }


    const handleCancel = () => {
        setVisible(false);
    }

    return (
        <Modal
            title={moment(item?.recordTimestamp).format('YYYY/MM/DD') + ' - Edit'}
            destroyOnClose
            visible={visible}
            confirmLoading={submitting}
            onOk={handleOk}
            okText='Submit'
            onCancel={handleCancel}>

            <Form form={form} layout='vertical'>
                <Form.Item name="temperature" label="Temperature" rules={[{ required: true }]}>
                    <InputNumber defaultValue={item.temperature} allowClear />
                </Form.Item>
                <Form.Item name="do" label="DO" rules={[{ required: true }]}>
                    <InputNumber defaultValue={item.do} allowClear />
                </Form.Item>
            </Form>

        </Modal>
    );
}

export default EditModal