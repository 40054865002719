import React from "react";
import { Form, Input, InputNumber, Select } from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const TankForm = (props) => {
  const { form, mode } = props;

  return (
    <Form {...layout} form={form} name="tank-form">
      <Form.Item name="label" label="Tank" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>
      <Form.Item name="shed" label="Shed">
        <Select allowClear>
          <Option value="">None</Option>
          <Option value="1">1</Option>
          <Option value="2">2</Option>
          <Option value="3">3</Option>
        </Select>
      </Form.Item>
      <Form.Item name="sizeInLitre" label="Size (L)" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>
      {mode === "edit" ? (
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select allowClear>
            <Option value="New">New</Option>
            <Option value="Operating">Operating</Option>
          </Select>
        </Form.Item>
      ) : null}
    </Form>
  );
};

export default TankForm;
