import React from "react";
import { DatePicker, Form, Select, Input, InputNumber, Divider } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditForm = (props) => {
  const { editItem, form, pondOption } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="growOutId" label="Grow Out Pond" rules={[{ required: true }]}>
        <Select placeholder="Select a Pond" allowClear>
          {pondOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {editItem.stockInTimestamp && (
        <>
          <Form.Item name="stockInTimestamp" label="Date">
            <DatePicker allowClear />
          </Form.Item>

          <Form.Item name="stockInQuantity" label="Quantity">
            <InputNumber min={0} allowClear />
          </Form.Item>

          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea rows={5} allowClear />
          </Form.Item>

          <Divider orientation="left">Source</Divider>

          <Form.Item name="source" label="External">
            <Input allowClear />
          </Form.Item>

          <Form.Item name="sourceGrowOutLabel" label="Grow Out (Pond)">
            <Input disabled />
          </Form.Item>

          <Form.Item name="sourceHapaLabel" label="Nursery (HAPA)">
            <Input disabled />
          </Form.Item>
        </>
      )}

      {editItem.stockOutTimestamp && (
        <>
          <Form.Item name="stockOutTimestamp" label="Date">
            <DatePicker allowClear />
          </Form.Item>

          <Form.Item name="stockOutQuantity" label="Quantity">
            <InputNumber min={0} allowClear />
          </Form.Item>
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea rows={5} allowClear />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default EditForm;
