import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "./forms/AddNewForm";
import moment from "moment";
import { useLarvalData } from "../../hooks/useLarvalData";

const NewDailyLarvalModal = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findLarvalStage, create } = useLarvalData();

  const [submitting, setSubmitting] = useState(false);

  const [larvalStageData, setLarvalStageData] = useState([]);

  useEffect(() => {
    if (visible) {
      findLarvalStage().then((res) => {
        if (res.status === "success") {
          setLarvalStageData(res.data);
        }
      });
      form.setFieldsValue({
        recordTimestamp: moment(),
      });
    }
  }, [visible, findLarvalStage, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err && id) {
        // console.log(values);
        // return;
        const params = {
          tankUsageId: id,
          recordTimestamp: moment(values.recordTimestamp).format(),
          larvalStage: values.larvalStage,
          larvalDensity: values.larvalDensity,
          salinityInPpt: values.salinityInPpt,
          temperature1: values.temperature1,
          temperature2: values.temperature2,
          artemiaType: values.artemiaType,
          artemiaFedInGram1: values.artemiaFedInGram1,
          artemiaFedInGram2: values.artemiaFedInGram2,
          microInGram1: values.microInGram1,
          microInGram2: values.microInGram2,
          microInGram3: values.microInGram3,
          cifro: values.cifro,
          edta: values.edta,
          k5InGram: values.k5InGram,
          germFree: values.germFree,
          mplus: values.mplus,
          oreganoInMl: values.oreganoInMl,
          shrimpFavour: values.shrimpFavour,
          superms: values.superms,
          tystatin: values.tystatin,
          remarks: values.remarks,
        };
        setSubmitting(true);
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"New Daily Larval Record"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} larvalStageData={larvalStageData} />
    </Modal>
  );
};

export default NewDailyLarvalModal;
