import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useProcessingOrder = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl +
          "/wapi/processingOrders?status=" +
          query.status +
          "&startDate=" +
          query.startDate +
          "&endDate=" +
          query.endDate,
        config
      );
    },
    [authState.token, send]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/processingOrders/" + id, config);
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/processingOrders", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/processingOrders/" + id, config);
    },
    [authState.token, send]
  );

  // const addDetail = useCallback(
  //   async (id, params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //       body: JSON.stringify(params),
  //     };

  //     return await send(apiBaseUrl + "/wapi/processingOrders/" + id + "/addDetail", config);
  //   },
  //   [authState.token, send]
  // );

  // const deleteDetail = useCallback(
  //   async (id, params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //       body: JSON.stringify(params),
  //     };

  //     return await send(apiBaseUrl + "/wapi/processingOrders/" + id + "/deleteDetail", config);
  //   },
  //   [authState.token, send]
  // );

  const downloadDeliveryOrder = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await fetch(apiBaseUrl + "/wapi/processingOrders/haccp/deliveryOrder", config);
    },
    [authState.token]
  );

  return {
    findAll,
    findOne,
    create,
    update,
    // addDetail,
    // deleteDetail,
    downloadDeliveryOrder,
  };
};

export { useProcessingOrder };
