import React from "react";
import { Card, Descriptions } from "antd";
import moment from "moment";

const DeliveryOrderCard = (props) => {
  const { title, data } = props;

  return (
    <Card title={title} size="small" {...props}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="Order No">{data.orderNo ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Location">{data.SourceFarm ? data.SourceFarm.label : "-"}</Descriptions.Item>
        <Descriptions.Item label="Product">{data.product ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Person">{data.personInCharge ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Vehicle">{data.vehicleNumber ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Harvest Date">
          {" "}
          {data.SSCHarvestDaily ? moment(data.SSCHarvestDaily.harvestDate).format("YYYY-MM-DD") : "-"}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default DeliveryOrderCard;
