import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NewFeedTypeModal from "./components/NewFeedTypeModal";
import EditFeedTypeModal from "./components/EditFeedTypeModal";
import { useFeedType } from "../../hooks/useFeedType";
import FeedTypeTable from "./components/FeedTypeTable";

const { Title } = Typography;

const FeedType = () => {
  const { t } = useTranslation();

  const { findAll } = useFeedType();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [newFeedTypeModal, setNewFeedTypeModal] = useState(false);
  const [editFeedTypeModal, setEditFeedTypeModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditFeedTypeModal(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={3}>Feed Types</Title>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setNewFeedTypeModal(true)}>
            New
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </Col>
      </Row>

      <FeedTypeTable error={error} loading={loading} dataSource={data} editRow={editRow} />

      <NewFeedTypeModal visible={newFeedTypeModal} setVisible={setNewFeedTypeModal} setRefresh={setRefresh} />

      <EditFeedTypeModal
        editItem={editItem}
        visible={editFeedTypeModal}
        setVisible={setEditFeedTypeModal}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default FeedType;
