import React from "react";
import { List, Card, Button, Space, Row, Col, Divider, Typography, Tag } from "antd";
import moment from "moment";

const PondCycleList = (props) => {
  const { data, editRow } = props;
  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 5 }}
      dataSource={data}
      pagination={{
        onChange: (page) => {
          // console.log(page);
        },
        pageSize: 50,
      }}
      renderItem={(item) => (
        <List.Item>
          <Card
            title={item.Pond?.label}
            extra={<Tag color={item.status === "New" ? "success" : "default"}>{item.status}</Tag>}
          >
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Cycle No</Typography.Text>
              </Col>
              <Col>{item.cycleNo ? item.cycleNo : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Material</Typography.Text>
              </Col>
              <Col>{item.material ? item.material : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Start Date</Typography.Text>
              </Col>
              <Col>{item.startAt ? moment(item.startAt).format("YYYY-MM-DD") : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">End Date</Typography.Text>
              </Col>
              <Col>{item.endAt ? moment(item.endAt).format("YYYY-MM-DD") : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">DOC</Typography.Text>
              </Col>
              <Col>
                {item.endAt
                  ? moment
                      .duration(moment(item.endAt).startOf("day").diff(moment(item.startAt).startOf("day")))
                      .asDays()
                  : moment.duration(moment().startOf("day").diff(moment(item.startAt).startOf("day"))).asDays()}
              </Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Stock Density (pcs)</Typography.Text>
              </Col>
              <Col>
                {item.stockingDensity && !isNaN(item.stockingDensity)
                  ? Number(item.stockingDensity).toLocaleString()
                  : "-"}
              </Col>
            </Row>
            <Divider />
            <Row style={{ marginTop: 16 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button type="primary" ghost block onClick={() => editRow(item)}>
                  Edit
                </Button>
                {/* <Button block danger onClick={() => deleteRow(item)}>
                  Delete
                </Button> */}
              </Space>
            </Row>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default PondCycleList;
