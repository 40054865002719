import React, { useState, useEffect } from "react";
import { Modal, Form, Input, DatePicker, InputNumber, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRecipe } from "../../hooks/useRecipe";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const EditRecipeModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;
  console.log("edit item", editItem);
  const [form] = Form.useForm();

  const { update } = useRecipe();

  const [submitting, setSubmitting] = useState(false);

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        title: editItem.title,
        shortDescription: editItem.shortDescription,
        content: editItem.content,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recipeTitle: values.title,
          shortDescription: values.shortDescription,
          recipeContent: values.content,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Recipe"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width={1000}
    >
      <Form {...layout} form={form} name="edit-form">
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input placeholder="Fried Soft Shell Crab" />
        </Form.Item>

        {/* <Form.Item
          name="coverPhoto"
          label="Cover Photo"
          rules={[{ required: true }]}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item> */}

        <Form.Item
          name="shortDescription"
          label="Short Description"
          rules={[{ required: true }]}
        >
          <TextArea rows={4} placeholder="maxLength is 6" />
        </Form.Item>

        <Form.Item name="content" label="Recipe" rules={[{ required: true }]}>
          <ReactQuill
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image"],
                ["clean"],
              ],
            }}
            style={{ height: "300px" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRecipeModal;
