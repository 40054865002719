import React from "react";
import { DatePicker, Form, InputNumber, Input, Select } from "antd";
import { harvestShift } from "../../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditForm = (props) => {
  const { form, editItem } = props;

  const onChange = () => {
    const alive = editItem.alive;
    const dead = editItem.dead;
    const missing = editItem.missing;
    const harvest = editItem.harvest;

    const newDead = isNaN(form.getFieldValue("dead")) ? 0 : form.getFieldValue("dead");
    const newHarvest = isNaN(form.getFieldValue("harvest")) ? 0 : form.getFieldValue("harvest");
    const newMissing = isNaN(form.getFieldValue("missing")) ? 0 : form.getFieldValue("missing");

    form.setFieldsValue({
      alive: alive + dead + missing + harvest - newDead - newHarvest - newMissing,
    });
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item name="shift" label="Shift" rules={[{ required: true }]}>
        <Select allowClear>
          {harvestShift.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {editItem.alive >= 0 ? (
        <>
          <Form.Item name="alive" label="Alive">
            <InputNumber min={0} allowClear disabled />
          </Form.Item>
          <Form.Item name="harvest" label="SSC">
            <InputNumber allowClear onChange={onChange} />
          </Form.Item>
          <Form.Item name="dead" label="Dead">
            <InputNumber allowClear onChange={onChange} />
          </Form.Item>
          <Form.Item name="missing" label="Missing">
            <InputNumber allowClear onChange={onChange} />
          </Form.Item>
        </>
      ) : null}

      {(editItem.stockInQuantity || editItem.stockOutQuantity) && (
        <>
          <Form.Item name="stockInQuantity" label="Stock In Quantity">
            <InputNumber min={0} allowClear />
          </Form.Item>
          <Form.Item name="stockOutQuantity" label="Stock Out Quantity">
            <InputNumber min={0} allowClear />
          </Form.Item>
        </>
      )}

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default EditForm;
