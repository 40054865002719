import React, { useState, useEffect } from "react";
import { Modal, Form, Divider } from "antd";
import moment from "moment";
import { useFactoryProduction } from "../../../hooks/useFactoryProduction";
import AddNewPackingForm from "../forms/AddNewPackingForm";
import { usePackingBatch } from "../../../hooks/usePackingBatch";
import OpenTransferTable from "../tables/OpenTransferTable";

const AddNewPackingModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useFactoryProduction();
  const { create } = usePackingBatch();

  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const [productionData, setProductionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "Frozen" }).then((res) => {
        if (res.status === "success") {
          setProductionData(res.data);
        }
      });
      form.setFieldsValue({
        packTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          packTimestamp: moment(values.packTimestamp).format(),
          countQr: values.countQr,
          batchNo: values.productionBatchNo,
          remarks: values.remarks,
        };

        create(params).then(async (res) => {
          if (res.status === 200) {
            setRefresh(true)
            setVisible(false)
            
            form.resetFields()
          }

          // setSubmitting(false)
          
          // const reader = res.body.getReader();
          // const contentLength = +res.headers.get("Content-Length");

          // // Step 3: read the data
          // let receivedLength = 0; // received that many bytes at the moment
          // let chunks = []; // array of received binary chunks (comprises the body)
          // while (true) {
          //   const { done, value } = await reader.read();

          //   if (done) {
          //     break;
          //   }

          //   chunks.push(value);
          //   receivedLength += value.length;

          //   setProgress(
          //     Number((receivedLength / contentLength) * 100).toFixed(0)
          //   );
          // }

          // const file = new Blob(chunks, {
          //   type: "application/pdf",
          // });
          // const fileURL = URL.createObjectURL(file);
          // setSubmitting(false);
          // setVisible(false);
          // setRefresh(true)
          // form.resetFields();
          // // Open the PDF in a new window
          // return window.open(fileURL);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Box Packing"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewPackingForm form={form} />
    </Modal>
  );
};

export default AddNewPackingModal;
