import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { TextArea } = Input;

const AddNewRecipeForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item
        name="recipeTitle"
        label="Title"
        rules={[{ required: true }]}
      >
        <Input placeholder="Fried Soft Shell Crab" />
      </Form.Item>

      <Form.Item
        name="shortDescription"
        label="Short Description"
        rules={[{ required: true }]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        name="recipeContent"
        label="Recipe"
        rules={[{ required: true }]}
      >
        <ReactQuill
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              style={{ height: "300px" }}
            />
      </Form.Item>
    </Form>
  );
};

export default AddNewRecipeForm;
