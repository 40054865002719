import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import StorageItemForm from "./StorageItemForm";
import { useFarmStorageRecord } from "../../hooks/useFarmStorageRecord";

const EditStorageItemModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { updateItem } = useFarmStorageRecord();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        grade: editItem.grade,
        size: editItem.size,
        quantity: editItem.quantity,
        weightInKg: editItem.weightInKg,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          grade: values.grade,
          size: values.size,
          quantity: values.quantity,
          weightInKg: values.weightInKg,
          remarks: values.remarks,
        };

        updateItem(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Storage Item"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <StorageItemForm form={form} />
    </Modal>
  );
};

export default EditStorageItemModal;
