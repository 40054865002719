import React from "react";
import { Form, Select, Input, InputNumber } from "antd";
import { sscSource } from "../../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const PontoonForm = (props) => {
  const { form, pondOption } = props;

  return (
    <Form {...layout} form={form} name="pontoon-form">
      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="pondId" label="Pond" rules={[{ required: true }]}>
        <Select placeholder="Select a Pond" allowClear>
          {pondOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="sscSource"
        label="SSC Source"
        rules={[{ required: true }]}
        extra='Select "External" if this pontoon is storing SSC from external party'
      >
        <Select placeholder="Select a source" allowClear>
          {sscSource.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="sscCount" label="SSC Count">
        <InputNumber min={0} allowClear />
      </Form.Item>
      <Form.Item name="status" label="Status" rules={[{ required: true }]}>
        <Select placeholder="Select a status" allowClear>
          <Option value="New">New</Option>
          <Option value="Inactive">Inactive</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default PontoonForm;
