import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Card, Empty } from "antd";
import { useLarvalDataReport } from "../../hooks/useLarvalDataReport";
import DetailDrawer from "./drawers/DetailDrawer";
import Filter from "./components/Filter";
import moment from "moment";

const { Title, Link } = Typography;

const LarvalData = () => {
  const { findBatchNo } = useLarvalDataReport();

  const [refresh, setRefresh] = useState(true);

  const [filterYear, setFilterYear] = useState(moment().format("YYYY"));
  const [data, setData] = useState([]);

  const [editItem, setEditItem] = useState({});

  const [detailDrawer, setDetailDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      findBatchNo({ year: filterYear }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [refresh, filterYear, findBatchNo]);

  const viewDetail = (item) => {
    setEditItem(item);
    setDetailDrawer(true);
  };

  return (
    <>
      <Filter filterYear={filterYear} setFilterYear={setFilterYear} setRefresh={setRefresh} />
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Larval Data</Title>

        {data.length > 0 ? (
          <Row gutter={[16, 16]}>
            {data.map((item) => (
              <Col xs={12} md={3} key={item.batchNo}>
                <Card size="small">
                  <Row justify="center">
                    <Col>
                      <Link onClick={() => viewDetail(item)}>{item.batchNo}</Link>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty />
        )}

        <DetailDrawer item={editItem} visible={detailDrawer} setVisible={setDetailDrawer} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default LarvalData;
