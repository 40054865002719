import React, { useState, useContext } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";

import "./BaseLayout.css";
import SideMenu from "./components/SideMenu";
import AppHeader from "./components/AppHeader";
import HatcheryDashboard from "../dashboard/HatcheryDashboard";

import TankTransferList from "../tankTransfer/TankTransferList";

// import HatcheryReceiptList from "../hatcheryReceipt/HatcheryReceiptList";
// import FarmReceiptList from "../farmReceipt/FarmReceiptList";
import FactoryReceiptList from "../factoryReceipts/FactoryReceiptList";
import HatcheryTransferList from "../hatcheryTransfer/HatcheryTransferList";
import DeliveryOrderList from "../deliveryOrder/DeliveryOrderList";
import IncubationRecordList from "../incubationRecord/IncubationRecordList";
// import LarvalDailyRecord from "../larvalDailyRecord/LarvalDailyRecord";
import DailyLarvalRecord from "../larvalDailyRecord/DailyLarvalRecord";
import LarvalHistoryRecord from "../larvalHistoryRecord/LarvalHistoryRecord";
import HatcheryProductionList from "../hatcheryProduction/HatcheryProductionList";
// import GrowOutRecordList from "../growOutRecord/GrowOutList";
import GrowOutRecord from "../growOutRecord/GrowOutRecord";

// import PondDailyRecord from "../pondDailyRecord/PondDailyRecord";
import PondDODailyRecord from "../pondDODailyRecord/PondDODailyRecord";
import SSCDailyMonitor from "../sscDailyMonitor/SSCDailyMonitor";
import FactoryProductionList from "../factoryProduction/FactoryProductionList";
import FactoryFreezingList from "../factoryFreezing/FactoryFreezingList";
import FarmList from "../farm/FarmList";
// import TankList from "../tank/TankList";
// import PondList from "../pond/PondList";
// import PontoonList from "../pontoon/PontoonList";
// import GrowOutList from "../growOut/GrowOutList";
import OptionList from "../option/OptionList";
// import QRCode from "../qrCode/QRCode";

import Account from "../account/Account";
import Notification from "../account/Notification";
import BabyPackList from "../babyPack/BabyPackList";
// import MasterPackList from "../masterPack/MasterPackList";
// import FactoryStorageList from "../factoryStorage/FactoryStorageList";

import RawMaterialList from "../rawMaterial/RawMaterialList";
import ProcessingList from "../processing/ProcessingList";
import ProcessingDashboard from "../dashboard/ProcessingDashboard";
import { AuthContext } from "../../App";
import LarvalData from "../report/LarvalData";
import Recall from "../recall/Recall";
import Trace from "../recall/Trace";

// Hatchery
import HatcheryReceiptList from "../../pages/hatcheryReceipt/HatcheryReceiptList";
import LarvalTank from "../../pages/larvalTank/LarvalTank";

// Farm
import FarmDashboard from "../../pages/farmDashboard/FarmDashboard";
import Pond from "../../pages/pond/Pond";
import PondCycle from "../../pages/pondCycle/PondCycle";
import PondPreparation from "../../pages/pondPreparation/PondPreparation";
import PondFeeding from "../../pages/pondFeeding/PondFeeding";
import PondTreatment from "../../pages/pondTreatment/PondTreatment";
import PondParameter from "../../pages/pondParameter/PondParameter";
import PondSampling from "../../pages/pondSampling/PondSampling";
import PondHarvest from "../../pages/pondHarvest/PondHarvest";
import HatcheryToFarm from "../../pages/farmReceipt/HatcheryToFarm";
import ExternalToFarm from "../../pages/farmReceipt/ExternalToFarm";
import NurseryRecord from "../../pages/farmNursery/NurseryRecord";
import NurseryHapa from "../../pages/farmNursery/NurseryHapa";
import NurseryHapaFeed from "../../pages/farmNursery/NurseryHapaFeed";

import FarmProduction from "../../pages/farmProduction/FarmProduction";
import SSCFeeding from "../../pages/sscFeeding/sscFeeding";
import SSCHarvest from "../../pages/sscHarvest/sscHarvest";
import FarmStorage from "../../pages/farmStorage/FarmStorage";
import SSCRawMatData from "../../pages/farmReport/SSCRawMatData";
import SSCHarvestData from "../../pages/farmReport/SSCHarvestData";

// Processing / Factory
import ProcessingOrder from "../../pages/processingOrder/ProcessingOrder";

// Settings
import FeedType from "../../pages/feedType/FeedType";
import TreatmentType from "../../pages/treatmentType/TreatmentType";
import ParameterType from "../../pages/parameterType/ParameterType";
import FarmRawMaterial from "../../pages/farmRawMaterial/FarmRawMaterial";
import CrabletProductionReport from "../../pages/hatcheryReport/CrabletProductionReport";
import HatcheryProductionReport from "../../pages/hatcheryReport/HatcheryProductionReport";

import RecipesList from "../recipes/RecipesList";

// Reports
// import PrawnProductionData from "../../pages/costingReport/PrawnProductionData";
// import NurseryCrabletData from "../../pages/costingReport/NurseryCrabletData";
// import CrabletsTransferData from "../../pages/costingReport/CrabletsTransferData";

const { Content, Footer } = Layout;

const PrivateRoute = ({ component: Component, accessRoute, ...rest }) => {
  const { can } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => (can(accessRoute) === true ? <Component {...props} /> : <Redirect to="/not-found" />)}
    />
  );
};

const BaseLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const { can } = useContext(AuthContext);

  return (
    <Router>
      <Content>
        <Switch>
          <Route exact path="/trace" component={Trace} />
          <Layout style={{ minHeight: "100vh" }}>
            <SideMenu collapsed={collapsed} setCollapsed={setCollapsed} />

            <Layout className="site-layout">
              <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} />

              <div style={{ margin: "0 16px" }}>
                <Route exact path="/">
                  {can("HatcheryDashboard") ? (
                    <Redirect to="/hatcheryDashboard" />
                  ) : can("FarmDashboard") ? (
                    <Redirect to="/farmReceipts" />
                  ) : (
                    <Redirect to="/processingDashboard" />
                  )}
                </Route>

                <Route exact path="/account/settings" component={Account} />
                <Route exact path="/account/notifications" component={Notification} />

                <PrivateRoute accessRoute="Hatchery" exact path="/hatcheryDashboard" component={HatcheryDashboard} />
                <PrivateRoute accessRoute="Hatchery" exact path="/hatcheryReceipts" component={HatcheryReceiptList} />
                <PrivateRoute accessRoute="Hatchery" exact path="/incubationRecords" component={IncubationRecordList} />
                <PrivateRoute accessRoute="Hatchery" exact path="/larvalTanks" component={LarvalTank} />
                <PrivateRoute accessRoute="Hatchery" exact path="/dailyLarvalRecords" component={DailyLarvalRecord} />
                <PrivateRoute
                  accessRoute="Hatchery"
                  exact
                  path="/larvalHistoryRecords"
                  component={LarvalHistoryRecord}
                />
                <PrivateRoute accessRoute="Hatchery" exact path="/tankUsages" component={TankTransferList} />
                <PrivateRoute
                  accessRoute="Hatchery"
                  exact
                  path="/hatcheryProductions"
                  component={HatcheryProductionList}
                />
                <PrivateRoute accessRoute="Hatchery" exact path="/hatcheryTransfers" component={HatcheryTransferList} />
                <PrivateRoute accessRoute="Hatchery" exact path="/larvalDataRpt" component={LarvalData} />
                <PrivateRoute
                  accessRoute="Hatchery"
                  exact
                  path="/crabletProductionReport"
                  component={CrabletProductionReport}
                />
                <PrivateRoute
                  accessRoute="Hatchery"
                  exact
                  path="/hatcheryProductionReport"
                  component={HatcheryProductionReport}
                />

                <PrivateRoute accessRoute="Farm" exact path="/farmDashboard" component={FarmDashboard} />
                <PrivateRoute accessRoute="Farm" exact path="/pond" component={Pond} />
                <PrivateRoute accessRoute="Farm" exact path="/pondCycles" component={PondCycle} />
                <PrivateRoute accessRoute="Farm" exact path="/pondPreparations" component={PondPreparation} />
                <PrivateRoute accessRoute="Farm" exact path="/pondFeeds" component={PondFeeding} />
                <PrivateRoute accessRoute="Farm" exact path="/pondTreatments" component={PondTreatment} />
                <PrivateRoute accessRoute="Farm" exact path="/pondParameters" component={PondParameter} />
                <PrivateRoute accessRoute="Farm" exact path="/pondSamplings" component={PondSampling} />
                <PrivateRoute accessRoute="Farm" exact path="/pondHarvests" component={PondHarvest} />
                <PrivateRoute accessRoute="Farm" exact path="/hatcheryToFarm" component={HatcheryToFarm} />
                <PrivateRoute accessRoute="Farm" exact path="/externalToFarm" component={ExternalToFarm} />
                <PrivateRoute accessRoute="Farm" exact path="/nurseryRecords" component={NurseryRecord} />
                <PrivateRoute accessRoute="Farm" exact path="/nurseryHapa" component={NurseryHapa} />
                <PrivateRoute accessRoute="Farm" exact path="/nurseryHapaFeeds" component={NurseryHapaFeed} />
                <PrivateRoute accessRoute="Farm" exact path="/growOutRecord" component={GrowOutRecord} />
                <PrivateRoute accessRoute="Farm" exact path="/pondDODailyRecords" component={PondDODailyRecord} />
                <PrivateRoute accessRoute="Farm" exact path="/sscDailyMonitor" component={SSCDailyMonitor} />
                <PrivateRoute accessRoute="Farm" exact path="/farmProductions" component={FarmProduction} />
                <PrivateRoute accessRoute="Farm" exact path="/sscFeeding" component={SSCFeeding} />
                <PrivateRoute accessRoute="Farm" exact path="/sscHarvest" component={SSCHarvest} />
                <PrivateRoute accessRoute="Farm" exact path="/farmStorage" component={FarmStorage} />
                <PrivateRoute accessRoute="Farm" exact path="/deliveryOrders" component={DeliveryOrderList} />
                <PrivateRoute accessRoute="Farm" exact path="/farmReports/sscRawMatData" component={SSCRawMatData} />
                <PrivateRoute accessRoute="Farm" exact path="/farmReports/sscHarvestData" component={SSCHarvestData} />

                <PrivateRoute accessRoute="HatcherySettings" exact path="/rawMaterials" component={RawMaterialList} />
                <PrivateRoute accessRoute="HatcherySettings" exact path="/options" component={OptionList} />
                {/* <PrivateRoute accessRoute="HatcherySettings" exact path="/tanks" component={TankList} /> */}
                <PrivateRoute accessRoute="HatcherySettings" exact path="/farms" component={FarmList} />

                <PrivateRoute accessRoute="FarmSettings" exact path="/farmRawMaterials" component={FarmRawMaterial} />
                <PrivateRoute accessRoute="FarmSettings" exact path="/feedTypes" component={FeedType} />
                <PrivateRoute accessRoute="FarmSettings" exact path="/treatmentTypes" component={TreatmentType} />
                <PrivateRoute accessRoute="FarmSettings" exact path="/parameterTypes" component={ParameterType} />
                <PrivateRoute accessRoute="FarmSettings" exact path="/processings" component={ProcessingList} />

                <PrivateRoute accessRoute="RecipeSettings" exact path="/recipesList" component={RecipesList} />

                <PrivateRoute
                  accessRoute="Processing"
                  exact
                  path="/processingDashboard"
                  component={ProcessingDashboard}
                />
                <PrivateRoute accessRoute="Processing" exact path="/factoryReceipts" component={FactoryReceiptList} />

                <PrivateRoute
                  accessRoute="Processing"
                  exact
                  path="/factoryProductions"
                  component={FactoryProductionList}
                />
                <PrivateRoute accessRoute="Processing" exact path="/factoryFreezings" component={FactoryFreezingList} />
                <PrivateRoute accessRoute="Processing" exact path="/babyBoxes" component={BabyPackList} />
                {/* <PrivateRoute accessRoute="Processing" exact path="/masterBoxes" component={MasterPackList} /> */}
                <PrivateRoute accessRoute="Processing" exact path="/processingOrder" component={ProcessingOrder} />
                <PrivateRoute accessRoute="Processing" exact path="/recall" component={Recall} />

                {/* <PrivateRoute accessRoute="Report" exact path="/prawnProductionData" component={PrawnProductionData} />
                <PrivateRoute accessRoute="Report" exact path="/nurseryCrabletData" component={NurseryCrabletData} />
                <PrivateRoute accessRoute="Report" exact path="/crabletsTransferData" component={CrabletsTransferData} /> */}

                {/* <PrivateRoute accessRoute="ProcessingSettings" exact path="/qrCodes" component={QRCode} /> */}

                {/* <Route exact path="/configuration" component={Configuration} /> */}

                <Footer style={{ textAlign: "right", fontSize: 10, padding: 8 }}>v3.14.220819</Footer>
              </div>
            </Layout>
          </Layout>
        </Switch>
      </Content>
    </Router>
  );
};

export default BaseLayout;
