import React from 'react';
import { DatePicker, Form, InputNumber } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const AddNewForm = (props) => {

    const { form } = props;

    return (
        <Form {...layout} form={form} name="add-new-form">
            <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
                <DatePicker showTime allowClear />
            </Form.Item>
            <Form.Item name="temperature" label="Temperature" rules={[{ required: true }]}>
                <InputNumber allowClear />
            </Form.Item>
            <Form.Item name="do" label="DO" rules={[{ required: true }]}>
                <InputNumber allowClear />
            </Form.Item>
        </Form>

    );
}

export default AddNewForm;