import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, List, Card } from "antd";
import { FormOutlined } from "@ant-design/icons";
// import { useTranslation } from "react-i18next";
import { useTankTransfer } from "../../hooks/useTankTransfer";
import DailyRecordDrawer from "./drawers/DailyRecordDrawer";
import Filter from "./components/Filter";
import moment from "moment";

const { Title } = Typography;

const LarvalDailyRecord = () => {
  // const { t } = useTranslation();

  const { findAll } = useTankTransfer();

  const [refresh, setRefresh] = useState(true);

  const [filterTank, setFilterTank] = useState("");
  const [data, setData] = useState([]);

  const [editItem, setEditItem] = useState();

  const [dailyRecordDrawer, setDailyRecordDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll("New").then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
      });
    }
  }, [refresh, findAll]);

  const viewDetail = (item) => {
    setEditItem(item);
    setDailyRecordDrawer(true);
  };

  return (
    <>
      <Filter setFilterTank={setFilterTank} />
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Daily Larval Record</Title>

        <List
          size="small"
          grid={{ gutter: [8, 16], column: 4 }}
          dataSource={data.filter((item) => {
            if (filterTank === "") {
              return true;
            }
            return item.Tank.label.toLowerCase().indexOf(filterTank) > -1;
          })}
          renderItem={(item) => (
            <List.Item>
              <Card
                size="small"
                title={item.Tank.label}
                extra={<Button onClick={() => viewDetail(item)} type="link" size="small" icon={<FormOutlined />} />}
              >
                <Row justify="space-between" style={{ fontSize: 10, color: "grey" }}>
                  <Col>DOC</Col>
                  <Col>Size: {item.Tank.sizeInLitre ? Number(item.Tank.sizeInLitre).toLocaleString() + " L" : "-"}</Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: 24, color: "green", textAlign: "center" }} span={24}>
                    {moment().startOf("day").diff(moment(item.hatchDate).startOf("day"), "days", false)}
                  </Col>
                </Row>
                <Row justify="space-between" style={{ fontSize: 10, color: "grey" }}>
                  <Col>Stock Date</Col>
                  <Col> {moment(item.stockInTimestamp).format("YYYY-MM-DD")}</Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />

        <DailyRecordDrawer
          item={editItem}
          visible={dailyRecordDrawer}
          setVisible={setDailyRecordDrawer}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default LarvalDailyRecord;
