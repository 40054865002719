import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Divider, Button, Row, Col, Typography } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import { useFactoryReceipt } from "../../../hooks/useFactoryReceipt";
import FactoryReceiptDetailTable from "../FactoryReceiptDetailTable";
import { ResponsiveContext } from "../../../App";

const { Text } = Typography;

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const [form] = Form.useForm();

  const { findAll } = useDeliveryOrder();
  const { create } = useFactoryReceipt();

  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [incomingTransfer, setIncomingTransfer] = useState([]);

  const [detailData, setDetailData] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New", targetProcessingId: 1 }).then((res) => {
        if (res.status === "success") {
          setIncomingTransfer(res.data);
        }
      });

      form.setFieldsValue({
        arrivalTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const detailRecord = detailData.filter((item) => item.processingQuantity > 0);

        if (detailRecord <= 0) {
          setError(true);
          return;
        }
        setError(false);

        setSubmitting(true);
        const params = {
          deliveryOrderId: values.deliveryOrderId,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          remarks: values.remarks,
          detailData: detailRecord,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setDetailData([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const addRow = () => {
    const item = {
      id: detailData.length,
      grade: null,
      size: null,
      farmQuantity: null,
      farmWeightInKg: null,
      processingQuantity: null,
      processingWeightInKg: null,
      remarks: null,
    };

    setDetailData([...detailData, item]);
  };

  return (
    <Modal
      title="New Material Receipt"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width={isMobile ? null : "75%"}
    >
      <AddNewForm form={form} incomingTransfer={incomingTransfer} setDetailData={setDetailData} />

      <Divider orientation="left">Details</Divider>

      <Row justify="end">
        <Col>
          <Button type="primary" onClick={addRow}>
            New Row
          </Button>
        </Col>
      </Row>

      {error && <Text type="danger">Please input at least one quantity </Text>}

      <FactoryReceiptDetailTable detailData={detailData} setDetailData={setDetailData} />
    </Modal>
  );
};

export default AddNewModal;
