import React from "react";
import { Dropdown, Menu, Typography, Button } from "antd";
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const { Link } = Typography;

const FactoryFreezingTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail, deleteRow } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{text}</Link>,
    },
    {
      title: "Date",
      dataIndex: "startTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Batch",
      render: (text, row) => (row.FactoryProductionRecord ? row.FactoryProductionRecord.batchNo : "-"),
    },
    {
      title: "Freezer",
      dataIndex: "freezerNo",
    },
    {
      title: "Start Time",
      dataIndex: "startTimestamp",
      render: (text) => moment(text).format("h:mm a"),
    },
    {
      title: "End Time",
      dataIndex: "endTimestamp",
      render: (text) => moment(text).format("h:mm a"),
    },
    {
      title: "Freezer Temp. (C)",
      dataIndex: "freezerTemperature",
    },
    {
      title: "Product Temp. (C)",
      dataIndex: "productTemperature",
    },
    // {
    //   title: "Details",
    //   // dataIndex: 'quantity',
    //   render: (text, row) =>
    //     row.FactoryProductionRecords && row.FactoryProductionRecords.length ? (
    //       <Space>
    //         <Tooltip title="View">
    //           <EyeOutlined style={{ color: blue.primary }} onClick={() => viewProduction(row)} />
    //         </Tooltip>
    //       </Space>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                Edit
              </Menu.Item>

              <Menu.Item key="delete" icon={<DeleteOutlined />} onClick={() => deleteRow(row)} danger>
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default FactoryFreezingTable;
