import React from "react";
import { Descriptions, Tag, Divider } from "antd";
import moment from "moment";

const LarvalInfo = (props) => {
  const { editItem } = props;
  return (
    <>
      <Descriptions title="Batch" style={{ marginTop: 24, marginBottom: 16 }}>
        <Descriptions.Item label="Batch No">{editItem.batchNo ? <Tag>{editItem.batchNo}</Tag> : "-"}</Descriptions.Item>
        <Descriptions.Item label="DOC">
          {editItem.stockInTimestamp
            ? moment().startOf("day").diff(moment(editItem.hatchDate).startOf("day"), "days", false)
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Stock Date">
          {editItem.stockInTimestamp ? moment(editItem.stockInTimestamp).format("YYYY-MM-DD") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Stock Quantity">
          {editItem.stockInQuantity ? Number(editItem.stockInQuantity).toLocaleString() : "-"}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions title="Broodstock" style={{ marginTop: 24, marginBottom: 16 }}>
        <Descriptions.Item label="Broodstock #">{editItem.broodstockNo ?? "-"}</Descriptions.Item>
        <Descriptions.Item label="Spawn Date">
          {editItem.IncubationRecord ? moment(editItem.IncubationRecord?.spawnDate).format("YYYY-MM-DD") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Hatch Date">
          {editItem.hatchDate ? moment(editItem.hatchDate).format("YYYY-MM-DD") : "-"}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions title="Larval Tank">
        <Descriptions.Item label="Curent">
          {editItem.Tank ? <Tag color="blue">{editItem.Tank.label}</Tag> : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Size (Litre)">
          {editItem.Tank.sizeInLitre ? Number(editItem.Tank.sizeInLitre).toLocaleString() : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Previous">
          {editItem.TankUsageSources && editItem.TankUsageSources.length
            ? editItem.TankUsageSources.map((item) => <Tag>{item.label}</Tag>)
            : "-"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default LarvalInfo;
