import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, DatePicker } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NurseryRecordTable from "../../components/nurseryRecord/NurseryRecordTable";

import EditNurseryRecordModal from "../../components/nurseryRecord/EditNurseryRecordModal";

import moment from "moment";
import { useNurseryRecord } from "../../hooks/useNurseryRecord";
import NurseryRecordDrawer from "../../components/nurseryRecord/NurseryRecordDrawer";

const { Title } = Typography;

const NurseryRecord = () => {
  const { t } = useTranslation();

  const { findAll, update } = useNurseryRecord();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(30, "days"), moment()]);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [nurseryRecordDrawer, setNurseryRecordDrawer] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0] : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1] : null;

      findAll({ startDate, endDate })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh]);

  const viewRow = (row) => {
    setEditItem(row);
    setNurseryRecordDrawer(true);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const markAsComplete = (item) => {
    update(item.id, { status: "Complete" }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  return (
    <>
      <Row style={{ marginTop: 16 }} gutter={16}>
        <Col>
          <DatePicker.RangePicker
            value={filterDate}
            onChange={(value) => {
              setFilterDate(value);
              setRefresh(true);
            }}
          />
        </Col>
      </Row>

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Nursery Batch</Title>

        <Row justify="end">
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <NurseryRecordTable
          error={error}
          loading={loading}
          dataSource={data}
          viewRow={viewRow}
          editRow={editRow}
          markAsComplete={markAsComplete}
        />

        <EditNurseryRecordModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />

        <NurseryRecordDrawer item={editItem} visible={nurseryRecordDrawer} setVisible={setNurseryRecordDrawer} />
      </div>
    </>
  );
};

export default NurseryRecord;
