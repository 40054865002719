import React from "react";
import { Button, Row } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";

const ExportToExcel = (props) => {
  const { columns, dataSource, docName } = props;

  return (
    <Row justify="end">
      <Button
        icon={<ExportOutlined />}
        type="link"
        style={{
          marginTop: 24,
        }}
        onClick={() => {
          const excel = new Excel();
          const cols = columns.filter((item) => item.title !== "Action");
          excel
            .addSheet("Sheet 1")
            .addColumns(cols)
            .addDataSource(dataSource)
            .saveAs(docName + ".xlsx");
        }}
      >
        Export to Excel
      </Button>
    </Row>
  );
};

export default ExportToExcel;
