import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, Input, InputNumber } from "antd";
import moment from "moment";
import { useTankTransfer } from "../../../hooks/useTankTransfer";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ProductionModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { convertToProduction } = useTankTransfer();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        productionTimestamp: moment(),
        batchNo: editItem.batchNo,
        broodstockNo: editItem.broodstockNo,
        broodstockSpecies: editItem.HatcheryReceipt.species,
      });
    }
  }, [visible, editItem, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          productionTimestamp: moment(values.productionTimestamp).format(),
          quantity: values.quantity,
          avgWeightInGram: values.avgWeightInGram,
          batchNo: values.batchNo,
          broodstockNo: values.broodstockNo,
          broodstockSpecies: values.broodstockSpecies,
          remarks: values.remarks,
        };
        convertToProduction(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"New Production"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="productionTimestamp" label="Harvest Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="batchNo" label="Batch No.">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="broodstockNo" label="Broodstock No.">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="broodstockSpecies" label="Broodstock Species.">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="avgWeightInGram" label="Avg Weight (g)">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProductionModal;
