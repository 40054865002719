import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Card, Empty, Divider, Space, Select } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import NurseryHarvestModal from "../../components/nurseryRecord/NurseryHarvestModal";
import { useNurseryHapa } from "../../hooks/useNurseryHapa";
import moment from "moment";
import NurseryHapaDrawer from "../../components/nurseryHapa/NurseryHapaDrawer";

const { Title } = Typography;

const NurseryHapa = () => {
  const { findAll } = useNurseryHapa();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const [filterStatus, setFilterStatus] = useState("New");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [nurseryHapaDrawer, setNurseryHapaDrawer] = useState(false);
  const [stockOutModal, setStockOutModal] = useState(false);

  useEffect(() => {
  }, [filterStatus])


  useEffect(() => {
    if (refresh) {
      findAll({
        status: filterStatus,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, [filterStatus]);

  const viewRow = (row) => {
    setEditItem(row);
    setNurseryHapaDrawer(true);
  };

  const editRow = (row) => {
    setEditItem(row);
    setStockOutModal(true);
  };

  return (
    <>
      <Row justify="space-between" style={{ marginTop: 16 }}>
        <Col>
          <Select style={{ width: 120 }} onChange={(value) => setFilterStatus(value)} value={filterStatus}>
            <Select.Option value="New">New</Select.Option>
            <Select.Option value="Complete">Complete</Select.Option>
          </Select>
        </Col>
      </Row>

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Nursery HAPA</Title>

        <Row justify="end">
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        {data.length ? (
          <Row gutter={[16, 16]}>
            {data.map((item) => (
              <Col key={item.id} xs={12} sm={6} onClick={() => viewRow(item)}>
                <Card size="small" title={"HAPA " + item.label}>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">DOC</Typography.Text>
                    </Col>
                    <Col>
                      {item.stockDate
                        ? moment().startOf("day").diff(moment(item.stockDate).startOf("day"), "days", false)
                        : "-"}
                    </Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Stock Date</Typography.Text>
                    </Col>
                    <Col>{moment(item.stockDate).format("YYYY-MM-DD")}</Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Stock Qty (pcs)</Typography.Text>
                    </Col>
                    <Col>{Number(item.stockQuantityInPcs).toLocaleString()}</Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Harvest Date</Typography.Text>
                    </Col>
                    <Col>
                      {item.harvestDate
                        ? moment().startOf("day").diff(moment(item.harvestDate).startOf("day"), "days", false)
                        : "-"}
                    </Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Harvest Qty (pcs)</Typography.Text>
                    </Col>
                    <Col>{item.harvestQuantityInPcs ? Number(item.harvestQuantityInPcs).toLocaleString() : "-"}</Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Accm. Feed (kg)</Typography.Text>
                    </Col>
                    <Col>{Number(item.runningFeedInKg).toLocaleString()}</Col>
                  </Row>

                  {
                    filterStatus != "Complete" &&
                    <>
                      <Divider
                        style={{
                          background: item.status === "New" ? "green" : "gray",
                          height: 4,
                        }}
                      />
                      <Row style={{ marginTop: 16 }}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                          <Button type="primary" ghost block onClick={() => editRow(item)}>
                            Harvest
                          </Button>
                        </Space>
                      </Row>
                    </>
                  }
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty />
        )}

        <NurseryHarvestModal
          editItem={editItem}
          visible={stockOutModal}
          setVisible={setStockOutModal}
          setRefresh={setRefresh}
        />

        <NurseryHapaDrawer item={editItem} visible={nurseryHapaDrawer} setVisible={setNurseryHapaDrawer} />
      </div>
    </>
  );
};

export default NurseryHapa;
