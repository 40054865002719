import React from "react";
import { EditOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Button, Space, Popconfirm, Typography } from "antd";
import TableLoader from "../common/TableLoader";
import moment from "moment";

const NurseryRecordTable = (props) => {
  const { dataSource, loading, error, viewRow, editRow, markAsComplete } = props;

  const columns = [
    // {
    //   title: "Arrival Date",
    //   dataIndex: "FarmReceipt",
    //   render: (text, row) => moment(row.FarmReceipt?.arrivalTimestamp).format("YYYY-MM-DD"),
    // },
    {
      title: "Stock Date",
      dataIndex: "stockDate",
      render: (text, row) => (
        <Typography.Link underline onClick={() => viewRow(row)}>
          {moment(text).format("YYYY-MM-DD")}
        </Typography.Link>
      ),
    },
    {
      title: "Stock Qty",
      dataIndex: "stockQuantityInPcs",
      align: "right",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Harvest Qty",
      dataIndex: "harvestQuantityInPcs",
      align: "right",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Total HAPA",
      dataIndex: "totalHapa",
      align: "right",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Survival (%)",
      align: "right",
      render: (text, row) =>
        row.harvestQuantityInPcs && row.stockQuantityInPcs
          ? ((Number(row.harvestQuantityInPcs) / Number(row.stockQuantityInPcs)) * 100).toLocaleString()
          : "-",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Space>
          <Button disabled={row.status === "Complete"} icon={<EditOutlined />} onClick={() => editRow(row)} />
          <Popconfirm title={"Are you sure to update this record as complete?"} onConfirm={() => markAsComplete(row)}>
            <Button disabled={row.status === "Complete"} icon={<FileDoneOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default NurseryRecordTable;
