import React, { useEffect, useReducer, useState } from "react";
import { ConfigProvider, Alert, Button, Layout } from "antd";
import "./App.less";
// import "antd/dist/antd.css";

import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";

import BaseLayout from "./components/layouts/BaseLayout";
import Login from "./components/login/Login";
import PageLoader from "./components/common/PageLoader";
// import { auth } from './services/AuthService';
import { useAuth } from "./hooks/useAuth";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import RolePermission from "./utils/RolePermission";
import UserRole from "./utils/UserRole";

import enUS from "antd/es/locale/en_US";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import { useSelector } from "react-redux";
import Trace from "./components/recall/Trace";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

const { Content } = Layout;

moment.locale("en");

export const fp = (async () => {
  const fp = await FingerprintJS.load();
  return await fp.get();
})();

export const LocaleContext = React.createContext();

const localeReducer = (state, action) => {
  if (action === "en") {
    return enUS;
  } else if (action === "cn") {
    return zhCN;
  }
  return enUS;
};

export const AuthContext = React.createContext();
export const ResponsiveContext = React.createContext();

const initialState = {
  token: null,
  authUser: null,
  isAuthenticated: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        authUser: action.payload.authUser,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        authUser: null,
        token: null,
      };
    case "UPDATE":
      return {
        ...state,
        authUser: action.payload.authUser,
      };
    default:
      return state;
  }
};

const App = () => {
  const isServiceWorkerUpdated = useSelector((state) => state.serviceWorkerUpdated);
  const serviceWorkerRegistration = useSelector((state) => state.serviceWorkerRegistration);

  // SEM: localeContext for Config Provider is used for AntD component.
  //      Not to mix up with I18Next (application)
  const [locale, setLocaleContext] = useReducer(localeReducer, enUS);

  const [authState, dispatch] = useReducer(reducer, initialState);

  const [isMobile, setIsMobile] = useState();

  const [loading, setLoading] = useState(true);

  const { auth } = useAuth();
  // const { httpPost } = useFetch('security');

  useEffect(() => {
    const fetchData = async () => {
      const res = await auth();

      if (res.status === "success") {
        dispatch({
          type: "LOGIN",
          payload: {
            authUser: res.data.auth,
            token: res.data.token,
          },
        });
      }
      setLoading(false);
    };
    fetchData();
  }, [auth]);

  const can = (route) => {
    // Temporary
    // if (authState.authUser.email === "superadmin@neocrab.com") {
    //   return true;
    // }
    const email = authState.authUser ? authState.authUser.email : "";

    if (UserRole[email]) {
      const [entity, role] = UserRole[email].split(".");

      if (role === "SUPERUSER") {
        return true;
      }

      const permissions = RolePermission[entity][role];
      if (permissions.indexOf(route) > -1) {
        return true;
      }
      return false;
    } else {
      return false;
    }

    // if (authState.authUser.email === "admin@sinisana.net") {
    //   return true;
    // }

    // if (authState.authUser) {
    //   let permissions = [];

    //   authState.authUser.Roles.forEach((role) => {
    //     permissions.push(...role.permissions);
    //   });

    //   permissions.forEach((permission) => {
    //     const module = permission.split(":::")[0];
    //     const index = permissions.indexOf(module);
    //     if (index <= -1) {
    //       permissions.push(module);
    //     }
    //   });

    //   return permissions.indexOf(route) > -1;
    // } else {
    //   return false;
    // }
  };

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  const AdminLayout = () => (!authState.isAuthenticated ? <Login /> : <BaseLayout />);

  return (
    <LocaleContext.Provider
      value={{
        setLocaleContext,
      }}
    >
      <ConfigProvider locale={locale}>
        <I18nextProvider i18n={i18n}>
          <AuthContext.Provider
            value={{
              authState,
              dispatch,
              can,
            }}
          >
            <ResponsiveContext.Provider
              value={{
                isMobile,
                setIsMobile,
              }}
            >
              {isServiceWorkerUpdated && (
                <Alert
                  message="There is a new version available."
                  type="warning"
                  showIcon
                  action={
                    <Button size="small" type="default" onClick={updateServiceWorker}>
                      Update
                    </Button>
                  }
                />
              )}

              {process.env.REACT_APP_ENV === "staging" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Alert
                    style={{ position: "absolute", zIndex: 99 }}
                    banner
                    message="Test Site"
                    type="error"
                    closable={false}
                    showIcon={false}
                  />
                </div>
              )}

              {loading ? (
                <PageLoader />
              ) : (
                <Router>
                  <Content>
                    <Switch>
                      <Route exact path="/trace" component={Trace} />
                      <Route exact path="*" component={AdminLayout} />
                    </Switch>
                  </Content>
                </Router>
              )}
            </ResponsiveContext.Provider>
          </AuthContext.Provider>
        </I18nextProvider>
      </ConfigProvider>
    </LocaleContext.Provider>
  );
};

export default App;
