import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import ParameterTypeForm from "./ParameterTypeForm";
import { useParameterType } from "../../../hooks/useParameterType";

const EditParameterTypeModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useParameterType();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: editItem.name,
        uom: editItem.uom,
        description: editItem.description,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          name: values.name,
          uom: values.uom,
          description: values.description,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Parameter Type"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <ParameterTypeForm form={form} />
    </Modal>
  );
};

export default EditParameterTypeModal;
