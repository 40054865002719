import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NewParameterTypeModal from "./components/NewParameterTypeModal";
import EditParameterTypeModal from "./components/EditParameterTypeModal";
import { useParameterType } from "../../hooks/useParameterType";
import ParameterTypeTable from "./components/ParameterTypeTable";

const { Title } = Typography;

const ParameterType = () => {
  const { t } = useTranslation();

  const { findAll } = useParameterType();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [newParameterTypeModal, setNewParameterTypeModal] = useState(false);
  const [editParameterTypeModal, setEditParameterTypeModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditParameterTypeModal(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={3}>Parameter Types</Title>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setNewParameterTypeModal(true)}>
            New
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </Col>
      </Row>

      <ParameterTypeTable error={error} loading={loading} dataSource={data} editRow={editRow} />

      <NewParameterTypeModal
        visible={newParameterTypeModal}
        setVisible={setNewParameterTypeModal}
        setRefresh={setRefresh}
      />

      <EditParameterTypeModal
        editItem={editItem}
        visible={editParameterTypeModal}
        setVisible={setEditParameterTypeModal}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default ParameterType;
