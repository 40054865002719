import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { useFactoryProduction } from "../../../hooks/useFactoryProduction";
import { useFactoryFreezing } from "../../../hooks/useFactoryFreezing";
import OpenTransferTable from "../tables/OpenTransferTable";

const EditDetailModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useFactoryProduction();
  const { addDetail } = useFactoryFreezing();

  const [submitting, setSubmitting] = useState(false);

  const [productionData, setProductionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setProductionData(res.data);
        }
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (selectedRows.length <= 0) {
        return;
      }
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          factoryProductionArrayId: selectedRows.map((item) => item.id),
        };

        addDetail(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="SSC Production Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <OpenTransferTable dataSource={productionData} setSelectedRows={setSelectedRows} />
    </Modal>
  );
};

export default EditDetailModal;
