import React from "react";
import { DatePicker, Form, Select, Input, InputNumber } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditForm = (props) => {
  const { form, farmOption } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="transferTimestamp" label="Transfer Timestamp" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>
      <Form.Item
        name="quantity"
        label="Quantity"
        rules={[{ required: true }]}
        extra="Adjust quantity last if there is discrepancy"
      >
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="vehicleNumber" label="Vehicle Number" rules={[{ required: true }]}>
        <Input placeholder="Example: QAA223F" />
      </Form.Item>

      <Form.Item name="personInCharge" label="Person In Charge" rules={[{ required: true }]}>
        <Input placeholder="Example: John Meyer" />
      </Form.Item>

      <Form.Item name="farmId" label="To Farm">
        <Select allowClear>
          {farmOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default EditForm;
