import React from "react";
import { Tooltip, Tag, Space } from "antd";
import TableLoader from "../common/TableLoader";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";

const HatcheryHarvestTable = (props) => {
  const { dataSource, loading, error, editRow } = props;

  const columns = [
    {
      title: "Date",
      dataIndex: "productionTimestamp",
      render: (text, row) => (
        <Space>
          {moment(text).format("YYYY-MM-DD")}
          {/* {row.antChainTraceCode && (
            <Button type="link" icon={<BlockOutlined />} href={row.antChainTraceCode} target="_blank" />
          )} */}
        </Space>
      ),
    },
    {
      title: "Batch",
      dataIndex: "batchNo",
      render: (text) => (text ? <Tag>{text}</Tag> : "-"),
    },
    {
      title: "B.S. #",
      dataIndex: "broodstockNo",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Species",
      dataIndex: "broodstockSpecies",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Avg. Weight (g)",
      dataIndex: "avgWeightInGram",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) =>
        row.status === "Transfer Out" ? (
          "-"
        ) : (
          <Tooltip title="Edit">
            <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
          </Tooltip>
        ),
    },
  ];

  return (
    <>
      <TableLoader
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        expandable={{
          expandedRowRender: (row) => row.remarks,
          rowExpandable: (row) => row.remarks,
        }}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default HatcheryHarvestTable;
