import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import moment from "moment";
import { useProcessing } from "../../../hooks/useProcessing";
import { useFarm } from "../../../hooks/useFarm";
import DeliveryOrderForm2 from "../forms/DeliveryOrderForm2";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findFarm } = useFarm();
  const { findAll: findProcessing } = useProcessing();
  const { update } = useDeliveryOrder();

  const [submitting, setSubmitting] = useState(false);
  const [farmOption, setFarmOption] = useState([]);
  const [processingOption, setProcessingOption] = useState([]);

  useEffect(() => {
    if (visible) {
      findFarm({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setFarmOption(res.data);
        }
      });

      findProcessing({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setProcessingOption(res.data);
        }
      });

      form.setFieldsValue({
        orderNo: editItem.orderNo,
        product: editItem.product,
        departTimestamp: moment(editItem.departTimestamp),
        personInCharge: editItem.personInCharge,
        vehicleNumber: editItem.vehicleNumber,
        status: editItem.status,
        remarks: editItem.remarks,
        farmId: editItem.sourceFarmId,
        processingId: editItem.targetProcessingId,
      });
    }
  }, [visible, form, editItem, findFarm, findProcessing]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          orderNo: values.orderNo,
          product: values.product,
          departTimestamp: moment(values.departTimestamp).format(),
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          farmId: values.farmId,
          processingId: values.processingId ? values.processingId : null,
          status: values.status,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Delivery Order"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width={"65%"}
    >
      <DeliveryOrderForm2 form={form} farmOption={farmOption} processingOption={processingOption} isEdit={true} />
    </Modal>
  );
};

export default EditModal;
