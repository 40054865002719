import React from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const IncubationParameterTable = (props) => {
  const { dataSource, loading, error, editRow, incubationRecord } = props;

  const columns = [
    {
      title: "DOC",
      render: (text, row) =>
        moment(row.timestamp).startOf("day").diff(moment(incubationRecord.spawnDate).startOf("day"), "days", false),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Due to Hatch",
      dataIndex: "dueToHatch",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "E.M. Size",
      dataIndex: "emSize",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Stage",
      dataIndex: "stage",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => <Button type="link" icon={<EditOutlined />} onClick={() => editRow(row)} />,
    },
  ];

  return (
    <TableLoader
      size="small"
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
    />
  );
};

export default IncubationParameterTable;
