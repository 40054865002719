import React from "react";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import ExportToExcel from "../../common/ExportToExcel";

const DailyRecordTable = (props) => {
  const { dataSource, loading, error, batchInfo } = props;

  const columns = [
    {
      title: "DOC",
      fixed: "left",
      dataIndex: "recordTimestamp",
      render: (text, row) => {
        if (row.subheader) {
          return {
            children: <div>{text}</div>,
            props: {
              colSpan: 22,
              style: { background: "#f5f5f5" },
            },
          };
        } else {
          return moment(text).startOf("day").diff(moment(batchInfo.hatchDate).startOf("day"), "days", false);
        }
      },
    },
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text, row) => {
        if (row.subheader) {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else {
          return moment(text).format("YYYY/MM/DD");
        }
      },
    },
    {
      title: "Stage",
      dataIndex: "larvalStage",
      render: (text, row) => {
        if (row.subheader) {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else {
          return text ? text : "-";
        }
      },
    },
    {
      title: "Density (/L)",
      dataIndex: "larvalDensity",
      render: (text, row) => {
        if (row.subheader) {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else {
          return text ? text : "-";
        }
      },
    },
    {
      title: "Salinity (ppt)",
      dataIndex: "salinityInPpt",
      render: (text, row) => {
        if (row.subheader) {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else {
          return text ? text : "-";
        }
      },
    },
    {
      title: "Temperature (C)",
      children: [
        {
          title: "6 a.m.",
          dataIndex: "temperature1",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "3 p.m.",
          dataIndex: "temperature2",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
      ],
    },
    {
      title: "Artemia Fed (g)",
      children: [
        {
          title: "Type",
          dataIndex: "artemiaType",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "6 a.m.",
          dataIndex: "artemiaFedInGram1",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "3 p.m.",
          dataIndex: "artemiaFedInGram2",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
      ],
    },
    {
      title: "Microparticulate (g/Tank)",
      children: [
        {
          title: "9 a.m.",
          dataIndex: "microInGram1",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "3 p.m.",
          dataIndex: "microInGram2",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "10 p.m.",
          dataIndex: "microInGram3",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
      ],
    },
    {
      title: "Water Treatment",
      children: [
        {
          title: "Oregano (ml)",
          dataIndex: "oreganoInMl",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "K-5 (g)",
          dataIndex: "k5InGram",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "M-Plus (g)",
          dataIndex: "mplus",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "EDTA (g)",
          dataIndex: "edta",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "Tystatin (ml)",
          dataIndex: "tystatin",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "Cifro (tablets)",
          dataIndex: "cifro",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "Super MS (ml)",
          dataIndex: "superms",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "Shrimp Favour (g)",
          dataIndex: "shrimpFavour",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
        {
          title: "GERM Free (tablets)",
          dataIndex: "germFree",
          render: (text, row) => {
            if (row.subheader) {
              return {
                props: {
                  colSpan: 0,
                },
              };
            } else {
              return text ? text : "-";
            }
          },
        },
      ],
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text, row) => {
        if (row.subheader) {
          return {
            props: {
              colSpan: 0,
            },
          };
        } else {
          return text ? text : "-";
        }
      },
    },
  ];

  return (
    <>
      <ExportToExcel docName="Daily Larval Record" columns={columns} dataSource={dataSource} />
      <TableLoader
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        scroll={{ x: "max-content" }}
        bordered
      />
    </>
  );
};

export default DailyRecordTable;
