import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import PontoonTable from "../../../components/pontoon/tables/PontoonTable";
import NewPontoonModal from "../../../components/pontoon/NewPontoonModal";
import EditPontoonModal from "../../../components/pontoon/EditPontoonModal";
import { usePontoon } from "../../../hooks/usePontoon";

const PondPontoonTab = (props) => {
  const { pond, hasValidCycle } = props;

  const { findAll: findPontoon } = usePontoon();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [pontoonData, setPontoonData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [newPontoonModal, setNewPontoonModal] = useState(false);
  const [editPontoonModal, setEditPontoonModal] = useState(false);

  useEffect(() => {
    if (pond.id && refresh) {
      setLoading(true);
      findPontoon({ pondId: pond.id }).then((res) => {
        if (res.status === "success") {
          setPontoonData(res.data);
        } else {
          setPontoonData([]);
        }
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh, pond, findPontoon]);

  const editPontoonRow = (row) => {
    setEditItem(row);
    setEditPontoonModal(true);
  };

  return (
    <>
      <div class="flex justify-between mb-4">
        <div>
          <Button
            type="primary"
            onClick={() => setNewPontoonModal(true)}
            hidden={pond.pondType !== "Pontoon"}
            disabled={!hasValidCycle()}
          >
            New
          </Button>
        </div>
        <div>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </div>
      </div>

      <PontoonTable dataSource={pontoonData} loading={loading} editRow={editPontoonRow} />

      <NewPontoonModal pond={pond} visible={newPontoonModal} setVisible={setNewPontoonModal} setRefresh={setRefresh} />

      <EditPontoonModal
        editItem={editItem}
        visible={editPontoonModal}
        setVisible={setEditPontoonModal}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default PondPontoonTab;
