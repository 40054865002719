import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useFarmReport = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const sscRawMatData = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/farmReports/sscRawMatData", config);
    },
    [authState.token, send]
  );

  const sscHarvestData = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/farmReports/sscHarvestData", config);
    },
    [authState.token, send]
  );

  return {
    sscRawMatData,
    sscHarvestData,
  };
};

export { useFarmReport };
