import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Typography, Table, InputNumber, Input, Space } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useSSCDailyMonitor } from "../../../hooks/useSSCDailyMonitor";
import { usePontoon } from "../../../hooks/usePontoon";
import { ResponsiveContext } from "../../../App";
import { sscGrade } from "../../../utils/Options";
const { Text } = Typography;

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const [form] = Form.useForm();

  const { create } = useSSCDailyMonitor();
  const { findAll } = usePontoon();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [pontoonOption, setPontoonOption] = useState([]);
  const [pontoonArray, setPontoonArray] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New", sscSource: "Internal" }).then((res) => {
        if (res.status === "success") {
          setPontoonOption(res.data);
        }
      });

      form.setFieldsValue({
        recordTimestamp: moment(),
        sourceType: "Internal",
      });
    }
  }, [visible, findAll, form]);

  useEffect(() => {
    setPontoonArray(
      pontoonOption.map((item) => {
        return {
          pontoonId: item.id,
          label: item.label,
          pondLabel: item.Pond.label,
          deadQuantity: null,
          harvestQuantity: null,
          missingQuantity: null,
          aliveQuantity: item.sscCount,
          originalAliveQuantity: item.sscCount,
          remarks: null,
        };
      })
    );
  }, [pontoonOption]);

  useEffect(() => {
    console.log(pontoonArray);
  }, [pontoonArray]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const pontoonRecord = pontoonArray.filter(
          (item) => item.harvestQuantity || item.deadQuantity || item.missingQuantity
        );

        if (pontoonRecord.length <= 0) {
          setError(true);
          return;
        }
        setError(false);

        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          harvestShift: values.harvestShift,
          sourceType: values.sourceType,
          pontoonArray: pontoonRecord,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onHarvestChange = (value, item, grade) => {
    let array = [...pontoonArray];

    const index = array.map((item) => item.pontoonId).indexOf(item.pontoonId);

    if (index > -1) {
      array[index].harvestQuantity = {
        ...array[index].harvestQuantity,
        [grade]: value,
      };
      setPontoonArray(array);
    }
  };

  const onDeadChange = (value, item) => {
    let array = [...pontoonArray];

    const index = array.map((item) => item.pontoonId).indexOf(item.pontoonId);

    if (index > -1) {
      array[index].deadQuantity = value;
      setPontoonArray(array);
    }
  };

  const onMissingChange = (value, item) => {
    let array = [...pontoonArray];

    const index = array.map((item) => item.pontoonId).indexOf(item.pontoonId);

    if (index > -1) {
      array[index].missingQuantity = value;
      setPontoonArray(array);
    }
  };

  const onRemarkChange = (value, item) => {
    let array = [...pontoonArray];

    const index = array.map((item) => item.pontoonId).indexOf(item.pontoonId);

    if (index > -1) {
      array[index].remarks = value;
      setPontoonArray(array);
    }
  };

  const columns = [
    {
      title: "Pontoon",
      dataIndex: "label",
      render: (text, row) => (
        <>
          <div>{text}</div>
          <div className="text-xs text-gray-400">{row.pondLabel}</div>
        </>
      ),
    },
    {
      title: "Open Bal.",
      dataIndex: "originalAliveQuantity",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "SSC",
      dataIndex: "harvestQuantity",
      render: (text, row) => (
        <Space>
          {sscGrade.map((grade) => (
            <InputNumber
              key={grade}
              placeholder={grade}
              inputMode="numeric"
              min={0}
              value={text ? text[grade] : null}
              onChange={(e) => onHarvestChange(e, row, grade)}
            />
          ))}
        </Space>
      ),
    },
    {
      title: "Dead",
      dataIndex: "deadQuantity",
      render: (text, row) => (
        <InputNumber inputMode="numeric" min={0} value={text} onChange={(e) => onDeadChange(e, row)} />
      ),
    },
    {
      title: "Missing",
      dataIndex: "missingQuantity",
      render: (text, row) => (
        <InputNumber inputMode="numeric" min={0} value={text} onChange={(e) => onMissingChange(e, row)} />
      ),
    },
    {
      title: "Close",
      render: (text, row) =>
        row.originalAliveQuantity -
        (row.harvestQuantity ? Object.values(row.harvestQuantity).reduce((a, b) => a + b, 0) : 0) -
        row.deadQuantity -
        row.missingQuantity,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text, row) => <Input onChange={(e) => onRemarkChange(e.target.value, row)} />,
    },
  ];

  const onSSCSourceChanged = (value) => {
    findAll({ status: "New", sscSource: value }).then((res) => {
      if (res.status === "success") {
        setPontoonOption(res.data);
      }
    });
  };

  return (
    <Modal
      title={"New SSC Monitor Record"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width={isMobile ? null : "80%"}
    >
      <AddNewForm form={form} onSSCSourceChanged={onSSCSourceChanged} />

      {error && <Text type="danger">Please input at least one field </Text>}

      <Table
        size="small"
        dataSource={pontoonArray}
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{ pageSize: 100 }}
      />
    </Modal>
  );
};

export default AddNewModal;
