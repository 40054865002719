import React from "react";
import { Descriptions, Table, InputNumber } from "antd";
import moment from "moment";

import { sscGrade, sscSize } from "../../../utils/Options";

const OpenTransferTable = (props) => {
  const { dataSource, selectedRows, setSelectedRows, setFarmProductionData } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Product",
      dataIndex: "type",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
    },
    {
      title: "Weight",
      dataIndex: "weightInKg",
    },
    {
      title: "Date",
      dataIndex: "productionTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    // },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onInputChange = (row, grade, size, value) => {
    const index = dataSource.map((item) => item.id).indexOf(row.id);

    if (index > -1) {
      const newArray = [...dataSource];

      let grades = { ...newArray[index].grades };

      let sizes = { ...grades[grade] };
      sizes = {
        ...sizes,
        [size]: value,
      };
      grades = {
        ...grades,
        [grade]: sizes,
      };
      newArray[index] = { ...newArray[index], grades: grades };
      setFarmProductionData(newArray);
    }
  };

  return (
    <Table
      size="small"
      rowKey="id"
      rowSelection={{
        // type: selectionType,
        ...rowSelection,
      }}
      columns={columns}
      dataSource={dataSource}
      style={{ marginTop: 24 }}
      // expandable={{
      //   expandedRowRender: (row) => (
      //     <>
      //       {sscGrade.map((grade) => (
      //         <Descriptions title={"Grade " + grade} size="small">
      //           {sscSize.map((item) => (
      //             <Descriptions.Item label={item} key={item} contentStyle={{ justifyContent: "flex-end" }}>
      //               <InputNumber
      //                 defaultValue={row.grades && row.grades[grade] ? row.grades[grade][item] : null}
      //                 allowClear
      //                 onChange={(value) => onInputChange(row, grade, item, value)}
      //               />
      //             </Descriptions.Item>
      //           ))}
      //         </Descriptions>
      //       ))}
      //     </>
      //   ),
      //   rowExpandable: (row) => selectedRows.map((item) => item.id).includes(row.id) && row.type === "Soft Shell Crab",
      //   expandedRowKeys: selectedRows.map((item) => item.id),
      // }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default OpenTransferTable;
