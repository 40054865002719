import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import { useFarmReceipt } from "../../../hooks/useFarmReceipt";
import HatcheryToFarmForm from "../HatcheryToFarmForm";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFarmReceipt();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        location: editItem.location,
        arrivalTimestamp: moment(editItem.arrivalTimestamp),
        hatcheryQuantity: editItem.hatcheryQuantity,
        farmQuantity: editItem.farmQuantity,
        weightInKg: editItem.weightInKg,
        vehicleNumber: editItem.vehicleNumber,
        personInCharge: editItem.personInCharge,
        remarks: editItem.remarks,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          location: values.location,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          hatcheryQuantity: values.hatcheryQuantity,
          farmQuantity: values.farmQuantity,
          weightInKg: values.weightInKg,
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          remarks: values.remarks,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Hatchery to Nursery Farm Transfer"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <HatcheryToFarmForm form={form} isEdit />
    </Modal>
  );
};

export default EditModal;
