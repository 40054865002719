import React, { useState, useEffect } from "react";
import { Descriptions, Row, Col, Divider, Select, Form, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { useTank } from "../../../hooks/useTank";
import { useDashboard } from "../../../hooks/useDashboard";
const moment = require("moment");

const { Option } = Select;
const { RangePicker } = DatePicker;

const ChemicalUsage = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { findAll } = useTank();
  const { findChemicalUsageData } = useDashboard();

  const [selectedDateRange, setSelectedDateRange] = useState([moment().subtract(7, "day"), moment()]);
  const [selectedTank, setSelectedTank] = useState("All");
  const [tankOption, setTankOption] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const [artemiaUsage, setArtemiaUsage] = useState();
  const [oreganoUsage, setOreganoUsage] = useState();
  const [k5InGramUsage, setK5InGramUsage] = useState();
  const [mplusUsage, setMplusUsage] = useState();
  const [edtaUsage, setEdtaUsage] = useState();
  const [tystatinUsage, setTystatinUsage] = useState();
  const [cifroUsage, setCifroUsage] = useState();
  const [superMsUsage, setSuperMsUsage] = useState();
  const [shrimpUsage, setShrimpUsage] = useState();
  const [germFreeUsage, setGermFreeUsage] = useState();

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setTankOption(res.data);
      }
    });
  }, [findAll]);

  useEffect(() => {
    if (selectedDateRange && selectedTank) {
      const startDate = moment(selectedDateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(selectedDateRange[1]).format("YYYY-MM-DD");
      findChemicalUsageData(startDate, endDate, selectedTank).then((res) => {
        if (res.status === "success") {
          setUsageData(res.data);
        }
      });
    }
  }, [selectedDateRange, selectedTank, findChemicalUsageData]);

  useEffect(() => {
    setArtemiaUsage(
      usageData
        .map((item) => Number(item.artemiaFedInGram1) + Number(item.artemiaFedInGram2))
        .reduce((a, b) => a + b, 0)
    );
    setOreganoUsage(usageData.map((item) => item.oreganoInMl).reduce((a, b) => a + b, 0));
    setK5InGramUsage(usageData.map((item) => item.k5InGram).reduce((a, b) => a + b, 0));
    setMplusUsage(usageData.map((item) => item.mplus).reduce((a, b) => a + b, 0));
    setEdtaUsage(usageData.map((item) => item.edta).reduce((a, b) => a + b, 0));
    setTystatinUsage(usageData.map((item) => item.tystatin).reduce((a, b) => a + b, 0));
    setCifroUsage(usageData.map((item) => item.cifro).reduce((a, b) => a + b, 0));
    setSuperMsUsage(usageData.map((item) => item.superms).reduce((a, b) => a + b, 0));
    setShrimpUsage(usageData.map((item) => item.shrimpFavour).reduce((a, b) => a + b, 0));
    setGermFreeUsage(usageData.map((item) => item.germFree).reduce((a, b) => a + b, 0));
  }, [usageData]);

  const onDateChanged = (value) => {
    if (value) {
      setSelectedDateRange(value);
    }
  };

  const onTankChanged = (value) => {
    if (value) {
      setSelectedTank(value);
    }
  };

  return (
    <>
      <Form form={form} layout="inline">
        <Form.Item label={t("common.date")} style={{ lineHeight: "40px" }}>
          <RangePicker style={{ width: 250 }} defaultValue={selectedDateRange} onChange={onDateChanged} />
        </Form.Item>

        <Form.Item label="Larval Tank" style={{ lineHeight: "40px" }}>
          <Select style={{ width: 250 }} defaultValue={selectedTank} onChange={onTankChanged}>
            <Option value={"All"}>All</Option>
            {tankOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
          {/* <Select style={{ width: 230 }} defaultValue={selectedFilter.product} onChange={onProductChanged}>
              {productFilter.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.productName}
                </Option>
              ))}
            </Select> */}
        </Form.Item>
      </Form>

      <Divider />

      <Row>
        <Col xs={24}>
          <Descriptions title="Total Artemia & Chemical Usage" style={{ marginTop: 24, marginBottom: 16 }}>
            <Descriptions.Item label="Artemia (g)">
              {artemiaUsage ? artemiaUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Oregano (ml)">
              {oreganoUsage ? oreganoUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="K-5 (g)">
              {k5InGramUsage ? k5InGramUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="M-Plus (g)">{mplusUsage ? mplusUsage.toLocaleString() : "-"}</Descriptions.Item>
            <Descriptions.Item label="EDTA (g)">{edtaUsage ? edtaUsage.toLocaleString() : "-"}</Descriptions.Item>
            <Descriptions.Item label="Tystatin (ml)">
              {tystatinUsage ? tystatinUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Cifro (tablets)">
              {cifroUsage ? cifroUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Super MS (ml)">
              {superMsUsage ? superMsUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Shrimp Favour (g)">
              {shrimpUsage ? shrimpUsage.toLocaleString() : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="GERM Free (tablets)">
              {germFreeUsage ? germFreeUsage.toLocaleString() : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

export default ChemicalUsage;
