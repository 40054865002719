import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, InputNumber } from "antd";
import moment from "moment";
import { usePondSampling } from "../../hooks/usePondSampling";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditPondSamplingModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePondSampling();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        avgWeightInGram: editItem.avgWeightInGram,
        stockDensityInPcs: editItem.stockDensityInPcs,
        estSurvivalInPerc: editItem.estSurvivalInPerc,
        estBiomassInKg: editItem.estBiomassInKg,
        runningFeedInKg: editItem.runningFeedInKg,
        runningFCR: editItem.runningFCR,
        weightGainInGram: editItem.weightGainInGram,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          avgWeightInGram: values.avgWeightInGram,
          stockDensityInPcs: values.stockDensityInPcs,
          estSurvivalInPerc: values.estSurvivalInPerc,
          estBiomassInKg: values.estBiomassInKg,
          runningFeedInKg: values.runningFeedInKg,
          runningFCR: values.runningFCR,
          weightGainInGram: values.weightGainInGram,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Sampling"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item label="Record Timestamp" name="recordTimestamp" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item label="Avg Body Weight (g)" name="avgWeightInGram" rules={[{ required: true }]}>
          <InputNumber inputMode="decimal" />
        </Form.Item>
        <Form.Item label="Stock Density (pcs)" name="stockDensityInPcs" rules={[{ required: true }]}>
          <InputNumber inputMode="numeric" />
        </Form.Item>
        <Form.Item label="Est Survival (%)" name="estSurvivalInPerc" rules={[{ required: true }]}>
          <InputNumber inputMode="decimal" />
        </Form.Item>
        <Form.Item label="Est Biomass (kg)" name="estBiomassInKg" rules={[{ required: true }]}>
          <InputNumber inputMode="decimal" />
        </Form.Item>
        <Form.Item label="Running Feed (kg)" name="runningFeedInKg" rules={[{ required: true }]}>
          <InputNumber inputMode="decimal" />
        </Form.Item>
        <Form.Item label="Running FCR" name="runningFCR" rules={[{ required: true }]}>
          <InputNumber inputMode="decimal" />
        </Form.Item>
        <Form.Item label="Weight Gain (g)" name="weightGainInGram">
          <InputNumber inputMode="decimal" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPondSamplingModal;
