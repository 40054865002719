import React from "react";
import { DatePicker, Form, Select } from "antd";
import { harvestShift, sscSource } from "../../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const AddNewForm = (props) => {
  const { form, onSSCSourceChanged } = props;

  return (
    <Form {...layout} form={form} name="add-new-form" labelAlign="left">
      <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item name="harvestShift" label="Shift" rules={[{ required: true }]}>
        <Select allowClear>
          {harvestShift.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="sourceType" label="SSC Source" rules={[{ required: true }]}>
        <Select allowClear onChange={onSSCSourceChanged}>
          {sscSource.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.value}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
