import React, { useEffect } from "react";
import { Form, Select, Row, Col, DatePicker } from "antd";

const { Option } = Select;

const Filter = (props) => {
  const { pondOption, filterPond, setFilterPond, filterDate, setFilterDate, setRefresh } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      pondId: filterPond,
    });
  }, [form, filterPond]);

  return (
    <Row style={{ marginTop: 16 }} gutter={16}>
      <Col>
        <Select
          placeholder="Select Pond #"
          allowClear
          style={{ width: 150 }}
          onChange={(value) => {
            setFilterPond(value);
            setRefresh(true);
          }}
          value={filterPond}
        >
          <Option value="All">All</Option>
          {pondOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <DatePicker.RangePicker
          value={filterDate}
          onChange={(value) => {
            setFilterDate(value);
            setRefresh(true);
          }}
        />
      </Col>
    </Row>
  );
};

export default Filter;
