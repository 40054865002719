import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Steps,
  DatePicker,
  Select,
  Descriptions,
  Divider,
  Row,
  Col,
  Button,
  Space,
  Input,
  Alert,
  InputNumber,
  Typography,
} from "antd";
import { usePondSampling } from "../../hooks/usePondSampling";
import moment from "moment";
import { useGrowOut } from "../../hooks/useGrowOut";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const NewPondSamplingModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  const { findAll } = useGrowOut();
  const { create } = usePondSampling();

  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [pondOption, setPondOption] = useState([]);
  const [selectedPond, setSelectedPond] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [avgBodyWeight, setAvgBodyWeight] = useState();
  const [sampleCaseData, setSampleCaseData] = useState([
    {
      pcs: 0,
      weightInGram: 0,
      avgWeightInGram: 0,
    },
  ]);
  const [stockDensityInPcs, setStockDensityInPcs] = useState();
  const [estSurvivalInPerc, setEstSurvivalInPerc] = useState();
  const [estBiomassInKg, setEstBiomassInKg] = useState();
  const [runningFeedInKg, setRunningFeedInKg] = useState();
  const [runningFCR, setRunningFCR] = useState();
  const [weightGainInGram, setWeightGainInGram] = useState();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(),
      });

      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setPondOption(res.data);
        }
      });
    } else {
      form.resetFields();
      form2.resetFields();
      form3.resetFields();
      setSelectedPond({});
      setSelectedDate(null);
      setAvgBodyWeight(null);
      setSampleCaseData([
        {
          pcs: 0,
          weightInGram: 0,
          avgWeightInGram: 0,
        },
      ]);
      setStockDensityInPcs(null);
      setEstSurvivalInPerc(null);
      setEstBiomassInKg(null);
      setRunningFeedInKg(null);
      setRunningFCR(null);
      setWeightGainInGram(null);
      setSubmitting(false);
      setCurrentStep(0);
    }
  }, [visible, form, findAll]);

  useEffect(() => {
    if (sampleCaseData.length > 0) {
      const totalPcs = sampleCaseData
        .filter((item) => item.pcs > 0 && item.weightInGram > 0)
        .reduce((acc, cur) => {
          return acc + parseInt(cur.pcs);
        }, 0);
      const totalWeight = sampleCaseData
        .filter((item) => item.pcs > 0 && item.weightInGram > 0)
        .reduce((acc, cur) => acc + cur.weightInGram, 0);

      if (totalPcs > 0 && totalWeight > 0) {
        form2.setFieldsValue({
          avgWeightInGram: Number(totalWeight / totalPcs).toFixed(3),
        });
      }
    }
  }, [sampleCaseData]);

  const nextStep = () => {
    if (currentStep === 0) {
      form.validateFields().then((values, err) => {
        if (!err) {
          setSelectedDate(values.recordTimestamp);
          setCurrentStep(currentStep + 1);
        }
      });
    } else if (currentStep === 1) {
      form2.validateFields().then((values, err) => {
        if (!err) {
          setAvgBodyWeight(values.avgWeightInGram);
          setCurrentStep(currentStep + 1);
        }
      });
    } else if (currentStep === 2) {
      form3.validateFields().then((values, err) => {
        if (!err) {
          setStockDensityInPcs(values.stockDensityInPcs);
          setEstSurvivalInPerc(values.estSurvivalInPerc);
          setEstBiomassInKg(values.estBiomassInKg);
          setRunningFeedInKg(values.runningFeedInKg);
          setRunningFCR(values.runningFCR);
          setWeightGainInGram(values.weightGainInGram);
          setCurrentStep(currentStep + 1);
        }
      });
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const updatePcs = (index, value) => {
    const newSampleCaseData = [...sampleCaseData];
    newSampleCaseData[index].pcs = value;
    newSampleCaseData[index].avgWeightInGram = Number(Number(newSampleCaseData[index].weightInGram) / value).toFixed(3);
    setSampleCaseData(newSampleCaseData);
  };

  const updateWeight = (index, value) => {
    const newSampleCaseData = [...sampleCaseData];
    newSampleCaseData[index].weightInGram = value;
    newSampleCaseData[index].avgWeightInGram = Number(value / Number(newSampleCaseData[index].pcs)).toFixed(3);
    setSampleCaseData(newSampleCaseData);
  };

  const updateData = () => {
    const abwInKg = Number(avgBodyWeight / 1000).toFixed(3);
    const stockDensityInPcs = isNaN(form3.getFieldValue("stockDensityInPcs"))
      ? 0
      : form3.getFieldValue("stockDensityInPcs");
    const estSurvivalInPerc = isNaN(form3.getFieldValue("estSurvivalInPerc"))
      ? 0
      : (form3.getFieldValue("estSurvivalInPerc") / 100).toFixed(3);

    const estBiomassInKg = Number(abwInKg * stockDensityInPcs * estSurvivalInPerc).toFixed(3);

    const runningFeedInKg = isNaN(form3.getFieldValue("runningFeedInKg")) ? 0 : form3.getFieldValue("runningFeedInKg");

    const runningFCR = estBiomassInKg > 0 ? Number(runningFeedInKg / estBiomassInKg).toFixed(2) : 0;

    form3.setFieldsValue({
      estBiomassInKg,
      runningFCR,
    });

    setEstBiomassInKg(estBiomassInKg);
    setRunningFCR(runningFCR);
  };

  const handleSubmit = () => {
    setSubmitting(true);

    const params = {
      recordTimestamp: moment(selectedDate).format(),
      avgWeightInGram: avgBodyWeight,
      stockDensityInPcs: stockDensityInPcs,
      estSurvivalInPerc: estSurvivalInPerc,
      estBiomassInKg: estBiomassInKg,
      runningFeedInKg: runningFeedInKg,
      runningFCR: runningFCR,
      weightGainInGram: weightGainInGram,
      pondId: selectedPond.pondId,
      growOutId: selectedPond.id,
      samples: sampleCaseData,
    };

    create(params)
      .then((res) => {
        if (res.status === "success") {
          setRefresh(true);
          setVisible(false);
        }
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "You will lost all unsaved data.",
      content: "Are you sure to continue?",
      okText: "Yes",
      onOk: () => {
        setVisible(false);
      },
      cancelText: "Cancel",
    });
  };

  return (
    <Modal title={"New Sampling"} destroyOnClose visible={visible} onCancel={handleCancel} footer={null}>
      <Steps current={currentStep} size="small">
        <Steps.Step title="Pond" />
        <Steps.Step title="Sampling" />
        <Steps.Step title="More Data" />
        <Steps.Step title="Summary" />
      </Steps>

      {currentStep === 0 ? (
        <>
          <Descriptions bordered size="small" column={1} style={{ marginTop: 16 }}>
            <Descriptions.Item label="Pond">{selectedPond.Pond ? selectedPond.Pond.label : "-"}</Descriptions.Item>
            <Descriptions.Item label="Cycle">{selectedPond.cycleNo ? selectedPond.cycleNo : "-"}</Descriptions.Item>
            <Descriptions.Item label="Material">
              {selectedPond.material ? selectedPond.material : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Stock Density (PCS)">
              {selectedPond.stockDensity ? selectedPond.stockDensity : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Stock Date">
              {selectedPond.startAt ? moment(selectedPond.startAt).format("YYYY-MM-DD") : "-"}
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Form {...layout} labelAlign="left" form={form} name="pond-form">
            <Form.Item name="pondId" label="Pond" rules={[{ required: true }]}>
              <Select
                onChange={(value) => {
                  const pond = pondOption.find((p) => p.id === value);
                  setSelectedPond(pond);
                }}
              >
                {pondOption.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.Pond ? item.Pond.label : "Not available"}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
              <DatePicker allowClear style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </>
      ) : currentStep === 1 ? (
        <>
          <Descriptions bordered size="small" column={1} style={{ marginTop: 16 }}>
            <Descriptions.Item label="Average Body Weight (g)">
              <Form form={form2} name="form2">
                <Form.Item name="avgWeightInGram" rules={[{ required: true }]}>
                  <Input placeholder="Enter here" />
                </Form.Item>
              </Form>
            </Descriptions.Item>
          </Descriptions>

          <Divider orientation="left">Sampling Case Data</Divider>

          <Alert
            style={{ marginBottom: 16 }}
            type="info"
            description="This section is optional. Enter this section will auto calculate Average Body Weight at the top."
          />

          {sampleCaseData.map((item, index) => (
            <Row key={index} justify="space-between" style={{ marginBottom: 8 }}>
              <Col>{index + 1}.</Col>
              <Col>
                <Space>
                  <Typography.Text>ABW (g): {item.avgWeightInGram ? item.avgWeightInGram : "-"}</Typography.Text>
                  <InputNumber
                    inputMode="numeric"
                    placeholder="PCS"
                    value={item.pcs}
                    onChange={(value) => updatePcs(index, value)}
                  />
                  <InputNumber
                    inputMode="decimal"
                    placeholder="Weight (g)"
                    value={item.weightInGram}
                    onChange={(value) => updateWeight(index, value)}
                  />
                </Space>
              </Col>
            </Row>
          ))}

          <Button
            type="primary"
            block
            ghost
            style={{ marginTop: 24 }}
            onClick={() => {
              const newSampleCaseData = [...sampleCaseData];
              const lastItem = newSampleCaseData[newSampleCaseData.length - 1];
              newSampleCaseData.push({
                ...lastItem,
              });
              setSampleCaseData(newSampleCaseData);
            }}
          >
            Add New
          </Button>
        </>
      ) : currentStep === 2 ? (
        <>
          <Form {...layout} labelAlign="left" form={form3} name="form3" style={{ marginTop: 16 }}>
            <Form.Item label="Avg Body Weight (g)">
              <Typography.Text strong>{avgBodyWeight}</Typography.Text>
            </Form.Item>
            <Form.Item label="Stock Density (PCS)" name="stockDensityInPcs" rules={[{ required: true }]}>
              <InputNumber inputMode="numeric" onChange={updateData} />
            </Form.Item>
            <Form.Item label="Est Survival (%)" name="estSurvivalInPerc" rules={[{ required: true }]}>
              <InputNumber inputMode="decimal" onChange={updateData} />
            </Form.Item>
            <Form.Item
              label="Est Biomass (kg)"
              name="estBiomassInKg"
              rules={[{ required: true }]}
              extra="ABW (kg) x Stock Density (pcs) x Surival %"
            >
              <Typography.Text strong>{estBiomassInKg ?? "-"}</Typography.Text>
            </Form.Item>
            <Form.Item label="Running Feed (kg)" name="runningFeedInKg" rules={[{ required: true }]}>
              <InputNumber inputMode="decimal" onChange={updateData} />
            </Form.Item>
            <Form.Item
              label="Running FCR"
              name="runningFCR"
              rules={[{ required: true }]}
              extra="Running Feed (kg) / Est Biomass (kg)"
            >
              <Typography.Text strong>{runningFCR ?? "-"}</Typography.Text>
            </Form.Item>
            <Form.Item label="Weight Gain (g)" name="weightGainInGram">
              <InputNumber inputMode="decimal" />
            </Form.Item>
          </Form>
        </>
      ) : currentStep === 3 ? (
        <Descriptions bordered size="small" column={1} style={{ marginTop: 16 }}>
          <Descriptions.Item label="Date">
            <Typography.Text strong>{moment(selectedDate).format("YYYY-MM-DD")}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Pond">
            <Typography.Text strong>{selectedPond.Pond ? selectedPond.Pond.label : "-"}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Cycle No">
            <Typography.Text strong>{selectedPond.cycleNo ? selectedPond.cycleNo : "-"}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Material">
            <Typography.Text strong>{selectedPond.material ? selectedPond.material : "-"}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Avg Body Weight (g)">
            <Typography.Text strong>{avgBodyWeight}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Stock Density (PCS)">
            <Typography.Text strong>{stockDensityInPcs}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Est Survival (%)">
            <Typography.Text strong>{estSurvivalInPerc}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Est Biomass (kg)">
            <Typography.Text strong>{estBiomassInKg}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Running Feed (kg)">
            <Typography.Text strong>{runningFeedInKg}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Running FCR">
            <Typography.Text strong>{runningFCR}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Weight Gained (g)">
            <Typography.Text strong>{weightGainInGram}</Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      ) : null}

      <Divider />

      <Row justify="space-between">
        <Col>
          <Button onClick={handleCancel}>Cancel</Button>
        </Col>
        <Col>
          <Space>
            {currentStep > 0 && <Button onClick={prevStep}>Previous</Button>}
            {currentStep < 3 && <Button onClick={nextStep}>Next</Button>}
            {currentStep === 3 && (
              <Button type="primary" onClick={handleSubmit} loading={submitting}>
                Submit
              </Button>
            )}
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default NewPondSamplingModal;
