import React, { useState, useEffect } from "react";
import { Descriptions, Row, Col, Divider, Form, DatePicker, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDashboard } from "../../../hooks/useDashboard";
import { sscGrade, sscSize } from "../../../utils/Options";
const moment = require("moment");

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

const MaterialReceipt = (props) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { findTotalRawMat } = useDashboard();

  const [selectedDateRange, setSelectedDateRange] = useState([moment().subtract(7, "day"), moment()]);

  const [total, setTotal] = useState(0);
  const [totalSsc, setTotalSsc] = useState({});

  useEffect(() => {
    if (selectedDateRange) {
      const startDate = moment(selectedDateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(selectedDateRange[1]).format("YYYY-MM-DD");

      findTotalRawMat({ startDate: startDate, endDate: endDate }).then((res) => {
        if (res.status === "success") {
          let obj = {};
          let total = 0;
          for (const factoryReceipt of res.data) {
            for (const detail of factoryReceipt.FactoryReceiptDetails) {
              const grade = detail.grade;
              const size = detail.size;
              const qty = detail.processingQuantity;
              total = total + qty;
              // If grade not exists, push into obj, with the sizes
              if (!obj.hasOwnProperty(grade)) {
                obj = {
                  ...obj,
                  [grade]: {
                    [size]: qty,
                  },
                };
              }
              // If grade already exists, search the size, and increment the total if exists the size, else add the new size
              else {
                if (!obj[grade].hasOwnProperty(size)) {
                  obj[grade] = {
                    ...obj[grade],
                    [size]: qty,
                  };
                } else {
                  obj[grade][size] = Number(obj[grade][size]) + Number(qty);
                }
              }
            }
          }
          setTotalSsc(obj);
          setTotal(total);
        }
      });
    }
  }, [selectedDateRange]);

  const onDateChanged = (value) => {
    if (value) {
      setSelectedDateRange(value);
    }
  };

  return (
    <>
      <Form form={form} layout="inline">
        <Form.Item label={t("common.date")} style={{ lineHeight: "40px" }}>
          <RangePicker style={{ width: 250 }} defaultValue={selectedDateRange} onChange={onDateChanged} />
        </Form.Item>
      </Form>

      <Divider />

      <Title level={5}>Total Soft Shell Crab Received: {total}</Title>

      <Row style={{ marginTop: 32 }}>
        <Col xs={24}>
          {sscGrade.map((grade) => (
            <Descriptions title={"Grade " + grade} key={grade}>
              {sscSize.map((size) => (
                <Descriptions.Item label={size} key={size}>
                  {totalSsc[grade] ? (
                    totalSsc[grade][size] ? (
                      <Text type="success" strong={true}>
                        {totalSsc[grade][size]}
                      </Text>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default MaterialReceipt;
