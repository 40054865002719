import React from "react";
import { DatePicker, Form, Select, Input, InputNumber } from "antd";
import { broodstockStatus } from "../../../utils/Status";

const { Option } = Select;

const EditForm = (props) => {
  const { form, rawMaterialData, speciesOption, sourceOption, locationOption } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="rawMaterialId" label="Raw Material" rules={[{ required: true }]}>
        <Select allowClear>
          {rawMaterialData.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="label" label="Broodstock #">
        <Input />
      </Form.Item>
      <Form.Item name="species" label="Species" rules={[{ required: true }]}>
        <Select allowClear>
          {speciesOption.map((item) => (
            <Option key={item.label} value={item.label}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="stockDate" label="Stock Date">
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="weightInGram" label="Weight (g)" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="source" label="Source">
        <Select allowClear>
          {sourceOption.map((item) => (
            <Option key={item.label} value={item.label}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="arrivalTimestamp" label="Arrival Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="location" label="Broodstock Tank">
        <Select allowClear>
          {locationOption.map((item) => (
            <Option key={item.label} value={item.label}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="releaseDate" label="Release Date">
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="status" label="Status">
        <Select allowClear>
          {Object.keys(broodstockStatus).map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} />
      </Form.Item>
    </Form>
  );
};

export default EditForm;
