import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Form } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useBabyPack } from "../../hooks/useBabyPack";

import BabyPackTable from "./tables/BabyPackTable";
import AddNewModal from "./modals/AddNewModal";
import PackingTable from "./tables/PackingTable";
import AddNewPackingModal from "./modals/AddNewPackingModal";
import EditModal from "./modals/EditPackingModal";
// import EditModal from "./modals/EditModal";
import EditDetailModal from "./modals/EditDetailModal";
import ProductionDrawer from "./drawers/ProductionDrawer";
import Filter from "./components/Filter";
import moment from "moment";

import { useFactoryProduction } from "../../hooks/useFactoryProduction";
import { usePackingBatch } from "../../hooks/usePackingBatch";
import QrCodeModal from "../factoryProduction/modals/QrCodeModal";

const { Title } = Typography;

const BabyPackList = () => {
  const { t } = useTranslation();

  // const { findAll } = useBabyPack();
  const { findAll, retrieveQrDetails } = usePackingBatch();
  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([
    moment().subtract(1, "month"),
    moment(),
  ]);
  const [filterStatus, setFilterStatus] = useState("New");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [addNewPackingModal, setAddNewPackingModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editDetailModal, setEditDetailModal] = useState(false);
  const [productionDrawer, setProductionDrawer] = useState(false);
  const [viewQrCodeModal, setViewQrCodeModal] = useState(false);
  // const [retrieveQr, setViewQrCodeModal] = useState(false);
  const [form] = Form.useForm();

  const viewQrCode = (item) => {
    setEditItem(item);
    setViewQrCodeModal(true);
  };

  const retrieveQr = (item) => {
        setSubmitting(true);

        retrieveQrDetails(item.id).then(async (res) => {
            const reader = res.body.getReader();
            const contentLength = +res.headers.get("Content-Length");

            // Step 3: read the data
            let receivedLength = 0; // received that many bytes at the moment
            let chunks = []; // array of received binary chunks (comprises the body)
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                break;
              }

              chunks.push(value);
              receivedLength += value.length;

              setProgress(
                Number((receivedLength / contentLength) * 100).toFixed(0)
              );
            }

            const file = new Blob(chunks, {
              type: "application/pdf",
            });
            const fileURL = URL.createObjectURL(file);
            // setSubmitting(false);
            // setVisible(false);
            // Open the PDF in a new window
            return window.open(fileURL);
        });

  };

  useEffect(() => {
    if (refresh) {
      const startDate =
        filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate =
        filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [data, editItem]);

  const editRow = (item) => {
    setEditItem(item);
    setEditModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setProductionDrawer(true);
  };

  const addDetail = () => {
    setEditDetailModal(true);
  };

  return (
    <>
      <Filter
        // filterStatus={filterStatus}
        // setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div
        className="site-layout-background"
        style={{ padding: 24, marginTop: 16 }}
      >
        <Title level={2}>Packing Box</Title>

        <Row justify="space-between">
          <Col>
            {/* <Button type="primary" onClick={() => setAddNewModal(true)}> */}
            <Button type="primary" onClick={() => setAddNewPackingModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button
              icon={<ReloadOutlined />}
              disabled={loading}
              onClick={() => setRefresh(true)}
            />
          </Col>
        </Row>

        <PackingTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          viewDetail={viewDetail}
          viewQrCode={viewQrCode}
          retrieveQr={retrieveQr}
        />

        <AddNewPackingModal
          visible={addNewPackingModal}
          setVisible={setAddNewPackingModal}
          setRefresh={setRefresh}
        />
        {/* <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} /> */}

        <EditModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />

        <EditDetailModal
          editItem={editItem}
          visible={editDetailModal}
          setVisible={setEditDetailModal}
          setRefresh={setRefresh}
        />

        <QrCodeModal
          editItem={editItem}
          visible={viewQrCodeModal}
          setVisible={setViewQrCodeModal}
          setRefresh={setRefresh}
        />

        <ProductionDrawer
          editItem={editItem}
          visible={productionDrawer}
          setVisible={setProductionDrawer}
          addDetail={addDetail}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default BabyPackList;
