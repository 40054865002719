import React, { useEffect } from "react";
import { Card, Form, Select, Button, DatePicker, Input } from "antd";
import { factoryProductionStatus } from "../../../utils/Options";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Filter = (props) => {
  const { filterBatchNo, setFilterBatchNo, filterDate, setFilterDate, filterStatus, setFilterStatus, setRefresh } =
    props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      batchNo: filterBatchNo,
      date: filterDate,
      status: filterStatus,
    });
  }, [form]);

  const onFinish = (values) => {
    setFilterBatchNo(values.batchNo);
    setFilterDate(values.date);
    setFilterStatus(values.status);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Date" name="date">
          <RangePicker />
        </Form.Item>

        <Form.Item label="Batch" name="batchNo">
          <Input allowClear />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select allowClear style={{ width: 150 }}>
            <Option value="All">All</Option>
            {factoryProductionStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
