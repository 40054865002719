import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import EditForm from "./forms/EditForm";
import { useHatcheryProduction } from "../../hooks/useHatcheryProduction";

const EditHatcheryHarvestModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useHatcheryProduction();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        productionTimestamp: moment(editItem.productionTimestamp),
        quantity: editItem.quantity,
        tankUsageId: editItem.tankUsageId,
        avgWeightInGram: editItem.avgWeightInGram,
        batchNo: editItem.batchNo,
        broodstockNo: editItem.broodstockNo,
        broodstockSpecies: editItem.broodstockSpecies,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          productionTimestamp: moment(values.productionTimestamp).format(),
          quantity: values.quantity,
          tankUsageId: values.tankUsageId,
          avgWeightInGram: values.avgWeightInGram,
          batchNo: values.batchNo,
          broodstockNo: values.broodstockNo,
          broodstockSpecies: values.broodstockSpecies,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Hatchery Harvest"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <EditForm form={form} />
    </Modal>
  );
};

export default EditHatcheryHarvestModal;
