import React from "react";
import { Tooltip, Typography, Dropdown, Menu, Button } from "antd";
import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import moment from "moment";
import QRCode from "qrcode.react";
import { QrcodeOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";

const { Link } = Typography;

const RecipeTable = (props) => {
  const {
    dataSource,
    loading,
    error,
    editRow,
    viewDetail,
    viewQrCode,
    retrieveQr,
  } = props;

  const maxLength = 200; // Maximum length of the text to display

  const columns = [
    {
      title: "Title",
      render: (text, row) => (row.title ? row.title : "N/A"),
    },
    {
      title: "Short Description",
      render: (text, row) =>
        row.shortDescription ? row.shortDescription : "N/A",
    },
    {
      title: "Content",
      render: (text, row) => {
        if (row.content) {
          const shortenedData =
            row.content.length > maxLength
              ? `${row.content.slice(0, maxLength)}...`
              : row.content;
          return (
            <div dangerouslySetInnerHTML={{ __html: shortenedData }}></div>
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined
            style={{ color: blue.primary }}
            onClick={() => editRow(row)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
    />
  );
};

export default RecipeTable;
