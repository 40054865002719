import { useCallback, useContext } from 'react';
import { AuthContext, fp } from '../App';
import Constants from "../utils/Constants";
import { useFetch } from '../hooks/useFetch';

const apiBaseUrl = Constants.SERVER_URL;

const usePondDODailyRecord = () => {

    const { authState } = useContext(AuthContext);

    const { send } = useFetch();


    const create = useCallback(async (params) => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params)
        }

        return await send(apiBaseUrl + '/wapi/pondDODailyRecords', config)
    }, [authState.token, send])

    const update = useCallback(async (id, params) => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params)
        }

        return await send(apiBaseUrl + '/wapi/pondDODailyRecords/' + id, config)
    }, [authState.token, send])


    return {
        create,
        update
    };

};


export { usePondDODailyRecord };