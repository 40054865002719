import React, { useState, useEffect } from "react";
import { Modal, Form, Input, InputNumber, Select } from "antd";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import { deliveryProductType, farmProductionType, sscGrade, sscSize } from "../../../utils/Options";
import { useSSCHarvest } from "../../../hooks/useSSCHarvest";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const AddDetailModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findOne } = useSSCHarvest();

  const { addDetail } = useDeliveryOrder();

  const [isSSC, setIsSSC] = useState(false);
  const [sscBalance, setSSCBalance] = useState();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible && editItem.sscHarvestDailyId) {
      setIsSSC(true);
      findOne(editItem.sscHarvestDailyId).then((res) => {
        if (res.status === "success") {
          setSSCBalance(res.data.balance);
        }
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          type: values.type,
          grade: values.grade,
          size: values.size,
          quantity: values.quantity,
          weightInKg: values.weightInKg,
          remarks: values.remarks,
        };

        addDetail(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"New Order Item"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="type" label="Type">
          <Select allowClear>
            {isSSC ? (
              <Option key={"Soft Shell Crab"} value={"Soft Shell Crab"}>
                {"Soft Shell Crab"}
              </Option>
            ) : (
              deliveryProductType.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>
        <Form.Item name="grade" label="Grade">
          <Select allowClear>
            {sscGrade.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="size" label="Size">
          <Select allowClear>
            {sscSize.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[{ required: true }]}
          extra={isSSC ? sscBalance + " remaining" : null}
        >
          <InputNumber min={0} max={sscBalance} allowClear />
        </Form.Item>
        <Form.Item name="weightInKg" label="Weight (kg)">
          <InputNumber min={0} allowClear />
        </Form.Item>
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDetailModal;
