import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFarmReceipt } from "../../hooks/useFarmReceipt";

import Filter from "../../components/farmReceipt/components/Filter";

import NewExternalToFarmModal from "../../components/farmReceipt/modals/NewExternalToFarmModal";
import EditExternalToFarmModal from "../../components/farmReceipt/modals/EditExternalToFarmModal";
import ExternalToFarmTable from "../../components/farmReceipt/tables/ExternalToFarmTable";
import ExternalPontoonStockInModal from "../../components/farmReceipt/modals/ExternalPontoonStockInModal";

const { Title } = Typography;

const ExternalToFarm = () => {
  const { t } = useTranslation();

  const { findAll } = useFarmReceipt();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterStatus, setFilterStatus] = useState("New");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [stockInModal, setStockInModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll({ status: filterStatus, sourceType: "External" })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterStatus, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editTransferToHapa = (row) => {
    setEditItem(row);
    setStockInModal(true);
  };

  return (
    <>
      <Filter filterStatus={filterStatus} setFilterStatus={setFilterStatus} setRefresh={setRefresh} />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>External To Nursery Farm Transfer</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <ExternalToFarmTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          editTransferToHapa={editTransferToHapa}
        />

        <NewExternalToFarmModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditExternalToFarmModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />

        <ExternalPontoonStockInModal
          editItem={editItem}
          visible={stockInModal}
          setVisible={setStockInModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default ExternalToFarm;
