import React from "react";
import { Descriptions, Tag } from "antd";
import moment from "moment";

const BroodstockInfo = (props) => {
  const { broodstockInfo } = props;

  return (
    <Descriptions title="Information">
      <Descriptions.Item label="Broodstock No.">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.label ? item.label : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Species">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.species ? item.species : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Source">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.source ? item.source : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Weight (g)">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.weightInGram ? item.weightInGram : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Arrival Date">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.arrivalTimestamp ? moment(item.arrivalTimestamp).format("YYYY-MM-DD") : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Stock Date">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.stockDate ? moment(item.stockDate).format("YYYY-MM-DD") : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Location">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.location ? item.location : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Remarks">
        {broodstockInfo.map((item) => (
          <Tag key={item.id}>{item.remarks ? item.remarks : "-"}</Tag>
        ))}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default BroodstockInfo;
