import React, { useState, useEffect, useContext } from "react";
import { Drawer, Descriptions, Row, Col, Typography } from "antd";
import { useFactoryFreezing } from "../../hooks/useFactoryFreezing";
import moment from "moment";
import { ResponsiveContext } from "../../App";

const { Title } = Typography;

const FactoryFreezingDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne } = useFactoryFreezing();

  const [data, setData] = useState({});

  useEffect(() => {
    if (visible && id) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
      });
    }
  }, [visible]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "70%"}>
      <Row justify="space-between" style={{ marginTop: 16, marginBottom: 16 }}>
        <Col>
          <Title level={4}>{`SSC Freezing #${id}`}</Title>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col span={24}>
          <Descriptions>
            <Descriptions.Item label="Date">{moment(data.startTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
            <Descriptions.Item label="Batch No">{data.FactoryProductionRecord?.batchNo}</Descriptions.Item>
            <Descriptions.Item label="Freezer No">{data.freezerNo}</Descriptions.Item>
            <Descriptions.Item label="Start Time">{moment(data.startTimestamp).format("h:mm a")}</Descriptions.Item>
            <Descriptions.Item label="End Time">{moment(data.endTimestamp).format("h:mm a")}</Descriptions.Item>
            <Descriptions.Item label="Duration">
              {parseInt(moment.duration(moment(data.endTimestamp).diff(moment(data.startTimestamp))).asHours())} hours{" "}
              {moment.duration(moment(data.endTimestamp).diff(moment(data.startTimestamp))).asMinutes() % 60} minutes
            </Descriptions.Item>
            <Descriptions.Item label="Freezer Temperature (C)">{data.freezerTemperature}</Descriptions.Item>
            <Descriptions.Item label="Product Temperature (C)">{data.productTemperature}</Descriptions.Item>
            <Descriptions.Item label="Remarks">{data.remarks ? data.remarks : "-"}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Drawer>
  );
};

export default FactoryFreezingDrawer;
