import React from "react";
import { Form, Select, Input } from "antd";
import { hatcheryType } from "../../../utils/Options";

const { Option } = Select;

const AddNewForm = (props) => {
  const { form } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select allowClear>
          {hatcheryType.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
