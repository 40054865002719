import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

const OpenTransferTable = (props) => {
  const { t } = useTranslation();

  const { dataSource, setSelectedRows } = props;

  const columns = [
    {
      title: "Batch",
      dataIndex: "batchNo",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Production Date",
      dataIndex: "productionTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Grade",
      dataIndex: "grade",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Weight Range(KG)",
      dataIndex: "weightRangeInKg",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  return (
    <Table
      size="small"
      rowKey="id"
      rowSelection={{
        ...rowSelection,
      }}
      columns={columns}
      dataSource={dataSource}
      style={{ marginTop: 24 }}
    />
  );
};

export default OpenTransferTable;
