import React from "react";
import { Button, Typography, Space, Dropdown, Menu, Tooltip } from "antd";
import {
  EditOutlined,
  EnterOutlined,
  EllipsisOutlined,
  FileDoneOutlined,
  DeleteOutlined,
  QrcodeOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const FactoryProductionTable = (props) => {
  const {
    dataSource,
    loading,
    error,
    editRow,
    freezeRow,
    createDO,
    viewDetail,
    deleteRow,
    assignQR,
    // viewQrCode,
  } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, row) => (
        <Space>
          {text}
          {/* {row.antChainTraceCode && (
            <Button type="link" icon={<BlockOutlined />} href={row.antChainTraceCode} target="_blank" />
          )} */}
        </Space>
      ),
    },

    {
      title: "Batch No",
      dataIndex: "batchNo",
      render: (text, row) =>
        text ? (
          <Typography.Link onClick={() => viewDetail(row)}>
            {text}
          </Typography.Link>
        ) : (
          "-"
        ),
    },
    {
      title: "Date",
      dataIndex: "productionTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Total PCS",
      render: (text, row) =>
        row.FactoryProductionDetails ? (
          <>
            {row.FactoryProductionDetails.reduce(
              (acc, cur) => acc + cur.balanceQuantity,
              0
            )}
            <sub>remains</sub>
            &nbsp;/&nbsp;
            {row.FactoryProductionDetails.reduce(
              (acc, cur) => acc + cur.quantity,
              0
            )}
            <sub> pcs</sub>
          </>
        ) : (
          "-"
        ),
    },
    {
      title: "Total Weight",
      render: (text, row) =>
        row.FactoryProductionDetails ? (
          <>
            {Number(
              row.FactoryProductionDetails.reduce(
                (acc, cur) => acc + cur.balanceWeightInKg,
                0
              )
            ).toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}
            <sub>remains</sub>
            &nbsp;/&nbsp;
            {Number(
              row.FactoryProductionDetails.reduce(
                (acc, cur) => acc + cur.weightInKg,
                0
              )
            ).toLocaleString(undefined, {
              minimumFractionDigits: 3,
              maximumFractionDigits: 3,
            })}
            <sub> kg</sub>
          </>
        ) : (
          "-"
        ),
    },
    // {
    //   title: "Generated QR Codes",
    //   render: (text, row) => (
    //     <div style={{ display: "flex", justifyContent: "center" }}>
    //       <Tooltip title="View Generated QR Codes">
    //         <EyeOutlined onClick={() => viewQrCode(row)} />
    //       </Tooltip>
    //     </div>
    //   ),
    // },
    {
      title: "Submitted By",
      render: (text, row) =>
        row.createdBy ? row.createdBy.split(":::")[1] : "-",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="edit"
                icon={<EditOutlined />}
                onClick={() => editRow(row)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="freeze"
                icon={<EnterOutlined />}
                onClick={() => freezeRow(row)}
                disabled={row.status !== "New"}
              >
                Blast Freeze
              </Menu.Item>
              <Menu.Item
                key="delivery"
                icon={<FileDoneOutlined />}
                onClick={() => createDO(row)}
                disabled={row.status !== "Frozen"}
              >
                Create Order
              </Menu.Item>

              {/* <Menu.Item
                key="assignQR"
                icon={<QrcodeOutlined />}
                onClick={() => assignQR(row)}
              >
                Generate QR Code
              </Menu.Item> */}

              <Menu.Item
                key="delete"
                icon={<DeleteOutlined />}
                onClick={() => deleteRow(row)}
                danger
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
      // expandable={{
      //   expandedRowRender: (row) => (
      //     <Link href={row.QRCode.antChainTraceCode} target="_blank">
      //       {row.QRCode.antChainTraceCode}
      //     </Link>
      //   ),
      //   rowExpandable: (row) => row.QRCode && row.QRCode.antChainTraceCode !== null,
      // }}
    />
  );
};

export default FactoryProductionTable;
