import React, { useContext } from "react";
import { Descriptions, Row, Col, Typography, Tag } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ResponsiveContext } from "../../App";

const { Title } = Typography;

const ProcessingOrderInfo = (props) => {
  const { item } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>Order #{item.id}</Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions>
            <Descriptions.Item label="Date">{moment(item.orderDate).format("YYYY-MM-DD")}</Descriptions.Item>
            <Descriptions.Item label="Sold To">{item.deliverTo}</Descriptions.Item>
            <Descriptions.Item label="Location">{item.location ? item.location : "-"}</Descriptions.Item>
            <Descriptions.Item label="Box ID" span={3}>
              {item.ProductionBatches ? item.ProductionBatches.map((item) => <Tag>{item.PackingBatches[0]["packingBatchNo"]}</Tag>) : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Batch No" span={3}>
              {item.ProductionBatches ? item.ProductionBatches.map((item) => <Tag>{item.batchNo}</Tag>) : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Remarks" span={3}>
              {item.remarks ? item.remarks : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right" }}>{t("common.status")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>{item.status ? item.status : "-"}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ProcessingOrderInfo;
