import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Select, Space, Descriptions } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import DailyRecordTable from "./tables/DailyRecordTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import moment from "moment";
import { usePondActivity } from "../../hooks/usePondActivity";

const { Title } = Typography;
const { Option } = Select;

const PondDODailyRecord = () => {
  const { t } = useTranslation();

  const { findOperatingPond, findDODailyRecordById } = usePondActivity();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [pondOption, setPondOption] = useState([]);
  const [selectedPond, setSelectedPond] = useState({});
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findOperatingPond()
        .then((res) => {
          if (res.status === "success") {
            setPondOption(res.data);
          }
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));

      if (selectedPond) {
        findDODailyRecordById(selectedPond.id).then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        });
      }
    }
  }, [refresh, findDODailyRecordById, findOperatingPond, selectedPond, t]);

  const editRow = (item) => {
    setEditItem(item);
    setEditModal(true);
  };

  const onPondChange = (e) => {
    const index = pondOption.map((item) => item.id).indexOf(e);

    if (index > -1) {
      setSelectedPond(pondOption[index]);
      setRefresh(true);
    }
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>Pond Dissolved Oxygen (DO) Daily Record</Title>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setAddNewModal(true)}>
            New
          </Button>
        </Col>
        <Col>
          <Space>
            <Select placeholder="Select a Pond" onChange={onPondChange} style={{ width: 200 }}>
              {pondOption.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.Pond.label}
                </Option>
              ))}
            </Select>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Space>
        </Col>
      </Row>

      <Descriptions title="Information" style={{ marginTop: 24, marginBottom: 16 }}>
        <Descriptions.Item label="Pond">{selectedPond.Pond?.label ?? "-"}</Descriptions.Item>

        <Descriptions.Item label="Stock In Timestamp">
          {selectedPond.stockInTimestamp ? moment(selectedPond.stockInTimestamp).format("lll") : "-"}
        </Descriptions.Item>
      </Descriptions>

      <DailyRecordTable error={error} loading={loading} dataSource={data} tankData={selectedPond} editRow={editRow} />

      <AddNewModal
        selectedPond={selectedPond}
        visible={addNewModal}
        setVisible={setAddNewModal}
        setRefresh={setRefresh}
      />

      <EditModal item={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
    </div>
  );
};

export default PondDODailyRecord;
