import React from "react";
import { List, Card, Button, Space, Row, Col, Divider, Typography } from "antd";
import moment from "moment";

const PondHarvestList = (props) => {
  const { data, editRow, deleteRow } = props;
  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 5 }}
      dataSource={data}
      pagination={{
        onChange: (page) => {
          // console.log(page);
        },
        pageSize: 10,
      }}
      renderItem={(item) => (
        <List.Item>
          <Card title={item.Pond?.label} extra={moment(item.recordTimestamp).format("MMM, D")}>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Stock Date</Typography.Text>
              </Col>
              <Col>{item.GrowOut ? moment(item.GrowOut.startAt).format("YYYY-MM-DD") : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Stock Density (pcs)</Typography.Text>
              </Col>
              <Col>
                {item.GrowOut && item.GrowOut.stockingDensity
                  ? Number(item.GrowOut.stockingDensity).toLocaleString()
                  : "-"}
              </Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Total Harvest (pcs)</Typography.Text>
              </Col>
              <Col>{item.totalHarvestInPcs ? item.totalHarvestInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Small (to ponds)</Typography.Text>
              </Col>
              <Col>{item.totalSmallInPcs ? item.totalSmallInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">SSC</Typography.Text>
              </Col>
              <Col>{item.totalSSCInPcs ? item.totalSSCInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">HSC</Typography.Text>
              </Col>
              <Col>{item.totalHSCInPcs ? item.totalHSCInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Shrimp</Typography.Text>
              </Col>
              <Col>{item.totalShrimpInPcs ? item.totalShrimpInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Dead</Typography.Text>
              </Col>
              <Col>{item.totalDeadInPcs ? item.totalDeadInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Broodstock</Typography.Text>
              </Col>
              <Col>{item.totalBroodstockInPcs ? item.totalBroodstockInPcs : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">To Green House</Typography.Text>
              </Col>
              <Col>{item.totalGreenhouseInPcs ? item.totalGreenhouseInPcs : "-"}</Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Total Weight (kg)</Typography.Text>
              </Col>
              <Col>{item.totalHarvestInKg ? item.totalHarvestInKg : "-"}</Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">ABW (g)</Typography.Text>
              </Col>
              <Col>{item.avgWeightInGram ? item.avgWeightInGram : "-"}</Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Survival Rate (%)</Typography.Text>
              </Col>
              <Col>{item.survivalRate ? item.survivalRate : "-"}</Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Total Feed (kg)</Typography.Text>
              </Col>
              <Col>{item.totalFeedInKg ? item.totalFeedInKg : "-"}</Col>
            </Row>

            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">FCR</Typography.Text>
              </Col>
              <Col>{item.fcr ? item.fcr : "-"}</Col>
            </Row>

            <Divider />

            <Row style={{ marginTop: 16 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button type="primary" ghost block onClick={() => editRow(item)}>
                  Edit
                </Button>
                <Button block danger onClick={() => deleteRow(item)}>
                  Delete
                </Button>
              </Space>
            </Row>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default PondHarvestList;
