import React, { useState, useEffect } from "react";
import { Card, List, Typography, Tag } from "antd";
import { useDashboard } from "../../../hooks/useDashboard";
import DailyRecordDrawer from "../../larvalDailyRecord/drawers/DailyRecordDrawer";
const { Title } = Typography;

const LarvalStage = (props) => {
  const { setRefresh } = props;
  const { findLarvalStage } = useDashboard();

  const [data, setData] = useState([]);

  const [editItem, setEditItem] = useState();
  const [dailyRecordDrawer, setDailyRecordDrawer] = useState(false);

  useEffect(() => {
    findLarvalStage().then((res) => {
      if (res.status === "success") {
        let array = [];

        for (const item of res.data) {
          if (item.TankDailyRecords && item.TankDailyRecords.length) {
            const stage = item.TankDailyRecords[0].larvalStage;

            const index = array.map((item) => item.stage).indexOf(stage);

            if (index > -1) {
              array[index].tanks.push({
                item: item,
                label: item.Tank.label,
              });
            } else {
              array.push({
                stage: stage,
                tanks: [
                  {
                    item: item,
                    label: item.Tank.label,
                  },
                ],
              });
            }
          }
        }
        setData(array);
      }
    });
  }, [findLarvalStage]);

  const viewDetail = (item) => {
    setEditItem(item.item);
    setDailyRecordDrawer(true);
  };

  return (
    <>
      <Card>
        <List
          size="small"
          itemLayout="vertical"
          header={<Title level={5}>Total Larval Tank by Stage</Title>}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta title={"Stage " + item.stage + " ( Total: " + item.tanks.length + ")"} />
              {item.tanks.map((tank) => (
                <Tag key={tank.item.id} style={{ marginBottom: 8, cursor: "pointer" }} onClick={() => viewDetail(tank)}>
                  {tank.label}
                </Tag>
              ))}
            </List.Item>
          )}
          pagination={{
            pageSize: 5,
          }}
        />
      </Card>

      <DailyRecordDrawer
        item={editItem}
        visible={dailyRecordDrawer}
        setVisible={setDailyRecordDrawer}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default LarvalStage;
