import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useLarvalData = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findLarvalStage = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await send(apiBaseUrl + "/wapi/tankDailyRecords/larvalStage", config);
  }, [authState.token, send]);

  const findLarvalDataOption = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await send(apiBaseUrl + "/wapi/tankDailyRecords/larvalDataOption", config);
  }, [authState.token, send]);

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id, config);
    },
    [authState.token, send]
  );

  const updateDensity = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateLarvalDensity", config);
    },
    [authState.token, send]
  );

  const updateSalinity = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateSalinity", config);
    },
    [authState.token, send]
  );

  const updateTemperature = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateTemperature", config);
    },
    [authState.token, send]
  );

  const updateArtemia = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateArtemia", config);
    },
    [authState.token, send]
  );

  const updateMicroparticulate = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateMicroparticulate", config);
    },
    [authState.token, send]
  );

  const updateWaterTreatment = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateWaterTreatment", config);
    },
    [authState.token, send]
  );

  const updateRemarks = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/updateRemarks", config);
    },
    [authState.token, send]
  );

  const duplicateRow = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/tankDailyRecords/" + id + "/duplicateRow", config);
    },
    [authState.token, send]
  );

  return {
    findLarvalStage,
    findLarvalDataOption,
    updateDensity,
    updateSalinity,
    updateTemperature,
    updateArtemia,
    updateMicroparticulate,
    updateWaterTreatment,
    updateRemarks,
    create,
    update,
    duplicateRow,
  };
};

export { useLarvalData };
