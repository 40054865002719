import React, { useState, useEffect } from "react";
import { Modal, Form, DatePicker, Select, Input, InputNumber } from "antd";
import { harvestShift } from "../../../utils/Options";
import moment from "moment";
import { useSSCHarvestRecord } from "../../../hooks/useSSCHarvestRecord";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditSSCHarvestRecordModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useSSCHarvestRecord();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      const gradeA = editItem.SSCHarvestRecordDetails.find((el) => el.grade === "A");
      const gradeAF = editItem.SSCHarvestRecordDetails.find((el) => el.grade === "AF");
      const gradeBF = editItem.SSCHarvestRecordDetails.find((el) => el.grade === "BF");
      form.setFieldsValue({
        harvestDate: moment(editItem.harvestDate),
        // quantity: editItem.quantity,
        gradeA: gradeA ? gradeA.quantity : 0,
        gradeAF: gradeAF ? gradeAF.quantity : 0,
        gradeBF: gradeBF ? gradeBF.quantity : 0,
        harvestShift: editItem.harvestShift,
        remarks: editItem.remarks,
        status: editItem.status,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          harvestDate: moment(values.harvestDate).format(),
          // quantity: values.quantity,
          gradeA: values.gradeA,
          gradeAF: values.gradeAF,
          gradeBF: values.gradeBF,
          harvestShift: values.harvestShift,
          remarks: values.remarks,
          status: values.status,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit SSC Harvest Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="edit-form">
        <Form.Item name="harvestDate" label="Harvest Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="harvestShift" label="Harvest Shift">
          <Select allowClear>
            {harvestShift.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="gradeA" label="Grade A" extra="pcs">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="gradeAF" label="Grade AF" extra="pcs">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="gradeBF" label="Grade BF" extra="pcs">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
        {/* 
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select allowClear>
            {farmProductionStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default EditSSCHarvestRecordModal;
