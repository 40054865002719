import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import Filter from "../../components/sscHarvestRecord/components/Filter";
import SSCHarvestTable from "../../components/sscHarvestRecord/tables/SSCHarvestTable";
import EditSSCHarvestRecordModal from "../../components/sscHarvestRecord/modals/EditSSCHarvestRecordModal";

import moment from "moment";
import { useSSCHarvest } from "../../hooks/useSSCHarvest";
import NewSSCDeliveryOrderModal from "../../components/deliveryOrder/modals/NewSSCDeliveryOrderModal";
import SSCHarvestDrawer from "../../components/sscHarvest/SSCHarvestDrawer";
import NewFarmStorageModal from "../../components/farmStorage/NewFarmStorageModal";

const { Title } = Typography;

const SSCHarvest = () => {
  const { t } = useTranslation();

  const { findAll, consolidate } = useSSCHarvest();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(3, "month"), moment()]);
  const [filterStatus, setFilterStatus] = useState("All");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [newDeliveryOrderModal, setNewDeliveryOrderModal] = useState(false);
  const [newFarmStorageModal, setNewFarmStorageModal] = useState(false);
  const [editSSCHarvestRecordModal, setEditSSCHarvestRecordModal] = useState(false);
  const [sscHarvestDrawer, setSSCHarvestDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, findAll, t]);

  const createDeliveryOrder = (row) => {
    setEditItem(row);
    setNewDeliveryOrderModal(true);
  };

  const storeInFridge = (row) => {
    setEditItem(row);
    setNewFarmStorageModal(true);
  };

  const editHarvestRecord = (row) => {
    setEditItem(row);
    setEditSSCHarvestRecordModal(true);
  };

  const doConsolidate = (row) => {
    consolidate(row.id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  const viewDetail = (row) => {
    setEditItem(row);
    setSSCHarvestDrawer(true);
  };

  return (
    <>
      <Filter
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Soft Shell Crab Harvest</Title>

        <Row justify="end">
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <SSCHarvestTable
          error={error}
          loading={loading}
          dataSource={data}
          view={viewDetail}
          createDeliveryOrder={createDeliveryOrder}
          editHarvestRecord={editHarvestRecord}
          consolidate={doConsolidate}
          storeInFridge={storeInFridge}
        />

        <EditSSCHarvestRecordModal
          editItem={editItem}
          visible={editSSCHarvestRecordModal}
          setVisible={setEditSSCHarvestRecordModal}
          setRefresh={setRefresh}
        />

        <NewFarmStorageModal
          sscHarvestItem={editItem}
          visible={newFarmStorageModal}
          setVisible={setNewFarmStorageModal}
          setRefresh={setRefresh}
        />

        <NewSSCDeliveryOrderModal
          sscHarvestItem={editItem}
          visible={newDeliveryOrderModal}
          setVisible={setNewDeliveryOrderModal}
          setRefresh={setRefresh}
        />

        <SSCHarvestDrawer
          id={editItem.id}
          visible={sscHarvestDrawer}
          setVisible={setSSCHarvestDrawer}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default SSCHarvest;
