import React, { useState, useEffect } from "react";
import { Descriptions, Divider } from "antd";

const ChemicalUsage = (props) => {
  const { data } = props;

  const [artemiaUsage, setArtemiaUsage] = useState();
  const [oreganoUsage, setOreganoUsage] = useState();
  const [k5InGramUsage, setK5InGramUsage] = useState();
  const [mplusUsage, setMplusUsage] = useState();
  const [edtaUsage, setEdtaUsage] = useState();
  const [tystatinUsage, setTystatinUsage] = useState();
  const [cifroUsage, setCifroUsage] = useState();
  const [superMsUsage, setSuperMsUsage] = useState();
  const [shrimpUsage, setShrimpUsage] = useState();
  const [germFreeUsage, setGermFreeUsage] = useState();

  useEffect(() => {
    setArtemiaUsage(
      data.map((item) => Number(item.artemiaFedInGram1) + Number(item.artemiaFedInGram2)).reduce((a, b) => a + b, 0)
    );
    setOreganoUsage(data.map((item) => item.oreganoInMl).reduce((a, b) => a + b, 0));
    setK5InGramUsage(data.map((item) => item.k5InGram).reduce((a, b) => a + b, 0));
    setMplusUsage(data.map((item) => item.mplus).reduce((a, b) => a + b, 0));
    setEdtaUsage(data.map((item) => item.edta).reduce((a, b) => a + b, 0));
    setTystatinUsage(data.map((item) => item.tystatin).reduce((a, b) => a + b, 0));
    setCifroUsage(data.map((item) => item.cifro).reduce((a, b) => a + b, 0));
    setSuperMsUsage(data.map((item) => item.superms).reduce((a, b) => a + b, 0));
    setShrimpUsage(data.map((item) => item.shrimpFavour).reduce((a, b) => a + b, 0));
    setGermFreeUsage(data.map((item) => item.germFree).reduce((a, b) => a + b, 0));
  }, [data]);

  return (
    <>
      <Descriptions title="Artemia" style={{ marginTop: 24, marginBottom: 16 }}>
        <Descriptions.Item label="Artemia (g)">{artemiaUsage ? artemiaUsage.toLocaleString() : "-"}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions title="Treatment">
        <Descriptions.Item label="Oregano (ml)">{oreganoUsage ? oreganoUsage.toLocaleString() : "-"}</Descriptions.Item>
        <Descriptions.Item label="K-5 (g)">{k5InGramUsage ? k5InGramUsage.toLocaleString() : "-"}</Descriptions.Item>
        <Descriptions.Item label="M-Plus (g)">{mplusUsage ? mplusUsage.toLocaleString() : "-"}</Descriptions.Item>
        <Descriptions.Item label="EDTA (g)">{edtaUsage ? edtaUsage.toLocaleString() : "-"}</Descriptions.Item>
        <Descriptions.Item label="Tystatin (ml)">
          {tystatinUsage ? tystatinUsage.toLocaleString() : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Cifro (tablets)">{cifroUsage ? cifroUsage.toLocaleString() : "-"}</Descriptions.Item>
        <Descriptions.Item label="Super MS (ml)">
          {superMsUsage ? superMsUsage.toLocaleString() : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Shrimp Favour (g)">
          {shrimpUsage ? shrimpUsage.toLocaleString() : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="GERM Free (tablets)">
          {germFreeUsage ? germFreeUsage.toLocaleString() : "-"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ChemicalUsage;
