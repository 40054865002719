import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useTank } from "../../../hooks/useTank";
import { useTankTransfer } from "../../../hooks/useTankTransfer";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findTank } = useTank();
  const { findAll, create } = useTankTransfer();

  const [submitting, setSubmitting] = useState(false);

  const [tankOption, setTankOption] = useState([]);
  const [sourceTankUsageOption, setSourceTankUsageOption] = useState([]);

  useEffect(() => {
    if (visible) {
      findTank({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setTankOption(res.data);
        }
      });
      findAll("New").then((res) => {
        if (res.status === "success") {
          setSourceTankUsageOption(res.data);
        }
      });

      form.setFieldsValue({
        stockInTimestamp: moment(),
      });
    }
  }, [visible, form, findAll, findTank]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        // return;
        setSubmitting(true);

        const params = {
          batchNo: values.batchNo,
          broodstockNo: values.broodstockNo,
          tankId: values.tankId,
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          stockInQuantity: values.stockInQuantity,
          // incubationRecordId: values.incubationRecordId,
          sourceTankUsageId: values.sourceTankUsageId,
          hatchDate: moment(values.hatchDate).format(),
          remarks: values.remarks,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Tank Transfer"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} tankOption={tankOption} sourceTankUsageOption={sourceTankUsageOption} />
    </Modal>
  );
};

export default AddNewModal;
