import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useHatcheryTransfer = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl +
          "/wapi/hatcheryTransfers?status=" +
          query.status +
          "&startDate=" +
          query.startDate +
          "&endDate=" +
          query.endDate +
          "&farmId=" +
          query.farmId,
        config
      );
    },
    [authState.token, send]
  );

  // const findOpenTransfer = useCallback(async () => {
  //   const config = {
  //     method: "get",
  //     headers: {
  //       Authorization: "Bearer " + authState.token,
  //       "Content-Type": "application/json",
  //       FpId: (await fp).visitorId,
  //     },
  //   };

  //   return await send(apiBaseUrl + "/wapi/hatcheryTransfers/open", config);
  // }, [authState.token, send]);

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/hatcheryTransfers", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/hatcheryTransfers/" + id, config);
    },
    [authState.token, send]
  );

  const exportToExcel = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/hatcheryTransfers/export/excel", config);
    },
    [authState.token, send]
  );

  return {
    findAll,
    // findOpenTransfer,
    create,
    update,
    exportToExcel,
  };
};

export { useHatcheryTransfer };
