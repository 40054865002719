import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFactoryFreezing } from "../../hooks/useFactoryFreezing";

import FactoryFreezingTable from "./tables/FactoryFreezingTable";
import NewFactoryFreezingModal from "./NewFactoryFreezingModal";
import EditModal from "./modals/EditModal";
import EditDetailModal from "./modals/EditDetailModal";
import Filter from "./components/Filter";

import moment from "moment";
import ProductionDrawer from "./drawers/ProductionDrawer";
import { BlastFreezerTemperature } from "../../haccp/BlastFreezerTemperature";
import FactoryFreezingDrawer from "./FactoryFreezingDrawer";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";

const { Title } = Typography;

const FactoryFreezingList = () => {
  const { t } = useTranslation();

  const { findAll, deleteItem: deleteDetail } = useFactoryFreezing();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);
  const [filterFreezerNo, setFilterFreezerNo] = useState("");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [deleteItem, setDeleteItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editDetailModal, setEditDetailModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [factoryFreezingDrawer, setFactoryFreezingDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        startDate: startDate,
        endDate: endDate,
        freezerNo: filterFreezerNo,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [data, editItem]);

  const editRow = (item) => {
    setEditItem(item);
    setEditModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setFactoryFreezingDrawer(true);
  };

  const deleteRow = (item) => {
    setDeleteItem(item);
    setDeleteModal(true);
  };

  const confirmDelete = () => {
    deleteDetail(deleteItem.id).then((res) => {
      setDeleteModal(false);
      setRefresh(true);
    });
  };

  return (
    <>
      <Filter
        filterFreezerNo={filterFreezerNo}
        setFilterFreezerNo={setFilterFreezerNo}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Freezer Temperature Monitoring (Blast Freezer)</Title>

        <Row justify="space-between">
          <Col>
            <Space>
              <Button type="primary" onClick={() => setAddNewModal(true)}>
                New
              </Button>
              {/* <Button disabled={!data.length} type="default" onClick={() => BlastFreezerTemperature(data)}>
                HACCP
              </Button> */}
            </Space>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <FactoryFreezingTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          viewDetail={viewDetail}
          deleteRow={deleteRow}
        />

        <NewFactoryFreezingModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <EditDetailModal
          editItem={editItem}
          visible={editDetailModal}
          setVisible={setEditDetailModal}
          setRefresh={setRefresh}
        />

        <FactoryFreezingDrawer
          id={editItem.id}
          visible={factoryFreezingDrawer}
          setVisible={setFactoryFreezingDrawer}
          setRefresh={setRefresh}
        />

        <ConfirmDeleteModal visible={deleteModal} onOk={() => confirmDelete()} onCancel={() => setDeleteModal(false)} />
      </div>
    </>
  );
};

export default FactoryFreezingList;
