import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useFarmProduction } from "../../../hooks/useFarmProduction";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFarmProduction();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        type: "Hard Shell Crab",
        productionTimestamp: moment(),
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          type: values.type,
          productionTimestamp: moment(values.productionTimestamp).format(),
          quantity: values.quantity,
          weightInKg: values.weightInKg,
          harvestShift: values.harvestShift,
          remarks: values.remarks,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Farm Production"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} />
    </Modal>
  );
};

export default AddNewModal;
