import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker } from "antd";
import moment from "moment";
import { useHatcheryReceipt } from "../../../hooks/useHatcheryReceipt";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ReleaseModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useHatcheryReceipt();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        releaseDate: moment(),
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          releaseDate: moment(values.releaseDate).format(),
          status: "Released",
        };
        setSubmitting(true);
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Releasing Broodstock"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="releaseDate" label="Release Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReleaseModal;
