import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useLarvalDataReport = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findBatchNo = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/larvalDataReport/findBatchNo?year=" + query.year, config);
    },
    [authState.token, send]
  );

  const findBroodstockData = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/larvalDataReport/findBroodstockData?batchNo=" + query.batchNo, config);
    },
    [authState.token, send]
  );

  const findIncubationData = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/larvalDataReport/findIncubationData?batchNo=" + query.batchNo, config);
    },
    [authState.token, send]
  );

  const findLarvalData = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/larvalDataReport/findLarvalData?batchNo=" + query.batchNo, config);
    },
    [authState.token, send]
  );

  const findHarvestData = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/larvalDataReport/findHarvestData?batchNo=" + query.batchNo, config);
    },
    [authState.token, send]
  );

  const findHatcheryTransferData = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl + "/wapi/larvalDataReport/findHatcheryTransferData?batchNo=" + query.batchNo,
        config
      );
    },
    [authState.token, send]
  );

  return {
    findBatchNo,
    findBroodstockData,
    findIncubationData,
    findLarvalData,
    findHarvestData,
    findHatcheryTransferData,
  };
};

export { useLarvalDataReport };
