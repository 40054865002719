import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// import Filter from "../../components/sscHarvestRecord/components/Filter";
// import FarmStorageTable from "../../components/sscHarvestRecord/tables/FarmStorageTable";
// import EditFarmStorageRecordModal from "../../components/sscHarvestRecord/modals/EditFarmStorageRecordModal";

import moment from "moment";
import { useFarmStorageRecord } from "../../hooks/useFarmStorageRecord";
import FarmStorageTable from "../../components/farmStorage/FarmStorageTable";
import FarmStorageDrawer from "../../components/farmStorage/FarmStorageDrawer";
import EditFarmStorageModal from "../../components/farmStorage/EditFarmStorageModal";
import FarmStorageStockOutModal from "../../components/farmStorage/FarmStorageStockOutModal";
import FarmStorageStockCountModal from "./components/FarmStorageStockCountModal";
// import NewSSCDeliveryOrderModal from "../../components/deliveryOrder/modals/NewSSCDeliveryOrderModal";
// import FarmStorageDrawer from "../../components/sscHarvest/FarmStorageDrawer";
// import NewFarmStorageModal from "../../components/farmStorage/NewFarmStorageModal";

const { Title } = Typography;

const FarmStorage = () => {
  const { t } = useTranslation();

  const { findAll } = useFarmStorageRecord();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(3, "month"), moment()]);
  const [filterStatus, setFilterStatus] = useState("All");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [newDeliveryOrderModal, setNewDeliveryOrderModal] = useState(false);
  const [editFarmStorageRecordModal, setEditFarmStorageRecordModal] = useState(false);
  const [farmStorageDrawer, setFarmStorageDrawer] = useState(false);
  const [farmStorageStockCountModal, setFarmStorageStockCountModal] = useState(false);
  const [accumulatedSSC, setAccumulatedSSC] = useState({});

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, findAll, t]);

  useEffect(() => {
    if (data.length && data[0].accumulatedStock) {
      setAccumulatedSSC(data[0].accumulatedStock);
    } else {
      setAccumulatedSSC({});
    }
  }, [data]);

  const createDeliveryOrder = (row) => {
    setEditItem(row);
    setNewDeliveryOrderModal(true);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditFarmStorageRecordModal(true);
  };

  const viewDetail = (row) => {
    setEditItem(row);
    setFarmStorageDrawer(true);
  };

  return (
    <>
      {/* <Filter
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      /> */}
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Soft Shell Crab Storage</Title>

        <Row>
          <Col>
            <Space wrap>
              {JSON.stringify(accumulatedSSC) !== "{}" &&
                Object.entries(accumulatedSSC).map(([key, value]) => (
                  <Tag>
                    {key} : {value.quantity} pcs
                  </Tag>
                ))}
            </Space>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginTop: 16 }}>
          <Col>
            <Space>
              <Button type="primary" onClick={() => setFarmStorageStockCountModal(true)}>
                Stock Count
              </Button>
              <Button onClick={() => setNewDeliveryOrderModal(true)}>New Delivery Order</Button>
            </Space>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <FarmStorageTable
          error={error}
          loading={loading}
          dataSource={data}
          viewDetail={viewDetail}
          editRow={editRow}
          createDeliveryOrder={createDeliveryOrder}
        />

        <EditFarmStorageModal
          editItem={editItem}
          visible={editFarmStorageRecordModal}
          setVisible={setEditFarmStorageRecordModal}
          setRefresh={setRefresh}
        />

        <FarmStorageStockOutModal
          visible={newDeliveryOrderModal}
          setVisible={setNewDeliveryOrderModal}
          setRefresh={setRefresh}
        />

        <FarmStorageDrawer
          id={editItem.id}
          visible={farmStorageDrawer}
          setVisible={setFarmStorageDrawer}
          setRefresh={setRefresh}
        />

        <FarmStorageStockCountModal
          visible={farmStorageStockCountModal}
          setVisible={setFarmStorageStockCountModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default FarmStorage;
