import React, { useEffect } from "react";
import { Card, Form, Button, Select } from "antd";

const { Option } = Select;

const Filter = (props) => {
  const { filterYear, setFilterYear, setRefresh } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      year: filterYear,
    });
  }, [form, filterYear]);

  const onFinish = (values) => {
    setFilterYear(values.year);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Year" name="year">
          <Select allowClear style={{ width: 150 }}>
            <Option value="2021">2021</Option>
            <Option value="2022">2022</Option>
            <Option value="2023">2023</Option>
            <Option value="2024">2024</Option>
            <Option value="2025">2025</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
