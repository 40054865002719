import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useTankTransfer } from "../../../hooks/useTankTransfer";
import { useHatcheryProduction } from "../../../hooks/useHatcheryProduction";
import { useRawMaterial } from "../../../hooks/useRawMaterial";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useRawMaterial();
  const { findAll: findOperatingTank } = useTankTransfer();
  const { create } = useHatcheryProduction();

  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [incubationOption, setIncubationOption] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      findAll("Active").then((res) => {
        if (res.status === "success") {
          setRawMaterialData(res.data);
        }
      });
      findOperatingTank("New").then((res) => {
        if (res.status === "success") {
          setIncubationOption(res.data);
        }
      });
      form.setFieldsValue({
        productionTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          // rawMaterialId: values.rawMaterialId,
          productionTimestamp: moment(values.productionTimestamp).format(),
          quantity: values.quantity,
          tankUsageId: values.tankUsageId,
          avgWeightInGram: values.avgWeightInGram,
          batchNo: values.batchNo,
          broodstockNo: values.broodstockNo,
          broodstockSpecies: values.broodstockSpecies,
          remarks: values.remarks,
          // fullStockOut: values.fullStockOut,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Hatchery Harvest"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} rawMaterialData={rawMaterialData} incubationOption={incubationOption} />
    </Modal>
  );
};

export default AddNewModal;
