import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tabs, Descriptions } from "antd";
import { useDashboard } from "../../hooks/useDashboard";
// import PondFeed from "../../components/dashboard/components/PondFeed";
import FarmActivity from "../../components/dashboard/components/FarmActivity";
import FarmReceipt from "../../components/dashboard/components/FarmReceipt";
import SoftShellCrab from "../../components/dashboard/components/SoftShellCrab";
import Pontoon from "../../components/dashboard/components/Pontoon";
import PondTab from "./components/PondTab";
import DeliveryOrderTab from "./components/DeliveryOrderTab";

const { TabPane } = Tabs;
const moment = require("moment");

const FarmDashboard = () => {
  const { findFarmActivity, findSummaryData } = useDashboard();
  const [refresh, setRefresh] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  const [activityDateFilter, setActivityDateFilter] = useState(moment());

  useEffect(() => {
    if (refresh) {
      if (activityDateFilter) {
        const date = moment(activityDateFilter).format("YYYY-MM-DD");

        Promise.all([findFarmActivity(date), findSummaryData(date)]).then((res) => {
          if (res[0].status === "success") {
            setActivityData(res[0].data);
          }
          if (res[1].status === "success") {
            setSummaryData(res[1].data);
          }

          setRefresh(false);
        });
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (activityDateFilter) {
      setRefresh(true);
    }
  }, [activityDateFilter]);

  return (
    <>
      <Tabs>
        <TabPane tab="General" key="general">
          <Row gutter={[16, 24]}>
            <Col xs={24} sm={24} md={16}>
              <Card>
                <FarmActivity
                  data={activityData}
                  activityDateFilter={activityDateFilter}
                  setActivityDateFilter={setActivityDateFilter}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Card
                size="small"
                title={"Material Receipt"}
                extra={moment(activityDateFilter).format("ll")}
                style={{ marginBottom: 16 }}
              >
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label="Hatchery">
                    {summaryData.materialReceiptFromHatchery
                      ? Number(summaryData.materialReceiptFromHatchery).toLocaleString()
                      : 0}{" "}
                    pcs
                  </Descriptions.Item>
                  <Descriptions.Item label="Others">
                    {summaryData.materialReceiptFromExternal
                      ? Number(summaryData.materialReceiptFromExternal).toLocaleString()
                      : 0}{" "}
                    pcs
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Card
                size="small"
                title={"Harvest"}
                extra={moment(activityDateFilter).format("ll")}
                style={{ marginBottom: 16 }}
              >
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label="SSC">
                    {summaryData.totalSSCHarvest ? Number(summaryData.totalSSCHarvest).toLocaleString() : 0} pcs
                  </Descriptions.Item>
                  <Descriptions.Item label="Others">
                    {summaryData.totalOtherHarvest ? Number(summaryData.totalOtherHarvest).toLocaleString() : 0} pcs
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              {/* <Card
                size="small"
                title={"Delivery Order"}
                extra={moment(activityDateFilter).format("ll")}
                style={{ marginBottom: 16 }}
              >
                <Descriptions size="small" column={1}>
                  <Descriptions.Item label="To Processing">{summaryData.totalProcessingDO}</Descriptions.Item>
                  <Descriptions.Item label="Others">{summaryData.totalOtherDO}</Descriptions.Item>
                </Descriptions>
              </Card> */}
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Material Receipt" key="receipt">
          <FarmReceipt />
        </TabPane>
        <TabPane tab="Pond" key="pond">
          <PondTab />
        </TabPane>
        <TabPane tab="Soft Shell Crab" key="ssc">
          <SoftShellCrab />
        </TabPane>
        <TabPane tab="Pontoon" key="pontoon">
          <Pontoon />
        </TabPane>
        <TabPane tab="Delivery Order" key="deliveryOrder">
          <DeliveryOrderTab />
        </TabPane>
      </Tabs>
    </>
  );
};

export default FarmDashboard;
