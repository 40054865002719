import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useHatcheryReceipt } from "../../../hooks/useHatcheryReceipt";
import { useIncubationRecord } from "../../../hooks/useIncubationRecord";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useHatcheryReceipt();

  const { create } = useIncubationRecord();

  const [submitting, setSubmitting] = useState(false);

  const [hatcheryData, setHatcheryData] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setHatcheryData(res.data);
        }
      });
      form.setFieldsValue({
        spawnDate: moment(),
      });
    }
  }, [visible, form, findAll]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        const params = {
          spawnDate: moment(values.spawnDate).format(),
          hatcheryReceiptId: values.hatcheryReceiptId,
          remarks: values.remarks,
        };
        setSubmitting(true);
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Incubation Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} hatcheryData={hatcheryData} />
    </Modal>
  );
};

export default AddNewModal;
