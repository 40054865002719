import React, { useState, useEffect } from "react";
import { Modal, Form, Input, DatePicker } from "antd";
import { useIncubationParameter } from "../../../hooks/useIncubationParameter";
import moment from "moment";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ParameterModal = (props) => {
  const { visible, item, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useIncubationParameter();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        timestamp: moment(),
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          incubationId: item.id,
          timestamp: moment(values.timestamp).format(),
          dueToHatch: values.dueToHatch,
          emSize: values.emSize,
          stage: values.stage,
          remarks: values.remarks,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={item?.batchNo + " - " + item?.HatcheryReceipt?.location + " - " + item?.HatcheryReceipt?.species}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="timestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>
        <Form.Item name="dueToHatch" label="Due To Hatch">
          <Input allowClear />
        </Form.Item>
        <Form.Item name="emSize" label="E.M. Size">
          <Input allowClear />
        </Form.Item>
        <Form.Item name="stage" label="Stage">
          <Input allowClear />
        </Form.Item>
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ParameterModal;
