import React, { useState, useEffect, useContext } from "react";
import { Layout, Menu, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import BasicSettings from "./components/BasicSettings";
import SecuritySettings from "./components/SecuritySettings";
import Preferences from "./components/Preferences";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ResponsiveContext } from "../../App";

const { Content, Sider } = Layout;

const Account = () => {
  const { t } = useTranslation();

  const { isMobile } = useContext(ResponsiveContext);

  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("basic");

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isMobile]);

  const onMenuSelected = (menu) => {
    setSelectedMenu(menu.key);
    if (isMobile) {
      setCollapsed(true);
    }
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "basic":
        return <BasicSettings />;

      case "security":
        return <SecuritySettings />;

      case "preference":
        return <Preferences />;

      default:
        return <BasicSettings />;
    }
  };

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Layout>
        <Sider width={isMobile ? "70%" : 200} collapsedWidth="0" breakpoint="sm" trigger={null} collapsed={collapsed}>
          <Menu mode="inline" style={{ height: "100%" }} defaultSelectedKeys={["basic"]} onClick={onMenuSelected}>
            <Menu.Item key="basic">{t("account.setting.basic")}</Menu.Item>

            <Menu.Item key="security">{t("account.setting.security")}</Menu.Item>
            {/* 
                        <Menu.Item key="preference">
                            {t('account.setting.preference')}
                        </Menu.Item> */}
          </Menu>
        </Sider>

        <Content style={{ padding: "0 24px", background: "#fff" }}>
          {isMobile && (
            <Row justify="end">
              <Button onClick={toggle} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
            </Row>
          )}

          <div hidden={isMobile && !collapsed}>{renderContent()}</div>
        </Content>
      </Layout>
    </div>
  );
};

export default Account;
