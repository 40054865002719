import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import PondPreparationForm from "./PondPreparationForm";
import moment from "moment";
import { usePondPreparation } from "../../hooks/usePondPreparation";
import { useTreatmentType } from "../../hooks/useTreatmentType";

const EditPondPreparationModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePondPreparation();
  const { findAll: findTreatmentType } = useTreatmentType();

  const [submitting, setSubmitting] = useState(false);
  const [treatmentTypeOption, setTreatmentTypeOption] = useState([]);
  const [treatmentTypeArray, setTreatmentTypeArray] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        activity: editItem.activity,
        remarks: editItem.remarks,
      });
      findTreatmentType().then((res) => {
        if (res.status === "success") {
          let options = [...res.data];

          for (let option of options) {
            let defaultValue = 0;
            for (const item of editItem.PondPreparationDetails) {
              if (item.preparationType === option.name && item.uom === option.uom) {
                defaultValue = item.amount;
              }
            }
            option.defaultAmount = defaultValue;
          }

          setTreatmentTypeOption(options);
        }
      });

      setTreatmentTypeArray(
        editItem.PondPreparationDetails.map((item) => {
          return {
            name: item.preparationType,
            amount: item.amount,
            uom: item.uom,
          };
        })
      );
    } else {
      setTreatmentTypeOption([]);
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const treatmentTypeRecord = treatmentTypeArray.filter((item) => item.amount && item.amount > 0);

        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          activity: values.activity,
          treatmentTypeRecord: treatmentTypeRecord,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Pond Preparation"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondPreparationForm
        form={form}
        treatmentTypeOption={treatmentTypeOption}
        treatmentTypeArray={treatmentTypeArray}
        setTreatmentTypeArray={setTreatmentTypeArray}
        isEdit
      />
    </Modal>
  );
};

export default EditPondPreparationModal;
