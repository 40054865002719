import React from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import { incubationStatus } from "../../../utils/Status";

const { Option } = Select;
const EditForm = (props) => {
  const { form } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="spawnDate" label="Spawn Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>
      <Form.Item name="hatchDate" label="Hatch Date">
        <DatePicker allowClear />
      </Form.Item>
      <Form.Item name="quantity" label="Quantity">
        <InputNumber min={0} allowClear />
      </Form.Item>
      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <Select>
          {Object.keys(incubationStatus).map((status) => (
            <Option key={status} value={status}>
              {status}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default EditForm;
