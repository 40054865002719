import React from "react";
import { Table } from "antd";
import moment from "moment";

const OpenTransferTable = (props) => {
  const { dataSource, selectedRows, setSelectedRows } = props;

  const columns = [
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    // },
    // {
    //     title: 'Material',
    //     render: (text, row) => row.RawMaterial ? row.RawMaterial.name : '-'
    // },
    {
      title: "Date",
      dataIndex: "productionTimestamp",
      render: (text) => moment(text).format("YYYY/MM/DD"),
    },
    {
      title: "Tank",
      render: (text, row) => (row.TankUsage ? row.TankUsage.Tank.label : "-"),
    },
    {
      title: "Batch",
      render: (text, row) => (row.TankUsage ? row.TankUsage.batchNo : "-"),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    // {
    //   title: "Remarks",
    //   dataIndex: "remarks",
    // },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    selectedRowKeys: selectedRows.map((item) => item.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Table
      rowKey="id"
      rowSelection={{
        // type: selectionType,
        ...rowSelection,
      }}
      columns={columns}
      dataSource={dataSource}
      style={{ marginTop: 24 }}
    />
    // <TableLoader
    //     error={error}
    //     loading={loading}
    //     dataSource={dataSource}
    //     columns={columns}
    //     style={{ marginTop: 24 }} />
  );
};

export default OpenTransferTable;
