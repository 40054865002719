import React, { useEffect } from "react";
import { Card, Form, Select, Button } from "antd";
import { growOutStatus } from "../../../utils/Options";

const { Option } = Select;

const Filter = (props) => {
  const { pondOption, filterPond, setFilterPond, filterStatus, setFilterStatus, setRefresh } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      status: filterStatus,
      pondId: filterPond,
    });
  }, [form]);

  const onFinish = (values) => {
    setFilterStatus(values.status);
    setFilterPond(values.pondId);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Pond #" name="pondId">
          <Select allowClear style={{ width: 150 }}>
            <Option value="All">All</Option>
            {pondOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select allowClear style={{ width: 150 }}>
            {growOutStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
