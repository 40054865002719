import React, { useEffect, useState } from "react";
import { DatePicker, Form, Select, Input } from "antd";
import { factoryProductionStatus } from "../../utils/Options";
import { useFactoryReceipt } from "../../hooks/useFactoryReceipt";
import { useQRCode } from "../../hooks/useQRCode";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const SSCProductionForm = (props) => {
  const { form, isEdit } = props;

  const { findAll } = useFactoryReceipt();
  const { findAll: findQRCode } = useQRCode();

  const [receiptOption, setReceiptOption] = useState([]);
  const [selectedReceiptDetails, setSelectedReceiptDetails] = useState([]);
  const [qrCount, setQrCount] = useState();

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setReceiptOption(res.data);
      }
    });
  }, []);

  const onReceiptChange = (value) => {
    const index = receiptOption.map((item) => item.id).indexOf(value);

    if (index > -1) {
      const details = receiptOption[index]["FactoryReceiptDetails"];
      setSelectedReceiptDetails(details);
    } else {
      setSelectedReceiptDetails([]);
    }
  };

  const onGradeChange = (value) => {
    const index = selectedReceiptDetails.map((item) => item.grade).indexOf(value);

    if (index > -1) {
      const item = selectedReceiptDetails[index];
      form.setFieldsValue({
        quantity: item.processingQuantity,
      });
    }
  };

  const onQRChange = (value) => {
    findQRCode({
      status: "New",
      type: value,
    }).then((res) => {
      if (res.status === "success") {
        setQrCount(res.data.length);
      }
    });
  };
  return (
    <Form {...layout} form={form} name="add-new-form">
      {!isEdit && (
        <Form.Item name="factoryReceiptId" label="Material Receipt" rules={[{ required: true }]}>
          <Select allowClear onChange={onReceiptChange} mode="multiple">
            {receiptOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {moment(item.arrivalTimestamp).format("YYYY-MM-DD")} - {item.vehicleNumber} - {item.personInCharge}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item name="productionTimestamp" label="Timestamp" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="batchNo" label="Batch No">
        <Input allowClear />
      </Form.Item>
      {/* 
      <Form.Item {...tailLayout} name="markAsComplete" valuePropName="checked">
        <Checkbox>Set Material Receipt record as complete</Checkbox>
      </Form.Item> */}
      {/* 
      <Form.Item name="grade" label="Grade" rules={[{ required: true }]}>
        <Select onChange={onGradeChange}>
          {sscGrade.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item> */}

      {/* <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item> */}
      {/* 
      <Form.Item name="weightRangeInKg" label="Weight Range(KG)">
        <Input allowClear />
      </Form.Item> */}

      {/* <Form.Item name="weightInKg" label="Weight (KG)">
        <InputNumber min={0} allowClear />
      </Form.Item> */}

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>

      {isEdit && (
        <Form.Item name="status" label="Status">
          <Select>
            {factoryProductionStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {/* <Form.Item
        name="qrType"
        label="QR Code"
        rules={[{ required: true }]}
        extra={qrCount !== undefined ? qrCount + " remaining" : ""}
      >
        <Select onChange={onQRChange}>
          {qrType.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item> */}
      {/* 
      <Form.Item {...tailLayout}>
        <Alert type="warning" message={"Records will be created without QR code if there is not enough QR code"} />
      </Form.Item> */}
    </Form>
  );
};

export default SSCProductionForm;
