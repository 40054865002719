import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import EditForm from "../forms/EditForm";
import { useLarvalData } from "../../../hooks/useLarvalData";

const EditModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findLarvalStage, update } = useLarvalData();

  const [submitting, setSubmitting] = useState(false);

  const [larvalStageData, setLarvalStageData] = useState([]);

  useEffect(() => {
    if (visible) {
      findLarvalStage().then((res) => {
        if (res.status === "success") {
          setLarvalStageData(res.data);
        }
      });
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        larvalStage: editItem.larvalStage,
        larvalDensity: editItem.larvalDensity,
        salinityInPpt: editItem.salinityInPpt,
        temperature1: editItem.temperature1,
        temperature2: editItem.temperature2,
        artemiaType: editItem.artemiaType,
        artemiaFedInGram1: editItem.artemiaFedInGram1,
        artemiaFedInGram2: editItem.artemiaFedInGram2,
        microInGram1: editItem.microInGram1,
        microInGram2: editItem.microInGram2,
        microInGram3: editItem.microInGram3,
        cifro: editItem.cifro,
        edta: editItem.edta,
        k5InGram: editItem.k5InGram,
        germFree: editItem.germFree,
        mplus: editItem.mplus,
        oreganoInMl: editItem.oreganoInMl,
        shrimpFavour: editItem.shrimpFavour,
        superms: editItem.superms,
        tystatin: editItem.tystatin,
        remarks: editItem.remarks,
      });
    }
  }, [visible, editItem, form, findLarvalStage]);

  useEffect(() => {
    if (visible) {
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          larvalStage: values.larvalStage,
          larvalDensity: values.larvalDensity,
          salinityInPpt: values.salinityInPpt,
          temperature1: values.temperature1,
          temperature2: values.temperature2,
          artemiaType: values.artemiaType,
          artemiaFedInGram1: values.artemiaFedInGram1,
          artemiaFedInGram2: values.artemiaFedInGram2,
          microInGram1: values.microInGram1,
          microInGram2: values.microInGram2,
          microInGram3: values.microInGram3,
          cifro: values.cifro,
          edta: values.edta,
          k5InGram: values.k5InGram,
          germFree: values.germFree,
          mplus: values.mplus,
          oreganoInMl: values.oreganoInMl,
          shrimpFavour: values.shrimpFavour,
          superms: values.superms,
          tystatin: values.tystatin,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Tank Transfer"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <EditForm form={form} larvalStageData={larvalStageData} />
    </Modal>
  );
};

export default EditModal;
