import React from "react";
import { Select, Row, Col } from "antd";
import { growOutStatus } from "../../utils/Options";

const { Option } = Select;

const Filter = (props) => {
  const { pondOption, filterPond, setFilterPond, filterStatus, setFilterStatus, setRefresh } = props;

  return (
    <Row style={{ marginTop: 16 }} gutter={16}>
      <Col>
        <Select
          placeholder="Pond #"
          allowClear
          style={{ width: 150 }}
          onChange={(value) => {
            setFilterPond(value);
            setRefresh(true);
          }}
          value={filterPond}
        >
          <Option value="All">All</Option>
          {pondOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <Select
          placeholder="Status"
          allowClear
          style={{ width: 150 }}
          onChange={(value) => {
            setFilterStatus(value);
            setRefresh(true);
          }}
          value={filterStatus}
        >
          {growOutStatus.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default Filter;
