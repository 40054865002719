import React, { useState, useEffect } from "react";
import { Modal, Form, Typography, Table, InputNumber, Input, Select, DatePicker } from "antd";
import moment from "moment";
import { usePontoon } from "../../hooks/usePontoon";
import { usePontoonFeed } from "../../hooks/usePontoonFeed";
import { harvestShift } from "../../utils/Options";
const { Text } = Typography;

const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const NewSSCFeedModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = usePontoonFeed();
  const { findAll } = usePontoon();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [pontoonOption, setPontoonOption] = useState([]);
  const [pontoonArray, setPontoonArray] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setPontoonOption(res.data);
        }
      });

      form.setFieldsValue({
        recordTimestamp: moment(),
      });
    }
  }, [visible, findAll, form]);

  useEffect(() => {
    setPontoonArray(
      pontoonOption.map((item) => {
        return {
          pontoonId: item.id,
          label: item.label,
          pondLabel: item.Pond.label,
          weightInKg: null,
          remarks: null,
        };
      })
    );
  }, [pontoonOption]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const pontoonRecord = pontoonArray.filter((item) => item.weightInKg);

        if (pontoonRecord.length <= 0) {
          setError(true);
          return;
        }
        setError(false);

        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          shift: values.shift,
          feedType: values.feedType,
          pontoonArray: pontoonRecord,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onValueChange = (value, item) => {
    let array = [...pontoonArray];

    const index = array.map((item) => item.pontoonId).indexOf(item.pontoonId);

    if (index > -1) {
      array[index].weightInKg = value;
      setPontoonArray(array);
    }
  };

  const onRemarkChange = (value, item) => {
    let array = [...pontoonArray];

    const index = array.map((item) => item.pontoonId).indexOf(item.pontoonId);

    if (index > -1) {
      array[index].remarks = value;
      setPontoonArray(array);
    }
  };

  const columns = [
    {
      title: "Pontoon",
      dataIndex: "label",
      render: (text, row) => (
        <>
          <div>{text}</div>
          <div className="text-xs text-gray-400">{row.pondLabel}</div>
        </>
      ),
    },
    {
      title: "Feed (kg)",
      dataIndex: "weightInKg",
      render: (text, row) => <InputNumber inputMode="numeric" min={0} onChange={(e) => onValueChange(e, row)} />,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text, row) => <Input onChange={(e) => onRemarkChange(e.target.value, row)} />,
    },
  ];

  return (
    <Modal
      title={"New SSC Feed"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="ssc-feed-form" labelAlign="left">
        <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="shift" label="Shift" rules={[{ required: true }]}>
          <Select allowClear>
            {harvestShift.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="feedType" label="Feed Type" rules={[{ required: true }]}>
          <Select allowClear>
            {["Fish", "Shrimp"].map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {error && <Text type="danger">Please input at least one field </Text>}

      <Table
        size="small"
        dataSource={pontoonArray}
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{ pageSize: 100 }}
      />
    </Modal>
  );
};

export default NewSSCFeedModal;
