import React from "react";
import { DatePicker, Form, Input, Select } from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const ProcessingOrderForm = (props) => {
  const { form, data } = props;
  
  return (
    <>
    <Form {...layout} form={form} name="processing-order-form" labelAlign="left">
      <Form.Item name="orderNo" label="Order No." rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="orderDate" label="Order Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="location" label="Location" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="deliverTo" label="Sold To" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="productType" label="Product Type" rules={[{ required: true }]}>
        <Select>
          <Option value="Soft Shell Crab">Soft Shell Crab</Option>
        </Select>
      </Form.Item>

      {/* <Form.Item
        name="productionBatchNo"
        label="Production Batch No."
        rules={[{ required: true }]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          // onChange={handleChange}
        >
          {data.map((item) => (
            <Option key={item.id} value={item.packingBatchNo}>
              {item.packingBatchNo}
            </Option>
          ))}
        </Select>
      </Form.Item> */}

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
    </>
  );
};

export default ProcessingOrderForm;
