import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import OptionTable from "./tables/OptionTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import { useOption } from "../../hooks/useOption";

const { Title } = Typography;

const OptionList = () => {
  const { t } = useTranslation();

  const { findAll } = useOption();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>Option List</Title>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setAddNewModal(true)}>
            New
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </Col>
      </Row>

      <OptionTable error={error} loading={loading} dataSource={data} editRow={editRow} />

      <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

      <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
    </div>
  );
};

export default OptionList;
