import React, { useState, useEffect } from "react";
import { Modal, Form, DatePicker, Input, Select } from "antd";
import moment from "moment";
import { usePackingBatch } from "../../../hooks/usePackingBatch";
import { babyPackStatus } from "../../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const EditPackingModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePackingBatch();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        packTimestamp: moment(editItem.packTimestamp),
        remarks: editItem.remarks,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // return false
        setSubmitting(true);
        const params = {
          packTimestamp: moment(values.packTimestamp).format(),
          remarks: values.remarks,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Packing Box"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="edit-form">
        <Form.Item name="packTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker showTime allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remark">
        <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditPackingModal;
