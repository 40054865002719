import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Divider, Descriptions, Tag, InputNumber, Typography, Space } from "antd";
import moment from "moment";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import { useProcessing } from "../../../hooks/useProcessing";
import { useFarm } from "../../../hooks/useFarm";
import DeliveryOrderForm2 from "../forms/DeliveryOrderForm2";
import { sscGrade, sscSize } from "../../../utils/Options";
import { ResponsiveContext } from "../../../App";

const { Text } = Typography;

const NewSSCDeliveryOrderModal = (props) => {
  const { sscHarvestItem, visible, setVisible, setRefresh } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const [form] = Form.useForm();

  const { findAll: findFarm } = useFarm();
  const { findAll: findProcessing } = useProcessing();

  const { create } = useDeliveryOrder();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);

  const [farmOption, setFarmOption] = useState([]);
  const [processingOption, setProcessingOption] = useState([]);
  const [softShellGrade, setSoftShellGrade] = useState();

  useEffect(() => {
    if (visible) {
      findFarm().then((res) => {
        if (res.status === "success") {
          setFarmOption(res.data);
        }
      });

      findProcessing({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setProcessingOption(res.data);
        }
      });

      form.setFieldsValue({
        departTimestamp: moment(),
      });
    }
  }, [visible, findFarm, findProcessing, form]);

  useEffect(() => {
    if (softShellGrade) {
      // console.log(softShellGrade);
      let total = 0;
      for (const [grade, gradeObj] of Object.entries(softShellGrade)) {
        for (const [size, sizeVal] of Object.entries(gradeObj)) {
          total = Number(total) + Number(sizeVal);
        }
      }

      if (total > sscHarvestItem.balance) {
        setError("Total SSC cannot exceed balance.");
      } else {
        setError();
      }
    }
  }, [softShellGrade]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (Object.keys(softShellGrade).length === 0) {
        return;
      }
      if (!err && !error) {
        setSubmitting(true);
        const params = {
          orderNo: values.orderNo,
          product: "Soft Shell Crab",
          departTimestamp: moment(values.departTimestamp).format(),
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          farmId: values.farmId,
          processingId: values.processingId,
          remarks: values.remarks,
          sscHarvestDailyId: sscHarvestItem.id,
          grades: softShellGrade,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setSoftShellGrade();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onInputChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        quantity: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  const onWeightChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        weightInKg: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  return (
    <Modal
      title="New Delivery Order"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width={isMobile ? null : "65%"}
    >
      <DeliveryOrderForm2 form={form} farmOption={farmOption} processingOption={processingOption} />

      <Divider />

      <Descriptions column={2} layout="vertical">
        <Descriptions.Item label="Product">
          <Tag>Soft Shell Crab</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Source">
          <Tag color={sscHarvestItem.sourceType === "External" ? "red" : "green"}>{sscHarvestItem.sourceType}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Harvest Date">
          <Tag>{moment(sscHarvestItem.harvestDate).format("YYYY-MM-DD")}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Balance Qty">
          <Tag>{Number(sscHarvestItem.balance).toLocaleString()}</Tag>
        </Descriptions.Item>
      </Descriptions>
      <Divider />

      <h4 style={{ marginBottom: 16 }}>Order Items</h4>

      {error && <Text type="danger">{error}</Text>}

      {sscGrade.map((grade) => (
        <Descriptions title={"Grade " + grade} size="small" key={grade}>
          {sscSize.map((item) => (
            <Descriptions.Item
              label={item}
              key={grade + "_" + item}
              contentStyle={{ justifyContent: "flex-end" }}
              style={{ paddingLeft: 16 }}
            >
              <Space>
                <InputNumber
                  placeholder="pcs"
                  inputMode="numeric"
                  allowClear
                  onChange={(value) => onInputChange(grade, item, value)}
                />
                <InputNumber
                  placeholder="(kg)"
                  inputMode="numeric"
                  allowClear
                  onChange={(value) => onWeightChange(grade, item, value)}
                />
              </Space>
            </Descriptions.Item>
          ))}
        </Descriptions>
      ))}
    </Modal>
  );
};

export default NewSSCDeliveryOrderModal;
