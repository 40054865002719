import React from "react";
import { Dropdown, Menu, Typography, Tag, Button } from "antd";
import { EditOutlined, EnterOutlined, EllipsisOutlined } from "@ant-design/icons";
import TableLoader from "../../components/common/TableLoader";
import moment from "moment";

const { Link } = Typography;

const ProcessingOrderTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail, markAsComplete } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
    },
    {
      title: "Date",
      dataIndex: "orderDate",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{moment(text).format("YYYY-MM-DD")}</Link>,
    },
    {
      title: "Box ID",
      dataIndex: "boxId",
      render: (text, row) => row.ProductionBatches ? row.ProductionBatches.map((item,index) => {return <>{index > 0 && <span>, </span>}{item.PackingBatches[0]["packingBatchNo"]}</>}) : "-",
    },
    {
      title: "Batch No",
      render: (text, row) =>
        row.ProductionBatches ? row.ProductionBatches.map((item) => <Tag>{item.batchNo}</Tag>) : "-",
    },
    {
      title: "Sold To",
      dataIndex: "deliverTo",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="complete"
                icon={<EnterOutlined />}
                onClick={() => markAsComplete(row)}
                disabled={row.status === "Complete"}
              >
                Complete
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default ProcessingOrderTable;
