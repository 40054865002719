import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Select, DatePicker } from "antd";
import { usePond } from "../../hooks/usePond";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const PondTreatmentForm = (props) => {
  const { form, treatmentTypeOption, treatmentTypeArray, setTreatmentTypeArray, isEdit } = props;

  const { findAll } = usePond();

  const [pondOption, setPondOption] = useState([]);

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setPondOption(res.data.filter((item) => item.GrowOuts && item.GrowOuts.length));
      }
    });
  }, [findAll]);

  const handleChange = (value, item) => {
    const index = treatmentTypeArray.map((item) => item.name).indexOf(item.name);

    if (index > -1) {
      const newArray = [...treatmentTypeArray];
      newArray[index] = {
        name: item.name,
        amount: value,
        uom: item.uom,
      };
      setTreatmentTypeArray(newArray);
    } else {
      setTreatmentTypeArray([...treatmentTypeArray, { name: item.name, amount: value }]);
    }
  };

  return (
    <Form {...layout} form={form} name="pond-form">
      {!isEdit && (
        <Form.Item name="pondId" label="Pond" rules={[{ required: true }]}>
          <Select mode="multiple">
            {pondOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      {treatmentTypeOption.map((item) => (
        <Form.Item key={item.id} label={item.name}>
          <InputNumber
            inputMode="decimal"
            min={0}
            allowClear
            defaultValue={item.defaultAmount}
            onChange={(value) => handleChange(value, item)}
            placeholder={item.uom}
          />
        </Form.Item>
      ))}

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default PondTreatmentForm;
