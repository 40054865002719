import React from "react";
import { Card, Form, Input } from "antd";

const Filter = (props) => {
  const { setFilterTank } = props;

  const [form] = Form.useForm();

  const onTankChange = (e) => {
    setFilterTank(e.target.value);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline">
        <Form.Item label="Tank" name="label">
          <Input allowClear placeholder="Ex. Shed 1" onChange={onTankChange} />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
