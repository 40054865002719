import React, { useContext } from "react";
import { Descriptions, Row, Col, Typography, Tag } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ResponsiveContext } from "../../../App";

const { Title } = Typography;

const OrderInfo = (props) => {
  const { item } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { t } = useTranslation();

  return (
    <>
      <Title level={5}>
        {t("common.orderNo").toUpperCase()} : {item.orderNo}
      </Title>
      <Row justify="space-between">
        <Col span={16}>
          <Descriptions>
            <Descriptions.Item label="Date">{moment(item.departTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
            <Descriptions.Item label="From">{item.SourceFarm ? item.SourceFarm.label : "-"}</Descriptions.Item>
            <Descriptions.Item label="To">
              {item.TargetProcessing ? item.TargetProcessing.label : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle">{item.vehicleNumber ? item.vehicleNumber : "-"}</Descriptions.Item>
            <Descriptions.Item label="Person">{item.personInCharge ? item.personInCharge : "-"}</Descriptions.Item>
            <Descriptions.Item label="Product">
              {item.product ? <Tag color="blue">{item.product}</Tag> : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Total PCS">
              {item.DeliveryOrderDetails
                ? item.DeliveryOrderDetails.map((item) => item.quantity).reduce((a, b) => a + b, 0)
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Total KG">
              {item.DeliveryOrderDetails
                ? item.DeliveryOrderDetails.map((item) => item.weightInKg).reduce((a, b) => a + b, 0)
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Remarks">{item.remarks}</Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={12}>
              <div style={{ color: "grey", textAlign: "right" }}>{t("common.status")}</div>
              <div style={{ fontSize: 24, textAlign: "right" }}>{item.status ? item.status : "-"}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OrderInfo;
