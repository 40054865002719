import React from "react";
import { DatePicker, Form, InputNumber, Select } from "antd";
import { qrType } from "../../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddNewForm = (props) => {
  const { form } = props;

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  
  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item
        name="packTimestamp"
        label="Timestamp"
        rules={[{ required: true }]}
      >
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item
        name="weightInKg"
        label="Total Weight (KG)"
        rules={[{ required: true }]}
      >
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item
        name="sscQuantity"
        label="Total Pieces"
        rules={[{ required: true }]}
      >
        <InputNumber min={0} allowClear disabled />
      </Form.Item>

      <Form.Item name="qrType" label="QR Code" rules={[{ required: true }]}>
        <Select>
          {qrType.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="qrType" label="QR Code" rules={[{ required: true }]}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          defaultValue={["a10", "c12"]}
          onChange={handleChange}
          // options={options}
        />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
