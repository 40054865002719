import React, { useState } from "react";
import { Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import GrowOutTable from "../../../components/pondCycle/tables/GrowOutTable";
import NewPondCycleModal from "../../../components/pondCycle/NewPondCycleModal";
import EditPondCycleModal from "../../../components/pondCycle/EditPondCycleModal";
import GrowOutDrawer from "../../../components/growOutRecord/drawers/GrowOutDrawer";
import { toArrayBuffer } from "../../../utils/HelperFunctions";
import { useGrowOut } from "../../../hooks/useGrowOut";

const PondCycleTab = (props) => {
  const { pond, dataSource, loading, setRefresh } = props;

  const { exportPondDailyRecord } = useGrowOut();

  const [editItem, setEditItem] = useState({});
  const [newGrowOutModal, setNewGrowOutModal] = useState(false);
  const [editGrowOutModal, setEditGrowOutModal] = useState(false);

  const [growOutDrawer, setGrowOutDrawer] = useState(false);

  const editGrowOutRow = (row) => {
    setEditItem(row);
    setEditGrowOutModal(true);
  };

  const viewGrowOutRecord = (row) => {
    setEditItem(row);
    setGrowOutDrawer(true);
  };

  const doExcelExport = (row) => {
    // const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
    // const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

    exportPondDailyRecord(row.id).then((res) => {
      if (res.status === "success") {
        const file = new Blob([toArrayBuffer(res.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "Pond Daily Record.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <>
      <div class="flex justify-between mb-4">
        <div>
          <Button type="primary" onClick={() => setNewGrowOutModal(true)}>
            New
          </Button>
        </div>
        <div>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </div>
      </div>

      <GrowOutTable
        dataSource={dataSource}
        loading={loading}
        editRow={editGrowOutRow}
        viewRow={viewGrowOutRecord}
        doExcelExport={doExcelExport}
      />
      <NewPondCycleModal
        pond={pond}
        visible={newGrowOutModal}
        setVisible={setNewGrowOutModal}
        setRefresh={setRefresh}
      />

      <EditPondCycleModal
        editItem={editItem}
        visible={editGrowOutModal}
        setVisible={setEditGrowOutModal}
        setRefresh={setRefresh}
      />

      <GrowOutDrawer item={editItem} visible={growOutDrawer} setVisible={setGrowOutDrawer} setRefresh={setRefresh} />
    </>
  );
};

export default PondCycleTab;
