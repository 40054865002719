import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select, InputNumber, DatePicker } from "antd";
import moment from "moment";
import { factoryProductionStatus, sscGrade } from "../../../utils/Options";
import { useFactoryProduction } from "../../../hooks/useFactoryProduction";
import SSCProductionForm from "../SSCProductionForm";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const EditModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFactoryProduction();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        productionTimestamp: moment(editItem.productionTimestamp),
        batchNo: editItem.batchNo,
        grade: editItem.grade,
        weightRangeInKg: editItem.weightRangeInKg,
        weightInKg: editItem.weightInKg,
        status: editItem.status,
        remarks: editItem.remarks,
      });
    }
  }, [visible, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          productionTimestamp: moment(values.productionTimestamp).format(),
          batchNo: values.batchNo,
          grade: values.grade,
          weightRangeInKg: values.weightRangeInKg,
          weightInKg: values.weightInKg,
          status: values.status,
          remarks: values.remarks,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit SSC Production"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <SSCProductionForm form={form} isEdit />
      {/* <Form form={form} {...layout} name="edit-form">
        <Form.Item name="productionTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker showTime allowClear />
        </Form.Item>

        <Form.Item name="batchNo" label="Batch No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="grade" label="Grade" rules={[{ required: true }]}>
          <Select>
            {sscGrade.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="weightRangeInKg" label="Weight Range(KG)">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="weightInKg" label="Weight (KG)">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>

        <Form.Item name="status" label="Status">
          <Select>
            {factoryProductionStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form> */}
    </Modal>
  );
};

export default EditModal;
