import React from "react";
import { Descriptions, Tag } from "antd";
import moment from "moment";

const IncubationInfo = (props) => {
  const { incubationInfo } = props;

  return (
    <Descriptions title="Information">
      <Descriptions.Item label="Incubation Batch">
        {incubationInfo.map((item) => (
          <Tag key={item.id}>{item.batchNo ? item.batchNo : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Spawn Date">
        {incubationInfo.map((item) => (
          <Tag key={item.id}>{item.spawnDate ? moment(item.spawnDate).format("YYYY-MM-DD") : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Hatch Date">
        {incubationInfo.map((item) => (
          <Tag key={item.id}>{item.hatchDate ? moment(item.hatchDate).format("YYYY-MM-DD") : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Quantity">
        {incubationInfo.map((item) => (
          <Tag key={item.id}>{!isNaN(item.quantity) ? Number(item.quantity).toLocaleString() : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Remarks">
        {incubationInfo.map((item) => (
          <Tag key={item.id}>{item.remarks ? item.remarks : "-"}</Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        {incubationInfo.map((item) => (
          <Tag key={item.id}>{item.status ? item.status : "-"}</Tag>
        ))}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default IncubationInfo;
