import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, Select, Input, InputNumber } from "antd";
import moment from "moment";
import { useTankTransfer } from "../../../hooks/useTankTransfer";
import { useTank } from "../../../hooks/useTank";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const StockOutModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useTank();
  const { findAll: findTankUsage, update } = useTankTransfer();

  const [submitting, setSubmitting] = useState(false);

  const [tankOption, setTankOption] = useState([]);
  const [sourceTankUsageOption, setSourceTankUsageOption] = useState([]);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setTankOption(res.data);
        }
      });
      findTankUsage("New").then((res) => {
        if (res.status === "success") {
          setSourceTankUsageOption(res.data);
        }
      });
      form.setFieldsValue({
        batchNo: editItem.batchNo,
        broodstockNo: editItem.broodstockNo,
        tankId: editItem.tankId,
        sourceTankUsageId: editItem.TankUsageSources.map((item) => item.sourceTankUsageId),
        stockInTimestamp: moment(editItem.stockInTimestamp),
        stockOutTimestamp: editItem.stockOutTimestamp ? moment(editItem.stockOutTimestamp) : null,
        stockInQuantity: editItem.stockInQuantity,
        hatchDate: moment(editItem.hatchDate),
        remarks: editItem.remarks,
        status: editItem.status,
      });
    }
  }, [visible, editItem, findAll, findTankUsage, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          batchNo: values.batchNo,
          broodstockNo: values.broodstockNo,
          tankId: values.tankId,
          sourceTankUsageId: values.sourceTankUsageId ? values.sourceTankUsageId : null,
          hatchDate: moment(values.hatchDate).format(),
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          stockOutTimestamp: values.stockOutTimestamp ? moment(values.stockOutTimestamp).format() : null,
          stockInQuantity: values.stockInQuantity,
          remarks: values.remarks,
          status: values.status,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Tank Transfer"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="sourceTankUsageId" label="From Tank">
          <Select allowClear mode="multiple">
            {sourceTankUsageOption.map((item) => (
              <Option key={item.id} value={item.id} disabled={item.id === editItem.id}>
                {item.id + " - " + item.Tank.label + " (" + item.batchNo + " - " + item.stockInQuantity + ")"}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="tankId" label="To Tank" rules={[{ required: true }]}>
          <Select allowClear>
            {tankOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="batchNo" label="Batch No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="broodstockNo" label="Broodstock No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="hatchDate" label="Hatch Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockInTimestamp" label="Stock In Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockOutTimestamp" label="Stock Out Timestamp">
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockInQuantity" label="Stock In Quantity">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>

        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select>
            <Option value="New">New</Option>
            <Option value="Transfer Out">Transfer Out</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StockOutModal;
