import React, { useEffect, useState } from "react";
import { Modal, Form, Divider } from "antd";
import moment from "moment";
import EditForm from "../forms/EditForm";
import { useHatcheryProduction } from "../../../hooks/useHatcheryProduction";
import { useHatcheryTransfer } from "../../../hooks/useHatcheryTransfer";
import { useFarm } from "../../../hooks/useFarm";
import OpenTransferTable from "../tables/OpenTransferTable";

const EditModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useFarm();
  const { findOpenTransfer } = useHatcheryProduction();
  const { update } = useHatcheryTransfer();

  const [farmOption, setFarmOption] = useState([]);
  const [crabletProductionData, setCrabletProductionData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      findOpenTransfer().then((res) => {
        if (res.status === "success") {
          setCrabletProductionData([...editItem.HatcheryProductionRecords, ...res.data]);
          setSelectedRows(editItem.HatcheryProductionRecords);
        }
      });
      findAll().then((res) => {
        if (res.status === "success") {
          setFarmOption(res.data);
        }
      });
      form.setFieldsValue({
        transferTimestamp: moment(editItem.transferTimestamp),
        quantity: editItem.quantity,
        vehicleNumber: editItem.vehicleNumber,
        personInCharge: editItem.personInCharge,
        remarks: editItem.remarks,
        farmId: editItem.targetFarmId,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          transferTimestamp: moment(values.transferTimestamp),
          quantity: values.quantity,
          vehicleNumber: values.vehicleNumber,
          personInCharge: values.personInCharge,
          remarks: values.remarks,
          farmId: values.farmId ? values.farmId : null,
          hatcheryProductionArrayId: selectedRows.map((item) => item.id),
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Hatchery Production"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <EditForm form={form} farmOption={farmOption} />

      <Divider />

      <h4>Open Transfer</h4>

      <OpenTransferTable
        dataSource={crabletProductionData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </Modal>
  );
};

export default EditModal;
