import React, { useState, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { useFarm } from "../../hooks/useFarm";
import { pondType } from "../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const PondForm = (props) => {
  const { form, isEdit } = props;

  const { findAll } = useFarm();

  const [farmOption, setFarmOption] = useState([]);

  useEffect(() => {
    findAll().then((res) => {
      if (res.status === "success") {
        setFarmOption(res.data);
      }
    });
  }, [findAll]);

  return (
    <Form {...layout} form={form} name="pond-form">
      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="farmId" label="Farm">
        <Select allowClear>
          {farmOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="pondTypes" label="Type" rules={[{ required: true }]}>
        <Select allowClear mode="multiple">
          {pondType.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {isEdit && (
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select allowClear>
            <Option value="New">New</Option>
          </Select>
        </Form.Item>
      )}
    </Form>
  );
};

export default PondForm;
