import React, { useState } from "react";
import { Modal, Form } from "antd";
import RawMaterialForm from "./RawMaterialForm";
import { useFarmRawMaterial } from "../../../hooks/useFarmRawMaterial";

const AddNewModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFarmRawMaterial();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          name: values.name,
          description: values.description,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Raw Material"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <RawMaterialForm form={form} />
    </Modal>
  );
};

export default AddNewModal;
