import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Modal, Space, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { usePond } from "../../hooks/usePond";
import Filter from "../../components/pondCycle/PondCycleFilter";

import moment from "moment";
import NewPondCycleModal from "../../components/pondCycle/NewPondCycleModal";
import EditPondCycleModal from "../../components/pondCycle/EditPondCycleModal";
import { useGrowOut } from "../../hooks/useGrowOut";
import PondCycleList from "../../components/pondCycle/PondCycleList";

const { Title } = Typography;

const PondCycle = () => {
  const { t } = useTranslation();

  const { findAll: findPond } = usePond();
  const { findAll, destroy } = useGrowOut();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterPond, setFilterPond] = useState("All");
  const [filterStatus, setFilterStatus] = useState("New");

  const [pondOption, setPondOption] = useState([]);
  const [data, setData] = useState([]);
  const [parameterData, setParameterData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findPond({ status: "New" })
        .then((res) => {
          if (res.status === "success") {
            setPondOption(res.data);
          }
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));

      findAll({ pondId: filterPond, status: filterStatus }).then((res) => {
        if (res.status === "success") {
          setData(res.data);

          let array = [];
          for (const item of res.data) {
            if (array.findIndex((i) => i.material === item.material) === -1) {
              array.push({
                material: item.material,
                stockingDensity: Number(item.stockingDensity),
                count: 1,
              });
            } else {
              const index = array.findIndex((i) => i.material === item.material);
              array[index].stockingDensity += Number(item.stockingDensity);
              array[index].count += 1;
            }
          }
          setParameterData(array);
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh, filterPond, filterStatus, findPond, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const deleteRow = (row) => {
    Modal.confirm({
      title: "Are you sure delete this item?",
      onOk: () => {
        destroy(row.id).then(() => {
          setRefresh(true);
        });
      },
      okType: "danger",
      okText: "Delete",
    });
  };

  return (
    <>
      <Filter
        pondOption={pondOption}
        filterPond={filterPond}
        setFilterPond={setFilterPond}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Pond Cycle</Title>

        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Space wrap>
              {parameterData.map((item, index) => (
                <Tag key={index}>
                  {item.material}: {Number(item.stockingDensity).toLocaleString()} pcs / {item.count} pond
                </Tag>
              ))}
              <Tag>Total Pond: {parameterData.reduce((a, b) => a + b.count, 0)}</Tag>
            </Space>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <PondCycleList data={data} editRow={editRow} deleteRow={deleteRow} />

        <NewPondCycleModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditPondCycleModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default PondCycle;
