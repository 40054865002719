import React, { useState, useEffect, useContext } from "react";
import { Modal, Drawer, Row, Col, Button, Divider } from "antd";
import { useProcessingOrder } from "../../hooks/useProcessingOrder";
import ProcessingOrderInfo from "./ProcessingOrderInfo";
import ProcessingOrderDetailTable from "./ProcessingOrderDetailTable";
import NewProcessingOrderDetailModal from "./NewProcessingOrderDetailModal";
import { sscGrade, sscSize } from "../../utils/Options";
import { ResponsiveContext } from "../../App";

const ProcessingOrderDetailDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findOne, deleteDetail } = useProcessingOrder();

  const [refresh, setRefresh] = useState(true);
  const [order, setOrder] = useState({});
  const [detailData, setDetailData] = useState([]);

  const [deleteItem, setDeleteItem] = useState();
  const [confirmModal, setConfirmModal] = useState(false);

  const [addDetailModal, setAddDetailModal] = useState(false);

  useEffect(() => {
    if (visible || (visible && refresh)) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setOrder(res.data);

          let array = [];
          for (const grade of sscGrade) {
            for (const size of sscSize) {
              for (const item of res.data.ProcessingOrderDetails) {
                if (item.grade === grade && item.size === size) {
                  array.push(item);
                }
              }
            }
          }
          setDetailData(array);
        }
        setRefresh(false);
      });
    }
  }, [visible, refresh, id]);

  const onClose = () => {
    setVisible(false);
  };

  // const deleteRow = (item) => {
  //   setDeleteItem(item);
  //   setConfirmModal(true);
  // };

  const confirmDelete = () => {
    const params = {
      processingOrderDetailId: deleteItem.id,
    };
    deleteDetail(order.id, params).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setConfirmModal(false);
      }
    });
  };
  
  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "70%"}>
      <ProcessingOrderInfo item={order} />

      <Divider />

      <Row>
        <Col>
          <Button type="primary" onClick={() => setAddDetailModal(true)}>
            New Item
          </Button>
        </Col>
      </Row>

      <ProcessingOrderDetailTable dataSource={detailData} setRefresh={setRefresh} />

      <Modal visible={confirmModal} onOk={confirmDelete} onCancel={() => setConfirmModal(false)}>
        <p>Are you sure to delete this record?</p>
      </Modal>

      <NewProcessingOrderDetailModal
        order={order}
        visible={addDetailModal}
        setVisible={setAddDetailModal}
        setRefresh={setRefresh}
      />
    </Drawer>
  );
};

export default ProcessingOrderDetailDrawer;
