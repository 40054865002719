import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, Select, Input, InputNumber } from "antd";
import moment from "moment";
import { useTankTransfer } from "../../hooks/useTankTransfer";
import { larvalTransferStatus } from "../../utils/Status";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditTankTransferModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useTankTransfer();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        batchNo: editItem.batchNo,
        broodstockNo: editItem.broodstockNo,
        stockInTimestamp: moment(editItem.stockInTimestamp),
        stockOutTimestamp: editItem.stockOutTimestamp ? moment(editItem.stockOutTimestamp) : null,
        stockInQuantity: editItem.stockInQuantity,
        hatchDate: moment(editItem.hatchDate),
        remarks: editItem.remarks,
        status: editItem.status,
      });
    }
  }, [visible, editItem, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          batchNo: values.batchNo,
          broodstockNo: values.broodstockNo,
          hatchDate: moment(values.hatchDate).format(),
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          stockOutTimestamp: values.stockOutTimestamp ? moment(values.stockOutTimestamp).format() : null,
          stockInQuantity: values.stockInQuantity,
          remarks: values.remarks,
          status: values.status,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Tank Transfer"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="batchNo" label="Batch No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="broodstockNo" label="Broodstock No">
          <Input allowClear />
        </Form.Item>

        <Form.Item name="hatchDate" label="Hatch Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockInTimestamp" label="Stock In" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockOutTimestamp" label="Stock Out">
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="stockInQuantity" label="Quantity">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>

        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select>
            {Object.keys(larvalTransferStatus).map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTankTransferModal;
