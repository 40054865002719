import React, { useState, useEffect } from "react";
import { List, Typography, Row, Col, Card, Divider, Space, Select } from "antd";
import { useDashboard } from "../../../hooks/useDashboard";

const { Title, Text } = Typography;

const Pontoon = () => {
  const { findPontoonData } = useDashboard();

  const [filterPond, setFilterPond] = useState("All");
  const [data, setData] = useState([]);
  const [pondOption, setPondOption] = useState([]);
  const [totalGreen, setTotalGreen] = useState(0);
  const [totalRed, setTotalRed] = useState(0);
  const [totalGrey, setTotalGrey] = useState(0);

  useEffect(() => {
    findPontoonData().then((res) => {
      if (res.status === "success") {
        setData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (data.length) {
      const green = data
        .filter((item) => item.pondLabel === filterPond || filterPond === "All")
        .filter((item) => item.totalHarvest > item.totalDead);
      const red = data
        .filter((item) => item.pondLabel === filterPond || filterPond === "All")
        .filter((item) => item.totalHarvest < item.totalDead);
      const grey = data
        .filter((item) => item.pondLabel === filterPond || filterPond === "All")
        .filter((item) => item.totalHarvest === item.totalDead);

      setTotalGreen(green.length);
      setTotalRed(red.length);
      setTotalGrey(grey.length);

      setPondOption(
        data
          .map((item) => item.pondLabel)
          .reduce((acc, cur) => {
            if (!acc.includes(cur)) {
              acc.push(cur);
            }
            return acc;
          }, [])
      );
    }
  }, [data, filterPond]);

  return (
    <Card>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col>
          <Space>
            <div style={{ background: "green", color: "white", padding: 8 }}>Total Pontoon : {totalGreen}</div>
            <div style={{ background: "red", color: "white", padding: 8 }}>Total Pontoon : {totalRed}</div>
            <div style={{ background: "lightgrey", color: "white", padding: 8 }}>Total Pontoon : {totalGrey}</div>
          </Space>
        </Col>

        <Col>
          <Select value={filterPond} style={{ width: 200 }} onChange={(value) => setFilterPond(value)}>
            <Select.Option value="All">All</Select.Option>
            {pondOption.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Divider />

      <List
        pagination={{
          pageSize: 20,
        }}
        grid={{ gutter: 16, column: 4, xs: 2 }}
        dataSource={filterPond === "All" ? data : data.filter((item) => item.pondLabel === filterPond)}
        renderItem={(item) => (
          <List.Item>
            <Card>
              <div style={{ textAlign: "center" }}>
                <Title level={4}>{item.label}</Title>

                <Text type="secondary">{item.pondLabel}</Text>
              </div>
              <Divider
                style={{
                  background:
                    item.totalHarvest > item.totalDead ? "green" : item.totalHarvest < item.totalDead ? "red" : null,
                  height: 4,
                }}
              />
              <Row justify="space-between">
                <Col>
                  <Text type="secondary">Total Harvest</Text>
                </Col>
                <Col>{item.totalHarvest}</Col>
              </Row>
              <Row justify="space-between">
                <Col>
                  <Text type="secondary">Total Dead</Text>
                </Col>
                <Col>{item.totalDead}</Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default Pontoon;
