import React, { useState, useEffect } from "react";
import { DatePicker, Form, Select, Input, InputNumber } from "antd";
import moment from "moment";
import { useFarm } from "../../hooks/useFarm";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const HatcheryToFarmForm = (props) => {
  const { form, incomingTransfer, isEdit } = props;

  const { findAll } = useFarm();

  const [farmOption, setFarmOption] = useState([]);

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setFarmOption(res.data);
      }
    });
  }, []);

  const onChange = (value) => {
    const index = incomingTransfer.map((item) => item.id).indexOf(value);

    if (index > -1) {
      form.setFieldsValue({
        hatcheryQuantity: incomingTransfer[index].quantity,
        farmQuantity: incomingTransfer[index].quantity,
        vehicleNumber: incomingTransfer[index].vehicleNumber,
        personInCharge: incomingTransfer[index].personInCharge,
        remarks: incomingTransfer[index].remarks,
      });
    }
  };

  return (
    <Form {...layout} form={form} name="hatchery-to-farm-form">
      <Form.Item name="location" label="Location" rules={[{ required: true }]}>
        <Select>
          {farmOption.map((item) => (
            <Select.Option key={item.label} value={item.label}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="arrivalTimestamp" label="Arrival Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      {!isEdit && (
        <Form.Item name="hatcheryTransferId" label="From Hatchery" rules={[{ required: true }]}>
          <Select onChange={onChange} allowClear>
            {incomingTransfer.map((item) => (
              <Option key={item.id} value={item.id}>
                {moment(item.transferTimestamp).format("ll")} - {item.personInCharge} - {item.vehicleNumber}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item name="hatcheryQuantity" label="Hatchery Quantity">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="farmQuantity" label="Farm Quantity">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="weightInKg" label="Weight (KG)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="vehicleNumber" label="Vehicle Number">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="personInCharge" label="Person In Charge">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default HatcheryToFarmForm;
