import React, { useState, useEffect, useContext } from "react";
import { Drawer, Row, Col, Button, Descriptions, Tabs, Space, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useSSCDailyMonitor } from "../../../hooks/useSSCDailyMonitor";
import DailyRecordTable from "../tables/DailyRecordTable";
import AuditRecordTable from "../tables/AuditRecordTable";
import EditModal from "../modals/EditModal";
import EditAuditModal from "../modals/EditAuditModal";
import { useSSCAudit } from "../../../hooks/useSSCAudit";
import moment from "moment";
import { ResponsiveContext } from "../../../App";

const { TabPane } = Tabs;

const SscDrawer = (props) => {
  const { item, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findAllByPontoon } = useSSCDailyMonitor();
  const { findAll: findAuditRecord } = useSSCAudit();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [editModal, setEditModal] = useState(false);
  const [editAuditModal, setEditAuditModal] = useState(false);

  useEffect(() => {
    if ((visible || refresh) && item.id) {
      setLoading(true);

      Promise.all([findAllByPontoon(item.id), findAuditRecord({ pontoonId: item.id })]).then((res) => {
        if (res[0].status === "success") {
          setData(res[0].data);
        }
        if (res[1].status === "success") {
          setAuditData(res[1].data);
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [visible, refresh, item, findAllByPontoon, findAuditRecord]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editAuditRow = (row) => {
    setEditItem(row);
    setEditAuditModal(true);
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      visible={visible}
      width={isMobile ? "100%" : "70%"}
      title={
        <Space>
          {item.label} <Tag color={item.sscSource === "External" ? "red" : "green"}>{item.sscSource}</Tag>
        </Space>
      }
    >
      <Descriptions size="small" style={{ marginBottom: 16 }}>
        <Descriptions.Item label="Total Soft Shell Crab">{item.sscCount} pcs</Descriptions.Item>
        <Descriptions.Item label="Last Audit On">
          {auditData.length ? moment(auditData[0].recordTimestamp).format("YYYY-MM-DD h:mm a") : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Last Audit Figure">
          {auditData.length ? auditData[0].auditAlive + " pcs" : "-"}
        </Descriptions.Item>
      </Descriptions>

      <Tabs defaultActiveKey="1">
        <TabPane tab="SSC Daily Record" key="sscDailyRecord">
          <Row justify="end" style={{ marginBottom: 16 }}>
            <Col>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Col>
          </Row>

          <DailyRecordTable error={error} loading={loading} dataSource={data} editRow={editRow} />

          <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
        </TabPane>
        <TabPane tab="SSC Weekly Audit" key="sscWeeklyAudit">
          <Row justify="end" style={{ marginBottom: 16 }}>
            <Col>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Col>
          </Row>

          <AuditRecordTable error={error} loading={loading} dataSource={auditData} editRow={editAuditRow} />

          <EditAuditModal
            editItem={editItem}
            visible={editAuditModal}
            setVisible={setEditAuditModal}
            setRefresh={setRefresh}
          />
        </TabPane>

        <TabPane tab="Feeding" key="feeding"></TabPane>
      </Tabs>
    </Drawer>
  );
};

export default SscDrawer;
