import React, { useState } from "react";
import { Modal, Drawer, List, Row, Col, Typography, Space, Tag, Button } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import QRCode from "qrcode.react";
import { useBabyPack } from "../../../hooks/useBabyPack";

const { Text } = Typography;

const ProductionDrawer = (props) => {
  const { editItem, visible, setVisible, addDetail, setRefresh } = props;

  const { deleteDetail } = useBabyPack();

  const [deleteItem, setDeleteItem] = useState();
  const [confirmModal, setConfirmModal] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const Header = () => (
    <Row justify="space-between">
      <Col>
        <h4>Soft Shell Crabs</h4>
      </Col>
      <Col>
        <Button
          onClick={addDetail}
          disabled={editItem.status !== "New"}
          size="small"
          type="link"
          icon={<PlusOutlined />}
        >
          Add
        </Button>
      </Col>
    </Row>
  );

  const deleteRow = (item) => {
    setDeleteItem(item);
    setConfirmModal(true);
  };

  const confirmDelete = () => {
    const params = {
      factoryProductionRecordId: deleteItem.id,
    };
    deleteDetail(editItem.id, params).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setConfirmModal(false);
      }
    });
  };

  return (
    <Drawer title="Baby Box Content" placement="right" onClose={onClose} visible={visible} width={550}>
      <List
        header={<Header />}
        size="small"
        dataSource={editItem.FactoryProductionRecords ? editItem.FactoryProductionRecords : []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                onClick={() => deleteRow(item)}
                disabled={item.status !== "Baby Pack"}
                size="small"
                type="link"
                icon={<DeleteOutlined />}
              />,
            ]}
          >
            <List.Item.Meta
              avatar={<QRCode id={item.id} value={item.QRCode.code} size={50} />}
              title={"Grade " + item.grade}
              description={"Packed on " + moment(item.productionTimestamp).format("YYYY-MM-DD")}
            />
            <div>
              <Space>
                <Tag color="magenta">{item.weightRangeInKg ? item.weightRangeInKg : "-"} kg</Tag>
                <Tag>{item.status}</Tag>
              </Space>
            </div>
          </List.Item>
        )}
      />

      <Modal
        // title="Are you sure to delete this record?"
        visible={confirmModal}
        onOk={confirmDelete}
        onCancel={() => setConfirmModal(false)}
      >
        <p>Are you sure to delete this record?</p>
      </Modal>
    </Drawer>
  );
};

export default ProductionDrawer;
