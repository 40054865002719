import React, { useEffect } from "react";
import { Card, Form, Select, Button, DatePicker } from "antd";
import { tankTransferStatus } from "../../../utils/Options";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Filter = (props) => {
  const {
    tankOption,
    filterDateRange,
    filterTank,
    filterStatus,
    setFilterDateRange,
    setFilterTank,
    setFilterStatus,
    setRefresh,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      date: filterDateRange,
      tankId: filterTank,
      status: filterStatus,
    });
  }, [form, filterDateRange, filterTank, filterStatus]);

  const onFinish = (values) => {
    setFilterDateRange(values.date);
    setFilterTank(values.tankId);
    setFilterStatus(values.status);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Date" name="date">
          <RangePicker allowClear />
        </Form.Item>

        <Form.Item label="Tank" name="tankId">
          <Select allowClear style={{ width: 150 }}>
            <Option value="All">All</Option>
            {tankOption.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select allowClear style={{ width: 150 }}>
            {tankTransferStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
