import React, { useState, useEffect } from "react";
import { Descriptions, Row, Col, DatePicker, Typography, Space, Button, Card } from "antd";
import { useDashboard } from "../../../hooks/useDashboard";
const moment = require("moment");

const { RangePicker } = DatePicker;

const FarmReceipt = () => {
  const { findMaterialReceiptData } = useDashboard();

  const [selectedDateRange, setSelectedDateRange] = useState([moment().subtract(7, "day"), moment()]);

  const [internalData, setInternalData] = useState([]);
  const [externalData, setExternalData] = useState([]);

  useEffect(() => {
    if (selectedDateRange) {
      const startDate = moment(selectedDateRange[0]).format("YYYY-MM-DD");
      const endDate = moment(selectedDateRange[1]).format("YYYY-MM-DD");

      findMaterialReceiptData({ startDate: startDate, endDate: endDate }).then((res) => {
        if (res.status === "success") {
          setInternalData(res.data.fromHatchery);
          setExternalData(res.data.fromExternal);
        }
      });
    }
  }, [selectedDateRange]);

  const onDateChanged = (value) => {
    if (value) {
      setSelectedDateRange(value);
    }
  };

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} flex="none">
          <RangePicker style={{ width: 250 }} value={selectedDateRange} onChange={onDateChanged} />
        </Col>

        <Col xs={24} flex="auto">
          <Space>
            <Button size="small" type="link" onClick={() => setSelectedDateRange([moment(), moment()])}>
              Today
            </Button>
            <Button
              size="small"
              type="link"
              onClick={() => setSelectedDateRange([moment().subtract(7, "day"), moment()])}
            >
              Last 7 Days
            </Button>

            <Button
              size="small"
              type="link"
              onClick={() => setSelectedDateRange([moment().startOf("month"), moment().endOf("month")])}
            >
              This Month
            </Button>

            <Button
              size="small"
              type="link"
              onClick={() =>
                setSelectedDateRange([
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ])
              }
            >
              Last Month
            </Button>
          </Space>
        </Col>
      </Row>

      <Card style={{ marginTop: 16 }}>
        <Descriptions title="From Hatchery">
          <Descriptions.Item label={"Total PCS"}>
            <Typography.Text strong>
              {Number(internalData.reduce((acc, cur) => acc + cur.farmQuantity, 0)).toLocaleString()}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <Descriptions title="From External">
          <Descriptions.Item label={"Total PCS"}>
            <Typography.Text strong>
              {Number(externalData.reduce((acc, cur) => acc + cur.farmQuantity, 0)).toLocaleString()}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions>
          {externalData.map((item) => (
            <Descriptions.Item label={item.sourceName} key={item.id}>
              <Typography.Text strong>
                {Number(
                  externalData
                    .filter((el) => el.sourceName === item.sourceName)
                    .reduce((acc, cur) => acc + cur.farmQuantity, 0)
                ).toLocaleString()}
              </Typography.Text>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Card>
    </>
  );
};

export default FarmReceipt;
