import React, { useState, useEffect } from "react";
import { Descriptions, Row, Col, DatePicker, Space, Card, Select, Tag, Empty, Button } from "antd";
import { farmProductionType } from "../../../utils/Options";
import { useProcessing } from "../../../hooks/useProcessing";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import OrderDetailDrawer from "../../../components/deliveryOrder/drawers/OrderDetailDrawer";
const moment = require("moment");

const { RangePicker } = DatePicker;

const DeliveryOrderTab = () => {
  const { findAll } = useDeliveryOrder();

  const { findAll: findProcessing } = useProcessing();

  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState([]);
  const [dataDetail, setDataDetail] = useState({});
  const [totalProduct, setTotalProduct] = useState();

  const [soldToOption, setSoldToOption] = useState([]);

  const [filterDate, setFilterDate] = useState([moment().add(-30, "days"), moment()]);
  const [filterProduct, setFilterProduct] = useState();
  const [filterSoldTo, setFilterSoldTo] = useState();

  const [editItem, setEditItem] = useState({});
  const [orderDetailDrawer, setOrderDetailDrawer] = useState(false);

  useEffect(() => {
    findProcessing().then((res) => {
      if (res.status === "success") {
        setSoldToOption(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (data.length) {
      let obj = {};
      let total = 0;
      for (const item of data) {
        const orderDetails = item.DeliveryOrderDetails;

        if (orderDetails.length) {
          for (const detailItem of orderDetails) {
            const product = detailItem.type;
            const grade = detailItem.grade;
            const size = detailItem.size;

            if (obj[product] && obj[product][grade] && obj[product][grade][size]) {
              obj[product][grade][size] += Number(detailItem.quantity);
            } else {
              obj[product] = obj[product] || {};
              obj[product][grade] = obj[product][grade] || {};
              obj[product][grade][size] = Number(detailItem.quantity);
            }
            total += Number(detailItem.quantity);
          }
        }
      }
      setDataDetail(obj);
      setTotalProduct(total);
    } else {
      setDataDetail({});
      setTotalProduct(0);
    }
  }, [data]);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0] : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1] : null;

      findAll({
        startDate: startDate,
        endDate: endDate,
        targetProcessingId: filterSoldTo,
        product: filterProduct,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
      });
    }
  }, [refresh, filterDate, filterSoldTo, filterProduct]);

  const onDateChanged = (value) => {
    if (value) {
      setFilterDate(value);
    }
  };

  return (
    <>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24}>
          <Space wrap>
            <RangePicker style={{ width: 250 }} value={filterDate} onChange={onDateChanged} />
            <Select
              placeholder="Product"
              allowClear
              style={{ width: 150 }}
              onChange={(value) => {
                setFilterProduct(value);
                setRefresh(true);
              }}
              value={filterProduct}
            >
              {farmProductionType.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Sold To"
              allowClear
              style={{ width: 150 }}
              onChange={(value) => {
                setFilterSoldTo(value);
                setRefresh(true);
              }}
              value={filterSoldTo}
            >
              {soldToOption.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Space>
        </Col>
      </Row>

      <Card style={{ marginTop: 16 }} title={`Total Delivery Order: ${data.length}`}>
        <Space wrap>
          {data.length ? (
            data.map((item) => (
              <Button
                type="link"
                onClick={() => {
                  setEditItem(item);
                  setOrderDetailDrawer(true);
                }}
                key={item.id}
              >
                {item.orderNo}
              </Button>
            ))
          ) : (
            <Empty />
          )}
        </Space>
      </Card>

      <Card style={{ marginTop: 16 }} hidden={JSON.stringify(dataDetail) === "{}"}>
        {Object.entries(dataDetail)
          .filter(([key, value]) => key !== "null")
          .map(([key, value]) => (
            <Descriptions
              title={key}
              layout="vertical"
              column={1}
              extra={`Total: ${Number(totalProduct).toLocaleString()} pcs`}
            >
              {Object.entries(value).map(([key, value]) => (
                <Descriptions.Item label={"Grade " + key}>
                  {Object.entries(value).map(([key, value]) => (
                    <Tag>
                      {key} : {Number(value).toLocaleString()}
                    </Tag>
                  ))}
                  <Tag color={"blue"}>
                    Total : {Number(Object.values(value).reduce((a, b) => a + b, 0)).toLocaleString()}
                  </Tag>
                </Descriptions.Item>
              ))}
            </Descriptions>
          ))}
      </Card>

      <OrderDetailDrawer
        editItem={editItem}
        visible={orderDetailDrawer}
        setVisible={setOrderDetailDrawer}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default DeliveryOrderTab;
