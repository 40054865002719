import React, { useState, useEffect } from "react";
import { Modal, Form, DatePicker, Typography, Divider, InputNumber } from "antd";
import { useTank } from "../../../hooks/useTank";
import moment from "moment";
import { useIncubationRecord } from "../../../hooks/useIncubationRecord";

const { Paragraph } = Typography;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const LarvalStockInModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useTank();
  const { updateLarvalStockIn } = useIncubationRecord();

  const [submitting, setSubmitting] = useState(false);
  const [tankOption, setTankOption] = useState([]);
  const [stockInArray, setStockInArray] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (visible) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setTankOption(res.data);
          setStockInArray([]);
          setError("");
        }
      });
      form.setFieldsValue({
        stockInTimestamp: moment(),
      });
    }
  }, [visible, findAll, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          stockInArray: stockInArray.filter((item) => item.count !== null),
        };

        if (params.stockInArray.length <= 0) {
          setError("Please enter Larval Stock In Quantity");
          return;
        }

        setSubmitting(true);
        updateLarvalStockIn(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChange = (tankId, value) => {
    const index = stockInArray.map((item) => item.tankId).indexOf(tankId);
    if (index > -1) {
      let array = [...stockInArray];
      array[index] = { tankId: tankId, count: value };
      setStockInArray(array);
    } else {
      setStockInArray([...stockInArray, { tankId: tankId, count: value }]);
    }
  };

  return (
    <Modal
      title={"Larval Stock In"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        <Form.Item name="stockInTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Divider orientation="left">Larval Tank</Divider>

        {error && <Paragraph type="danger">{error}</Paragraph>}

        {tankOption.map((item) => (
          <Form.Item label={item.label}>
            <InputNumber allowClear onChange={(value) => onChange(item.id, value)} />
          </Form.Item>
        ))}
        {/* <Form.Item name="tankId" label="To Larval Tank" rules={[{ required: true }]}>
          <Checkbox.Group>
            {tankOption.length
              ? tankOption.map((item) => (
                  <Checkbox value={item.id} style={{ lineHeight: "32px" }}>
                    {item.label}
                  </Checkbox>
                ))
              : "All larval tanks are in used"}
          </Checkbox.Group>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default LarvalStockInModal;
