import React, { useEffect, useState } from "react";
import { Modal, Form, Input, InputNumber, Select, DatePicker } from "antd";
import moment from "moment";
import { harvestShift } from "../../utils/Options";
import { usePontoonFeed } from "../../hooks/usePontoonFeed";

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const EditSSCFeedModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePontoonFeed();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        shift: editItem.shift,
        feedType: editItem.feedType,
        weightInKg: editItem.weightInKg,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          shift: values.shift,
          feedType: values.feedType,
          weightInKg: values.weightInKg,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit SSC Feeding"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="add-new-form">
        <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="shift" label="Shift" rules={[{ required: true }]}>
          <Select allowClear>
            {harvestShift.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="feedType" label="Feed Type" rules={[{ required: true }]}>
          <Select allowClear>
            {["Fish", "Shrimp"].map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="weightInKg" label="Total Feed (kg)" rules={[{ required: true }]}>
          <InputNumber min={0} />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSSCFeedModal;
