import React from "react";
import { DatePicker, Form, Select, Input, InputNumber } from "antd";
import moment from "moment";

const { Option } = Select;

const AddNewForm = (props) => {
  const { form, tankOption, sourceTankUsageOption } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSourceTankSelected = (value) => {
    const index = sourceTankUsageOption.map((item) => item.id).indexOf(value);

    if (index > -1) {
      form.setFieldsValue({
        batchNo: sourceTankUsageOption[index].batchNo,
        broodstockNo: sourceTankUsageOption[index].broodstockNo,
        hatchDate: sourceTankUsageOption[index].hatchDate ? moment(sourceTankUsageOption[index].hatchDate) : null,
      });
    }
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="sourceTankUsageId" label="From Tank">
        <Select allowClear onSelect={onSourceTankSelected} mode="multiple">
          {sourceTankUsageOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.Tank.label + " (" + item.batchNo + " - " + item.stockInQuantity + ")"}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="tankId" label="To Tank" rules={[{ required: true }]}>
        <Select allowClear>
          {tankOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="batchNo" label="Batch No">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="broodstockNo" label="Broodstock No">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="hatchDate" label="Hatch Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="stockInTimestamp" label="Stock In Timestamp" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="stockInQuantity" label="Stock In Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
