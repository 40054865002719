import { useCallback, useContext } from 'react';
import { AuthContext, fp } from '../App';
import Constants from "../utils/Constants";

const apiBaseUrl = Constants.SECURITY_URL;

const useAuth = () => {

    const authCtx = useContext(AuthContext);

    const login = useCallback(async (params) => {
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params),
            credentials: 'include'
        }
        return await fetch(apiBaseUrl + '/login', config)
            .then(res => res.json()).catch(err => err);
    }, [])

    const logout = useCallback(async () => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authCtx.authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            credentials: 'include'
        }
        return await fetch(apiBaseUrl + '/logout', config)
            .then(res => res.json()).catch(err => err);
    }, [authCtx])

    const auth = useCallback(async () => {
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            credentials: 'include'
        }
        return await fetch(apiBaseUrl + '/auth', config)
            .then(res => res.json()).catch(err => err);
    }, []);


    const edit = useCallback(async () => {
        const url = apiBaseUrl + '/users/' + authCtx.authState.authUser.id + '/edit';

        const config = {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + authCtx.authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
        }

        return await fetch(url, config)
            .then(res => res.json()).catch(err => err);
    }, [authCtx])


    const updatePreference = useCallback(async (params) => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authCtx.authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params)
        }

        return await fetch(apiBaseUrl + '/auth/profile/preferences/update', config)
            .then(res => res.json()).catch(err => err);
    }, [authCtx]);

    const updateBasicSettings = useCallback(async (params) => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authCtx.authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params)
        }
        return await fetch(apiBaseUrl + '/auth/profile/basicSettings/update', config)
            .then(res => res.json()).catch(err => err);
    }, [authCtx]);

    const updatePassword = useCallback(async (params) => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authCtx.authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params)
        }
        return await fetch(apiBaseUrl + '/auth/profile/securitySettings/updatePassword', config)
            .then(res => res.json()).catch(err => err);
    }, [authCtx]);

    const updatePin = useCallback(async (params) => {
        const config = {
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + authCtx.authState.token,
                'Content-Type': 'application/json',
                'FpId': (await fp).visitorId,
            },
            body: JSON.stringify(params)
        }
        return await fetch(apiBaseUrl + '/auth/profile/securitySettings/updatePin', config)
            .then(res => res.json()).catch(err => err);
    }, [authCtx]);

    return { login, logout, auth, edit, updatePreference, 
        updateBasicSettings, updatePassword, updatePin }

};


export { useAuth };