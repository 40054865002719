import React, { useState, useEffect, useContext } from "react";
import { Drawer, Row, Col, Button, Descriptions, Space, Tag } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useGrowOutRecord } from "../../../hooks/useGrowOutRecord";
import GrowOutTable from "../tables/GrowOutTable";
import moment from "moment";
// import LarvalInfo from "../components/LarvalInfo";
// import ChemicalUsage from "../components/ChemicalUsage";
import AddNewModal from "../modals/AddNewModal";
import EditModal from "../modals/EditModal";
// import HarvestModal from "../modals/HarvestModal";
import { ResponsiveContext } from "../../../App";
// import DuplicateModal from "../modals/DuplicateModal";
// import DailyRecordTable from "../tables/DailyRecordTable";

const GrowOutDrawer = (props) => {
  const { item, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findAll } = useGrowOutRecord();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();
  const [totalStockIn, setTotalStockIn] = useState(0);
  const [totalStockOut, setTotalStockOut] = useState(0);

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [harvestModal, setHarvestModal] = useState(false);

  useEffect(() => {
    if ((visible || refresh) && item.id) {
      setLoading(true);
      findAll({ growOutId: item.id }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
          setTotalStockIn(res.data.map((item) => item.stockInQuantity).reduce((a, b) => a + b, 0));
          setTotalStockOut(res.data.map((item) => item.stockOutQuantity).reduce((a, b) => a + b, 0));
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [visible, refresh, item]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const doHarvest = () => {
    setHarvestModal(true);
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "80%"} title={item.label}>
      <Descriptions title={"Cycle " + item.cycleNo + ", " + item.material} size="small" style={{ marginBottom: 16 }}>
        <Descriptions.Item label="DOC">
          {data.length
            ? moment()
                .startOf("day")
                .diff(moment(data[data.length - 1].recordTimestamp).startOf("day"), "days", false)
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Survival Rate">
          {totalStockIn && totalStockIn > 0
            ? ((Number(totalStockOut) / Number(totalStockIn)) * 100).toFixed(2) + " %"
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Est. Remaining">
          {(Number(totalStockIn) - Number(totalStockOut)).toLocaleString()}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={item.status === "Complete" ? "default" : "green"}>{item.status ?? "-"}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Dead (pcs)">
          {data.map((item) => item.dead).reduce((a, b) => a + b, 0)}
        </Descriptions.Item>
        <Descriptions.Item label="Broodstock (pcs)">
          {data.map((item) => item.broodstock).reduce((a, b) => a + b, 0)}
        </Descriptions.Item>
      </Descriptions>

      <Row justify="space-between">
        <Col>
          <Space>
            {/* <Button type="primary" disabled={item.status === "Complete"} onClick={() => setAddNewModal(true)}>
              New Stock In
            </Button> */}

            {/* <Button type="default" disabled={item.status === "Complete"} onClick={doHarvest}>
              New Harvest
            </Button> */}
          </Space>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </Col>
      </Row>
      <GrowOutTable error={error} loading={loading} dataSource={data} editRow={editRow} />

      <AddNewModal visible={addNewModal} selectedPond={item} setVisible={setAddNewModal} setRefresh={setRefresh} />

      <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

      {/* <HarvestModal selectedPond={item} visible={harvestModal} setVisible={setHarvestModal} setRefresh={setRefresh} /> */}
    </Drawer>
  );
};

export default GrowOutDrawer;
