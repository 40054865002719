import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useFactoryReceipt } from "../../hooks/useFactoryReceipt";

import FactoryReceiptTable from "./FactoryReceiptTable";

import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import Filter from "./components/Filter";
import moment from "moment";
import FactoryReceiptDrawer from "./FactoryReceiptDrawer";
// import { useHaccp } from "../../hooks/useHaccp";
import NewFactoryProductionModal from "../factoryProduction/NewFactoryProductionModal";

const { Title } = Typography;

const FactoryReceiptList = () => {
  const { t } = useTranslation();

  const { findAll, update } = useFactoryReceipt();
  // const { downloadRawMatReceipt } = useHaccp();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);
  const [filterStatus, setFilterStatus] = useState("New");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [detailDrawer, setDetailDrawer] = useState(false);
  const [newFactoryProductionModal, setNewFactoryProductionModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, findAll, t]);

  const editRow = (item) => {
    setEditItem(item);
    setEditModal(true);
  };

  const processRow = (item) => {
    setEditItem(item);
    setNewFactoryProductionModal(true);
  };

  // const downloadHaccpRawMatReceipt = () => {
  //   downloadRawMatReceipt({
  //     receiptArrayId: selectedRows.map((item) => item.id),
  //   }).then(async (res) => {
  //     const stream = await res.body.getReader().read();

  //     const file = new Blob([stream.value], {
  //       type: "application/pdf",
  //     });

  //     const fileURL = URL.createObjectURL(file);

  //     window.open(fileURL);
  //   });
  // };

  const viewDetail = (item) => {
    setEditItem(item);
    setDetailDrawer(true);
  };

  const markAsComplete = (item) => {
    update(item.id, { status: "Complete" }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };
  return (
    <>
      <Filter
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Grading Details Record</Title>

        <Row justify="space-between">
          <Col>
            <Space>
              <Button type="primary" onClick={() => setAddNewModal(true)}>
                New
              </Button>
              {/* <Button disabled={selectedRows.length <= 0} onClick={downloadHaccpRawMatReceipt}>
                Sample HACCP
              </Button> */}
            </Space>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <FactoryReceiptTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          viewDetail={viewDetail}
          processRow={processRow}
          markAsComplete={markAsComplete}
        />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <FactoryReceiptDrawer
          id={editItem.id}
          visible={detailDrawer}
          setVisible={setDetailDrawer}
          setRefresh={setRefresh}
        />
        <NewFactoryProductionModal
          id={editItem.id}
          visible={newFactoryProductionModal}
          setVisible={setNewFactoryProductionModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default FactoryReceiptList;
