import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import AddNewForm from "../forms/AddNewForm";
import moment from "moment";
import { useGrowOut } from "../../../hooks/useGrowOut";
import { useGrowOutRecord } from "../../../hooks/useGrowOutRecord";

const AddNewModal = (props) => {
  const { visible, selectedPond, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findGrowOut } = useGrowOut();
  const { create } = useGrowOutRecord();

  const [pondOption, setPondOption] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      findGrowOut({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setPondOption(res.data);
        }
      });

      form.setFieldsValue({
        stockInTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          growOutId: selectedPond.id,
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          stockInQuantity: values.stockInQuantity,
          remarks: values.remarks,
          source: values.source,
          sourceGrowOutId: values.sourceGrowOutId,
          markGrowOutAsComplete: values.markGrowOutAsComplete,
          // sourceHapaId: values.sourceHapaId,
          // markHapaAsComplete: values.markHapaAsComplete,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Stock In"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <AddNewForm form={form} pondOption={pondOption} />
    </Modal>
  );
};

export default AddNewModal;
