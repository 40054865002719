import React, { useState, useEffect } from "react";
import { Typography, Row, Col, List, Tag, Card } from "antd";
import { useGrowOut } from "../../hooks/useGrowOut";

import Filter from "./components/Filter";
import GrowOutDrawer from "./drawers/GrowOutDrawer";
import { usePond } from "../../hooks/usePond";

const { Title } = Typography;

const GrowOutRecord = () => {
  const { findAll } = usePond();

  const { findGrowOut } = useGrowOut();

  const [refresh, setRefresh] = useState(true);

  const [filterStatus, setFilterStatus] = useState("New");
  const [filterPond, setFilterPond] = useState("All");

  const [pondOption, setPondOption] = useState([]);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [growOutDrawer, setGrowOutDrawer] = useState(false);

  useEffect(() => {
    findAll({ status: "New" }).then((res) => {
      if (res.status === "success") {
        setPondOption(res.data);
      }
    });
  }, []);

  useEffect(() => {
    if (refresh) {
      findGrowOut({ status: filterStatus, pondId: filterPond }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [refresh]);

  const viewDetail = (item) => {
    setEditItem(item);
    setGrowOutDrawer(true);
  };

  return (
    <>
      <Filter
        pondOption={pondOption}
        filterPond={filterPond}
        setFilterPond={setFilterPond}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Grow Out Record</Title>

        <List
          size="small"
          grid={{ gutter: [8, 16], column: 4 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item onClick={() => viewDetail(item)}>
              <Card size="small" title={item.Pond.label}>
                <Row>
                  <Col style={{ textAlign: "right" }} span={24}>
                    <Tag style={{ fontSize: 10 }} color={item.status === "Complete" ? "default" : "green"}>
                      Cy. {item.cycleNo}
                    </Tag>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{ fontSize: 24, color: "grey", textTransform: "capitalize", textAlign: "center" }}
                    span={24}
                  >
                    {item.material.toLowerCase()}
                  </Col>
                </Row>
                <Row justify="space-between" style={{ fontSize: 10, color: "grey", marginTop: 8 }}>
                  <Col>{item.label}</Col>
                </Row>
              </Card>
            </List.Item>
          )}
        />

        <GrowOutDrawer item={editItem} visible={growOutDrawer} setVisible={setGrowOutDrawer} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default GrowOutRecord;
