import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, Modal } from "antd";
import { useNurseryRecord } from "../../hooks/useNurseryRecord";
import moment from "moment";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const EditNurseryRecordModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useNurseryRecord();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        stockDate: moment(editItem.stockDate),
        remarks: editItem.remarks,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          stockDate: moment(values.stockDate).format(),
          remarks: values.remarks,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Nursery Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="edit-form">
        <Form.Item name="stockDate" label="Stock Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea allowClear rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditNurseryRecordModal;
