import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, InputNumber, Typography, Tabs, Button, Divider } from "antd";
import moment from "moment";
import { useFarmReceipt } from "../../../hooks/useFarmReceipt";
import { useGrowOut } from "../../../hooks/useGrowOut";
import { usePontoon } from "../../../hooks/usePontoon";

const { Text } = Typography;
const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const HapaModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findGrowOut } = useGrowOut();
  const { findAll: findPontoon } = usePontoon();

  const { stockInMaterial } = useFarmReceipt();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [growOutOption, setGrowOutOption] = useState([]);
  const [pontoonOption, setPontoonOption] = useState([]);
  const [growOutArray, setGrowOutArray] = useState([]);
  const [pontoonArray, setPontoonArray] = useState([]);
  const [hapaArray, setHapaArray] = useState([
    {
      hapaId: 1,
      quantity: 0,
    },
  ]);

  useEffect(() => {
    if (visible) {
      findGrowOut({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setGrowOutOption(res.data);
        }
      });

      findPontoon({ sscSource: "Internal" }).then((res) => {
        if (res.status === "success") {
          setPontoonOption(res.data);
        }
      });

      form.setFieldsValue({
        stockInTimestamp: moment(),
      });
    } else {
      setGrowOutArray([]);
      setPontoonArray([]);
      setHapaArray([
        {
          hapaId: 1,
          quantity: 0,
        },
      ]);
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const growOutRecord = growOutArray.filter((item) => item.quantity && item.quantity > 0);
        const pontoonRecord = pontoonArray.filter((item) => item.quantity && item.quantity > 0);
        const hapaRecord = hapaArray.filter((item) => item.quantity && item.quantity > 0);

        const params = {
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          growOutArray: growOutRecord,
          pontoonArray: pontoonRecord,
          hapaArray: hapaRecord,
          // nurseryStockInQuantity: values.nurseryStockInQuantity,
          // nurseryTotalHapa: values.nurseryTotalHapa,
        };
        setSubmitting(true);

        stockInMaterial(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onGrowOutChange = (value, item) => {
    const index = growOutArray.map((item) => item.growOutId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...growOutArray];
      newArray[index] = {
        growOutId: item.id,
        quantity: value,
      };
      setGrowOutArray(newArray);
    } else {
      setGrowOutArray([...growOutArray, { growOutId: item.id, quantity: value }]);
    }
  };

  const onPontoonChange = (value, item) => {
    const index = pontoonArray.map((item) => item.pontoonId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...pontoonArray];
      newArray[index] = {
        pontoonId: item.id,
        quantity: value,
      };
      setPontoonArray(newArray);
    } else {
      setPontoonArray([...pontoonArray, { pontoonId: item.id, quantity: value }]);
    }
  };

  const onHapaChange = (value, item) => {
    const index = hapaArray.map((item) => item.hapaId).indexOf(item.hapaId);

    if (index > -1) {
      const newArray = [...hapaArray];
      newArray[index] = {
        hapaId: item.hapaId,
        quantity: value,
      };
      setHapaArray(newArray);
    } else {
      setHapaArray([...hapaArray, { hapaId: item.hapaId, quantity: value }]);
    }
  };

  return (
    <Modal
      title={"Pond/Pontoon Stock In"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} labelAlign="left">
        <Form.Item name="stockInTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Small Crab" key="growOut">
            {growOutOption.map((item) => (
              <Form.Item
                label={item.Pond ? item.Pond.label : "-"}
                extra={"Cycle " + item.cycleNo + ", " + item.material}
              >
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  allowClear
                  onChange={(value) => onGrowOutChange(value, item)}
                />
              </Form.Item>
            ))}
          </TabPane>
          <TabPane tab="Soft Shell Crab" key="Pontoon">
            {pontoonOption.map((item) => (
              <Form.Item label={item.label}>
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  allowClear
                  onChange={(value) => onPontoonChange(value, item)}
                />
              </Form.Item>
            ))}
          </TabPane>

          <TabPane tab="Nursery" key="nursery">
            <Button
              block
              onClick={() => {
                setHapaArray([...hapaArray, { hapaId: hapaArray.length + 1, quantity: 0 }]);
              }}
            >
              Add
            </Button>
            <Divider />
            <Form.Item
              label="Total"
              extra={
                hapaArray.reduce((acc, item) => {
                  return acc + item.quantity;
                }, 0) > editItem.farmQuantity ? (
                  <Text type="danger">HAPA quantity exceeds received quantity</Text>
                ) : null
              }
            >
              {hapaArray.reduce((acc, item) => acc + item.quantity, 0)} / {editItem.farmQuantity}
            </Form.Item>

            {hapaArray.map((item, index) => (
              <Form.Item key={index} label={"HAPA " + item.hapaId}>
                <InputNumber inputMode="numeric" min={0} allowClear onChange={(value) => onHapaChange(value, item)} />
              </Form.Item>
            ))}
          </TabPane>
        </Tabs>

        {error && <Text type="danger">Please input at least one Stock In quantity </Text>}
      </Form>
    </Modal>
  );
};

export default HapaModal;
