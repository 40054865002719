import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { useTank } from "../../hooks/useTank";
import TankForm from "./forms/TankForm";

const EditTankModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useTank();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        label: editItem.label,
        shed: editItem.shed,
        sizeInLitre: editItem.sizeInLitre,
        status: editItem.status,
      });
    }
  }, [visible, editItem, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          label: values.label,
          shed: values.shed,
          sizeInLitre: values.sizeInLitre,
          status: values.status,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Tank"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <TankForm form={form} mode="edit" />
    </Modal>
  );
};

export default EditTankModal;
