import React from "react";
import { Tooltip, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const ExternalToFarmTable = (props) => {
  const { dataSource, loading, error, editRow, editTransferToHapa } = props;

  const columns = [
    {
      title: "Arrival Date",
      dataIndex: "arrivalTimestamp",
      render: (text, row) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Origin",
      dataIndex: "sourceName",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Qty (PCS)",
      dataIndex: "farmQuantity",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Weight (KG)",
      dataIndex: "weightInKg",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Dead (PCS)",
      dataIndex: "dead",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Dead (KG)",
      dataIndex: "deadWeightInKg",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Small (PCS)",
      dataIndex: "small",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, row) => (text === "New" ? <a onClick={() => editTransferToHapa(row)}>{text}</a> : text),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default ExternalToFarmTable;
