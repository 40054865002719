import React from "react";
import { Button, Tag, Dropdown, Menu } from "antd";
import { EditOutlined, EnterOutlined, EllipsisOutlined, DownloadOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";
import moment from "moment";
import { larvalTransferStatus } from "../../utils/Status";

const TankTransferRecordTable = (props) => {
  const { dataSource, loading, error, editRow, editProduction, downloadLarvalData } = props;

  const columns = [
    {
      title: "Batch",
      dataIndex: "batchNo",
      render: (text, row) => (text ? <Tag color={larvalTransferStatus[row.status].color}>{text}</Tag> : "-"),
    },
    {
      title: "B.S. #",
      dataIndex: "broodstockNo",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Species",
      render: (text, row) => (row.HatcheryReceipt ? row.HatcheryReceipt.species : "-"),
    },

    {
      title: "Hatch Date",
      dataIndex: "hatchDate",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Quantity",
      dataIndex: "stockInQuantity",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Stock In",
      dataIndex: "stockInTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Stock Out",
      dataIndex: "stockOutTimestamp",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   render: (text) => (text ? text : "-"),
    // },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="edit"
                icon={<EnterOutlined />}
                onClick={() => editProduction(row)}
                disabled={row.stockOutTimestamp}
              >
                Harvest
              </Menu.Item>
              <Menu.Item key="download" icon={<DownloadOutlined />} onClick={() => downloadLarvalData(row.id)}>
                Daily Larval Record
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <TableLoader
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        expandable={{
          expandedRowRender: (row) => (row.remarks ? row.remarks : "-"),
          rowExpandable: (row) => row.remarks,
        }}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default TankTransferRecordTable;
