import React, { useState, useEffect, useContext } from 'react';
import { Empty, List, Row, Col, Space, Button } from 'antd';
import { useTranslation } from "react-i18next";
import { BellOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { AuthContext } from '../../App';
import { useNotification } from '../../hooks/useNotification';

const moment = require('moment');

const Notification = (props) => {

    const { t } = useTranslation();

    const { authState } = useContext(AuthContext);

    const {
        getNotificationList,
        markNotificationAsRead,
        clearAllNotification,
        markAllAsRead
    } = useNotification();

    const [data, setData] = useState([]);

    useEffect(() => {
        getNotificationList().then(res => {
            if (res.status === 'success') {
                setData(res.data.notifications);
            }
        })
    }, [getNotificationList])

    const markAsRead = (item) => {
        let array = data;
        const index = data.map(item => item.id).indexOf(item.id);
        array[index].hasRead = true;
        setData(array);

        markNotificationAsRead(item.id, { userId: authState.authUser.id });
    }

    const markAll = () => {
        let array = data.map(item => {
            return { ...item, hasRead: true }
        });
        setData(array);
        markAllAsRead({ userId: authState.authUser.id });
    }

    const clearAll = () => {
        setData([]);
        clearAllNotification({ userId: authState.authUser.id });
    }

    return (
        <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
            <Row justify="end">
                <Col>
                    <Space>
                        <Button type="primary" onClick={() => markAll()}>
                            {t('action.markAllAsRead')}
                        </Button>
                        <Button type="ghost" danger onClick={() => clearAll()}>
                            {t('action.clearAll')}
                        </Button>
                    </Space>
                </Col>
            </Row>

            <div style={{ height: 16 }}></div>

            {data.length > 0 ?
                <List
                    bordered
                    pagination={{
                        onChange: page => {
                            console.log(page);
                        },
                        pageSize: 10,
                        // size:"small"
                    }}
                    header={t('notification.title')}
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <Link to={item.url}>

                            <List.Item style={{
                                padding: '12px 24px',
                                borderBottom: '1px solid #ececec',
                                backgroundColor: item.hasRead ? null : '#f0f8ff'
                            }} onClick={() => markAsRead(item)}>
                                <List.Item.Meta
                                    avatar={<BellOutlined />}
                                    title={<span style={{ fontWeight: 'normal' }}>{item.content}</span>}
                                    description={<span style={{ fontSize: 11 }}>{moment(item.createdAt).fromNow()}</span>}
                                />
                            </List.Item>
                        </Link>
                    )} />
                :
                <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                    <Empty description="No new notification" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            }
        </div>
    );
}

export default Notification;