import React, { useEffect, useState } from "react";
import { Modal, Button, Card, Typography, List } from "antd";
import { useRecall } from "../../hooks/useRecall";
import { BlockOutlined } from "@ant-design/icons";
import FlureeBlockModal from "./FlureeBlockModal";

const { Text } = Typography;

const FlureeHistoryModal = (props) => {
  const { id, visible, setVisible } = props;

  const { showHistory } = useRecall();

  const [data, setData] = useState([]);
  const [predicate, setPredicate] = useState({});
  const [blockId, setBlockId] = useState();
  const [flureeBlockModal, setFlureeBlockModal] = useState(false);

  useEffect(() => {
    if (visible && id) {
      showHistory(id).then((res) => {
        if (res.status === "success") {
          let array = res.data.historyData;
          array.sort((a, b) => {
            if (a.block < b.block) return 1;
            if (a.block > b.block) return -1;
            return 0;
          });
          setData(array);

          setPredicate(
            res.data.predicates
              .filter((item) => item.name.startsWith("Event/"))
              .reduce((a, v) => ({ ...a, [v._id]: v.name }), {})
          );
        }
      });
    }
  }, [visible, id]);

  //   useEffect(() => {
  //     console.log(data);
  //   }, [data]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Data History"
      destroyOnClose
      visible={visible}
      footer={<Button onClick={handleCancel}>Close</Button>}
      onCancel={handleCancel}
    >
      {data.length
        ? data.map((item) => (
            <Card
              title={"Block " + item.block}
              style={{ marginBottom: 16 }}
              extra={
                <Button
                  icon={<BlockOutlined />}
                  onClick={() => {
                    setBlockId(item.block);
                    setFlureeBlockModal(true);
                  }}
                />
              }
            >
              <List
                itemLayout="horizontal"
                dataSource={item.flakes}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={predicate[item[1]]}
                      description={JSON.stringify(item[4]) === "true" ? item[2] : <Text delete>{item[2]}</Text>}
                    />
                    <div style={{ marginLeft: 16 }}>{JSON.stringify(item[4]) === "true" ? "Assert" : "Retract"}</div>
                  </List.Item>
                )}
              />
            </Card>
          ))
        : null}

      <FlureeBlockModal id={blockId} visible={flureeBlockModal} setVisible={setFlureeBlockModal} />
    </Modal>
  );
};

export default FlureeHistoryModal;
