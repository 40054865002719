import React from "react";
import { Tooltip, Space, Row, Col, Card, Descriptions } from "antd";
import { EditOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";
import { blue } from "@ant-design/colors";
import moment from "moment";

const DailyLarvalRecordTable = (props) => {
  const { dataSource, loading, error, tankUsage, editRow } = props;

  const columns = [
    {
      title: "DOC",
      fixed: "left",
      dataIndex: "recordTimestamp",
      render: (text) =>
        tankUsage ? moment(text).startOf("day").diff(moment(tankUsage.hatchDate).startOf("day"), "days", false) : "-",
    },
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Stage",
      dataIndex: "larvalStage",
    },
    {
      title: "Density (/L)",
      dataIndex: "larvalDensity",
      render: (text, row) => (text ? text : "-"),
    },
    {
      title: "Salinity (ppt)",
      dataIndex: "salinityInPpt",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
          </Tooltip>
          {/* <Tooltip title="Duplicate">
            <CopyOutlined style={{ color: blue.primary }} onClick={() => editDuplicate(row)} />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <TableLoader
        size="small"
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        scroll={{ x: "max-content" }}
        bordered
        expandable={{
          expandedRowRender: (row) => (
            <Row justify="space-around">
              <Col>
                <Card size="small" style={{ marginBottom: 8 }}>
                  <Descriptions size="small" title="Temperature (C)" bordered column={2}>
                    <Descriptions.Item label="6 am">{row.temperature1 ? row.temperature1 : "-"}</Descriptions.Item>
                    <Descriptions.Item label="3 pm">{row.temperature2 ? row.temperature2 : "-"}</Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card size="small" style={{ marginBottom: 8 }}>
                  <Descriptions size="small" title="Artemia Fed (g)" bordered column={2}>
                    <Descriptions.Item label="Type">{row.artemiaType ? row.artemiaType : "-"}</Descriptions.Item>
                    <Descriptions.Item label="6 am">
                      {row.artemiaFedInGram1 ? row.artemiaFedInGram1 : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="3 pm">
                      {row.artemiaFedInGram2 ? row.artemiaFedInGram2 : "-"}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
                <Card size="small">
                  <Descriptions size="small" title="Microparticulate (g/Tank)" bordered column={2}>
                    <Descriptions.Item label="9 am">{row.microInGram1 ? row.microInGram1 : "-"}</Descriptions.Item>
                    <Descriptions.Item label="3 pm">{row.microInGram2 ? row.microInGram2 : "-"}</Descriptions.Item>
                    <Descriptions.Item label="10 pm">{row.microInGram3 ? row.microInGram3 : "-"}</Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>

              <Col>
                <Card size="small">
                  <Descriptions size="small" title="Water Treatment" bordered column={2}>
                    <Descriptions.Item label="Oregano (ml)">
                      {row.oreganoInMl ? row.oreganoInMl : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="K-5 (g)">{row.k5InGram ? row.k5InGram : "-"}</Descriptions.Item>
                    <Descriptions.Item label="M-Plus (g)">{row.mplus ? row.mplus : "-"}</Descriptions.Item>
                    <Descriptions.Item label="EDTA (g)">{row.edta ? row.edta : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Tystatin (ml)">{row.tystatin ? row.tystatin : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Cifro (tablets)">{row.cifro ? row.cifro : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Super MS (ml)">{row.superms ? row.superms : "-"}</Descriptions.Item>
                    <Descriptions.Item label="Shrimp Favour (g)">
                      {row.shrimpFavour ? row.shrimpFavour : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="GERM Free (tablets)">
                      {row.germFree ? row.germFree : "-"}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              </Col>
            </Row>
          ),
        }}
      />
    </>
  );
};

export default DailyLarvalRecordTable;
