import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import { usePond } from "../../hooks/usePond";
import PondForm from "./PondForm";

const EditPondModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePond();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        label: editItem.label,
        pondTypes: editItem.pondTypes,
        status: editItem.status,
        farmId: editItem.farmId,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          label: values.label,
          pondTypes: values.pondTypes,
          status: values.status,
          farmId: values.farmId,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Pond"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondForm form={form} isEdit={true} />
    </Modal>
  );
};

export default EditPondModal;
