import React from "react";
import { Button, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";
import moment from "moment";

const { Link } = Typography;

const FarmStorageTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{text}</Link>,
    },
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Stock Movement",
      dataIndex: "stockMovement",
    },
    {
      title: "Total PCS",
      render: (text, row) =>
        row.FarmStorageRecordDetails ? row.FarmStorageRecordDetails.reduce((acc, cur) => acc + cur.quantity, 0) : 0,
    },
    {
      title: "Total Weight (kg)",
      render: (text, row) =>
        row.FarmStorageRecordDetails
          ? Number(row.FarmStorageRecordDetails.reduce((acc, cur) => acc + cur.weightInKg, 0)).toFixed(3)
          : 0,
    },
    {
      title: "Accumulated SSC",
      dataIndex: "accumulatedStock",
      render: (text) =>
        text ? (
          <div className="flex flex-col">
            {Object.keys(text).map((key) => (
              <div key={key}>
                <div className="font-bold">
                  {key}&nbsp;:&nbsp;
                  <span className="text-gray-400 font-normal">
                    {text[key]["quantity"] ? text[key]["quantity"] + " pcs, " : 0 + " pcs, "}
                    {text[key]["weightInKg"] ? text[key]["weightInKg"] + " kg" : 0 + " kg"}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => <Button type="link" icon={<EditOutlined />} onClick={() => editRow(row)} />,
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default FarmStorageTable;
