import React, { useState } from "react";
import { Modal, Form } from "antd";
import PondForm from "./PondForm";
import { usePond } from "../../hooks/usePond";

const NewPondModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const { create } = usePond();

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          label: values.label,
          farmId: values.farmId,
          pondTypes: values.pondTypes,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Pond"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondForm form={form} />
    </Modal>
  );
};

export default NewPondModal;
