import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space, Card, Tag, Divider, Tabs, Empty, List } from "antd";
import { ReloadOutlined, ExportOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHatcheryReceipt } from "../../hooks/useHatcheryReceipt";

import HatcheryReceiptTable from "./tables/HatcheryReceiptTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import IncubationModal from "./modals/IncubationModal";
import Filter from "./components/Filter";
import moment from "moment";
import ReleaseModal from "./modals/ReleaseModal";
import TagStatusModal from "./modals/TagStatusModal";
import { toArrayBuffer } from "../../utils/HelperFunctions";
import { broodstockStatus } from "../../utils/Status";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const HatcheryReceiptList = () => {
  const { t } = useTranslation();

  const { findAll, exportToExcel } = useHatcheryReceipt();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterBroodstockNo, setFilterBroodstockNo] = useState();
  const [filterDate, setFilterDate] = useState([moment().subtract(6, "month"), moment()]);
  const [filterStatus, setFilterStatus] = useState("All");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [incubationModal, setIncubationModal] = useState(false);
  const [tagStatusModal, setTagStatusModal] = useState(false);
  const [releaseModal, setReleaseModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
        broodstockNo: filterBroodstockNo,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterBroodstockNo, filterDate, filterStatus, t, findAll]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editIncubation = (row) => {
    setEditItem(row);
    setIncubationModal(true);
  };

  const editTagStatus = (row) => {
    setEditItem(row);
    setTagStatusModal(true);
  };

  const release = (row) => {
    setEditItem(row);
    setReleaseModal(true);
  };

  const doExcelExport = () => {
    const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
    const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

    exportToExcel({
      status: filterStatus,
      startDate: startDate,
      endDate: endDate,
      broodstockNo: filterBroodstockNo,
    }).then((res) => {
      if (res.status === "success") {
        const file = new Blob([toArrayBuffer(res.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "Broodstock.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const showDetail = (row) => {
    let array = [...data];
    const index = array.map((item) => item.id).indexOf(row.id);
    if (index > -1) {
      array[index].detail = !array[index].detail;
    }
    setData(array);
  };

  return (
    <>
      <Filter
        filterBroodstockNo={filterBroodstockNo}
        setFilterBroodstockNo={setFilterBroodstockNo}
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Broodstock</Title>

        <Row justify="space-between" style={{ marginTop: 16, marginBottom: 16 }}>
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Space>
              <Button onClick={doExcelExport} icon={<ExportOutlined />}>
                Export to Excel
              </Button>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Space>
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" type="card">
          <TabPane tab="Table" key="1">
            <HatcheryReceiptTable
              error={error}
              loading={loading}
              dataSource={data}
              editRow={editRow}
              editTagStatus={editTagStatus}
              editIncubation={editIncubation}
              release={release}
            />
          </TabPane>
          <TabPane tab="Card" key="2">
            {data.length ? (
              <List
                grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 5 }}
                dataSource={data}
                pagination={{
                  onChange: (page) => {
                    console.log(page);
                  },
                  pageSize: 10,
                }}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      size="small"
                      title={item.location}
                      extra={item.status ? <Tag color={broodstockStatus[item.status].color}>{item.status}</Tag> : "-"}
                    >
                      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                        <Col>
                          <div style={{ fontSize: 12 }}>B.S. #</div>
                          <div style={{ fontSize: 32, textAlign: "center" }}>{item.label ? item.label : "-"}</div>
                          <div style={{ fontSize: 12, textAlign: "center" }}>{item.species}</div>
                        </Col>
                        <Col style={{ paddingLeft: 5, paddingRight: 5 }}>
                          <Divider type="vertical" style={{ height: "85px" }} />
                        </Col>
                        <Col flex={1}>
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Row justify="space-between">
                              <Col>
                                <Text type="secondary">Source</Text>
                              </Col>
                              <Col>{item.source ? item.source : "-"}</Col>
                            </Row>
                            <Row justify="space-between">
                              <Col>
                                <Text type="secondary">Weight (g)</Text>
                              </Col>
                              <Col>{item.weightInGram ? item.weightInGram : "-"}</Col>
                            </Row>
                            <Row justify="space-between">
                              <Col>
                                <Text type="secondary">Arrival</Text>
                              </Col>
                              <Col>{moment(item.arrivalTimestamp).format("YYYY-MM-DD")}</Col>
                            </Row>
                          </Space>
                        </Col>
                      </Row>
                      <Row>
                        <Button block onClick={() => showDetail(item)}>
                          {item.detail ? "Show Less" : "Show More"}
                        </Button>
                      </Row>
                      {item.detail === true && (
                        <>
                          <Divider />
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Row justify="space-between">
                              <Col>
                                <Text type="secondary">Stock</Text>
                              </Col>
                              <Col>{item.stockDate ? moment(item.stockDate).format("YYYY-MM-DD") : "-"}</Col>
                            </Row>
                            <Row justify="space-between">
                              <Col>
                                <Text type="secondary">Release</Text>
                              </Col>
                              <Col>{item.releaseDate ? moment(item.releaseDate).format("YYYY-MM-DD") : "-"}</Col>
                            </Row>
                            {item.remarks && (
                              <Row>
                                <Col>{item.remarks}</Col>
                              </Row>
                            )}
                            <Row style={{ marginTop: 16 }}>
                              <Space direction="vertical" style={{ width: "100%" }}>
                                <Button block onClick={() => editRow(item)}>
                                  Edit
                                </Button>
                                <Button block onClick={() => editTagStatus(item)}>
                                  Tag &amp; Status
                                </Button>
                                <Button block onClick={() => editIncubation(item)} disabled={item.status !== "New"}>
                                  Incubation
                                </Button>
                                <Button block onClick={() => release(item)}>
                                  Release
                                </Button>
                              </Space>
                            </Row>
                          </Space>
                        </>
                      )}
                    </Card>
                  </List.Item>
                )}
              />
            ) : (
              <Empty />
            )}
          </TabPane>
        </Tabs>

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <IncubationModal
          editItem={editItem}
          visible={incubationModal}
          setVisible={setIncubationModal}
          setRefresh={setRefresh}
        />

        <TagStatusModal
          editItem={editItem}
          visible={tagStatusModal}
          setVisible={setTagStatusModal}
          setRefresh={setRefresh}
        />
        <ReleaseModal editItem={editItem} visible={releaseModal} setVisible={setReleaseModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default HatcheryReceiptList;
