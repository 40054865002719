import React, { useState } from "react";
import { Modal, Form, Select, Input } from "antd";
import { qrType } from "../../../utils/Options";
import { useQRCode } from "../../../hooks/useQRCode";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AssignQRModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { assign, downloadAsPdf } = useQRCode();
  const [submitting, setSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const generateQR = () => {
    form.validateFields().then((values, err) => {

      if (!err) {
        setSubmitting(true);
        const params = {
          factoryProductionRecordId: editItem.id,
          // qrType: values.qrType,
          qrNumber: values.qrNumber,
          batchNo: editItem.batchNo,
        };
        // assign(params).then((res) => {
        downloadAsPdf(params).then(async (res) => {
          const reader = res.body.getReader();

          const contentLength = +res.headers.get("Content-Length");

          // Step 3: read the data
          let receivedLength = 0; // received that many bytes at the moment
          let chunks = []; // array of received binary chunks (comprises the body)
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            chunks.push(value);
            receivedLength += value.length;

            setProgress(
              Number((receivedLength / contentLength) * 100).toFixed(0)
            );
          }

          const file = new Blob(chunks, {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(file);
          setSubmitting(false);
          setVisible(false);
          // Open the PDF in a new window
          return window.open(fileURL);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Assign QR Code"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={generateQR}
      okText="Generate QR Code"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout}>
        {/* <Form.Item name="qrType" label="QR Code" rules={[{ required: true }]}>
          <Select>
            {qrType.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          name="qrNumber"
          label="Number of QR"
          rules={[{ required: true }]}
        >
          <Input
            type="number"
            placeholder="Number of QR code to be generated"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignQRModal;
