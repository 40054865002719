import React, { useState } from "react";
import { Form, Select, InputNumber, Modal, Input } from "antd";
import { useProcessingOrderDetail } from "../../hooks/useProcessingOrderDetail";
import { sscGrade, sscSize } from "../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const NewProcessingOrderDetailModal = (props) => {
  const { order, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useProcessingOrderDetail();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          processingOrderId: order.id,
          grade: values.grade,
          size: values.size,
          quantity: values.quantity,
          weightInKg: values.weightInKg,
          remarks: values.remarks,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Order Item"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="processing-order-detail-form">
        <Form.Item name="grade" label="Grade" rules={[{ required: true }]}>
          <Select>
            {sscGrade.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="size" label="Size" rules={[{ required: true }]}>
          <Select>
            {sscSize.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="quantity" label="Quantity (PCS)" rules={[{ required: true }]}>
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="weightInKg" label="Weight (KG)" rules={[{ required: true }]}>
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewProcessingOrderDetailModal;
