import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import Filter from "./components/Filter";
import ProcessingOrderDetailDrawer from "../../components/processingOrder/ProcessingOrderDetailDrawer";
import ProcessingOrderTable from "../../components/processingOrder/ProcessingOrderTable";
import NewProcessingOrderModal from "../../components/processingOrder/NewProcessingOrderModal";
import EditProcessingOrderModal from "../../components/processingOrder/EditProcessingOrderModal";
import { useProcessingOrder } from "../../hooks/useProcessingOrder";

import moment from "moment";

const { Title } = Typography;

const ProcessingOrder = () => {
  const { t } = useTranslation();

  const {
    findAll,
    update,
    // downloadDeliveryOrder
  } = useProcessingOrder();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterStatus, setFilterStatus] = useState("All");
  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [orderDetailDrawer, setOrderDetailDrawer] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
        })
        .catch((err) => {
          setError(t("error.connectionError"));
        })
        .finally(() => {
          setLoading(false);
          setRefresh(false);
        });
    }
  }, [refresh, filterDate, filterStatus, findAll, t]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [data, editItem]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const viewDetail = (item) => {
    setEditItem(item);
    setOrderDetailDrawer(true);
  };

  const markAsComplete = (item) => {
    update(item.id, { status: "Complete" }).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
      }
    });
  };

  // const downloadHaccpDeliveryOrder = () => {
  //   downloadDeliveryOrder().then(async (res) => {
  //     const stream = await res.body.getReader().read();

  //     const file = new Blob([stream.value], {
  //       type: "application/pdf",
  //     });

  //     const fileURL = URL.createObjectURL(file);

  //     window.open(fileURL);
  //   });
  // };

  return (
    <>
      <Filter
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Delivery Order</Title>

        <Row justify="space-between">
          <Col>
            <Space>
              <Button type="primary" onClick={() => setAddNewModal(true)}>
                New
              </Button>
              {/* <Button onClick={downloadHaccpDeliveryOrder}>Sample HACCP</Button> */}
            </Space>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <ProcessingOrderTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          viewDetail={viewDetail}
          markAsComplete={markAsComplete}
        />

        <NewProcessingOrderModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh}/>

        <EditProcessingOrderModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />

        <ProcessingOrderDetailDrawer id={editItem.id} visible={orderDetailDrawer} setVisible={setOrderDetailDrawer} />
      </div>
    </>
  );
};

export default ProcessingOrder;
