import React from "react";
import { DatePicker, Form, Input, Select, Row, Col } from "antd";
import { farmDeliveryStatus } from "../../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const DeliveryOrderForm = (props) => {
  const { form, farmOption, processingOption, isEdit } = props;

  return (
    <Form {...layout} form={form} name="delivery-order-form" labelAlign="left">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="departTimestamp" label="Departure" rules={[{ required: true }]}>
            <DatePicker showTime allowClear />
          </Form.Item>
        </Col>

        <Col span={12}>
          {" "}
          <Form.Item name="orderNo" label="Order No." rules={[{ required: true }]}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          {" "}
          <Form.Item name="personInCharge" label="Person In Charge" rules={[{ required: true }]}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          {" "}
          <Form.Item name="vehicleNumber" label="Vehicle Number">
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          {" "}
          <Form.Item name="farmId" label="From Farm" rules={[{ required: true }]}>
            <Select allowClear>
              {farmOption.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          {" "}
          <Form.Item name="processingId" label="To Processing">
            <Select allowClear>
              {processingOption.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="remarks" label="Remarks">
            <Input.TextArea rows={5} allowClear />
          </Form.Item>
        </Col>

        {isEdit && (
          <Col span={12}>
            <Form.Item name="status" label="Status" rules={[{ required: true }]}>
              <Select allowClear>
                {farmDeliveryStatus.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default DeliveryOrderForm;
