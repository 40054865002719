import React, { useState, useEffect } from "react";
import { Card, Form, DatePicker, Select, Button } from "antd";
import { hatcheryHarvestStatus } from "../../../utils/Options";
import { useTank } from "../../../hooks/useTank";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Filter = (props) => {
  const { filterStatus, setFilterStatus, filterDate, setFilterDate, filterTank, setFilterTank, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useTank();

  const [tankData, setTankData] = useState([]);

  useEffect(() => {
    form.setFieldsValue({
      status: filterStatus,
      date: filterDate,
      tankId: filterTank,
    });

    findAll({ status: "New", opStatus: "All" }).then((res) => {
      if (res.status === "success") {
        setTankData(res.data);
      }
    });
  }, [form, filterStatus, filterDate, filterTank, findAll]);

  const onFinish = (values) => {
    setFilterStatus(values.status);
    setFilterDate(values.date);
    setFilterTank(values.tankId);
    setRefresh(true);
  };

  return (
    <Card title="Filter" size="small" style={{ marginTop: 16 }}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item label="Date" name="date">
          <RangePicker />
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select style={{ width: 120 }}>
            <Option value="All">All</Option>
            {hatcheryHarvestStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Tank" name="tankId">
          <Select style={{ width: 180 }}>
            <Option value="All">All</Option>
            {tankData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Apply
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Filter;
