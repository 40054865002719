import React from "react";
import { Form, Input } from "antd";

const AddNewForm = (props) => {
  const { form } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="label" label="Label" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
