import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import MaterialReceipt from "./components/MaterialReceipt";
import ProcessingActivity from "./components/ProcessingActivity";
import moment from "moment";
import { useDashboard } from "../../hooks/useDashboard";

const { TabPane } = Tabs;

const Dashboard = () => {
  const { findProcessingActivity } = useDashboard();
  const [refresh, setRefresh] = useState(true);
  const [activityData, setActivityData] = useState([]);

  const [activityDateFilter, setActivityDateFilter] = useState(moment());

  useEffect(() => {
    if (refresh) {
      if (activityDateFilter) {
        const date = moment(activityDateFilter).format("YYYY-MM-DD");

        findProcessingActivity({ date: date }).then((res) => {
          if (res.status === "success") {
            setActivityData(res.data);
          }

          setRefresh(false);
        });
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (activityDateFilter) {
      setRefresh(true);
    }
  }, [activityDateFilter]);

  return (
    <Tabs>
      <TabPane tab="General" key="general">
        <Card>
          <ProcessingActivity
            data={activityData}
            activityDateFilter={activityDateFilter}
            setActivityDateFilter={setActivityDateFilter}
          />
        </Card>
      </TabPane>
      <TabPane tab="SSC Received" key="materialReceipt">
        <Card>
          <MaterialReceipt />
        </Card>
      </TabPane>
    </Tabs>
  );
};

export default Dashboard;
