import React from "react";
import { List, Row, Col } from "antd";
import moment from "moment";

const TransferInfo = (props) => {
  const { data } = props;

  return (
    <List
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <div style={{ flex: 1 }}>
            <Row justify="space-between">
              <Col>
                <span style={{ fontSize: 12, color: "gray" }}>Date: </span>
                <p>{moment(item.transferTimestamp).format("YYYY-MM-DD")}</p>
              </Col>
              <Col>
                <span style={{ fontSize: 12, color: "gray" }}>To Farm: </span>
                <p>{item.TargetFarm ? item.TargetFarm.label : "-"}</p>
              </Col>
              <Col>
                <span style={{ fontSize: 12, color: "gray" }}>Vehicle: </span>
                <p>{item.vehicleNumber ? item.vehicleNumber : "-"}</p>
              </Col>
              <Col>
                <span style={{ fontSize: 12, color: "gray" }}>Person: </span>
                <p>{item.personInCharge ? item.personInCharge : "-"}</p>
              </Col>
              <Col>
                <span style={{ fontSize: 12, color: "gray" }}>Qty: </span>
                <p>{isNaN(item.quantity) ? item.quantity : Number(item.quantity).toLocaleString()}</p>
              </Col>
              <Col>
                <span style={{ fontSize: 12, color: "gray" }}>Remarks:</span>
                <p>{item.remarks ? item.remarks : "-"}</p>
              </Col>
            </Row>
          </div>
        </List.Item>
      )}
    />
  );
};

export default TransferInfo;
