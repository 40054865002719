import React, { useState } from "react";
import { Typography, Spin, Input, Row, Col } from "antd";
import { useRecall } from "../../hooks/useRecall";
import "./Recall.css";
import SearchByBatchResult from "./components/SearchByBatchResult";
// import BabyBoxNode from "./components/BabyBoxNode";
// import MasterBoxNode from "./components/MasterBoxNode";
// import DeliveryOrderNode from "./components/DeliveryOrderNode";

const { Title } = Typography;
const { Search } = Input;

const Trace = () => {
  const { internalRetrieve } = useRecall();

  const [searching, setSearching] = useState(false);
  const [error, setError] = useState();

  const [currentNode, setCurrentNode] = useState({});
  const [parentNodes, setParentNodes] = useState([]);
  const [childNodes, setChildNodes] = useState([]);

  const onSearch = (term) => {
    if (term && term !== "") {
      setSearching(true);
      internalRetrieve({ searchTerm: term }).then((res) => {
        if (res.status === "success") {
          setCurrentNode(res.data.node);
          setParentNodes(res.data.parentNodes);
          setChildNodes(res.data.childNodes);
          setError();
        } else {
          setCurrentNode({});
          setParentNodes([]);
          setChildNodes([]);
          setError(res.message);
        }
        setSearching(false);
      });
    }
  };

  return (
    <Row justify="center">
      <Col xs={24} lg={16}>
        <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
          <Title level={2}>Trace</Title>
          <div
            style={{
              textAlign: "center",
              background: "rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              marginBottom: "20px",
              padding: "30px 30px 30px 30px",
              margin: "20px 0 20px 0",
            }}
          >
            <Spin spinning={searching} tip="Retrieving Data">
              <Search
                onSearch={onSearch}
                size="large"
                enterButton="Search"
                defaultValue=""
                placeholder="Enter Batch Number"
                allowClear
              />
            </Spin>
          </div>
        </div>
        {searching ? (
          <div className="site-layout-background" style={{ padding: 24, marginTop: 16, textAlign: "center" }}>
            Searching ...
          </div>
        ) : error ? (
          <div className="site-layout-background" style={{ padding: 24, marginTop: 16, textAlign: "center" }}>
            {error}
          </div>
        ) : currentNode.data ? (
          <SearchByBatchResult currentNode={currentNode} parentNodes={parentNodes} childNodes={childNodes} />
        ) : null}
      </Col>
    </Row>
  );
};

export default Trace;
