import React from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { farmDeliveryStatus, sscSource, farmProductionType } from "../../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const DeliveryOrderForm = (props) => {
  const { form, farmOption, processingOption, isEdit, onProductChange, productType, onSSCSourceChange } = props;

  return (
    <Form {...layout} form={form} name="delivery-order-form">
      <Form.Item name="departTimestamp" label="Departure Timestamp" rules={[{ required: true }]}>
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item name="product" label="Product" rules={[{ required: true }]}>
        <Select allowClear onChange={onProductChange}>
          {farmProductionType.map((item) => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {productType === "Soft Shell Crab" ? (
        <Form.Item name="sscSource" label="SSC Source" rules={[{ required: true }]}>
          <Select allowClear onChange={onSSCSourceChange}>
            {sscSource.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ) : null}

      <Form.Item name="orderNo" label="Order No." rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="personInCharge" label="Person In Charge" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>

      <Form.Item name="vehicleNumber" label="Vehicle Number">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="farmId" label="From Farm" rules={[{ required: true }]}>
        <Select allowClear>
          {farmOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="processingId" label="To Processing">
        <Select allowClear>
          {processingOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>

      {isEdit && (
        <Form.Item name="status" label="Status" rules={[{ required: true }]}>
          <Select allowClear>
            {farmDeliveryStatus.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </Form>
  );
};

export default DeliveryOrderForm;
