import React, { useState, useEffect } from "react";
import { Modal, Form } from "antd";
import moment from "moment";
import { useFarmReceipt } from "../../../hooks/useFarmReceipt";
import ExternalToFarmForm from "../ExternalToFarmForm";

const EditExternalToFarmModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useFarmReceipt();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        location: editItem.location,
        arrivalTimestamp: moment(editItem.arrivalTimestamp),
        farmQuantity: editItem.farmQuantity,
        weightInKg: editItem.weightInKg,
        dead: editItem.dead,
        deadWeightInKg: editItem.deadWeightInKg,
        small: editItem.small,
        remarks: editItem.remarks,
        sourceName: editItem.sourceName,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const params = {
          location: values.location,
          arrivalTimestamp: moment(values.arrivalTimestamp).format(),
          farmQuantity: values.farmQuantity,
          weightInKg: values.weightInKg,
          dead: values.dead,
          deadWeightInKg: values.deadWeightInKg,
          small: values.small,
          remarks: values.remarks,
          sourceName: values.sourceName,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit External to Nursery Farm Transfer"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <ExternalToFarmForm form={form} />
    </Modal>
  );
};

export default EditExternalToFarmModal;
