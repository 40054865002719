const UserRole = {
  "superadmin@neocrab.com": "NEOCRAB.SUPERUSER",
  "umang@neocrab.com": "NEOCRAB.MANAGER",
  "cyrill@neocrab.com": "NEOCRAB.MANAGER",
  "sally@neocrab.com": "NEOCRAB.MANAGER",
  "technician.hatchery@neocrab.com": "HATCHERY.TECHNICIAN",
  "manager.hatchery@neocrab.com": "HATCHERY.MANAGER",
  "technician.farm@neocrab.com": "FARM.TECHNICIAN",
  "manager.farm@neocrab.com": "FARM.MANAGER",
  "technician.processing@neocrab.com": "PROCESSING.TECHNICIAN",
  "manager.processing@neocrab.com": "PROCESSING.MANAGER",
  "bert@neocrab.com": "HATCHERY.TECHNICIAN",
  "joe@neocrab.com": "HATCHERY.MANAGER",
  // "angelinna@neocrab.com": "HATCHERY.MANAGER",
  // "anna@neocrab.com": "HATCHERY.MANAGER",
  "angel@neocrab.com": "FARM.TECHNICIAN",
  "nurul@neocrab.com": "FARM.TECHNICIAN",
  "masnah@neocrab.com": "FARM.TECHNICIAN",
  "jalilah@neocrab.com": "FARM.TECHNICIAN",
  "likan@neocrab.com": "FARM.TECHNICIAN",
  "easter@neocrab.com": "FARM.TECHNICIAN",
  "anthony@neocrab.com": "FARM.TECHNICIAN",
  "anthonyigat@neocrab.com": "FARM.TECHNICIAN",

  "bernard@neocrab.com": "FARM.MANAGER",
  "rod@neocrab.com": "FARM.TECHNICIAN",
  "neckwella@neocrab.com": "FARM.TECHNICIAN",
  "jimmy@neocrab.com": "FARM.TECHNICIAN",
  "johnny@neocrab.com": "FARM.TECHNICIAN",
  "rubynadi@neocrab.com": "FARM.TECHNICIAN",

  // "schnell@neocrab.com": "FARM.MANAGER",
  // "silang@neocrab.com": "FARM.MANAGER",
  // "myckle@neocrab.com": "FARM.MANAGER",
  // "felixity@neocrab.com": "PROCESSING.MANAGER",
  "petrus@neocrab.com": "PROCESSING.MANAGER",
  "stanley@neocrab.com": "PROCESSING.MANAGER",
  "jhun@neocrab.com": "NEOCRAB.ADMIN",
  "aichuen@neocrab.com": "NEOCRAB.HATCHERY_FARM_MANAGER",

  "patricia@neocrab.com": "FARM.TECHNICIAN",
  "gabriel@neocrab.com": "FARM.TECHNICIAN",
  "bujai@neocrab.com": "FARM.TECHNICIAN",
  "sharifah@neocrab.com": "FARM.TECHNICIAN",
  "clerence@neocrab.com": "FARM.TECHNICIAN",
  "luis@neocrab.com": "FARM.TECHNICIAN",

  "adriana@neocrab.com": "NEOCRAB.FARM_PROCESSING_MANAGER",
};

export default UserRole;
