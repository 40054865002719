import React, { useEffect, useState } from "react";
import { Modal, Form, Input, InputNumber, Select, DatePicker } from "antd";
import moment from "moment";
import { useSSCAudit } from "../../../hooks/useSSCAudit";
import { harvestShift } from "../../../utils/Options";

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const EditAuditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useSSCAudit();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        shift: editItem.shift,
        alive: editItem.alive,
        auditAlive: editItem.auditAlive,
        remarks: editItem.remarks,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          shift: values.shift,
          alive: values.alive,
          auditAlive: values.auditAlive,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit SSC Weekly Audit"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="add-new-form">
        <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Form.Item name="shift" label="Shift" rules={[{ required: true }]}>
          <Select allowClear>
            {harvestShift.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="alive" label="Alive (System)">
          <InputNumber min={0} allowClear disabled />
        </Form.Item>
        <Form.Item name="auditAlive" label="Alive (Audit)">
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAuditModal;
