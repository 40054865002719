import React, { useState, useEffect } from "react";
import { DatePicker, Form, Select, Divider, Input, Button, InputNumber } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const EditForm = (props) => {
  const { form, larvalStageData } = props;

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [inputValue, setInputValue] = useState("");
  const [larvalStageOption, setLarvalStageOption] = useState([]);

  useEffect(() => {
    setLarvalStageOption(larvalStageData);
  }, [larvalStageData]);

  const onInputChange = (e) => setInputValue(e.target.value);

  const addItem = () => {
    if (larvalStageOption.includes(inputValue) || inputValue === "") {
      return;
    }
    setLarvalStageOption([...larvalStageOption, { larvalStage: inputValue }]);
    setInputValue("");
  };

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="larvalStage" label="Larval Stage" rules={[{ required: true }]}>
        <Select
          allowClear
          placeholder="Select a Larval Stage"
          dropdownRender={(menu) => (
            <div>
              {menu}

              <Divider style={{ margin: "4px 0" }} />

              <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                <Input
                  style={{ flex: "auto", marginRight: 8 }}
                  allowClear
                  value={inputValue}
                  onChange={onInputChange}
                />
                <Button type="link" onClick={addItem}>
                  <PlusOutlined /> Add
                </Button>
              </div>
            </div>
          )}
        >
          {larvalStageOption.map((item) => (
            <Option key={item.larvalStage} value={item.larvalStage}>
              {item.larvalStage}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="larvalDensity" label="Density (/L)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="salinityInPpt" label="Salinity (ppt)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>

      <Divider orientation="left">Temperature</Divider>

      <Form.Item name="temperature1" label="6 am">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="temperature2" label="3 pm">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Divider orientation="left">Artemia</Divider>

      <Form.Item name="artemiaType" label="Type">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="artemiaFedInGram1" label="Fed (g) - 6am">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="artemiaFedInGram2" label="Fed (g) - 3pm">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Divider orientation="left">Microparticulate</Divider>

      <Form.Item name="microInGram1" label="g/Tank - 9am">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="microInGram2" label="g/Tank - 3pm">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="microInGram3" label="g/Tank - 10pm">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Divider orientation="left">Water Treatment</Divider>

      <Form.Item name="oreganoInMl" label="Oregano (ml)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="k5InGram" label="K-5 (g)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="mplus" label="M-Plus (g)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="edta" label="EDTA (g)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="tystatin" label="Tystatin (ml)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="cifro" label="Cifro (tablets)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="superms" label="Super MS (ml)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="shrimpFavour" label="Shrimp Favour (g)">
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="germFree" label="GERM Free (tablets)">
        <InputNumber min={0} allowClear />
      </Form.Item>
    </Form>
  );
};

export default EditForm;
