import React, { useState, useEffect } from "react";
import { Modal, Form, Divider } from "antd";
import AddNewRecipeForm from "./AddNewRecipeForm";
import { useRecipe } from "../../hooks/useRecipe";

const AddNewRecipeModal = (props) => {
  const { visible, setVisible, setRefresh, handleSubmit } = props;

  const [form] = Form.useForm();

  const { create } = useRecipe();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recipeTitle: values.recipeTitle,
          shortDescription: values.shortDescription,
          recipeContent: values.recipeContent,
        };

        create(params).then(async (res) => {
          handleSubmit(res)
          if (res.status === 201) {
            form.resetFields()
          }
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Recipe"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
      width={1000}
    >
      <AddNewRecipeForm form={form} />
    </Modal>
  );
};

export default AddNewRecipeModal;
