import React, { useState } from "react";
import { Modal, Form } from "antd";
import ParameterTypeForm from "./ParameterTypeForm";
import { useParameterType } from "../../../hooks/useParameterType";

const NewParameterTypeModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useParameterType();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          name: values.name,
          uom: values.uom,
          description: values.description,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Parameter Type"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <ParameterTypeForm form={form} />
    </Modal>
  );
};

export default NewParameterTypeModal;
