import React, { useState } from "react";
import { Typography, Spin, Input, Row, Col, Radio } from "antd";
import { useRecall } from "../../hooks/useRecall";
import "./Recall.css";
import SearchByBatchResult from "./components/SearchByBatchResult";
import SearchResult from "./components/SearchResult";

const { Title } = Typography;
const { Search } = Input;

const Recall = () => {
  const { internalRetrieve } = useRecall();

  const [searching, setSearching] = useState(false);
  const [error, setError] = useState();
  const [recallType, setRecallType] = useState("Broodstock");
  const [currentNode, setCurrentNode] = useState({});
  const [parentNodes, setParentNodes] = useState([]);
  const [childNodes, setChildNodes] = useState([]);
  const [data, setData] = useState([]);

  const onSearch = (term) => {
    if (term && term !== "") {
      setSearching(true);
      internalRetrieve({ searchTerm: term, recallType: recallType }).then((res) => {
        if (res.status === "success") {
          setCurrentNode(res.data.node);
          setParentNodes(res.data.parentNodes);
          setChildNodes(res.data.childNodes);
          setData(res.data);
          setError();
        } else {
          setCurrentNode({});
          setParentNodes([]);
          setChildNodes([]);
          setData({});
          setError(res.message);
        }
        setSearching(false);
      });
    }
  };

  return (
    <Row justify="center">
      <Col xs={24} xxl={12}>
        <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
          <Title level={2}>Recall</Title>
          <div
            style={{
              background: "rgba(0, 0, 0, 0.05)",
              borderRadius: "4px",
              marginBottom: "20px",
              padding: "30px 30px 30px 30px",
              margin: "20px 0 20px 0",
            }}
          >
            <Radio.Group
              onChange={(e) => {
                setRecallType(e.target.value);
              }}
              value={recallType}
              style={{ marginBottom: 24 }}
            >
              <Radio value={"Broodstock"}>Broodstock</Radio>
              <Radio value={"Delivery Order (Farm)"}>Delivery Order (Farm)</Radio>
              <Radio value={"Soft Shell Crab"}>Soft Shell Crab</Radio>
            </Radio.Group>
            <Spin spinning={searching} tip="Retrieving Data">
              <Search
                onSearch={onSearch}
                size="large"
                enterButton="Search"
                defaultValue=""
                placeholder="Enter Broodstock No / DO No / SSC Batch No"
                allowClear
              />
            </Spin>
          </div>
        </div>
        {searching ? (
          <div className="site-layout-background" style={{ padding: 24, marginTop: 16, textAlign: "center" }}>
            Searching ...
          </div>
        ) : error ? (
          <div className="site-layout-background" style={{ padding: 24, marginTop: 16, textAlign: "center" }}>
            {error}
          </div>
        ) : currentNode && currentNode.data ? (
          <SearchByBatchResult currentNode={currentNode} parentNodes={parentNodes} childNodes={childNodes} />
        ) : data ? (
          <SearchResult data={data} />
        ) : (
          <div className="site-layout-background" style={{ padding: 24, marginTop: 16, textAlign: "center" }}>
            No results
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Recall;
