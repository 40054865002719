import React, { useState, useEffect } from "react";
import { Modal, Form, InputNumber, DatePicker, Typography, Tabs, Radio, Select, Alert } from "antd";
import moment from "moment";
import { usePondHarvest } from "../../hooks/usePondHarvest";
import { usePontoon } from "../../hooks/usePontoon";
import { useGrowOut } from "../../hooks/useGrowOut";

const { Text } = Typography;
const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const NewPondHarvestModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findGrowOut } = useGrowOut();
  const { findAll: findPontoon } = usePontoon();

  const { create } = usePondHarvest();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [growOutOption, setGrowOutOption] = useState([]);
  const [pontoonOption, setPontoonOption] = useState([]);

  const [growOutArray, setGrowOutArray] = useState([]);
  const [pontoonArray, setPontoonArray] = useState([]);

  useEffect(() => {
    if (visible) {
      findGrowOut({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setGrowOutOption(res.data);
        }
      });
      findPontoon({ sscSource: "Internal" }).then((res) => {
        if (res.status === "success") {
          setPontoonOption(res.data);
        }
      });
      form.setFieldsValue({
        stockOutTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const growOutRecord = growOutArray.filter((item) => item.quantity && item.quantity > 0);
        const pontoonRecord = pontoonArray.filter((item) => item.quantity && item.quantity > 0);
        const small = isNaN(values.totalSmall) ? 0 : values.totalSmall;
        const ssc = isNaN(values.totalSSC) ? 0 : values.totalSSC;
        const hsc = isNaN(values.totalHSC) ? 0 : values.totalHSC;
        const shrimp = isNaN(values.totalShrimp) ? 0 : values.totalShrimp;
        const dead = isNaN(values.totalDead) ? 0 : values.totalDead;
        const broodstock = isNaN(values.totalBroodstock) ? 0 : values.totalBroodstock;
        const greenhouse = isNaN(values.totalGreenhouse) ? 0 : values.totalGreenhouse;
        const totalStockOut = small + ssc + hsc + shrimp + dead + broodstock + greenhouse;

        if (totalStockOut <= 0) {
          setError(true);
          return;
        }
        setError(false);

        setSubmitting(true);
        const params = {
          growOutId: values.growOut.split(":::")[0],
          pondId: values.growOut.split(":::")[1],
          stockOutTimestamp: moment(values.stockOutTimestamp).format(),
          growOutArray: growOutRecord,
          pontoonArray: pontoonRecord,
          harvestType: values.harvestType,
          totalSmall: values.totalSmall,
          totalSSC: values.totalSSC,
          totalHSC: values.totalHSC,
          totalShrimp: values.totalShrimp,
          totalDead: values.totalDead,
          totalBroodstock: values.totalBroodstock,
          totalGreenhouse: values.totalGreenhouse,
          totalHarvest: totalStockOut,

          avgWeightInGram: values.avgWeightInGram,
          survivalRate: values.survivalRate,
          totalFeedInKg: values.totalFeedInKg,
          fcr: values.fcr,
          totalHarvestInKg: values.totalHarvestInKg,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setPontoonArray([]);
            setGrowOutArray([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onGrowOutChange = (value, item) => {
    const index = growOutArray.map((item) => item.growOutId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...growOutArray];
      newArray[index] = {
        growOutId: item.id,
        quantity: value,
      };
      setGrowOutArray(newArray);
    } else {
      setGrowOutArray([...growOutArray, { growOutId: item.id, quantity: value }]);
    }

    // const total = growOutArray.reduce((acc, cur) => {
    //   return acc + cur.quantity;
    // }, 0);

    // // form.setFieldsValue({
    // //   totalSmall: total,
    // // });
  };

  const onPontoonChange = (value, item) => {
    const index = pontoonArray.map((item) => item.pontoonId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...pontoonArray];
      newArray[index] = {
        pontoonId: item.id,
        quantity: value,
      };
      setPontoonArray(newArray);
    } else {
      setPontoonArray([...pontoonArray, { pontoonId: item.id, quantity: value }]);
    }

    // const total = pontoonArray.reduce((acc, cur) => {
    //   return acc + cur.quantity;
    // }, 0);

    // form.setFieldsValue({
    //   totalSSC: total,
    // });
  };

  const updateForm = () => {
    const small = isNaN(form.getFieldValue("totalSmall")) ? 0 : form.getFieldValue("totalSmall");
    const ssc = isNaN(form.getFieldValue("totalSSC")) ? 0 : form.getFieldValue("totalSSC");
    const hsc = isNaN(form.getFieldValue("totalHSC")) ? 0 : form.getFieldValue("totalHSC");
    const shrimp = isNaN(form.getFieldValue("totalShrimp")) ? 0 : form.getFieldValue("totalShrimp");
    const dead = isNaN(form.getFieldValue("totalDead")) ? 0 : form.getFieldValue("totalDead");
    const broodstock = isNaN(form.getFieldValue("totalBroodstock")) ? 0 : form.getFieldValue("totalBroodstock");
    const greenhouse = isNaN(form.getFieldValue("totalGreenhouse")) ? 0 : form.getFieldValue("totalGreenhouse");

    const totalHarvestPcs = small + ssc + hsc + shrimp + dead + broodstock + greenhouse;
    const totalHarvestKg = isNaN(form.getFieldValue("totalHarvestInKg")) ? 0 : form.getFieldValue("totalHarvestInKg");

    if (totalHarvestPcs > 0) {
      form.setFieldsValue({
        avgWeightInGram: ((totalHarvestKg * 1000) / totalHarvestPcs).toFixed(2),
      });
    } else {
      form.setFieldsValue({
        avgWeightInGram: null,
      });
    }

    const totalFeedInKg = isNaN(form.getFieldValue("totalFeedInKg")) ? 0 : form.getFieldValue("totalFeedInKg");

    if (totalHarvestKg > 0) {
      form.setFieldsValue({
        fcr: (totalFeedInKg / totalHarvestKg).toFixed(2),
      });
    } else {
      form.setFieldsValue({
        fcr: null,
      });
    }
  };

  return (
    <Modal
      title={"New Pond Harvest"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} {...layout} labelAlign="left">
        <Form.Item name="growOut" label="Pond" rules={[{ required: true }]}>
          <Select>
            {growOutOption.map((item) => (
              <Select.Option key={item.id} value={item.id + ":::" + item.pondId}>
                {item.Pond.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="stockOutTimestamp" label="Date" rules={[{ required: true }]}>
          <DatePicker allowClear showTime />
        </Form.Item>

        <Form.Item name="harvestType" label="Harvest" rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="Partial">Partial</Radio>
            <Radio value="Full">Full</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="totalSmall" label="Small (to Ponds)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalSSC" label="SSC">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalHSC" label="HSC">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalShrimp" label="Shrimp">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalDead" label="Dead">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalBroodstock" label="Broodstock">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalGreenhouse" label="Greenhouse">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="pcs" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="totalHarvestInKg" label="Total Weight (kg)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="kg" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="avgWeightInGram" label="ABW (g)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="g" />
        </Form.Item>

        <Form.Item name="survivalRate" label="Survival Rate">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="%" />
        </Form.Item>

        <Form.Item name="totalFeedInKg" label="Total Feed (kg)">
          <InputNumber inputMode="numeric" min={0} allowClear placeholder="kg" onChange={updateForm} />
        </Form.Item>

        <Form.Item name="fcr" label="FCR" extra="Total Feed / Total Weight">
          <InputNumber inputMode="numeric" min={0} allowClear />
        </Form.Item>

        {error && <Text type="danger">Total harvest cannot be zero.</Text>}

        <Tabs defaultActiveKey="1">
          <TabPane tab="Small Crab" key="growOut">
            <Alert
              message="Please enter the quantity of small crab that stock into other ponds."
              type="info"
              style={{ marginBottom: 16 }}
            />

            {growOutOption.map((item) => (
              <Form.Item label={item.Pond.label}>
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  allowClear
                  onChange={(value) => onGrowOutChange(value, item)}
                  placeholder="pcs"
                />
              </Form.Item>
            ))}
          </TabPane>
          <TabPane tab="Soft Shell Crab" key="SSC">
            <Alert
              message="Please enter the quantity of crab that stock into each pontoon."
              type="info"
              style={{ marginBottom: 16 }}
            />

            {pontoonOption.map((item) => (
              <Form.Item label={item.label} extra={item.Pond.label}>
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  allowClear
                  onChange={(value) => onPontoonChange(value, item)}
                  placeholder="pcs"
                />
              </Form.Item>
            ))}
          </TabPane>
        </Tabs>

        {error && <Text type="danger">Total harvest cannot be zero.</Text>}
      </Form>
    </Modal>
  );
};

export default NewPondHarvestModal;
