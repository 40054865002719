import React, { useState } from "react";
import { Modal, Form, InputNumber, Input, Select } from "antd";
import { useFactoryReceiptDetail } from "../../hooks/useFactoryReceiptDetail";
import { sscGrade, sscSize } from "../../utils/Options";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const NewFactoryReceiptDetailModal = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFactoryReceiptDetail();

  const [submitting, setSubmitting] = useState(false);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          grade: values.grade,
          size: values.size,
          processingQuantity: values.processingQuantity,
          processingWeightInKg: values.processingWeightInKg,
          remarks: values.remarks,
          factoryReceiptId: id,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New Item"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="add-new-form" labelAlign="left">
        <Form.Item name="grade" label="Grade">
          <Select allowClear>
            {sscGrade.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="size" label="Size">
          <Select allowClear>
            {sscSize.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="processingQuantity" label="Quantity (PCS)">
          <InputNumber />
        </Form.Item>

        <Form.Item name="processingWeightInKg" label="Weight (KG)">
          <InputNumber />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewFactoryReceiptDetailModal;
