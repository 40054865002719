import React from "react";
import { Dropdown, Typography, Menu, Button, Space, Tag } from "antd";
import {
  EditOutlined,
  RollbackOutlined,
  EllipsisOutlined,
  EnterOutlined,
  RotateRightOutlined,
} from "@ant-design/icons";
import TableLoader from "../../../components/common/TableLoader";
import moment from "moment";
import { broodstockStatus } from "../../../utils/Status";

const { Link } = Typography;

const HatcheryReceiptTable = (props) => {
  const { dataSource, loading, error, editRow, editIncubation, release, editTagStatus } = props;

  const columns = [
    {
      title: "B.S. #",
      dataIndex: "label",
      render: (text) => (text ? <Space>{text}</Space> : "-"),
    },
    {
      title: "Species",
      dataIndex: "species",
    },
    {
      title: "Tank",
      dataIndex: "location",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Source",
      dataIndex: "source",
    },
    {
      title: "Weight (g)",
      dataIndex: "weightInGram",
    },
    {
      title: "Stock",
      dataIndex: "stockDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Arrival",
      dataIndex: "arrivalTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Release",
      dataIndex: "releaseDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (text ? <Tag color={broodstockStatus[text].color}>{text}</Tag> : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                Edit
              </Menu.Item>
              <Menu.Item key="release" icon={<RotateRightOutlined />} onClick={() => editTagStatus(row)}>
                Tag &amp; Status
              </Menu.Item>
              <Menu.Item
                key="edit"
                icon={<EnterOutlined />}
                onClick={() => editIncubation(row)}
                disabled={row.status !== "New"}
              >
                Incubation
              </Menu.Item>
              <Menu.Item key="release" icon={<RollbackOutlined />} onClick={() => release(row)}>
                Release
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default HatcheryReceiptTable;
