import React from "react";
import { Descriptions, Empty } from "antd";
import moment from "moment";

const FarmReceiptInfo = (props) => {
  const { item } = props;

  return item ? (
    <Descriptions>
      <Descriptions.Item label={"Arrival Date"}>{moment(item.arrivalTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>

      <Descriptions.Item label={"Person In Charge"}>{item.personInCharge}</Descriptions.Item>

      <Descriptions.Item label={"Vehicle Number"}>{item.vehicleNumber}</Descriptions.Item>

      <Descriptions.Item label={"Quantity (Farm)"}>{Number(item.farmQuantity).toLocaleString()}</Descriptions.Item>

      <Descriptions.Item label={"Quantity (Hatchery)"}>
        {item.hatcheryQuantity ? Number(item.hatcheryQuantity).toLocaleString() : "-"}
      </Descriptions.Item>

      <Descriptions.Item label={"Source"}>
        {item.sourceType === "External" ? item.sourceName : "Hatchery"}
      </Descriptions.Item>

      <Descriptions.Item label={"Remarks"} span={3}>
        {item.remarks ? item.remarks : "-"}
      </Descriptions.Item>
    </Descriptions>
  ) : (
    <Empty />
  );
};

export default FarmReceiptInfo;
