import React, { useState, useEffect } from "react";
import { Modal, Form, Divider, Descriptions, Space, InputNumber } from "antd";
import moment from "moment";
import { useFactoryProduction } from "../../hooks/useFactoryProduction";
import SSCProductionForm from "./SSCProductionForm";
import { sscGrade, sscSize } from "../../utils/Options";

const NewFactoryProductionModal = (props) => {
  const { id, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFactoryProduction();

  const [submitting, setSubmitting] = useState(false);

  const [softShellGrade, setSoftShellGrade] = useState();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        productionTimestamp: moment(),
        factoryReceiptId: id,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          batchNo: values.batchNo,
          productionTimestamp: moment(values.productionTimestamp).format(),
          factoryReceiptId: Array.isArray(values.factoryReceiptId)
            ? values.factoryReceiptId
            : [values.factoryReceiptId],
          // markAsComplete: values.markAsComplete,
          // grade: values.grade,
          // quantity: values.quantity,
          // weightRangeInKg: values.weightRangeInKg,
          // weightInKg: values.weightInKg,
          remarks: values.remarks,
          // qrType: values.qrType,
          softShellGrade: softShellGrade,
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setSoftShellGrade();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onInputChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        quantity: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  const onWeightChange = (grade, size, value) => {
    let grades = { ...softShellGrade };

    let sizes = { ...grades[grade] };
    sizes = {
      ...sizes,
      [size]: {
        ...sizes[size],
        weightInKg: value,
      },
    };
    grades = {
      ...grades,
      [grade]: sizes,
    };

    setSoftShellGrade(grades);
  };

  return (
    <Modal
      title="New Weighing & Packaging Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <SSCProductionForm form={form} />

      <Divider />

      {sscGrade.map((grade) => (
        <Descriptions title={"Grade " + grade} size="small" key={grade} column={1}>
          {sscSize.map((item) => (
            <Descriptions.Item
              label={item}
              key={grade + "_" + item}
              contentStyle={{ justifyContent: "flex-end" }}
              style={{ paddingLeft: 16 }}
            >
              <Space>
                <InputNumber
                  placeholder="pcs"
                  inputMode="numeric"
                  allowClear
                  onChange={(value) => onInputChange(grade, item, value)}
                />
                <InputNumber
                  placeholder="(kg)"
                  inputMode="numeric"
                  allowClear
                  onChange={(value) => onWeightChange(grade, item, value)}
                />
              </Space>
            </Descriptions.Item>
          ))}
        </Descriptions>
      ))}
    </Modal>
  );
};

export default NewFactoryProductionModal;
