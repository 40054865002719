import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, InputNumber, Select } from "antd";
import { qrType } from "../../../utils/Options";
import { useFactoryProduction } from "../../../hooks/useFactoryProduction";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddNewPackingForm = (props) => {
  const { form } = props;

  const { findAll } = useFactoryProduction();
  const [batchNoOption, setBatchNoOption] = useState([]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    findAll({ status: "Frozen" }).then((res) => {
      if (res.status === "success") {
        setBatchNoOption(res.data);
      }
    });
  }, []);

  function filterOption(inputValue, option) {
    return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  }

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item
        name="packTimestamp"
        label="Timestamp"
        rules={[{ required: true }]}
      >
        <DatePicker showTime allowClear />
      </Form.Item>

      <Form.Item
        name="countQr"
        label="Number of QR"
        rules={[{ required: true }]}
      >
        <InputNumber min={1} allowClear />
      </Form.Item>

      <Form.Item
        name="productionBatchNo"
        label="Production Batch No."
        rules={[{ required: true }]}
      >
        <Select
          mode="multiple"
          showSearch
          filterOption={filterOption}
          onChange={handleChange}
        >
          {batchNoOption.map((option) => (
            <Option key={option.id} value={option.id} label={option.batchNo}>
              {option.batchNo}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default AddNewPackingForm;
