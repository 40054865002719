import React from "react";
import { Typography, Button, Dropdown, Menu } from "antd";
import { EditOutlined, EnterOutlined, EllipsisOutlined, FileDoneOutlined } from "@ant-design/icons";
import TableLoader from "../common/TableLoader";
import moment from "moment";

const { Link } = Typography;

const FactoryReceiptTable = (props) => {
  const { dataSource, loading, error, editRow, viewDetail, processRow, markAsComplete } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "arrivalTimestamp",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{moment(text).format("YYYY-MM-DD")}</Link>,
    },
    {
      title: "Delivered By",
      dataIndex: "personInCharge",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Vehicle",
      dataIndex: "vehicleNumber",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Received By",
      render: (text, row) => (row.createdBy ? row.createdBy.split(":::")[1] : "-"),
    },
    {
      title: "Total PCS",
      align: "right",
      render: (text, row) =>
        row.FactoryReceiptDetails
          ? row.FactoryReceiptDetails.reduce((acc, cur) => acc + cur.processingQuantity, 0)
          : "-",
    },
    {
      title: "Total Weight (KG)",
      align: "right",
      render: (text, row) =>
        row.FactoryReceiptDetails
          ? Number(row.FactoryReceiptDetails.reduce((acc, cur) => acc + cur.processingWeightInKg, 0)).toFixed(3)
          : "-",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="edit"
                icon={<EnterOutlined />}
                onClick={() => processRow(row)}
                disabled={row.status !== "New"}
              >
                Process
              </Menu.Item>
              <Menu.Item
                key="complete"
                icon={<FileDoneOutlined />}
                onClick={() => markAsComplete(row)}
                disabled={row.status === "Complete"}
              >
                Complete
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default FactoryReceiptTable;
