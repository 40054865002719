import React from "react";
import { Tooltip } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import moment from "moment";

const FarmProductionTable = (props) => {
  const { dataSource, loading, error, editRow } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "productionTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Shift",
      dataIndex: "harvestShift",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    // {
    //   title: "Grade",
    //   dataIndex: "grade",
    //   render: (text) => (text ? text : "-"),
    // },
    // {
    //   title: "Harvest Pond",
    //   render: (text, row) => (row.GrowOut ? row.GrowOut.label : row.Pontoon ? row.Pontoon.label : "-"),
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      scroll={{ x: "max-content" }}
    />
  );
};

export default FarmProductionTable;
