import React, { useContext, useEffect, useState } from "react";
import { Drawer, Descriptions, Divider, Typography, Button, Space, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ResponsiveContext } from "../../App";
import { useFactoryProduction } from "../../hooks/useFactoryProduction";
import { useFactoryProductionDetail } from "../../hooks/useFactoryProductionDetail";
import moment from "moment";
import ConfirmDeleteModal from "../common/ConfirmDeleteModal";
import NewFactoryProductionDetailModal from "./NewFactoryProductionDetailModal";
import EditFactoryProductionDetailModal from "./EditFactoryProductionDetailModal";

const { Title } = Typography;

const FactoryProductionDrawer = (props) => {
  const { id, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);
  const { findOne } = useFactoryProduction();
  const { deleteItem: deleteDetail } = useFactoryProductionDetail();

  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [editItem, setEditItem] = useState();
  const [deleteItem, setDeleteItem] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [newFactoryProductionDetailModal, setNewFactoryProductionDetailModal] = useState(false);
  const [editFactoryProductionDetailModal, setEditFactoryProductionDetailModal] = useState(false);

  useEffect(() => {
    if (visible || refresh) {
      findOne(id).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setRefresh(false);
      });
    }
  }, [visible, id, refresh]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (item) => {
    setEditItem(item);
    setEditFactoryProductionDetailModal(true);
  };

  const deleteRow = (item) => {
    setDeleteItem(item);
    setConfirmModal(true);
  };

  const confirmDelete = () => {
    deleteDetail(deleteItem.id).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setConfirmModal(false);
      }
    });
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "50%"}>
      <Title level={5}>Batch No : {data.batchNo}</Title>

      <Descriptions>
        <Descriptions.Item label="Date">{moment(data.productionTimestamp).format("YYYY-MM-DD")}</Descriptions.Item>
        <Descriptions.Item label="Quantity">
          {data.FactoryProductionDetails
            ? data.FactoryProductionDetails.reduce((acc, cur) => acc + cur.balanceQuantity, 0)
            : 0}{" "}
          <sub>remains</sub>
          &nbsp;/&nbsp;
          {data.FactoryProductionDetails
            ? data.FactoryProductionDetails.reduce((acc, cur) => acc + cur.quantity, 0)
            : 0}{" "}
          <sub> pcs</sub>
        </Descriptions.Item>
        <Descriptions.Item label="Weight">
          {data.FactoryProductionDetails
            ? data.FactoryProductionDetails.reduce((acc, cur) => acc + cur.balanceWeightInKg, 0)
            : 0}{" "}
          <sub>remains</sub>
          &nbsp;/&nbsp;
          {data.FactoryProductionDetails
            ? data.FactoryProductionDetails.reduce((acc, cur) => acc + cur.weightInKg, 0)
            : 0}{" "}
          <sub> kg</sub>
        </Descriptions.Item>
        <Descriptions.Item label="Status">{data.status}</Descriptions.Item>
        <Descriptions.Item label="Remarks">{data.remarks ? data.remarks : "-"}</Descriptions.Item>
      </Descriptions>

      <Divider orientation="left" />

      <Row style={{ marginBottom: 16 }}>
        <Col>
          <Button onClick={() => setNewFactoryProductionDetailModal(true)} type="primary" icon={<PlusOutlined />}>
            Add
          </Button>
        </Col>
      </Row>

      {data.FactoryProductionDetails
        ? data.FactoryProductionDetails.map((item) => (
            <div className="flex justify-between p-2 border-b">
              <div>ID: {item.id}</div>
              <div>
                <div>Grade {item.grade}</div>
                <div className="text-xs text-gray-400">{item.size}</div>
              </div>
              <div>
                <div>
                  {item.balanceQuantity} / {item.quantity} pcs
                </div>
              </div>
              <div>
                <div>
                  {item.balanceWeightInKg} / {item.weightInKg} kg
                </div>
              </div>
              <div>
                <Space>
                  <Button type="link" icon={<EditOutlined />} onClick={() => editRow(item)} />
                  <Button type="link" danger icon={<DeleteOutlined />} onClick={() => deleteRow(item)} />
                </Space>
              </div>
            </div>
          ))
        : null}

      <NewFactoryProductionDetailModal
        id={id}
        visible={newFactoryProductionDetailModal}
        setVisible={setNewFactoryProductionDetailModal}
        setRefresh={setRefresh}
      />

      <EditFactoryProductionDetailModal
        editItem={editItem}
        visible={editFactoryProductionDetailModal}
        setVisible={setEditFactoryProductionDetailModal}
        setRefresh={setRefresh}
      />

      <ConfirmDeleteModal visible={confirmModal} onOk={confirmDelete} onClose={() => setConfirmModal(false)} />
    </Drawer>
  );
};

export default FactoryProductionDrawer;
