import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Card, Empty, Divider, Space, Select, DatePicker, Modal } from "antd";
import { ReloadOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import moment from "moment";
import NewHapaFeedModal from "../../components/nurseryHapaFeed/NewHapaFeedModal";
import { useNurseryHapaFeed } from "../../hooks/useNurseryHapaFeed";
import { useNurseryHapa } from "../../hooks/useNurseryHapa";
import EditHapaFeedModal from "../../components/nurseryHapaFeed/EditHapaFeedModal";

const { Title } = Typography;

const NurseryHapaFeed = () => {
  const { findAll: findHapa } = useNurseryHapa();
  const { findAll, destroy } = useNurseryHapaFeed();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const [filterHapa, setFilterHapa] = useState("All");
  const [filterDate, setFilterDate] = useState(moment());

  const [hapaOption, setHapaOption] = useState([]);

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findHapa({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setHapaOption(res.data);
        }
      });

      findAll({
        nurseryHapaId: filterHapa,
        startDate: filterDate.startOf("day"),
        endDate: filterDate.endOf("day"),
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh]);

  useEffect(() => {
    setRefresh(true);
  }, [filterHapa, filterDate]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const deleteRow = (row) => {
    Modal.confirm({
      title: "Are you sure delete this item?",
      onOk: () => {
        destroy(row.id).then(() => {
          setRefresh(true);
        });
      },
      okType: "danger",
      okText: "Delete",
    });
  };

  return (
    <>
      <Row justify="space-between" style={{ marginTop: 16 }}>
        <Col>
          <Select
            style={{ width: 120 }}
            placeholder="Select HAPA"
            onChange={(value) => setFilterHapa(value)}
            value={filterHapa}
          >
            <Select.Option value="All">All</Select.Option>
            {hapaOption.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                HAPA {item.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Space>
            <Button
              type="link"
              icon={<DoubleLeftOutlined />}
              onClick={() => {
                const date = moment(filterDate).subtract(1, "day");
                setFilterDate(date);
              }}
            />
            <DatePicker value={filterDate} allowClear onChange={(date) => setFilterDate(date)} />
            <Button
              type="link"
              icon={<DoubleRightOutlined />}
              onClick={() => {
                const date = moment(filterDate).add(1, "day");
                setFilterDate(date);
              }}
            />
          </Space>
        </Col>
      </Row>

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>HAPA Feed</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        {data.length ? (
          <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
            {data.map((item) => (
              <Col key={item.id} xs={12} sm={6}>
                <Card
                  title={"HAPA " + item.NurseryHapa?.label}
                  size="small"
                  extra={moment(item.recordTimestamp).format("YYYY-MM-DD")}
                >
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Feed Type</Typography.Text>
                    </Col>
                    <Col>{item.feedType}</Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Feed (Kg)</Typography.Text>
                    </Col>
                    <Col>{Number(item.feedInKg).toLocaleString()}</Col>
                  </Row>
                  <Row justify="space-between" style={{ marginBottom: 10 }}>
                    <Col>
                      <Typography.Text type="secondary">Remarks</Typography.Text>
                    </Col>
                    <Col>{item.remarks ? item.remarks : "-"}</Col>
                  </Row>

                  <Divider />

                  <Row style={{ marginTop: 16 }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Button type="primary" ghost block onClick={() => editRow(item)}>
                        Edit
                      </Button>
                      <Button block danger onClick={() => deleteRow(item)}>
                        Delete
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty />
        )}

        <NewHapaFeedModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditHapaFeedModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default NurseryHapaFeed;
