import React from "react";
import { DatePicker, Form, Select, Input, InputNumber, Divider, Checkbox } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const AddNewForm = (props) => {
  const { form, pondOption, hapaOption } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="stockInTimestamp" label="Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="stockInQuantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber min={0} allowClear />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>

      <Divider orientation="left">Source</Divider>

      <Form.Item name="source" label="External">
        <Input allowClear />
      </Form.Item>

      <Form.Item name="sourceGrowOutId" label="Grow Out (Pond)">
        <Select allowClear>
          {pondOption.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...tailLayout} name="markGrowOutAsComplete" valuePropName="checked">
        <Checkbox>Set Grow Out cycle as complete</Checkbox>
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
