import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const useNurseryHapa = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query = {}) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(
        apiBaseUrl +
          "/wapi/nurseryHapa?startDate=" +
          query.startDate +
          "&endDate=" +
          query.endDate +
          "&status=" +
          query.status +
          "&nurseryRecordId=" +
          query.nurseryRecordId,
        config
      );
    },
    [authState.token, send]
  );

  // const create = useCallback(
  //   async (params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //       body: JSON.stringify(params),
  //     };

  //     return await send(apiBaseUrl + "/wapi/hapaRecords", config);
  //   },
  //   [authState.token, send]
  // );

  // const update = useCallback(
  //   async (id, params) => {
  //     const config = {
  //       method: "post",
  //       headers: {
  //         Authorization: "Bearer " + authState.token,
  //         "Content-Type": "application/json",
  //         FpId: (await fp).visitorId,
  //       },
  //       body: JSON.stringify(params),
  //     };

  //     return await send(apiBaseUrl + "/wapi/hapaRecords/" + id, config);
  //   },
  //   [authState.token, send]
  // );

  const harvest = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/nurseryHapa/" + id + "/harvest", config);
    },
    [authState.token, send]
  );

  return {
    findAll,
    // create,
    // update,
    // updateStockOut,
    harvest,
  };
};

export { useNurseryHapa };
