import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
// import openSocket from 'socket.io-client';
import { useFetch } from "../hooks/useFetch";

// const socket = openSocket('http://localhost:8000');
const apiBaseUrl = Constants.SERVER_URL;

const useNotification = () => {
  const { authState } = useContext(AuthContext);
  const { send } = useFetch();

  const subscribeToNotification = (cb) => {
    // const channel = 'newNotification/' + authState.authUser.id;
    // socket.on(channel, res => cb(res));
  };

  const unSubscribeNotification = () => {
    // const channel = 'newNotification/' + authState.authUser.id;
    // socket.off(channel, res => () => { })
  };

  const getUnSeenNotification = useCallback(async () => {
    const url = apiBaseUrl + "/wapi/notifications/unseen?userId=" + authState.authUser.id;

    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await send(url, config);
  }, [authState.token, authState.authUser.id, send]);

  const clearHasSeenNotification = useCallback(
    async (params) => {
      const url = apiBaseUrl + "/wapi/notifications/clearHasSeenNotification";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const getNotificationList = useCallback(async () => {
    const url = apiBaseUrl + "/wapi/notifications/list?userId=" + authState.authUser.id;

    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await send(url, config);
  }, [authState.token, authState.authUser.id, send]);

  const markNotificationAsRead = useCallback(
    async (id, params) => {
      const url = apiBaseUrl + "/wapi/notifications/" + id + "/markAsRead";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const markAllAsRead = useCallback(
    async (params) => {
      const url = apiBaseUrl + "/wapi/notifications/markAllAsRead";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  const clearAllNotification = useCallback(
    async (params) => {
      const url = apiBaseUrl + "/wapi/notifications/clearAllNotification";

      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(url, config);
    },
    [authState.token, send]
  );

  return {
    subscribeToNotification,
    unSubscribeNotification,
    getUnSeenNotification,
    clearHasSeenNotification,
    getNotificationList,
    markNotificationAsRead,
    markAllAsRead,
    clearAllNotification,
  };
};

export { useNotification };
