import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Space } from "antd";
import { ReloadOutlined, ExportOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useIncubationRecord } from "../../hooks/useIncubationRecord";

import IncubationRecordTable from "./tables/IncubationRecordTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import ParameterModal from "./modals/ParameterModal";
import EditParameterModal from "./modals/EditParameterModal";
import LarvalStockInModal from "./modals/LarvalStockInModal";
import Filter from "./components/Filter";
import moment from "moment";
import HatchModal from "./modals/HatchModal";
import { toArrayBuffer } from "../../utils/HelperFunctions";

const { Title } = Typography;

const IncubationRecordList = () => {
  const { t } = useTranslation();

  const { findAll, exportToExcel } = useIncubationRecord();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState([moment().subtract(1, "month"), moment()]);
  const [filterStatus, setFilterStatus] = useState("All");

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [parameterModal, setParameterModal] = useState(false);
  const [editParameterModal, setEditParameterModal] = useState(false);
  const [larvalStockInModal, setLarvalStockInModal] = useState(false);
  const [hatchModal, setHatchModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

      findAll({
        status: filterStatus,
        startDate: startDate,
        endDate: endDate,
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, filterStatus, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editParameter = (item) => {
    setEditItem(item);
    setParameterModal(true);
  };

  const editParameterItem = (item) => {
    setEditItem(item);
    setEditParameterModal(true);
  };

  const editLarvalStockIn = (item) => {
    setEditItem(item);
    setLarvalStockInModal(true);
  };

  const markAsHatch = (item) => {
    setEditItem(item);
    setHatchModal(true);
  };

  const doExcelExport = () => {
    const startDate = filterDate && filterDate[0] ? filterDate[0].format("YYYY-MM-DD") : null;
    const endDate = filterDate && filterDate[1] ? filterDate[1].format("YYYY-MM-DD") : null;

    exportToExcel({
      status: filterStatus,
      startDate: startDate,
      endDate: endDate,
    }).then((res) => {
      if (res.status === "success") {
        const file = new Blob([toArrayBuffer(res.data.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "Incubation Record.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <>
      <Filter
        filterStatus={filterStatus}
        setFilterStatus={setFilterStatus}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Incubation Record</Title>

        <Row justify="space-between">
          <Col>
            {/* <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button> */}
          </Col>
          <Col>
            <Space>
              <Button onClick={doExcelExport} icon={<ExportOutlined />}>
                Export to Excel
              </Button>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Space>
          </Col>
        </Row>

        <IncubationRecordTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
          editParameter={editParameter}
          editLarvalStockIn={editLarvalStockIn}
          editParameterItem={editParameterItem}
          markAsHatch={markAsHatch}
        />

        <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

        <ParameterModal
          item={editItem}
          visible={parameterModal}
          setVisible={setParameterModal}
          setRefresh={setRefresh}
        />

        <EditParameterModal
          item={editItem}
          visible={editParameterModal}
          setVisible={setEditParameterModal}
          setRefresh={setRefresh}
        />

        <LarvalStockInModal
          editItem={editItem}
          visible={larvalStockInModal}
          setVisible={setLarvalStockInModal}
          setRefresh={setRefresh}
        />

        <HatchModal editItem={editItem} visible={hatchModal} setVisible={setHatchModal} setRefresh={setRefresh} />
      </div>
    </>
  );
};

export default IncubationRecordList;
