import { useCallback, useContext } from "react";
import { AuthContext, fp } from "../App";
import Constants from "../utils/Constants";
import { useFetch } from "../hooks/useFetch";

const apiBaseUrl = Constants.SERVER_URL;

const usePond = () => {
  const { authState } = useContext(AuthContext);

  const { send } = useFetch();

  const findAll = useCallback(
    async (query) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds?status=" + query.status + "&farmId=" + query.farmId, config);
    },
    [authState.token, send]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/ponds", config);
    },
    [authState.token, send]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id, config);
    },
    [authState.token, send]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id, config);
    },
    [authState.token, send]
  );

  const findPondPreparation = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/pondPreparations", config);
    },
    [authState.token, send]
  );

  const findGrowOut = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/growOuts", config);
    },
    [authState.token, send]
  );

  const findPondFeed = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/pondFeeds", config);
    },
    [authState.token, send]
  );

  const findPondTreatment = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/pondTreatments", config);
    },
    [authState.token, send]
  );

  const findPondParameter = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/pondParameters", config);
    },
    [authState.token, send]
  );

  const findPondSampling = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/pondSamplings", config);
    },
    [authState.token, send]
  );

  const findDailyRecord = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await send(apiBaseUrl + "/wapi/ponds/" + id + "/dailyRecords", config);
    },
    [authState.token, send]
  );
  return {
    findAll,
    create,
    update,
    findOne,
    findPondPreparation,
    findGrowOut,
    findPondFeed,
    findPondTreatment,
    findPondParameter,
    findDailyRecord,
    findPondSampling,
  };
};

export { usePond };
