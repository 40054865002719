import React, { useState, useEffect } from "react";
import { Table, Space, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { sscGrade, sscSize } from "../../../utils/Options";

const OrderDetailTable = (props) => {
  const { item, detailData, deleteRow } = props;

  const [data, setData] = useState([]);

  const columns = [
    {
      title: "Grade",
      dataIndex: "grade",
      align: "center",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Size",
      dataIndex: "size",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Weight (KG)",
      dataIndex: "weightInKg",
      render: (text) => (text ? Number(text).toFixed(2) : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Space>
          <Button
            type="link"
            disabled={item.status !== "New"}
            icon={<DeleteOutlined />}
            onClick={() => deleteRow(row)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (detailData && detailData.length && item.product === "Soft Shell Crab") {
      let array = [];
      for (const grade of sscGrade) {
        for (const size of sscSize) {
          for (const item of detailData) {
            if (item.grade === grade && item.size === size) {
              array.push(item);
            }
          }
        }
      }

      setData(array);
    } else {
      setData(detailData);
    }
  }, [detailData]);

  return <Table rowKey="id" size="small" columns={columns} dataSource={data} style={{ marginTop: 24 }} />;
};

export default OrderDetailTable;
