import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import EditForm from "../forms/EditForm";
import moment from "moment";
import { useGrowOut } from "../../../hooks/useGrowOut";
import { useGrowOutRecord } from "../../../hooks/useGrowOutRecord";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findGrowOut } = useGrowOut();
  const { update } = useGrowOutRecord();

  const [pondOption, setPondOption] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      findGrowOut().then((res) => {
        if (res.status === "success") {
          setPondOption(res.data);
        }
      });

      form.setFieldsValue({
        growOutId: editItem.growOutId,
        stockInTimestamp: editItem.stockInTimestamp ? moment(editItem.stockInTimestamp) : null,
        stockInQuantity: editItem.stockInQuantity,
        stockOutTimestamp: editItem.stockOutTimestamp ? moment(editItem.stockOutTimestamp) : null,
        stockOutQuantity: editItem.stockOutQuantity,
        source: editItem.source,
        sourceHapaLabel: editItem.SourceHapa?.label,
        sourceGrowOutLabel: editItem.SourceGrowOut?.label,
        remarks: editItem.remarks,
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          growOutId: values.growOutId,
          stockInTimestamp: values.stockInTimestamp ? moment(values.stockInTimestamp).format() : null,
          stockInQuantity: values.stockInQuantity,
          stockOutTimestamp: values.stockOutTimestamp ? moment(values.stockOutTimestamp).format() : null,
          stockOutQuantity: values.stockOutQuantity,
          source: values.source,
          remarks: values.remarks,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Grow Out Record"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <EditForm editItem={editItem} form={form} pondOption={pondOption} />
    </Modal>
  );
};

export default EditModal;
