import React from "react";
// import { useTranslation } from "react-i18next";
import { Tooltip, Space, Popover, Button, Tag, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import TableLoader from "../../common/TableLoader";
import ExportToExcel from "../../common/ExportToExcel";
import moment from "moment";

const { Link } = Typography;

const TankTransferTable = (props) => {
  // const { t } = useTranslation();

  const { dataSource, loading, error, editRow, editProduction, viewDetail } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Tank #",
      dataIndex: "Tank",
      render: (text, row) => <Link onClick={() => viewDetail(row)}>{row.Tank.label}</Link>,
    },
    {
      title: "Batch #",
      dataIndex: "batchNo",
      render: (text) => (text ? <Tag>{text}</Tag> : "-"),
    },
    {
      title: "Broodstock #",
      dataIndex: "broodstockNo",
      render: (text) => (text ? text : "-"),
    },
    // {
    //   title: "From",
    //   dataIndex: "TankUsageSources",
    //   render: (text, row) =>
    //     row.TankUsageSources && row.TankUsageSources.length
    //       ? row.TankUsageSources.map((item) => item.label).join(", ")
    //       : "-",
    // },
    // {
    //   title: "Hatch Date",
    //   dataIndex: "hatchDate",
    //   render: (text) => moment(text).format("YYYY-MM-DD"),
    // },
    {
      title: "Stock In Date",
      dataIndex: "stockInTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Stock In Quantity",
      dataIndex: "stockInQuantity",
      render: (text) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Stock Out Date",
      dataIndex: "stockOutTimestamp",
      render: (text, row) =>
        text ? (
          moment(text).format("YYYY-MM-DD")
        ) : (
          <Popover
            content={
              <Space>
                {/* <Button size="small" type="primary" onClick={() => editTransferToTank(row)}>
                  Tank Transfer
                </Button> */}
                <Button size="small" type="default" onClick={() => editProduction(row)}>
                  New Harvest
                </Button>
              </Space>
            }
          >
            <EditOutlined style={{ color: blue.primary }} />
          </Popover>
        ),
      excelRender: (text, row) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Space>
          <Tooltip title="Edit">
            <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
          </Tooltip>
          {/* <Tooltip title="View">
            <FileSearchOutlined style={{ color: green.primary }} onClick={() => viewDetail(row)} />
          </Tooltip> */}
        </Space>
      ),
    },
  ];

  return (
    <>
      <ExportToExcel
        docName="Tank Transfer"
        columns={[
          ...columns,
          {
            title: "Transferred From",
            dataIndex: "TankUsageSources",
            render: (text, row) =>
              row.TankUsageSources && row.TankUsageSources.length
                ? row.TankUsageSources.map((item) => item.label).join(", ")
                : "-",
          },
          {
            title: "Hatch Date",
            dataIndex: "hatchDate",
            render: (text) => moment(text).format("YYYY-MM-DD"),
          },
        ]}
        dataSource={dataSource}
      />
      <TableLoader
        error={error}
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: 24 }}
        // expandable={{
        //   expandedRowRender: (row) => (
        //     <Row justify="space-between">
        //       <Col>
        //         <span>
        //           <strong>Transferred From: </strong>
        //         </span>
        //         {row.TankUsageSources && row.TankUsageSources.length
        //           ? row.TankUsageSources.map((item) => item.label).join(", ")
        //           : "-"}
        //       </Col>
        //       <Col>
        //         <span>
        //           <strong>Hatch Date: </strong>
        //         </span>
        //         {row.hatchDate ? moment(row.hatchDate).format("YYYY-MM-DD") : "-"}
        //       </Col>
        //       <Col></Col>
        //     </Row>
        //   ),
        // }}
        scroll={{ x: "max-content" }}
      />
    </>
  );
};

export default TankTransferTable;
