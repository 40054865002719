import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Modal, Space, Tag, Divider } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { usePond } from "../../hooks/usePond";
import Filter from "../../components/pondSampling/PondSamplingFilter";

import moment from "moment";
import NewPondHarvestModal from "../../components/pondHarvest/NewPondHarvestModal";
import PondHarvestList from "../../components/pondHarvest/PondHarvestList";
import { usePondHarvest } from "../../hooks/usePondHarvest";
import EditPondHarvestModal from "../../components/pondHarvest/EditPondHarvestModal";

const { Title } = Typography;

const PondHarvest = () => {
  const { t } = useTranslation();

  const { findAll: findPond } = usePond();
  const { findAll, destroy } = usePondHarvest();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterPond, setFilterPond] = useState("All");
  const [filterDate, setFilterDate] = useState([moment().add(-7, "days"), moment()]);

  const [pondOption, setPondOption] = useState([]);
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findPond({ status: "New" })
        .then((res) => {
          if (res.status === "success") {
            setPondOption(res.data);
          }
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));

      const startDate = filterDate && filterDate[0] ? filterDate[0] : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1] : null;

      findAll({ pondId: filterPond, startDate: startDate, endDate: endDate }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh, filterPond, findPond, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const deleteRow = (row) => {
    Modal.confirm({
      title: "Are you sure delete this item?",
      onOk: () => {
        destroy(row.id).then(() => {
          setRefresh(true);
        });
      },
      okType: "danger",
      okText: "Delete",
    });
  };

  return (
    <>
      <Filter
        pondOption={pondOption}
        filterPond={filterPond}
        setFilterPond={setFilterPond}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Pond Harvests</Title>
        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Space wrap>
              <Tag>Total Harvest: {data.reduce((acc, cur) => acc + cur.totalHarvestInPcs, 0)}</Tag>
              <Tag>Small: {data.reduce((acc, cur) => acc + cur.totalSmallInPcs, 0)}</Tag>
              <Tag>SSC: {data.reduce((acc, cur) => acc + cur.totalSSCInPcs, 0)}</Tag>
              <Tag>HSC: {data.reduce((acc, cur) => acc + cur.totalHSCInPcs, 0)}</Tag>
              <Tag>Shrimp: {data.reduce((acc, cur) => acc + cur.totalShrimpInPcs, 0)}</Tag>
              <Tag>Dead: {data.reduce((acc, cur) => acc + cur.totalDeadInPcs, 0)}</Tag>
              <Tag>Broodstock: {data.reduce((acc, cur) => acc + cur.totalBroodstockInPcs, 0)}</Tag>
              <Tag>Greenhouse: {data.reduce((acc, cur) => acc + cur.totalGreenhouseInPcs, 0)}</Tag>
            </Space>
          </Col>
        </Row>

        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Space wrap>
              <Tag>Total Weight (kg): {data.reduce((acc, cur) => acc + cur.totalHarvestInKg, 0)}</Tag>
              <Tag>Total Feed (kg): {data.reduce((acc, cur) => acc + cur.totalFeedInKg, 0)}</Tag>
              <Tag>
                Average ABW:{" "}
                {data.filter((item) => item.avgWeightInGram > 0).length > 0
                  ? (
                      data.reduce((acc, cur) => acc + cur.avgWeightInGram, 0) /
                      data.filter((item) => item.avgWeightInGram > 0).length
                    ).toFixed(0)
                  : "-"}
              </Tag>
              <Tag>
                Average SR:{" "}
                {data.filter((item) => item.survivalRate > 0).length > 0
                  ? (
                      data.reduce((acc, cur) => acc + cur.survivalRate, 0) /
                      data.filter((item) => item.survivalRate > 0).length
                    ).toFixed(2)
                  : "-"}
              </Tag>
              <Tag>
                Average FCR:{" "}
                {data.filter((item) => item.fcr > 0).length > 0
                  ? (data.reduce((acc, cur) => acc + cur.fcr, 0) / data.filter((item) => item.fcr > 0).length).toFixed(
                      2
                    )
                  : "-"}
              </Tag>
            </Space>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <PondHarvestList data={data} editRow={editRow} deleteRow={deleteRow} />

        <NewPondHarvestModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditPondHarvestModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default PondHarvest;
