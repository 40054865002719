import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, Input, Space, InputNumber } from "antd";
import moment from "moment";
import { sscGrade } from "../../../utils/Options";
import { useFarmStorageRecord } from "../../../hooks/useFarmStorageRecord";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const FarmStorageStockCountModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { create } = useFarmStorageRecord();

  const [submitting, setSubmitting] = useState(false);
  const [accumulatedSSC, setAccumulatedSSC] = useState();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(),
      });
    }
  }, [visible, form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          remarks: values.remarks,
          accumulatedSSC: accumulatedSSC,
          stockMovement: "Stock Count",
        };

        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            setAccumulatedSSC(null);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onAccInputChange = (grade, value) => {
    let grades = { ...accumulatedSSC };

    grades = {
      ...grades,
      [grade]: {
        ...grades[grade],
        quantity: value,
      },
    };
    setAccumulatedSSC(grades);
  };

  const onAccWeightChange = (grade, value) => {
    let grades = { ...accumulatedSSC };

    grades = {
      ...grades,
      [grade]: {
        ...grades[grade],
        weightInKg: value,
      },
    };

    setAccumulatedSSC(grades);
  };

  return (
    <Modal
      title="Stock Count"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="farm-storage-form">
        <Form.Item name="recordTimestamp" label="Date" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea rows={5} />
        </Form.Item>

        {sscGrade.map((grade) => (
          <Form.Item key={grade} label={"Grade " + grade}>
            <Space>
              <InputNumber
                min={0}
                placeholder="PCS"
                inputMode="numeric"
                allowClear
                onChange={(value) => onAccInputChange(grade, value)}
                value={accumulatedSSC && accumulatedSSC[grade] ? accumulatedSSC[grade]["quantity"] : null}
              />
              <InputNumber
                min={0}
                placeholder="KG"
                inputMode="decimal"
                allowClear
                onChange={(value) => onAccWeightChange(grade, value)}
                value={accumulatedSSC && accumulatedSSC[grade] ? accumulatedSSC[grade]["weightInKg"] : null}
              />
            </Space>
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default FarmStorageStockCountModal;
