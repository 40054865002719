import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import EditForm from "../forms/EditForm";
import { useRawMaterial } from "../../../hooks/useRawMaterial";

const EditModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = useRawMaterial();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name: editItem.name,
        type: editItem.type,
        status: editItem.status,
      });
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        // console.log(values);
        setSubmitting(true);
        const params = {
          name: values.name,
          type: values.type,
          status: values.status,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Raw Material"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Save"
      onCancel={handleCancel}
    >
      <EditForm form={form} />
    </Modal>
  );
};

export default EditModal;
