import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import ProcessingTable from "./tables/ProcessingTable";
import AddNewModal from "./modals/AddNewModal";
import EditModal from "./modals/EditModal";
import { useProcessing } from "../../hooks/useProcessing";

const { Title } = Typography;

const ProcessingList = () => {
  const { t } = useTranslation();

  const { findAll } = useProcessing();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={2}>Processing List</Title>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setAddNewModal(true)}>
            New
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </Col>
      </Row>

      <ProcessingTable error={error} loading={loading} dataSource={data} editRow={editRow} />

      <AddNewModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

      <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />
    </div>
  );
};

export default ProcessingList;
