import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, List, Modal } from "antd";
import { ReloadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import NewTreatmentTypeModal from "./components/NewTreatmentTypeModal";
import EditTreatmentTypeModal from "./components/EditTreatmentTypeModal";
import { useTreatmentType } from "../../hooks/useTreatmentType";
import { unitOfMeasure } from "../../utils/Options";

const { Title } = Typography;

const TreatmentType = () => {
  const { t } = useTranslation();

  const { findAll, destroy } = useTreatmentType();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [newTreatmentTypeModal, setNewTreatmentTypeModal] = useState(false);
  const [editTreatmentTypeModal, setEditTreatmentTypeModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditTreatmentTypeModal(true);
  };

  const deleteRow = (row) => {
    Modal.confirm({
      title: "Are you sure delete this item?",
      onOk: () => {
        destroy(row.id).then(() => {
          setRefresh(true);
        });
      },
      okType: "danger",
      okText: "Delete",
    });
  };

  return (
    <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
      <Title level={3}>Treatment Types</Title>

      <Row justify="space-between">
        <Col>
          <Button type="primary" onClick={() => setNewTreatmentTypeModal(true)}>
            New
          </Button>
        </Col>
        <Col>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
        </Col>
      </Row>

      <List
        loading={loading}
        style={{ marginTop: 16 }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button onClick={() => editRow(item)} icon={<EditOutlined />} />,

              <Button danger onClick={() => deleteRow(item)} icon={<DeleteOutlined />} />,
            ]}
          >
            <List.Item.Meta title={item.name} description={item.uom} />
            <Typography.Text type="secondary">{unitOfMeasure[item.uom]}</Typography.Text>
          </List.Item>
        )}
      />

      <NewTreatmentTypeModal
        visible={newTreatmentTypeModal}
        setVisible={setNewTreatmentTypeModal}
        setRefresh={setRefresh}
      />

      <EditTreatmentTypeModal
        editItem={editItem}
        visible={editTreatmentTypeModal}
        setVisible={setEditTreatmentTypeModal}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default TreatmentType;
