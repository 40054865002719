import React from "react";
import { Form, Input, Select } from "antd";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AddNewForm = (props) => {
  const { form } = props;

  return (
    <Form {...layout} form={form} name="add-new-form">
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input allowClear />
      </Form.Item>
      <Form.Item name="type" label="Type" rules={[{ required: true }]}>
        <Select allowClear>
          <Option value="Hatchery">Hatchery</Option>
          <Option value="Farm">Farm</Option>
          <Option value="Processing">Processing</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
