const hatcheryTechnician = [
  "Hatchery",
  "HatcheryDashboard",
  "HatcheryReceipt",
  "HatcheryReceipt.Add",
  "HatcheryReceipt.Edit",
  "IncubationRecord",
  "IncubationRecord.Add",
  "Incubation.Edit",
  "TankTransfer",
  "LarvalDailyRecord",
  "LarvalHistoryRecord",
  "HatcheryProduction",
  "HatcheryTransfer",
  "HatcheryReport",
];

const hatcheryManager = [...hatcheryTechnician, "Settings", "HatcherySettings"];

const farmTechnician = [
  "Farm",
  "FarmDashboard",
  "FarmReceipt",
  "HapaRecord",
  "GrowOutRecord",
  "PondPreparationRecord",
  "PondDailyRecord",
  "SSCDailyMonitor",
  "FarmProduction",
  "DeliveryOrder",
];

const farmManager = [...farmTechnician, "Settings", "FarmSettings", "Confirm SSC Harvest"];

const processingTechnician = [
  "Processing",
  "ProcessingDashboard",
  "ProcessingReceipt",
  "ProcessingProduction",
  "ProcessingFreezing",
  "ProcessingBabyBox",
  "ProcessingMasterBox",
  "ProcessingStorage",
  "ProcessingOrder",
];

const processingManager = [...processingTechnician, "Settings", "ProcessingSettings"];

const RolePermission = {
  HATCHERY: {
    TECHNICIAN: hatcheryTechnician,
    MANAGER: hatcheryManager,
  },
  FARM: {
    TECHNICIAN: farmTechnician,
    MANAGER: farmManager,
  },
  PROCESSING: {
    TECHNICIAN: processingTechnician,
    MANAGER: processingManager,
  },
  NEOCRAB: {
    ADMIN: [...hatcheryManager, ...farmManager],
    MANAGER: [...hatcheryManager, ...farmManager, ...processingManager],
    HATCHERY_FARM_MANAGER: [...hatcheryManager, ...farmManager],
    FARM_PROCESSING_MANAGER: [...farmManager, ...processingManager],
  },
};

export default RolePermission;
