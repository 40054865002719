import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Form } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

import AddNewRecipeModal from "./AddNewRecipeModal";
import EditRecipeModal from "./EditRecipeModal";
import { useRecipe } from "../../hooks/useRecipe";
import RecipeTable from "./RecipeTable";

const { Title } = Typography;

const RecipesList = () => {
  const { t } = useTranslation();
  const { findAll } = useRecipe();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [editRecipeModal, setEditRecipeModal] = useState(false);
  const [addNewRecipeModal, setAddNewRecipeModal] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (refresh) {
      findAll()
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh]);

  useEffect(() => {
    if (editItem.id) {
      const index = data.map((item) => item.id).indexOf(editItem.id);

      if (index > -1) {
        setEditItem(data[index]);
      }
    }
  }, [data, editItem]);

  const editRow = (item) => {
    setEditItem(item);
    setEditRecipeModal(true);
  };

  return (
    <>
      <div
        className="site-layout-background"
        style={{ padding: 24, marginTop: 16 }}
      >
        <Title level={2}>Recipes</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setAddNewRecipeModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button
              icon={<ReloadOutlined />}
              disabled={loading}
              onClick={() => setRefresh(true)}
            />
          </Col>
        </Row>

        <RecipeTable
          error={error}
          loading={loading}
          dataSource={data}
          editRow={editRow}
        />

        <AddNewRecipeModal
          visible={addNewRecipeModal}
          handleSubmit={(res) => {
            if (res.status == 201) {
              setRefresh(true);
              setAddNewRecipeModal(false);
            }
          }}
        />

        <EditRecipeModal
          editItem={editItem}
          visible={editRecipeModal}
          setVisible={setEditRecipeModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default RecipesList;
