import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, DatePicker, Tooltip } from "antd";
import { ReloadOutlined, EditOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { useTranslation } from "react-i18next";
import TableLoader from "../../components/common/TableLoader";
import moment from "moment";
import { usePontoonFeed } from "../../hooks/usePontoonFeed";
import NewSSCFeedModal from "../../components/sscFeeding/NewSSCFeedModal";
import EditSSCFeedModal from "../../components/sscFeeding/EditSSCFeedModal";

const { Title } = Typography;

const SSCFeeding = () => {
  const { t } = useTranslation();

  const { findAll } = usePontoonFeed();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "recordTimestamp",
      render: (text) => moment(text).format("YYYY-MM-DD"),
    },
    {
      title: "Shift",
      dataIndex: "shift",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Pontoon",
      render: (text, row) => row.Pontoon.label,
    },
    {
      title: "Feed Type",
      dataIndex: "feedType",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Feed (KG)",
      dataIndex: "weightInKg",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Action",
      render: (text, row) => (
        <Tooltip title="Edit">
          <EditOutlined style={{ color: blue.primary }} onClick={() => editRow(row)} />
        </Tooltip>
      ),
    },
  ];

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterDate, setFilterDate] = useState(moment());

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});

  const [feedingModal, setFeedingModal] = useState(false);
  const [editFeedingModal, setEditFeedingModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findAll({
        date: filterDate.format("YYYY-MM-DD"),
      })
        .then((res) => {
          if (res.status === "success") {
            setData(res.data);
          }
          setError();
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, filterDate, findAll, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditFeedingModal(true);
  };

  return (
    <>
      <div className="flex justify-end mt-4">
        <DatePicker
          value={filterDate}
          onChange={(value) => {
            setFilterDate(value);
            setRefresh(true);
          }}
        />
      </div>

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Soft Shell Crab Feeding</Title>

        <Row justify="space-between">
          <Col>
            <Button type="primary" onClick={() => setFeedingModal(true)}>
              New Feeding
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <TableLoader
          error={error}
          loading={loading}
          dataSource={data}
          columns={columns}
          style={{ marginTop: 24 }}
          scroll={{ x: "max-content" }}
        />

        <NewSSCFeedModal visible={feedingModal} setVisible={setFeedingModal} setRefresh={setRefresh} />

        <EditSSCFeedModal
          visible={editFeedingModal}
          setVisible={setEditFeedingModal}
          setRefresh={setRefresh}
          editItem={editItem}
        />
      </div>
    </>
  );
};

export default SSCFeeding;
