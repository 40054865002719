import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { usePond } from "../../hooks/usePond";
import Filter from "../../components/pondSampling/PondSamplingFilter";
import PondSamplingList from "../../components/pondSampling/PondSamplingList";
import NewPondSamplingModal from "../../components/pondSampling/NewPondSamplingModal";
import EditPondSamplingModal from "../../components/pondSampling/EditPondSamplingModal";
import { usePondSampling } from "../../hooks/usePondSampling";

import moment from "moment";

const { Title } = Typography;

const PondSampling = () => {
  const { t } = useTranslation();

  const { findAll: findPond } = usePond();
  const { findAll: findSampling, destroy } = usePondSampling();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState();

  const [filterPond, setFilterPond] = useState("All");
  const [filterDate, setFilterDate] = useState([moment().add(-7, "days"), moment()]);

  const [pondOption, setPondOption] = useState([]);
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      findPond({ status: "New" })
        .then((res) => {
          if (res.status === "success") {
            setPondOption(res.data);
          }
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));

      const startDate = filterDate && filterDate[0] ? filterDate[0] : null;
      const endDate = filterDate && filterDate[1] ? filterDate[1] : null;

      findSampling({ pondId: filterPond, startDate: startDate, endDate: endDate }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh, filterPond, findPond, findSampling, t]);

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const deleteRow = (row) => {
    Modal.confirm({
      title: "Are you sure delete this item?",
      onOk: () => {
        destroy(row.id).then(() => {
          setRefresh(true);
        });
      },
      okType: "danger",
      okText: "Delete",
    });
  };

  return (
    <>
      <Filter
        pondOption={pondOption}
        filterPond={filterPond}
        setFilterPond={setFilterPond}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        setRefresh={setRefresh}
      />

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Pond Samplings</Title>

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <Button type="primary" onClick={() => setAddNewModal(true)}>
              New
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        <PondSamplingList data={data} editRow={editRow} deleteRow={deleteRow} />

        <NewPondSamplingModal visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

        <EditPondSamplingModal
          editItem={editItem}
          visible={editModal}
          setVisible={setEditModal}
          setRefresh={setRefresh}
        />
      </div>
    </>
  );
};

export default PondSampling;
