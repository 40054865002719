import React from "react";
import { List, Card, Button, Space, Row, Col, Divider, Typography } from "antd";
import moment from "moment";

const PondSamplingList = (props) => {
  const { data, editRow, deleteRow } = props;
  return (
    <List
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 5 }}
      dataSource={data}
      pagination={{
        onChange: (page) => {
          // console.log(page);
        },
        pageSize: 10,
      }}
      renderItem={(item) => (
        <List.Item>
          <Card title={item.Pond?.label} extra={moment(item.recordTimestamp).format("MMM, D")}>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Cycle No</Typography.Text>
              </Col>
              <Col>{item.GrowOut ? item.GrowOut.cycleNo : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Material</Typography.Text>
              </Col>
              <Col>{item.GrowOut ? item.GrowOut.material : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Stock Density (pcs)</Typography.Text>
              </Col>
              <Col>{item.stockDensityInPcs ? Number(item.stockDensityInPcs).toLocaleString() : "-"}</Col>
            </Row>
            <Divider />
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Avg Body Weight (g)</Typography.Text>
              </Col>
              <Col>{item.avgWeightInGram ? item.avgWeightInGram : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Est. Survival Rate (%)</Typography.Text>
              </Col>
              <Col>{item.estSurvivalInPerc ? item.estSurvivalInPerc : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Est. Biomass (kg)</Typography.Text>
              </Col>
              <Col>{item.estBiomassInKg ? item.estBiomassInKg : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Running Feed (kg)</Typography.Text>
              </Col>
              <Col>{item.runningFeedInKg ? item.runningFeedInKg : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Running FCR</Typography.Text>
              </Col>
              <Col>{item.runningFCR ? item.runningFCR : "-"}</Col>
            </Row>
            <Row justify="space-between" style={{ marginBottom: 10 }}>
              <Col>
                <Typography.Text type="secondary">Weight Gained (g)</Typography.Text>
              </Col>
              <Col>{item.weightGainInGram ? item.weightGainInGram : "-"}</Col>
            </Row>

            <Divider />

            <Row style={{ marginTop: 16 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Button type="primary" ghost block onClick={() => editRow(item)}>
                  Edit
                </Button>
                <Button block danger onClick={() => deleteRow(item)}>
                  Delete
                </Button>
              </Space>
            </Row>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default PondSamplingList;
