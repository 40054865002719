import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker } from "antd";
import moment from "moment";
import { useHatcheryReceipt } from "../../../hooks/useHatcheryReceipt";

const IncubationModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { updateIncubation } = useHatcheryReceipt();

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      spawnDate: moment(),
    });
  }, [form]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const params = {
          spawnDate: moment(values.spawnDate).format(),
        };
        setSubmitting(true);
        updateIncubation(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Transfer to Incubation"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form form={form} layout="inline">
        <Form.Item name="spawnDate" label="Spawn Date" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IncubationModal;
