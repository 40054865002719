import React, { useState, useEffect, useContext } from "react";
import { Drawer, Row, Col, Button, Tabs } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { useTank } from "../../../hooks/useTank";
import LarvalInfo from "../components/LarvalInfo";
import ChemicalUsage from "../components/ChemicalUsage";
import AddNewModal from "../modals/AddNewModal";
import EditModal from "../modals/EditModal";
import DuplicateModal from "../modals/DuplicateModal";
import DailyRecordTable from "../tables/DailyRecordTable";
import { ResponsiveContext } from "../../../App";

const { TabPane } = Tabs;

const DailyRecordDrawer = (props) => {
  const { item, visible, setVisible } = props;

  const { isMobile } = useContext(ResponsiveContext);

  const { findLarvalData } = useTank();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState();

  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState();

  const [addNewModal, setAddNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);

  useEffect(() => {
    if ((visible || refresh) && item.id) {
      setLoading(true);
      findLarvalData(item.id).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setError();
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [visible, refresh, item, findLarvalData]);

  const onClose = () => {
    setVisible(false);
  };

  const editRow = (row) => {
    setEditItem(row);
    setEditModal(true);
  };

  const editDuplicate = (row) => {
    setEditItem(row);
    setDuplicateModal(true);
  };

  return (
    <Drawer placement="right" onClose={onClose} visible={visible} width={isMobile ? null : "80%"}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="DOC" key="doc" style={{ marginTop: 16 }}>
          <Row justify="space-between">
            <Col>
              <Button type="primary" onClick={() => setAddNewModal(true)}>
                New
              </Button>
            </Col>
            <Col>
              <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
            </Col>
          </Row>

          <DailyRecordTable
            error={error}
            loading={loading}
            dataSource={data}
            tankData={item}
            editRow={editRow}
            editDuplicate={editDuplicate}
          />
        </TabPane>
        <TabPane tab="Larval Info" key="larvalInfo">
          <LarvalInfo editItem={item} />
        </TabPane>
        <TabPane tab="Chemical Usage" key="chemicalUsage">
          <ChemicalUsage data={data} />
        </TabPane>
      </Tabs>

      <AddNewModal selectedTank={item} visible={addNewModal} setVisible={setAddNewModal} setRefresh={setRefresh} />

      <EditModal editItem={editItem} visible={editModal} setVisible={setEditModal} setRefresh={setRefresh} />

      <DuplicateModal
        editItem={editItem}
        visible={duplicateModal}
        setVisible={setDuplicateModal}
        setRefresh={setRefresh}
      />
    </Drawer>
  );
};

export default DailyRecordDrawer;
