import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { usePond } from "../../hooks/usePond";
import { usePontoon } from "../../hooks/usePontoon";
import PontoonForm from "./forms/PontoonForm";

const EditPontoonModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = usePond();

  const { update } = usePontoon();

  const [pondOption, setPondOption] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (visible) {
      findAll({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setPondOption(res.data);
        }
      });
      form.setFieldsValue({
        label: editItem.label,
        pondId: editItem.pondId,
        sscSource: editItem.sscSource,
        sscCount: editItem.sscCount,
        status: editItem.status,
      });
    }
  }, [visible, form, editItem, findAll]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          label: values.label,
          pondId: values.pondId,
          sscSource: values.sscSource,
          sscCount: values.sscCount,
          status: values.status,
        };
        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Edit Pontoon"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PontoonForm form={form} pondOption={pondOption} />
    </Modal>
  );
};

export default EditPontoonModal;
