import React, { useEffect, useState } from "react";
import { Modal, Form, DatePicker, InputNumber, Typography, Tabs } from "antd";
import moment from "moment";
import { useGrowOut } from "../../hooks/useGrowOut";
import { useNurseryHapa } from "../../hooks/useNurseryHapa";

const { Text } = Typography;
const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const NurseryHarvestModal = (props) => {
  const { visible, editItem, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll: findGrowOut } = useGrowOut();

  const { harvest } = useNurseryHapa();

  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [growOutOption, setGrowOutOption] = useState([]);
  const [growOutArray, setGrowOutArray] = useState([]);
  const [directSellArray, setDirectSellArray] = useState([]);

  useEffect(() => {
    if (visible) {
      findGrowOut({ status: "New" }).then((res) => {
        if (res.status === "success") {
          setGrowOutOption(res.data);
        }
      });

      form.setFieldsValue({
        stockInTimestamp: moment(),
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        const growOutRecord = growOutArray.filter((item) => item.quantity && item.quantity > 0);
        const directSellRecord = directSellArray.filter((item) => item.quantity && item.quantity > 0);

        if (growOutRecord.length <= 0 && directSellRecord.length <= 0) {
          setError(true);
          return;
        }
        setError(false);

        const params = {
          stockInTimestamp: moment(values.stockInTimestamp).format(),
          growOutArray: growOutRecord,
          directSellArray: directSellRecord,
        };

        setSubmitting(true);

        harvest(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
            setGrowOutArray([]);
            setDirectSellArray([]);
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onGrowOutChange = (value, item) => {
    const index = growOutArray.map((item) => item.growOutId).indexOf(item.id);

    if (index > -1) {
      const newArray = [...growOutArray];
      newArray[index] = {
        growOutId: item.id,
        quantity: value,
      };
      setGrowOutArray(newArray);
    } else {
      setGrowOutArray([...growOutArray, { growOutId: item.id, quantity: value }]);
    }
  };

  const onDirectSellChange = (value, item) => {
    const index = directSellArray.map((item) => item.type).indexOf(item);

    if (index > -1) {
      const newArray = [...directSellArray];
      newArray[index] = {
        type: item,
        quantity: value,
      };
      setDirectSellArray(newArray);
    } else {
      setDirectSellArray([...directSellArray, { type: item, quantity: value }]);
    }
  };

  return (
    <Modal
      title={"Nursery Harvest"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} labelAlign="left">
        <Form.Item name="stockInTimestamp" label="Timestamp" rules={[{ required: true }]}>
          <DatePicker allowClear />
        </Form.Item>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Grow Out" key="growOut">
            {growOutOption.map((item) => (
              <Form.Item
                label={item.Pond ? item.Pond.label : "-"}
                extra={"Cycle " + item.cycleNo + ", " + item.material}
              >
                <InputNumber
                  inputMode="numeric"
                  min={0}
                  allowClear
                  onChange={(value) => onGrowOutChange(value, item)}
                />
              </Form.Item>
            ))}
          </TabPane>

          <TabPane tab="Direct Sell" key="directSell">
            <Form.Item label="Nursery Qty">
              <InputNumber min={0} allowClear onChange={(value) => onDirectSellChange(value, "Nursery")} />
            </Form.Item>
          </TabPane>
        </Tabs>

        {error && <Text type="danger">Please input at least one Stock In quantity </Text>}
      </Form>
    </Modal>
  );
};

export default NurseryHarvestModal;
