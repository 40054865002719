import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Card, Tag, Space, Empty, Divider } from "antd";
import { ReloadOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { usePond } from "../../hooks/usePond";
import PondDrawer from "./components/PondDrawer";
import EditPondModal from "../../components/pond/EditPondModal";
import NewPondModal from "../../components/pond/NewPondModal";
import { useFarm } from "../../hooks/useFarm";
import moment from "moment";

const { Title, Text } = Typography;

const Pond = () => {
  const { t } = useTranslation();

  const { findAll } = usePond();
  const { findAll: findFarm } = useFarm();

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const [filterFarm, setFilterFarm] = useState("All");
  const [farmData, setFarmData] = useState([]);
  const [data, setData] = useState([]);
  const [editItem, setEditItem] = useState({});
  const [pondTypeTotal, setPondTypeTotal] = useState({});

  const [pondDrawer, setPondDrawer] = useState(false);
  const [newPondModal, setNewPondModal] = useState(false);
  const [editPondModal, setEditPondModal] = useState(false);

  useEffect(() => {
    if (refresh) {
      setLoading(true);

      findFarm().then((res) => {
        if (res.status === "success") {
          setFarmData(res.data);
        }
      });

      findAll({
        status: "All",
        farmId: filterFarm,
      }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [refresh, findAll, t, filterFarm, findFarm]);

  useEffect(() => {
    if (data && data.length) {
      const pondTypeTotal = {};
      data.forEach((item) => {
        if (item.pondTypes && item.pondTypes.length) {
          item.pondTypes.forEach((pondType) => {
            if (pondTypeTotal[pondType]) {
              pondTypeTotal[pondType] += 1;
            } else {
              pondTypeTotal[pondType] = 1;
            }
          });
        }
      });
      setPondTypeTotal(pondTypeTotal);
    }
  }, [data]);

  useEffect(() => {
    setRefresh(true);
  }, [filterFarm]);

  const edit = (row) => {
    setEditItem(row);
    setEditPondModal(true);
  };

  const viewDetail = (row) => {
    setEditItem(row);
    setPondDrawer(true);
  };

  return (
    <>
      <Row style={{ marginTop: 16 }} justify="space-between">
        <Col>
          <Space>
            <Button onClick={() => setFilterFarm("All")} type={filterFarm === "All" ? "primary" : null}>
              All
            </Button>
            {farmData.map((item) => (
              <Button
                key={item.id}
                onClick={() => setFilterFarm(item.id)}
                type={filterFarm === item.id ? "primary" : null}
              >
                {item.label}
              </Button>
            ))}
          </Space>
        </Col>
      </Row>

      <div className="site-layout-background" style={{ padding: 16, marginTop: 16 }}>
        <Title level={3}>Pond Management</Title>

        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Space wrap>
              <Tag color="blue">Total Pond: {data.length}</Tag>
              <Tag>Running: {data.filter((item) => item.GrowOuts[0] && item.GrowOuts[0].startAt).length}</Tag>
              <Tag color={"error"}>
                Idle: {data.filter((item) => !item.GrowOuts[0] || !item.GrowOuts[0].startAt).length}
              </Tag>
              {Object.keys(pondTypeTotal).map((item) => (
                <Tag key={item}>
                  {item}: {pondTypeTotal[item]}
                </Tag>
              ))}
            </Space>
            <Space wrap></Space>
          </Col>
        </Row>

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <Button type="primary" disabled={loading} onClick={() => setNewPondModal(true)}>
              Add
            </Button>
          </Col>
          <Col>
            <Button icon={<ReloadOutlined />} disabled={loading} onClick={() => setRefresh(true)} />
          </Col>
        </Row>

        {data.length ? (
          <Row gutter={[16, 16]}>
            {data.map((item) => (
              <Col key={item.id} xs={12} sm={6}>
                <Card
                  size="small"
                  title={item.label}
                  extra={<Button type="link" icon={<EditOutlined />} onClick={() => edit(item)} />}
                >
                  <div onClick={() => viewDetail(item)}>
                    <Row style={{ textAlign: "center", color: "grey" }}>
                      <Col span={24}>
                        <div>
                          <Text style={{ color: "grey", fontSize: 10, fontStyle: "italic" }}>
                            {item.GrowOuts[0]?.cycleNo ? "Cycle " + item.GrowOuts[0]?.cycleNo : "-"}
                          </Text>
                        </div>
                        <div>
                          <Text style={{ color: "grey", fontSize: 18, textTransform: "capitalize" }}>
                            {item.GrowOuts[0] ? item.GrowOuts[0].material?.toLowerCase() : "-"}
                          </Text>
                        </div>
                        <div style={{ marginTop: 16 }}>
                          {item.pondTypes && item.pondTypes.map((pondType) => <Tag key={pondType}>{pondType}</Tag>)}
                        </div>
                      </Col>
                    </Row>

                    <Divider
                      style={{
                        background: item.GrowOuts[0] && item.GrowOuts[0].startAt ? null : "red",
                        height: 4,
                      }}
                    />

                    <Row justify="space-between" style={{ marginBottom: 10 }}>
                      <Col>
                        <Typography.Text type="secondary">DOC</Typography.Text>
                      </Col>
                      <Col>
                        {item.GrowOuts[0]
                          ? moment().startOf("day").diff(moment(item.GrowOuts[0].startAt).startOf("day"), "days", false)
                          : "-"}
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginBottom: 10 }}>
                      <Col>
                        <Typography.Text type="secondary">Stock Date</Typography.Text>
                      </Col>
                      <Col>{item.GrowOuts[0] ? moment(item.GrowOuts[0].startAt).format("YYYY-MM-DD") : "-"}</Col>
                    </Row>
                    <Row justify="space-between" style={{ marginBottom: 10 }}>
                      <Col>
                        <Typography.Text type="secondary">Stock Density (pcs)</Typography.Text>
                      </Col>
                      <Col>{item.GrowOuts[0] ? Number(item.GrowOuts[0].stockingDensity).toLocaleString() : "-"}</Col>
                    </Row>
                    <Row justify="space-between" style={{ marginBottom: 10 }}>
                      <Col>
                        <Typography.Text type="secondary">Accm. Feed (kg)</Typography.Text>
                      </Col>
                      <Col>
                        {item.GrowOuts[0]
                          ? Number(
                              item.GrowOuts[0].PondFeeds.map((item) => {
                                if (item.PondFeedDetails.length > 0) {
                                  return item.PondFeedDetails.reduce((a, b) => a + b.amount, 0);
                                }
                                return 0;
                              }).reduce((a, b) => a + b, 0)
                            ).toLocaleString()
                          : "-"}
                      </Col>
                    </Row>
                    <Row justify="space-between" style={{ marginBottom: 10 }}>
                      <Col>
                        <Typography.Text type="secondary">Accm. Harvest (pcs)</Typography.Text>
                      </Col>
                      <Col>
                        {item.GrowOuts[0]
                          ? Number(
                              item.GrowOuts[0].PondHarvests.reduce((a, b) => a + b.totalHarvestInPcs, 0)
                            ).toLocaleString()
                          : "-"}
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <Empty />
        )}

        <PondDrawer id={editItem.id} visible={pondDrawer} setVisible={setPondDrawer} />
      </div>

      <NewPondModal visible={newPondModal} setVisible={setNewPondModal} setRefresh={setRefresh} />

      <EditPondModal
        editItem={editItem}
        visible={editPondModal}
        setVisible={setEditPondModal}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default Pond;
