import React, { useState, useEffect } from "react";
import { Drawer, Descriptions, List } from "antd";
import moment from "moment";
import { useNurseryHapaFeed } from "../../hooks/useNurseryHapaFeed";

const NurseryHapaDrawer = (props) => {
  const { item, visible, setVisible } = props;

  const { findAll } = useNurseryHapaFeed();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    if ((visible || refresh) && item.id) {
      setLoading(true);
      findAll({ nurseryHapaId: item.id }).then((res) => {
        if (res.status === "success") {
          setData(res.data);
        }
        setLoading(false);
        setRefresh(false);
      });
    }
  }, [visible, refresh, item]);

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Drawer placement="bottom" onClose={onClose} visible={visible} height={"80%"}>
      <Descriptions style={{ marginBottom: 16 }}>
        <Descriptions.Item label="DOC">
          {item.stockDate ? moment().startOf("day").diff(moment(item.stockDate).startOf("day"), "days", false) : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Stock Date">{moment(item.stockDate).format("ll")}</Descriptions.Item>
        <Descriptions.Item label="Stock Qty">{Number(item.stockQuantityInPcs).toLocaleString()}</Descriptions.Item>
        <Descriptions.Item label="Harvest Date">
          {" "}
          {item.harvestDate
            ? moment().startOf("day").diff(moment(item.harvestDate).startOf("day"), "days", false)
            : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Harvest Qty">
          {item.harvestQuantityInPcs ? Number(item.harvestQuantityInPcs).toLocaleString() + " pcs" : "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Accm. Feed">{Number(item.runningFeedInKg).toFixed(3)} kg</Descriptions.Item>
        <Descriptions.Item label="Status">{item.status}</Descriptions.Item>
        <Descriptions.Item label="Remarks" span={3}>
          {item.remarks ? item.remarks : "-"}
        </Descriptions.Item>
      </Descriptions>

      <List
        loading={loading}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta title={item.feedType} description={moment(item.recordTimestamp).format("ll")} />
            <div>{item.remarks}</div>
            <div>{item.feedInKg} kg</div>
          </List.Item>
        )}
      />
    </Drawer>
  );
};

export default NurseryHapaDrawer;
