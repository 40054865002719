import React from "react";
import { DatePicker, Form, Select, Input } from "antd";
import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const AddNewForm = (props) => {
  const { form, incomingTransfer, setDetailData } = props;

  const onChange = (values) => {
    let array = [];

    for (const value of values) {
      const index = incomingTransfer.map((item) => item.id).indexOf(value);

      if (index > -1) {
        const row = incomingTransfer[index];

        form.setFieldsValue({
          vehicleNumber: row.vehicleNumber,
          personInCharge: row.personInCharge,
          remarks: row.remarks,
        });

        const orderDetailArray = row.DeliveryOrderDetails;

        if (orderDetailArray.length) {
          for (const item of orderDetailArray) {
            const idx = array.findIndex((el) => el.grade === item.grade && el.size === item.size);

            if (idx > -1) {
              array[idx].farmQuantity += item.quantity;
              array[idx].farmWeightInKg += item.weightInKg;
            } else {
              array.push({
                grade: item.grade,
                size: item.size,
                farmQuantity: item.quantity,
                farmWeightInKg: item.weightInKg,
                processingQuantity: null,
                processingWeightInKg: null,
                remarks: item.remarks,
              });
            }
          }
        }
      }
    }
    
    setDetailData(
      array.map((item, index) => {
        return {
          id: index,
          ...item,
        };
      })
    );
  };

  return (
    <Form {...layout} form={form} name="add-new-form" labelAlign="left">
      <Form.Item name="deliveryOrderId" label="From Farm">
        <Select allowClear onChange={onChange} mode="multiple">
          {incomingTransfer.map((item) => (
            <Option key={item.id} value={item.id}>
              Order No: {item.orderNo}, {moment(item.departTimestamp).format("YYYY-MM-DD")},{" "}
              {item.personInCharge ? item.personInCharge : "-"}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="arrivalTimestamp" label="Arrival Date" rules={[{ required: true }]}>
        <DatePicker allowClear />
      </Form.Item>

      <Form.Item name="vehicleNumber" label="Vehicle Number">
        <Input />
      </Form.Item>

      <Form.Item name="personInCharge" label="Delivered By">
        <Input />
      </Form.Item>

      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={5} allowClear />
      </Form.Item>
    </Form>
  );
};

export default AddNewForm;
