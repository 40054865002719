import React from "react";
import { Typography, Dropdown, Menu, Button, Space, Tag } from "antd";
import { EditOutlined, RollbackOutlined, EllipsisOutlined, BlockOutlined, PlusOutlined } from "@ant-design/icons";
import TableLoader from "../../common/TableLoader";
import moment from "moment";
import { incubationStatus } from "../../../utils/Status";
import IncubationParameterTable from "./IncubationParameterTable";

const { Link } = Typography;

const HatcheryReceiptTable = (props) => {
  const { dataSource, loading, error, editParameter, editParameterItem, editRow, editLarvalStockIn, markAsHatch } =
    props;

  const columns = [
    // {
    //   title: "DOC",
    //   render: (text, row) => moment().startOf("day").diff(moment(row.spawnDate).startOf("day"), "days", false),
    // },
    {
      title: "Batch #",
      dataIndex: "batchNo",
      render: (text, row) => (
        <Space>
          <Link onClick={() => editParameter(row)}>{text}</Link>
          {/* {row.antChainTraceCode && (
            <Button type="link" icon={<BlockOutlined />} href={row.antChainTraceCode} target="_blank" />
          )} */}
        </Space>
      ),
    },
    {
      title: "Broodstock",
      dataIndex: "hatcheryReceiptId",
      render: (text, row) => {
        if (row.HatcheryReceipt) {
          return row.HatcheryReceipt.label ? row.HatcheryReceipt.label : "-";
        } else {
          return "-";
        }
      },
    },
    {
      title: "Species",
      dataIndex: "hatcheryReceiptId",
      render: (text, row) => {
        if (row.HatcheryReceipt) {
          return row.HatcheryReceipt.species;
        } else {
          return "-";
        }
      },
    },
    // {
    //   title: "Tank",
    //   dataIndex: "hatcheryReceiptId",
    //   render: (text, row) => {
    //     if (row.HatcheryReceipt) {
    //       return row.HatcheryReceipt.location;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      title: "Spawn Date",
      dataIndex: "spawnDate",
      render: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Hatch Date",
      dataIndex: "hatchDate",
      render: (text, row) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
      excelRender: (text) => (text ? moment(text).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Zoea Quantity",
      dataIndex: "quantity",
      render: (text, row) => (text ? Number(text).toLocaleString() : "-"),
      excelRender: (text, row) => (text ? Number(text).toLocaleString() : "-"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => <Tag color={incubationStatus[text].color}>{text}</Tag>,
    },
    {
      title: "Action",
      render: (text, row) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="edit" icon={<EditOutlined />} onClick={() => editRow(row)}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="hatch"
                icon={<RollbackOutlined />}
                onClick={() => markAsHatch(row)}
                disabled={row.status !== "New"}
              >
                Hatch
              </Menu.Item>
              <Menu.Item
                key="tank"
                icon={<PlusOutlined />}
                onClick={() => editLarvalStockIn(row)}
                disabled={row.status !== "Hatched"}
              >
                Stock in Tank
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  return (
    <TableLoader
      error={error}
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      style={{ marginTop: 24 }}
      expandable={{
        expandedRowRender: (row) => (
          <IncubationParameterTable
            dataSource={row.IncubationParameters}
            loading={loading}
            error={error}
            editRow={editParameterItem}
            incubationRecord={row}
          />
        ),
        // expandedRowRender: (record) =>
        //   record.IncubationParameters &&
        //   record.IncubationParameters.map((item) => (
        //     <Row gutter={[16, 40]} key={item.id}>
        //       <Col span={1}>
        //         <EditOutlined style={{ color: blue.primary }} onClick={() => editParameterItem(item)} />
        //       </Col>
        //       <Col span={3}>
        //         <Text strong>DOC</Text> :{" "}
        //         <Text>
        //           {moment(item.timestamp).startOf("day").diff(moment(record.spawnDate).startOf("day"), "days", false)}
        //         </Text>
        //       </Col>
        //       <Col span={5}>
        //         <Text strong>Due To Hatch</Text> : <Text>{item.dueToHatch ? item.dueToHatch : "-"}</Text>
        //       </Col>
        //       <Col span={4}>
        //         <Text strong>E.M. Size</Text> : <Text>{item.emSize ? item.emSize : "-"}</Text>
        //       </Col>
        //       <Col span={5}>
        //         <Text strong>Stage</Text> : <Text>{item.stage ? item.stage : "-"}</Text>
        //       </Col>
        //       <Col span={6}>
        //         <Text strong>Remarks</Text> : <Text>{item.remarks ? item.remarks : "-"}</Text>
        //       </Col>
        //     </Row>
        //   )),
        rowExpandable: (record) => record.IncubationParameters !== null && record.IncubationParameters.length > 0,
      }}
    />
  );
};

export default HatcheryReceiptTable;
