import React, { useEffect, useState } from "react";
import { Modal, Form, Select, DatePicker, Input, InputNumber } from "antd";
import moment from "moment";
import { useFeedType } from "../../hooks/useFeedType";
import { useNurseryHapa } from "../../hooks/useNurseryHapa";
import { useNurseryHapaFeed } from "../../hooks/useNurseryHapaFeed";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const NewHapaFeedModal = (props) => {
  const { visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { findAll } = useNurseryHapa();
  const { findAll: findFeedType } = useFeedType();
  const { create } = useNurseryHapaFeed();

  const [submitting, setSubmitting] = useState(false);
  const [hapaOption, setHapaOption] = useState([]);
  const [feedTypeOption, setFeedTypeOption] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(),
      });
      findAll({
        status: "New",
      }).then((res) => {
        if (res.status === "success") {
          setHapaOption(res.data);
        }
      });
      findFeedType().then((res) => {
        if (res.status === "success") {
          setFeedTypeOption(res.data);
        }
      });
    }
  }, [visible]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);
        const params = {
          recordTimestamp: moment(values.recordTimestamp).format(),
          hapaIdArray: values.hapaIdArray,
          feedType: values.feedType,
          feedInKg: values.feedInKg,
          remarks: values.remarks,
        };
        create(params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };
  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="New HAPA Feed"
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <Form {...layout} form={form} name="hapa-feed-form">
        <Form.Item name="recordTimestamp" label="Date & Time" rules={[{ required: true }]}>
          <DatePicker allowClear showTime format={"YYYY-MM-DD h:mm a"} />
        </Form.Item>

        <Form.Item name="hapaIdArray" label="HAPA No." rules={[{ required: true }]} extra="Can select multiple">
          <Select mode="multiple">
            {hapaOption.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="feedType" label="Feed Type" rules={[{ required: true }]}>
          <Select>
            {feedTypeOption.map((item) => (
              <Select.Option key={item.id} value={item.name}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="feedInKg" label="Feed (KG)" rules={[{ required: true }]}>
          <InputNumber min={0} allowClear />
        </Form.Item>

        <Form.Item name="remarks" label="Remarks">
          <Input.TextArea allowClear rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewHapaFeedModal;
