import React, { useState, useEffect } from "react";
import { Typography, Button, Row, Col, Select, Form, Descriptions, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

import DailyRecordTable from "./tables/DailyRecordTable";
import moment from "moment";
import { useLarvalDataHistory } from "../../hooks/useLarvalDataHistory";

const { Title } = Typography;
const { Option } = Select;

const LarvalHistoryRecord = () => {
  const { t } = useTranslation();

  const { findInitialBatch, findLarvalData } = useLarvalDataHistory();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState();

  const [batchData, setBatchData] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState();
  const [selectedBatchInfo, setSelectedBatchInfo] = useState({});
  const [data, setData] = useState([]);

  const [artemiaUsage, setArtemiaUsage] = useState();
  const [oreganoUsage, setOreganoUsage] = useState();
  const [k5InGramUsage, setK5InGramUsage] = useState();
  const [mplusUsage, setMplusUsage] = useState();
  const [edtaUsage, setEdtaUsage] = useState();
  const [tystatinUsage, setTystatinUsage] = useState();
  const [cifroUsage, setCifroUsage] = useState();
  const [superMsUsage, setSuperMsUsage] = useState();
  const [shrimpUsage, setShrimpUsage] = useState();
  const [germFreeUsage, setGermFreeUsage] = useState();

  useEffect(() => {
    if (refresh && selectedBatch) {
      setLoading(true);
      findLarvalData(selectedBatch)
        .then((res) => {
          if (res.status === "success") {
            let array = [];
            for (const data of res.data.rows) {
              array.push({
                recordTimestamp: data.Tank.label,
                subheader: true,
              });
              for (const item of data.TankDailyRecords) {
                array.push(item);
              }
            }
            setData(array);
            setSelectedBatchInfo(res.data.info);
          }
          setLoading(false);
          setRefresh(false);
        })
        .catch(() => setError(t("error.connectionError")));
    }
  }, [refresh, findLarvalData, selectedBatch, t]);

  useEffect(() => {
    const array = [...data.filter((item) => !item.subheader)];

    setArtemiaUsage(
      array.map((item) => Number(item.artemiaFedInGram1) + Number(item.artemiaFedInGram2)).reduce((a, b) => a + b, 0)
    );
    setOreganoUsage(array.map((item) => item.oreganoInMl).reduce((a, b) => a + b, 0));
    setK5InGramUsage(array.map((item) => item.k5InGram).reduce((a, b) => a + b, 0));
    setMplusUsage(array.map((item) => item.mplus).reduce((a, b) => a + b, 0));
    setEdtaUsage(array.map((item) => item.edta).reduce((a, b) => a + b, 0));
    setTystatinUsage(array.map((item) => item.tystatin).reduce((a, b) => a + b, 0));
    setCifroUsage(array.map((item) => item.cifro).reduce((a, b) => a + b, 0));
    setSuperMsUsage(array.map((item) => item.superms).reduce((a, b) => a + b, 0));
    setShrimpUsage(array.map((item) => item.shrimpFavour).reduce((a, b) => a + b, 0));
    setGermFreeUsage(array.map((item) => item.germFree).reduce((a, b) => a + b, 0));
  }, [data]);

  const onStockDateChange = (value) => {
    if (!value) return;

    const date = moment(value).format("YYYY-MM");
    findInitialBatch(date).then((res) => {
      if (res.status === "success") {
        setBatchData(res.data);
      }
    });
  };

  const onBatchChange = (value) => {
    setSelectedBatch(value);
  };

  return (
    <>
      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Title level={2}>Larval Record History</Title>
        <Row justify="space-between">
          <Col>
            <Form layout="inline">
              <Form.Item label="Stocked Date">
                <DatePicker allowClear picker="month" onChange={onStockDateChange} />
              </Form.Item>
              <Form.Item label="Batch - Tank">
                <Select onChange={onBatchChange} style={{ width: 300 }} allowClear>
                  {batchData.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.batchNo + " - " + item.Tank.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col>
            <Button type="primary" disabled={loading} onClick={() => setRefresh(true)}>
              Search
            </Button>
          </Col>
        </Row>
      </div>

      <div className="site-layout-background" style={{ padding: 24, marginTop: 16 }}>
        <Descriptions title="Information" style={{ marginTop: 24, marginBottom: 16 }}>
          <Descriptions.Item label="Batch #">{selectedBatchInfo.batchNo ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="Broodstock #">{selectedBatchInfo.broodstockNo ?? "-"}</Descriptions.Item>
          <Descriptions.Item label="Stocked Quantity">
            {selectedBatchInfo.stockInQuantity ? Number(selectedBatchInfo.stockInQuantity).toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Spawn Date">
            {selectedBatchInfo.IncubationRecord
              ? moment(selectedBatchInfo.IncubationRecord?.spawnDate).format("YYYY-MM-DD")
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Hatch Date">
            {selectedBatchInfo.hatchDate ? moment(selectedBatchInfo.hatchDate).format("YYYY-MM-DD") : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Transferred From">
            {selectedBatchInfo.TankUsageSources && selectedBatchInfo.TankUsageSources.length
              ? selectedBatchInfo.TankUsageSources.map((item) => item.label).join(", ")
              : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Stock In Date">
            {selectedBatchInfo.stockInTimestamp ? moment(selectedBatchInfo.stockInTimestamp).format("YYYY-MM-DD") : "-"}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title="Total Artemia & Chemical Usage" style={{ marginTop: 24, marginBottom: 16 }}>
          <Descriptions.Item label="Artemia (g)">
            {artemiaUsage ? artemiaUsage.toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Oregano (ml)">
            {oreganoUsage ? oreganoUsage.toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="K-5 (g)">{k5InGramUsage ? k5InGramUsage.toLocaleString() : "-"}</Descriptions.Item>
          <Descriptions.Item label="M-Plus (g)">{mplusUsage ? mplusUsage.toLocaleString() : "-"}</Descriptions.Item>
          <Descriptions.Item label="EDTA (g)">{edtaUsage ? edtaUsage.toLocaleString() : "-"}</Descriptions.Item>
          <Descriptions.Item label="Tystatin (ml)">
            {tystatinUsage ? tystatinUsage.toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Cifro (tablets)">
            {cifroUsage ? cifroUsage.toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Super MS (ml)">
            {superMsUsage ? superMsUsage.toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Shrimp Favour (g)">
            {shrimpUsage ? shrimpUsage.toLocaleString() : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="GERM Free (tablets)">
            {germFreeUsage ? germFreeUsage.toLocaleString() : "-"}
          </Descriptions.Item>
        </Descriptions>

        <DailyRecordTable error={error} loading={loading} dataSource={data} batchInfo={selectedBatchInfo} />
      </div>
    </>
  );
};

export default LarvalHistoryRecord;
