import React, { useContext, useState } from "react";
import { Drawer, Row, Col, Button, Modal, Divider } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDeliveryOrder } from "../../../hooks/useDeliveryOrder";
import OrderDetailTable from "../tables/OrderDetailTable";
import OrderInfo from "../components/OrderInfo";
import { ResponsiveContext } from "../../../App";

const OrderDetailDrawer = (props) => {
  const { editItem, visible, setVisible, addDetail, setRefresh } = props;

  const { isMobile } = useContext(ResponsiveContext);
  const { deleteDetail } = useDeliveryOrder();

  const [deleteItem, setDeleteItem] = useState();
  const [confirmModal, setConfirmModal] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const deleteRow = (item) => {
    setDeleteItem(item);
    setConfirmModal(true);
  };

  const confirmDelete = () => {
    const params = {
      orderDetailId: deleteItem.id,
    };
    deleteDetail(editItem.id, params).then((res) => {
      if (res.status === "success") {
        setRefresh(true);
        setConfirmModal(false);
      }
    });
  };

  return (
    <Drawer
      // title="Delivery Order Details"
      placement="right"
      onClose={onClose}
      visible={visible}
      width={isMobile ? null : "80%"}
    >
      <OrderInfo item={editItem} />

      <Divider orientation="left">Order Items</Divider>

      <Row justify="end">
        <Col>
          <Button
            hidden={!addDetail}
            disabled={editItem.status !== "New"}
            onClick={addDetail}
            size="small"
            type="link"
            icon={<PlusOutlined />}
          >
            Add
          </Button>
        </Col>
      </Row>

      <OrderDetailTable
        item={editItem}
        detailData={editItem.DeliveryOrderDetails ? editItem.DeliveryOrderDetails : []}
        deleteRow={deleteRow}
      />

      <Modal visible={confirmModal} onOk={confirmDelete} onCancel={() => setConfirmModal(false)}>
        <p>Are you sure to delete this record?</p>
      </Modal>
    </Drawer>
  );
};

export default OrderDetailDrawer;
