import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { usePondParameter } from "../../hooks/usePondParameter";
import moment from "moment";
import { useParameterType } from "../../hooks/useParameterType";
import PondParameterForm from "./PondParameterForm";

const EditPondParameterModal = (props) => {
  const { editItem, visible, setVisible, setRefresh } = props;

  const [form] = Form.useForm();

  const { update } = usePondParameter();
  const { findAll: findParameterType } = useParameterType();

  const [submitting, setSubmitting] = useState(false);
  const [parameterOptionGroup1, setParameterOptionGroup1] = useState([]);
  const [parameterOptionGroup2, setParameterOptionGroup2] = useState([]);
  const [parameterTypeArray, setParameterTypeArray] = useState([]);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        recordTimestamp: moment(editItem.recordTimestamp),
        measureTime: moment(editItem.recordTimestamp),
        remarks: editItem.remarks,
      });
      findParameterType().then((res) => {
        if (res.status === "success") {
          let options = [...res.data];

          for (let option of options) {
            let defaultValue = 0;
            for (const item of editItem.PondParameterDetails) {
              if (item.parameterType === option.name && item.uom === option.uom) {
                defaultValue = item.amount;
              }
            }
            option.defaultAmount = defaultValue;
          }

          setParameterOptionGroup1(
            options.filter((item) => item.name.toLowerCase() === "do" || item.name.toLowerCase() === "temperature")
          );
          setParameterOptionGroup2(
            options.filter((item) => item.name.toLowerCase() !== "do" && item.name.toLowerCase() !== "temperature")
          );
        }
      });

      setParameterTypeArray(
        editItem.PondParameterDetails.map((item) => {
          return {
            name: item.parameterType,
            amount: item.amount,
            uom: item.uom,
          };
        })
      );
    } else {
      setParameterOptionGroup1([]);
      setParameterOptionGroup2([]);
    }
  }, [visible, form, editItem]);

  const handleOk = () => {
    form.validateFields().then((values, err) => {
      if (!err) {
        setSubmitting(true);

        const parameterTypeRecord = parameterTypeArray.filter((item) => item.amount && item.amount > 0);
        const date = moment(values.recordTimestamp).format("YYYY-MM-DD");
        const time = moment(values.measureTime).format("h:mm a");
        const datetime = `${date} ${time}`;

        const params = {
          recordTimestamp: moment(datetime, "YYYY-MM-DD h:mm a").format(),
          remarks: values.remarks,
          parameterTypeRecord: parameterTypeRecord,
        };

        update(editItem.id, params).then((res) => {
          if (res.status === "success") {
            setRefresh(true);
            setVisible(false);
            form.resetFields();
          }
          setSubmitting(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={"Edit Water Parameter"}
      destroyOnClose
      visible={visible}
      confirmLoading={submitting}
      onOk={handleOk}
      okText="Submit"
      onCancel={handleCancel}
    >
      <PondParameterForm
        form={form}
        parameterOptionGroup1={parameterOptionGroup1}
        parameterOptionGroup2={parameterOptionGroup2}
        parameterTypeArray={parameterTypeArray}
        setParameterTypeArray={setParameterTypeArray}
        isEdit
      />
    </Modal>
  );
};

export default EditPondParameterModal;
